// @flow
import React, {useMemo} from 'react';
import {useTranslation} from "react-i18next";
import {LOANS} from "../../../loans/locales/namespaces";
import {DataGridPremium} from '@mui/x-data-grid-premium';
import DocumentsGrid from "../customerLoans/documentsGrid";
import DataGridWrapper from "../../../../components/dataGridWrapper/dataGridWrapper";
import {muiGridLocales} from "../../../../helpers/muiGridLocales";
import {NumericFormat} from "react-number-format";
import useUserData from "../../../../hooks/user/useUserData";
import {textTranslate} from "../../../../helpers/helpers";

type Props = {};

const Loans = (props: Props) => {
    const {loading, loans} = props
    const {t} = useTranslation(LOANS)
    const {language, thousandSeparator, decimalSeparator} = useUserData()
    const columns = useMemo(() => [
        /*  {
              field: 'Summary',
              headerName: t('Summary'),
              minWidth: 140,
              flex: 1,
          },*/
        {
            field: 'TypeEl',
            headerName: t('Loan Purpose'),
            minWidth: 100,
            flex: 1,
            renderCell: ({row}) => (textTranslate(language)({en: row.TypeEn, el: row.TypeEl}))
        },
        {
            field: 'StatusEl',
            headerName: t('Loan status'),
            minWidth: 100,
            flex: 1,
            renderCell: ({row}) => (textTranslate(language)({en: row.StatusEn, el: row.StatusEl}))
        },
        {
            field: 'RequestedAmount',
            headerName: t('Requested Amount'),
            minWidth: 100,
            flex: 1,
            renderCell: ({row}) => {
                return row.RequestedAmount ? <NumericFormat
                    thousandSeparator={thousandSeparator}
                    decimalSeparator={decimalSeparator}
                    displayType="text"
                    value={row.RequestedAmount}
                /> : 0
            }
        },
        {
            field: 'RealEstateValue',
            headerName: t('Real Estate Value'),
            minWidth: 100,
            flex: 1,
            renderCell: ({row}) => {
                return row.RealEstateValue ? <NumericFormat
                    thousandSeparator={thousandSeparator}
                    decimalSeparator={decimalSeparator}
                    displayType="text"
                    value={row.RealEstateValue}
                /> : 0
            }
        },
        /*{
            field: 'MonthlyPaymentDay',
            headerName: t('Monthly Payment Day'),
            minWidth: 100,
            flex: 1,
        },
        {
            field: 'StartDt',
            headerName: t('Start Date'),
            minWidth: 100,
            flex: 1,
            hide: true,
        },
        {
            field: 'Duration',
            headerName: t('Duration'),
            minWidth: 100,
            flex: 1,
            hide: true,
        },*/
        /*{
            field: 'Comments',
            headerName: t('Comments'),
            minWidth: 100,
            flex: 1,
            hide: true,
        },*/

    ], [t])

    const getDetailPanelContent = React.useCallback(
        ({row}) => <DocumentsGrid row={row}/>,
        [],
    );

    const getDetailPanelHeight = React.useCallback(() => 250, []);

    return (
        <DataGridWrapper
            sx={{
                mt: 1,
                maxWidth: '1000px',
                height: '455px'
            }}
        >
            <DataGridPremium
                sx={{
                    bgcolor: 'background.paper', ".MuiDataGrid-aggregationColumnHeaderLabel": {
                        display: "none"
                    }
                }}
                loading={loading}
                columns={columns}
                density={'compact'}
                rows={loans}
                getDetailPanelContent={getDetailPanelContent}
                rowThreshold={0}
                getDetailPanelHeight={getDetailPanelHeight}
                localeText={muiGridLocales(t)}
                disableAggregation={true}
                disableRowGrouping={true}
            />
        </DataGridWrapper>
    );
};

export default Loans