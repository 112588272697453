// @flow
import React, {Fragment, useState} from 'react';
import {FormikProvider} from "formik";
import {useTranslation} from "react-i18next";
import {PAGE_LOGIN} from "../../locales/pages/namespaces";
import {Google} from "@mui/icons-material";
import {
	Grid,
	OutlinedInput,
	Stack,
	FormHelperText,
	Link,
	Typography,
	useMediaQuery,
	Button,
	useTheme,
	InputAdornment,
	IconButton
} from "@mui/material";
import AnimateButton from "../../theme/mantis/components/@extended/animateButton";
import {ThemeMode} from "../../theme/mantis/config";
import {Capacitor} from "@capacitor/core";
import {GoogleLogin} from "@react-oauth/google";
import {GoogleAuth} from "@codetrix-studio/capacitor-google-auth";
import useUserAction from "../../hooks/user/useUserAction";
import useUserData from "../../hooks/user/useUserData";
import {getRouteUrl} from "../../helpers/getRouteUrl";
import {ROUTE_PAGE_SIGN_UP} from "../../routers/routes";
import {Link as RouterLink} from 'react-router-dom'
import ResetPassModal from "../../pages/login/resetPassModal";
import VisibleEyeOff from "../../assets/images/icons/login/visibleEyeOff";
import VisibleEyeOn from "../../assets/images/icons/login/visibleEyeOn";
import EmailIcon from "../../assets/images/icons/login/emailIcon";
import PasswordIcon from "../../assets/images/icons/login/passwordIcon";
import ArrowDownCircle from "../../assets/images/icons/buttonsIcons/arrowDownCircle";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
// import {SignInWithApple} from "@capacitor-community/apple-sign-in";
// import AppleIcon from '@mui/icons-material/Apple';

type Props = {
	formik: Object
};

export const LoginForm = ({formik, loading, handleBiometricLogin, isLoginOneTime}: Props) => {
	const {t} = useTranslation(PAGE_LOGIN)
	const {googleLogin} = useUserAction()
	const {language} = useUserData()
	const theme = useTheme();
	const [showPassword, setShowPassword] = useState(false);
	const [resetPasswordOpen, setResetPasswordOpen] = useState(false);
	const handleResetPasswordOpen = (open) => setResetPasswordOpen(open)
	const handleClickShowPassword = () => setShowPassword((show) => !show);
	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};
	
	async function googleNativeLogin() {
		const result = await GoogleAuth.signIn();
		console.info('result', result);
		if (result) googleLogin({
			credential: result?.authentication?.idToken,
			clientId: '',
			select_by: ''
		})
	}
	
	// async function appleSignIn() {
	// 	try {
	// 		const result = await SignInWithApple.authorize();
	// 		console.log('apple result',result)
	// 		// result contains user info and identity token
	// 	} catch (error) {
	// 		console.error('apple error',error)
	// 	}
	// }
	
	const matchDownMD = useMediaQuery((theme) => theme.breakpoints.down('md'));
	
	return (
		<Fragment>
			<style>
				{`input:-webkit-autofill {-webkit-box-shadow: 0 0 0 1000px ${theme.palette.mode === ThemeMode.LIGHT ? theme.palette.primary.main : '#1E1E1E'} inset !important;}`}
			</style>
			<FormikProvider value={formik}>
				<form onSubmit={formik.handleSubmit} noValidate>
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<Stack spacing={1}>
								{/* <FormLabel htmlFor="username">{t("Email")}</FormLabel>*/}
								<OutlinedInput
									id="username"
									value={formik.values.username}
									name="username"
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									placeholder={t("Email")}
									fullWidth
									error={Boolean(formik.touched.username && formik.errors.username)}
									autoComplete={'username'}
									startAdornment={
										<InputAdornment
											position="start"
											sx={{bgColor: '#FFFFFF'}}>
											<EmailIcon/>
										</InputAdornment>
									}
								/>
								{formik.touched.username && formik.errors.username && (
									<FormHelperText error id="standard-weight-helper-text-email-login">
										{formik.errors.username}
									</FormHelperText>
								)}
							</Stack>
						</Grid>
						<Grid item xs={12}>
							<Stack spacing={1}>
								{/*<FormLabel htmlFor="password">{t("Password")}</FormLabel>*/}
								<OutlinedInput
									fullWidth
									error={Boolean(formik.touched.password && formik.errors.password)}
									id="password"
									type={showPassword ? 'text' : 'password'}
									value={formik.values.password}
									name="password"
									onBlur={formik.handleBlur}
									onChange={formik.handleChange}
									autoComplete={'current-password'}
									startAdornment={
										<InputAdornment position="start">
											<PasswordIcon/>
										</InputAdornment>
									}
									endAdornment={
										<InputAdornment position="end">
											<IconButton
												aria-label="toggle password visibility"
												onClick={handleClickShowPassword}
												onMouseDown={handleMouseDownPassword}
												edge="end"
												color={theme.palette.primary['100']}
											>
												{showPassword ? <VisibleEyeOff/> : <VisibleEyeOn/>}
											</IconButton>
										</InputAdornment>
									}
									placeholder={t("Password")}
								/>
								{formik.touched.password && formik.errors.password && (
									<FormHelperText error id="standard-weight-helper-text-password-login">
										{formik.errors.password}
									</FormHelperText>
								)}
							</Stack>
						</Grid>
						{/*TODO::FOR THE SECOND VERSION OF THE APP*/}
						<Grid item xs={12}>
							<Stack spacing={1}>
								{Capacitor.isNativePlatform() ? (
									<Button onClick={googleNativeLogin} size={'large'}>
										<Google/>{t("Sign in with Google")}
									</Button>
								) : (
									<GoogleLogin
										width={matchDownMD ? '280px' : '345px'}
										size="large"
										//shape="circle"
										onSuccess={credentialResponse => {
											googleLogin(credentialResponse)
										}}
										
										onError={(error) => {
											console.log('Login Failed:', error);
										}}
										useOneTap
										theme={'filled_white'}
										locale={language}
									/>
								)}
							</Stack>
						</Grid>
						{/*<Grid item xs={12}>
							<Stack spacing={1}>
								<Button onClick={appleSignIn} size={'large'}>
									<AppleIcon/>{t("Sign in with Apple")}
								</Button>
							</Stack>
						</Grid>*/}
						<Grid item xs={12} sx={{
							mt: -1,
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							gap: '20px'
						}}>
							<Link
								variant="h5"
								component={Button}
								onClick={() => handleResetPasswordOpen(true)}
								color="text.primary"
							>
								{t("Forgot password?")}
							</Link>
							{/*TODO::FOR THE SECOND VERSION OF THE APP*/}
							<Typography variant="h5" color={theme.palette.black[30]} component="span">
								{t('Don\'t have an account?')}{' '}
								<Link
									component={RouterLink}
									variant="h5"
									to={getRouteUrl(ROUTE_PAGE_SIGN_UP)}
									underline="hover"
									color="textPrimary"
								>
									{t('Sign Up')}
								</Link>
							</Typography>
						</Grid>
						<Grid item xs={12} textAlign={'center'}>
							<AnimateButton type={'slide'}>
								<Button
									disableElevation
									disabled={loading}
									//fullWidth
									type="submit"
									sx={{gap: '20px', p: '17px 54px'}}
									size={"small"}
									variant={"contained"}
									color={'info'}
								>
									<ArrowDownCircle/>{t('Login')}
								</Button>
							</AnimateButton>
						</Grid>
						{Capacitor.isNativePlatform() && isLoginOneTime &&
							<>`
								<Grid item xs={12} textAlign={'center'}>
									{t('Sign in with your biometrics')}
								</Grid>
								<Grid item xs={12} textAlign={'center'}>
									<IconButton size={"large"} onClick={handleBiometricLogin}>
										<FingerprintIcon sx={{fontSize: 50}}/>
									</IconButton>
								</Grid>
							</>
						}
					</Grid>
					<ResetPassModal
						open={resetPasswordOpen}
						onClose={() => handleResetPasswordOpen(false)}
					/>
				</form>
			</FormikProvider>
		</Fragment>
	);
};