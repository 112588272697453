import * as React from 'react';
import axios from "axios";
import {API} from "../../../helpers/constants";
import useUserData from "../../../hooks/user/useUserData";
import {handleAxiosErrors, logoutUnauthorizedUser} from "../../../helpers/errorHandling";
import {ALERTS} from "../../../locales/components/namespaces";
import {useTranslation} from "react-i18next";
import {enqueueSnackbar} from "notistack";
import {useFormik} from "formik";
import useUserAction from "../../../hooks/user/useUserAction";
import * as yup from "yup";
import {useState} from "react";

const useInterestRateForm = ({interests}) => {
	const {token, isLoggedIn} = useUserData()
	const {userLogout} = useUserAction()
	const {t} = useTranslation(ALERTS)
	const [isLoading, setIsLoading] = useState(false);
	
	const validationSchema = yup.object({
		NotificationDays: yup.number().required(t('Required')).positive(t('Value must be a positive number.')),
	})
	
	const onSubmit = (values) => {
		setIsLoading(true)
		axios({
			method: 'post',
			data: {
				Spread: values.Spread,
				Euribor: values.Euribor,
				StableInterest: values.StableInterest,
				RealEstateValue: values.RealEstateValue,
				RequestedAmount: values.LoanAmount,
				NotificationDays: values.NotificationDays,
			},
			headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
			url: `${API}/interests/update`
		}).then(response => {
			enqueueSnackbar(t('Interest Rates Updated Successfully!'), {
					variant: 'success'
				}
			)
			setIsLoading(false)
		}).catch((error) => {
			enqueueSnackbar(handleAxiosErrors(error, t('Failed to get interest rates.')), {
					variant: 'error'
				}
			)
			setIsLoading(false)
			logoutUnauthorizedUser(error, isLoggedIn, userLogout)
		})
	}
	
	const formik = useFormik({
		initialValues: {
			Spread: interests?.Spread || '',
			Euribor: interests?.Euribor || '',
			StableInterest: interests?.StableInterest || '',
			LoanAmount: interests?.LoanAmount || '',
			RealEstateValue: interests?.RealEstateValue || '',
			NotificationDays: interests?.NotificationDays || ''
			
		},
		onSubmit: onSubmit,
		validationSchema: validationSchema
	})
	
	return {
		formik,
		isLoading
	}
}

export default useInterestRateForm