import * as React from "react";
const UserCheckIcon = (props) => (
    <svg
        width={36}
        height={37}
        viewBox="0 0 36 37"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M24 32.227v-3a6 6 0 0 0-6-6H7.5a6 6 0 0 0-6 6v3m24-15 3 3 6-6m-21.75 3a6 6 0 1 0 0-12 6 6 0 0 0 0 12Z"
            stroke="#485776"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
export default UserCheckIcon;