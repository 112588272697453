import {useEffect, useState} from "react";
import axios from "axios";
import {API} from "../../../helpers/constants";
import useUserData from "../../../hooks/user/useUserData";
import {handleAxiosErrors, logoutUnauthorizedUser} from "../../../helpers/errorHandling";
import {ALERTS} from "../../../locales/components/namespaces";
import {useTranslation} from "react-i18next";
import {enqueueSnackbar} from "notistack";
import useUserAction from "../../../hooks/user/useUserAction";

const useCustomerStatus = () => {
	const {token, isLoggedIn} = useUserData()
	const {userLogout} = useUserAction()
	const {t} = useTranslation(ALERTS)
	const [loading, setLoading] = useState(false)
	const [custStatus, setCustStatus] = useState([])
	const [updateCustStatus, setUpdateCustStatus] = useState(false)
	
	useEffect(() => {
		setLoading(true)
		axios({
			method: 'get',
			headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
			url: `${API}/customer/status/list`,
		}).then((response) => {
			setCustStatus(response.data)
			setLoading(false)
		}).catch(error => {
			setLoading(false)
			enqueueSnackbar(handleAxiosErrors(error, t('Failed to get customer status list.')), {
					variant: 'error'
				}
			)
			logoutUnauthorizedUser(error, isLoggedIn, userLogout)
		})
	}, [updateCustStatus])
	
	const store = (newRow) => {
		axios({
			method: 'post',
			headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
			url: `${API}/customer/status/store`,
			data: newRow
		}).then(response => {
			setUpdateCustStatus(!updateCustStatus)
			enqueueSnackbar(t('New customer status added successfully!'), {
					variant: 'success'
				}
			)
		}).catch(error => {
			enqueueSnackbar(handleAxiosErrors(error, t('Failed to store new customer status!')), {
					variant: 'error'
				}
			)
			logoutUnauthorizedUser(error, isLoggedIn, userLogout)
		})
	}
	
	const update = (newRow, oldRow) => {
		axios({
			method: 'post',
			headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
			url: `${API}/customer/status/update`,
			data: newRow
		}).then(response => {
			setUpdateCustStatus(!updateCustStatus)
			enqueueSnackbar(t('Customer status updated successfully!'), {
					variant: 'success'
				}
			)
		}).catch(error => {
			enqueueSnackbar(handleAxiosErrors(error, t('Failed to update customer status!')), {
					variant: 'error'
				}
			)
			logoutUnauthorizedUser(error, isLoggedIn, userLogout)
		})
	}
	
	const remove = (id) => {
		axios({
			method: 'post',
			headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
			url: `${API}/customer/status/remove`,
			data: {
				id: id
			}
		}).then(response => {
			setUpdateCustStatus(!updateCustStatus)
			enqueueSnackbar(t('Customer status deleted successfully!'), {
					variant: 'success'
				}
			)
		}).catch(error => {
			enqueueSnackbar(handleAxiosErrors(error, t('Failed to delete customer status!')), {
					variant: 'error'
				}
			)
			logoutUnauthorizedUser(error, isLoggedIn, userLogout)
		})
	}
	
	return {
		loading,
		custStatus,
		store,
		update,
		remove
	}
}

export default useCustomerStatus