// @flow
import * as React from 'react';
import Form from "../../components/form/form"
import {Fragment} from "react";
import useDateFormats from "../../../customers/hooks/useDateFormats";
import useCountries from "../../hooks/useCountries";
import useCurrencies from "../../hooks/useCurrencies";
import useLanguages from "../../hooks/useLanguages";
import ContentLoader from "../../../../components/contentLoader/contentLoader";
import {useParams} from "react-router-dom";
import useUser from "../../hooks/useUser";
import {withPermissionHOC} from "../../../permission/components/permissionHOC/permissionHOC";
import useNationalities from "../../../customers/hooks/useNationalities";
import usePartners from "../../hooks/usePartners";

const Create = () => {
	let {id} = useParams()
	const {loading, dateFormats} = useDateFormats()
	const {loading: loadingCountries, countries} = useCountries()
	const {loading: loadingCurrencies, currencies} = useCurrencies()
	const {loading: loadingLanguages, languages} = useLanguages()
	const {loading: loadingNationalities, nationalities} = useNationalities()
	const {loading: loadingPartners, partners} = usePartners()
	const {loading: loadingUser, user, partner} = useUser({id})
	
	return !loading && !loadingCurrencies && !loadingCountries && !loadingLanguages && !loadingPartners && ((id && !loadingUser) || (!id)) ? (
		<Fragment>
			<Form
				id={id}
				user={user}
				nationalities={nationalities}
				countries={countries}
				dateFormats={dateFormats}
				currencies={currencies}
				languages={languages}
				partners={partners}
				partner={partner}
			/>
		</Fragment>
	) : (
		<ContentLoader
			loading={loading && loadingCurrencies && loadingCountries && loadingLanguages && loadingNationalities && loadingPartners}
		/>
	)
}

export default withPermissionHOC(Create, ['access_user_create', 'access_user_edit'])