import * as React from "react";
const UserCancelIcon = (props) => (
    <svg
        width={37}
        height={37}
        viewBox="0 0 37 37"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M24.5 32.227v-3a6 6 0 0 0-6-6H8a6 6 0 0 0-6 6v3m25.5-19.5 7.5 7.5m0-7.5-7.5 7.5m-14.25-3a6 6 0 1 0 0-12 6 6 0 0 0 0 12Z"
            stroke="#485776"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
export default UserCancelIcon;
