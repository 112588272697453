//@flow

// material-ui
import {styled, useTheme} from '@mui/material/styles';
import {Card, CardContent, Grid, Typography} from '@mui/material';

// styles
const IconWrapper = styled('div')({
    // position: 'absolute',
    // left: '-17px',
    // bottom: '-27px',
    // color: '#fff',
    // transform: 'rotate(25deg)',
    '& svg': {
        width: '36px',
        height: '36px',
        //opacity: '0.35'
    }
});

// =============================|| USER NUM CARD ||============================= //

const UserCountCard = ({primary, secondary, iconPrimary, color, borderColor}) => {
    const IconPrimary = iconPrimary;
    const primaryIcon = iconPrimary ? <IconPrimary fontSize="large"/> : null;
    const theme = useTheme();

    return (
        <Card elevation={0} sx={{
            borderRadius: '12px',
            border: borderColor,
            background: color,
            //display: 'flex',
            //padding: 16px;
            //flexDirection: 'column',
            //alignItems: 'flex-start',
            gap: '10px'
        }}
        >
            <CardContent>
                <IconWrapper>{primaryIcon}</IconWrapper>
                <Grid container direction="column" spacing={1}>
                    <Grid item sm={12}>
                        <Typography
                            variant="h5"
                            color={theme.palette.primary[60]}
                        >
                            {primary}
                        </Typography>
                    </Grid>
                    <Grid item sm={12}>
                        <Typography
                            variant="h4"
                            color={theme.palette.primary[100]}
                        >
                            {secondary}
                        </Typography>
                    </Grid>

                </Grid>
            </CardContent>
        </Card>
    );
};

// UserCountCard.propTypes = {
//   primary: PropTypes.string,
//   secondary: PropTypes.string,
//   iconPrimary: PropTypes.object,
//   color: PropTypes.string
// };

export default UserCountCard;
