// @flow
import * as React from 'react';
// material-ui
import {Grid, Stack, Typography} from '@mui/material';
import MainCard from "../../../theme/mantis/components/mainCard";

/*type Props = {
    title: string,
    displayValue: Number
};*/

/*const IconWrapper = styled('div')({
    position: 'absolute',
    left: '-17px',
    bottom: '-27px',
    color: '#fff',
    transform: 'rotate(25deg)',
    '& svg': {
        width: '100px',
        height: '100px',
        opacity: '0.35'
    }
});*/

// ===========================|| HOVER SOCIAL CARD ||=========================== //

export const CalculationKPI = ({primary, secondary, iconPrimary, color, symbol}) => {
    const IconPrimary = iconPrimary;
    const primaryIcon = iconPrimary ? <IconPrimary fontSize="large"/> : null;

    return (
        <MainCard
            elevation={0}
            sx={{
                position: 'relative',
                borderBottom: '2px solid #0BC1B6',
                boxShadow: '0px 4px 32px 0px rgba(61, 70, 112, 0.15)',
                height: '100%'
            }}
		>
            <Grid container
                  sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      gap: '10px',
                      flex: '1 0 0'
                  }}
            >
                <Grid item xs={12}>
                    <Typography variant="h2" style={{color}}>{primaryIcon}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Stack spacing={1}>
                        <Typography variant="h5" color="secondary">{secondary}</Typography>
                        <Typography variant="h4">{symbol}{primary}</Typography>
                    </Stack>
                </Grid>
            </Grid>
        </MainCard>
        /* <MainCard elevation={0}
                   sx={{
                       background: color,
                       position: 'relative',
                       borderColor: '#02767e',
                       color: '#fff',
                       height: '100%', ...customStyle
                   }}>

             <IconWrapper>{primaryIcon}</IconWrapper>
             <Grid container direction="column" alignItems="center" spacing={1}>
                 <Grid item sm={12}>
                     <Typography variant="h3" align="center" color="inherit">
                         {secondary}{symbol}
                     </Typography>
                 </Grid>
                 <Grid item sm={12}>
                     <Typography variant="body" align="center" color="inherit">
                         {primary}
                     </Typography>
                 </Grid>
             </Grid>

         </MainCard>*/
    );
};

