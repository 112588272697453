// @flow
import React from "react";
import {
	Box,
	Grid, List,
	ListItem,
	Stack,
	Typography,
	Button
} from "@mui/material";
import moment from "moment";
import {useHistory} from "react-router-dom";
import {getRouteUrl} from "../../../../helpers/getRouteUrl";
import {ROUTE_PAGE_LOANS_DETAILS} from "../../routes/routes";
import PermissionHOC from "../../../permission/components/permissionHOC/permissionHOC";
import {useTranslation} from "react-i18next";
import {LOANS} from "../../locales/namespaces";
import MainCard from "../../../../theme/mantis/components/mainCard";
import {textTranslate} from "../../../../helpers/helpers";
import useUserData from "../../../../hooks/user/useUserData";
import {NumericFormat} from "react-number-format";
import DocumentBlueIcon from "../../../../assets/images/icons/documentBlueIcon";
import ShowIcon from "../../../../assets/images/icons/buttonsIcons/showIcon";

const IndexListLoans = ({data}) => {
	const {t} = useTranslation(LOANS)
	const {language, thousandSeparator, decimalSeparator} = useUserData()
	const history = useHistory()
	
	return (
		<Box sx={{mb: 2}}>
			<PermissionHOC requiredPermissions={['access_loan_index_list']}>
				<Box sx={{display: 'block'}}>
					<Grid container spacing={2}>
						{data.map((item, index) => (
							<Grid item xs={12} sm={6} md={5} lg={4} key={index}>
								<MainCard sx={{
									mb: 2,
									border: '1px solid #485776',
									boxShadow: '0px 4px 32px 0px rgba(61, 70, 112,0.15 )',
									height: '100%',
									mt: 2
								}}>
									<List sx={{py: 0}}>
										<ListItem>
											<Grid container spacing={3}>
												<Grid item xs={12} md={12}>
													<Stack spacing={0.5}>
														<DocumentBlueIcon width={'25px'} height={'28px'}/>
													</Stack>
												</Grid>
											</Grid>
										</ListItem>
										<ListItem>
											<Grid container spacing={3}>
												<Grid item xs={12} md={12}>
													<Typography
														color="secondary"
														variant={'h6'}
													>
														{t('Loan amount (€)')}
													</Typography>
													<NumericFormat
														style={{
															fontSize: '16px',
															fontWeight: "bold"
														}}
														thousandSeparator={thousandSeparator}
														decimalSeparator={decimalSeparator}
														displayType="text"
														value={item.RequestedAmount ? item.RequestedAmount : '-'}
													/>
												</Grid>
											</Grid>
										</ListItem>
										<ListItem>
											<Grid container spacing={3}>
												<Grid item xs={12} md={12}>
													<Typography
														color="secondary"
														variant={'h6'}
													>
														{t('Approved amount (€)')}
													</Typography>
													<NumericFormat
														style={{
															fontSize: '16px',
															fontWeight: "bold"
														}}
														thousandSeparator={thousandSeparator}
														decimalSeparator={decimalSeparator}
														displayType="text"
														value={item.ApprovedAmount ? item.ApprovedAmount : '-'}
													/>
												</Grid>
											</Grid>
										</ListItem>
										<ListItem>
											<Grid container spacing={3}>
												<Grid item xs={12} md={12}>
													<Stack spacing={0.5}>
														<Typography
															color="secondary"
															variant='h6'
														>
															{t('Loan status')}
														</Typography>
														<Typography variant={'bodyMedium'}>
															{textTranslate(language)({
																en: item.StatusEn,
																el: item.StatusEl
															})}
														</Typography>
													</Stack>
												</Grid>
											</Grid>
										</ListItem>
										{/*<ListItem>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12} md={12}>
                                                    <Stack spacing={0.5}>
                                                        <Typography
                                                            color="secondary"
                                                            variant='h6'
                                                        >
                                                            {t('Property Commercial Value (€)')}
                                                        </Typography>
                                                        <NumericFormat
                                                            style={{
                                                                fontSize: '16px',
                                                                fontWeight: "bold"
                                                            }}
                                                            thousandSeparator={thousandSeparator}
                                                            decimalSeparator={decimalSeparator}
                                                            displayType="text"
                                                            value={item.RealEstateValue ? item.RealEstateValue : '-'}
                                                        />
                                                    </Stack>
                                                </Grid>
                                            </Grid>
                                        </ListItem>*/}
										<ListItem>
											<Grid container spacing={3}>
												<Grid item xs={12} md={12}>
													<Stack spacing={0.5}>
														<Typography
															color="secondary"
															variant='h6'
														>
															{t('Loan Purpose')}
														</Typography>
														<Typography variant={'bodyMedium'}>
															{textTranslate(language)({
																en: item.TypeEn,
																el: item.TypeEl
															})}
														</Typography>
													</Stack>
												</Grid>
											</Grid>
										</ListItem>
										<ListItem>
											<Grid container spacing={3}>
												<Grid item xs={12} md={12}>
													<Stack spacing={0.5}>
														<Typography
															color="secondary"
															variant='h6'
														>
															{t('Bank')}
														</Typography>
														<Typography variant={'bodyMedium'}>
															{item.Bank ? item.Bank : '-'}
														</Typography>
													</Stack>
												</Grid>
											</Grid>
										</ListItem>
										<ListItem>
											<Grid container spacing={3}>
												<Grid item xs={12} md={12}>
													<Stack spacing={0.5}>
														<Typography
															color="secondary"
															variant='h6'
														>
															{t('Created')}
														</Typography>
														<Typography variant={'bodyMedium'}>
															{moment(item.CreatedAt).format("DD-MM-YYYY")}
														</Typography>
													</Stack>
												</Grid>
											</Grid>
										</ListItem>
										<ListItem>
											<Grid container spacing={1} sx={{mt: 1, display: 'flex', gap: '30px'}}>
												<Grid item>
													<Typography>
														{/*<Tooltip title={t('View')}>*/}
														<Button
															variant="contained"
															size="small"
															color={'info'}
															sx={{display: 'flex', gap: '20px'}}
															onClick={() => history.push(getRouteUrl(ROUTE_PAGE_LOANS_DETAILS, {id: item.id}))}
														>
															{t('View')}<ShowIcon/>
														</Button>
														{/*  </Tooltip>*/}
													</Typography>
												</Grid>
											</Grid>
										</ListItem>
									
									</List>
									{/*<Typography>
                                        <Tooltip title={t('View')}>
                                            <IconButton
                                                variant="contained"
                                                size="small"
                                                component={Button}
                                                onClick={() => history.push(getRouteUrl(ROUTE_PAGE_LOANS_DETAILS, {id: item.id}))}
                                                sx={{float: "right", mr: 1, mt: 1, color: '#075c90'}}
                                            >
                                                <VisibilityIcon/>{t('View')}
                                            </IconButton>
                                        </Tooltip>
                                    </Typography>*/}
								</MainCard>
							</Grid>
						))}
					</Grid>
				</Box>
			</PermissionHOC>
		</Box>
	)
}

export default IndexListLoans