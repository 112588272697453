import * as React from 'react';
import {Typography, Breadcrumbs, Link, Toolbar} from '@mui/material';
import {Link as RouterLink} from "react-router-dom";

const BreadcrumbLink = ({to, children, iconComponent: IconComponent}) => {
	return (
		<Link
			component={RouterLink}
			to={to}
			underline="hover"
			sx={{display: 'flex', alignItems: 'center'}}
			color="inherit"
		>
			{IconComponent && <IconComponent sx={{mr: 0.5}} fontSize="inherit"/>}
			{children}
		</Link>
	)
}

const BreadcrumbItem = ({children, iconComponent: IconComponent, notFocused}) => {
	return (
		<Typography
			sx={{display: 'flex', alignItems: 'center'}}
			color={notFocused ? "inherit" : "text.primary"}
		>
			{IconComponent && <IconComponent sx={{mr: 0.5}} fontSize="inherit"/>}
			{children}
		</Typography>)
}
const Breadcrumb = ({children}) => {
	return (
		<Toolbar variant={"dense"} disableGutters pt={1}>
			<Breadcrumbs>
				{children}
			</Breadcrumbs>
		</Toolbar>
	)
}

Breadcrumb.Link = BreadcrumbLink
Breadcrumb.Item = BreadcrumbItem

export default Breadcrumb