import axios from "axios";
import {API} from "../../../helpers/constants";
import {useEffect, useState} from "react";
import useUserData from "../../../hooks/user/useUserData";
import {handleAxiosErrors, logoutUnauthorizedUser} from "../../../helpers/errorHandling";
import {useTranslation} from "react-i18next";
import {ALERTS} from "../../../locales/components/namespaces";
import {enqueueSnackbar} from "notistack";
import useUserAction from "../../../hooks/user/useUserAction";

const useLoan = ({id}) => {
    const {token, isLoggedIn} = useUserData()
    const {userLogout} = useUserAction()
    const {t} = useTranslation(ALERTS)
    const [loan, setLoan] = useState({})
    const [loading, setLoading] = useState(false)
    const [questionnaire, setQuestionnaire] = useState([])

    useEffect(() => {
        setLoading(true)
        axios({
            method: 'post',
            headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
            url: `${API}/loan/create/data`,
        }).then(response => {
            let data = response.data
            const questionnaire = data.questionnaire
            setQuestionnaire(questionnaire)
            setLoading(false)
        }).catch(error => {
            setLoading(false)
            enqueueSnackbar(handleAxiosErrors(error, t('Failed to get details.'), {
                variant: 'error'
            }))
            logoutUnauthorizedUser(error, isLoggedIn, userLogout)
        })
    }, [id])

    return {
        loading,
        loan,
        questionnaire
    }
}

export default useLoan;