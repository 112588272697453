// @flow
import React, {useMemo} from 'react';
import {useTranslation} from "react-i18next";
import {PARAMETERS} from "../../locales/namespaces";
import useRights from "../../hooks/useRights";
import DataGridWrapper from "../../../../components/dataGridWrapper/dataGridWrapper";
import EditableMUIDataGrid from "../../../../components/editableMUIDataGrid/editableMUIDataGrid";
import {withPermissionHOC} from "../../../permission/components/permissionHOC/permissionHOC";
import {Typography} from "@mui/material";
import MainCard from "../../../../theme/mantis/components/mainCard";
import EditableMUICellValidation from "../../../../components/editableMUICellValidation/editableMUICellValidation";
import {GridEditInputCell} from "@mui/x-data-grid-premium";
import ContentLoader from "../../../../components/contentLoader/contentLoader";

const Rights = () => {
	const {t} = useTranslation(PARAMETERS)
	const {
		loading,
		rights,
		store,
		remove
	} = useRights()
	
	const newRowModel = {
		Attribute: '',
		Description: ''
	}
	
	const columns = useMemo(() => [
		{
			field: 'Attribute',
			headerName: t('Attribute'),
			minWidth: 160,
			flex: 1,
			maxWidth: 220,
			editable: true,
			preProcessEditCellProps: (params) => {
				const inputValue = params.props.value.trim(); // Trim whitespace
				const inputMinLength = params.props.value.length < 3;
				const inputMaxLength = params.props.value.length > 50;
				if (inputValue === '') {
					return {...params.props, error: t('Required')};
				} else if (inputMinLength) {
					return {...params.props, error: t('Characters must be more than 3')};
				} else if (inputMaxLength) {
					return {...params.props, error: t('Length must be smaller than 50.')};
				}
				return {...params.props, error: null}; // No error
			},
			renderEditCell: (params) => {
				return <EditableMUICellValidation {...params}>
					<GridEditInputCell {...params} />
				</EditableMUICellValidation>
			},
		},
		{
			field: 'Description',
			headerName: t('Description'),
			minWidth: 160,
			flex: 1,
			editable: true,
			preProcessEditCellProps: (params) => {
				const inputValue = params.props.value.trim(); // Trim whitespace
				const inputMinLength = params.props.value.length < 3;
				const inputMaxLength = params.props.value.length > 50;
				if (inputValue === '') {
					return {...params.props, error: t('Required')};
				} else if (inputMinLength) {
					return {...params.props, error: t('Characters must be more than 3')};
				} else if (inputMaxLength) {
					return {...params.props, error: t('Length must be smaller than 50.')};
				}
				return {...params.props, error: null}; // No error
			},
			renderEditCell: (params) => {
				return <EditableMUICellValidation {...params}>
					<GridEditInputCell {...params} />
				</EditableMUICellValidation>
			},
		},
	], [rights, t])
	
	return !loading ? (
		<MainCard
			title={
				<Typography variant="h5" gutterBottom>
					{t('Rights')}
				</Typography>
			}
			sx={{mt: 2}}
		>
			<DataGridWrapper sx={{maxWidth: '600px'}}>
				<EditableMUIDataGrid
					loading={loading}
					data={rights}
					columns={columns}
					newRowModel={newRowModel}
					store={store}
					remove={remove}
					hideEditButton={true}
					dataGridProOtherProps={{
						disableAggregation: true,
						disableRowGrouping: true
					}}
				/>
			</DataGridWrapper>
		</MainCard>
	) : (
		<ContentLoader
			loading={loading}
		/>
	)
}

export default withPermissionHOC(Rights, ['access_parameters']);