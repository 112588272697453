import {Link} from 'react-router-dom';

// material-ui
import {ButtonBase, useMediaQuery} from '@mui/material';
import LogoIcon from "./logoIcon";
import LogoMain from "./logoMain";
import {ROUTE_PAGE_HOME} from "../../../../routers/routes";
import {getRouteUrl} from "../../../../helpers/getRouteUrl";

// project import


// ==============================|| MAIN LOGO ||============================== //

const LogoSection = ({reverse, isIcon, sx, to, isUnauthorizedCalc}) => {
	const isMobile = useMediaQuery('(max-width: 800px)');
	return (
		<ButtonBase disableRipple component={Link} to={getRouteUrl(ROUTE_PAGE_HOME)} sx={sx}>
			{isIcon ? (
				<LogoIcon/>
			) : (
				isUnauthorizedCalc ? (<LogoMain
					reverse={reverse}
					width={isMobile ? 145 : 145}
					height={isMobile ? 50 : 50}
				/>) : <LogoMain
					reverse={reverse}
					width={isMobile ? 145 : 145}
					height={isMobile ? 50 : 50}
				/>
			)}
		</ButtonBase>
	)
}

// LogoSection.propTypes = {
//   reverse: PropTypes.bool,
//   isIcon: PropTypes.bool,
//   sx: PropTypes.object,
//   to: PropTypes.string
// };

export default LogoSection;
