import * as React from "react";
const CalendarIcon = (props) => (
    <svg
        width={27}
        height={30}
        viewBox="0 0 29 33"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g
            stroke="#485776"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path d="M.97 12.833h26.737m-6.71 5.857h.014m-6.67 0h.014m-6.685 0h.014m13.313 5.83h.014m-6.67 0h.014m-6.685 0h.014M20.398 1.727v4.936M8.28 1.727v4.936" />
            <path
                clipRule="evenodd"
                d="M20.69 4.097H7.99c-4.406 0-7.157 2.454-7.157 6.965v13.574c0 4.582 2.751 7.092 7.156 7.092h12.688c4.418 0 7.156-2.468 7.156-6.979V11.063c.014-4.511-2.724-6.965-7.143-6.965Z"
            />
        </g>
    </svg>
);
export default CalendarIcon;