//@flow
import * as React from 'react'
import useUserData from "../../hooks/user/useUserData";
import {convertBlobToBase64, downloadFile} from "../../helpers/fileHandle";
import {Capacitor} from "@capacitor/core";
import {Directory, Filesystem} from "@capacitor/filesystem";
import {FileOpener} from "@capacitor-community/file-opener"

type Props = {
    url: string,
    filename: string,
    children: React.Node,
}

export const AuthenticatedLink = (props: Props) => {
    const {url, filename, disabled, children, ...otherProps} = props
    const {token} = useUserData()
    const headers = {'Authorization': `Bearer ${token}`}
    const handleAction = async () => {
        if (disabled) return
        const result = await fetch(url, {
            headers: headers
        })
        const blob = await result.blob()
        if (Capacitor.isNativePlatform()) {
            const base64 = await convertBlobToBase64(blob)
            const saveFile = await Filesystem.writeFile({
                path: filename,
                data: base64,
                directory: Directory.External
            })
            const path = saveFile.uri
            FileOpener.open({
                filePath: path,
                contentType: blob.type
            })
                .then(() => {
                    console.log('File is opened')
                })
                .catch((error) => {
                    console.log(error)
                })
        } else {
            const href = window.URL.createObjectURL(blob)
            downloadFile(href, filename);
        }
    }
    return (
        <a
            onClick={handleAction}
            {...otherProps}
        >
            {children}
        </a>
    )
}