import * as React from "react";
const ChartIcon = (props) => (
    <svg
        width={32}
        height={33}
        viewBox="0 0 32 33"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g
            stroke="#485776"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path d="M9.056 14.029v10.29m7.002-15.212V24.32m6.885-4.854v4.853" />
            <path
                clipRule="evenodd"
                d="M23.029 1.727H8.97C4.071 1.727 1 5.195 1 10.105v13.244c0 4.91 3.057 8.378 7.971 8.378H23.03c4.914 0 7.971-3.468 7.971-8.378V10.105c0-4.91-3.057-8.378-7.971-8.378Z"
            />
        </g>
    </svg>
);
export default ChartIcon;