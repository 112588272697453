export default function (theme) {
	return {
		MuiFormLabel: {
			styleOverrides: {
				root: {
					fontWeight: 600,
					fontSize: '12px',
					color: theme.palette.primary[100],
					fontStyle: 'normal',
					lineHeight: 'normal',
					'&.Mui-focused': {
						color: theme.palette.primary[100]
					},
				},
			}
		}
	}
}