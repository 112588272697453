// @flow
import * as React from 'react';
import Form from "../../components/form/form"
import {Fragment} from "react";
import useCountries from "../../../users/hooks/useCountries";
import useCurrencies from "../../../users/hooks/useCurrencies";
import useLanguages from "../../../users/hooks/useLanguages";
import useDateFormats from "../../hooks/useDateFormats";
import useNationalities from "../../hooks/useNationalities";
import ContentLoader from "../../../../components/contentLoader/contentLoader";
import {withPermissionHOC} from "../../../permission/components/permissionHOC/permissionHOC";

const Create = () => {
    const {loading, dateFormats} = useDateFormats()
    const {loading: loadingCountries, countries} = useCountries()
    const {loading: loadingNationalities, nationalities} = useNationalities()
    const {loading: loadingCurrencies, currencies} = useCurrencies()
    const {loading: loadingLanguages, languages} = useLanguages()

    return !loading && !loadingCurrencies && !loadingCountries && !loadingLanguages && !loadingNationalities ? (
        <Fragment>
            <Form
                countries={countries}
                nationalities={nationalities}
                currencies={currencies}
                languages={languages}
                dateFormats={dateFormats}
                isUser={false}
            />
        </Fragment>
    ) : (
        <ContentLoader
            loading={loading && loadingCurrencies && loadingCountries && loadingLanguages && loadingNationalities}
        />
    )
}

export default withPermissionHOC(Create, ['access_customer_create'])