import type {UnmappedUser, User} from "./types";

export const mapUser: User = (datum: UnmappedUser) => {
    if (!datum) return
    return {
        id: datum.userId,
        username: datum.username,
        loginName: datum.loginName,
        customerId: datum.customerId,
        language: {
            id: datum.languageId,
            code: datum.languageCode?.toLowerCase()
        },
    }
}