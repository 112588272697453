// @flow
import * as React from 'react';
import {Fragment} from "react";
import {useParams} from "react-router-dom";
import CalculationForm from "../../components/form/calculationForm";
import useCalculation from "../../hooks/useCalculation";
import useContribution from "../../hooks/useContribution";
import ContentLoader from "../../../../components/contentLoader/contentLoader";
import useRepayments from "../../hooks/useRepayments";
import {useLocation} from "react-router";
import Box from "@mui/material/Box";

const Create = () => {
    let {id} = useParams()
    const {contributions, interests, loading: loadingContributions} = useContribution()
    const {calculations, loading} = useCalculation({id})
    const {
        loading: repaymentsLoading,
        repayments,
        updateRepayments,
        handleUpdateRepayments,
        handleRemoveRepayment
    } = useRepayments({id})
    const location = useLocation()

    return !loading && !loadingContributions && (!id || (id && Object.keys(calculations).length > 0)) ? (
        <Fragment>
            {location.pathname === '/fast/calculation' ? (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: 'auto',
                        overflow: 'auto',
                        m: 2
                    }}
                >
                    <CalculationForm
                        id={id}
                        calculations={calculations}
                        contributions={contributions}
                        interests={interests}
                        repayments={repayments}
                        updateRepayments={updateRepayments}
                        handleUpdateRepayments={handleUpdateRepayments}
                        handleRemoveRepayment={handleRemoveRepayment}
                    />
                </Box>
            ) : (
                <CalculationForm
                    id={id}
                    calculations={calculations}
                    contributions={contributions}
                    interests={interests}
                    repayments={repayments}
                    updateRepayments={updateRepayments}
                    handleUpdateRepayments={handleUpdateRepayments}
                    handleRemoveRepayment={handleRemoveRepayment}
                />
            )}
        </Fragment>
    ) : (
        <ContentLoader
            loading={loading && loadingContributions && repaymentsLoading}
        />
    )
}

export default Create
// export default withPermissionHOC(Create, ['access_calculation_create'])