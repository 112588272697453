import {createContext} from 'react';

// project import
import config from "../config";
import useLocalStorage from "../hooks/useLocalStorage";

// initial state
const initialState = {
    ...config,
    onChangeContainer: () => {
    },
    onChangeLocalization: () => {
    },
    onChangeMode: () => {
    },
    onChangePresetColor: () => {
    },
    onChangeDirection: () => {
    },
    onChangeMiniDrawer: () => {
    },
    onChangeMenuOrientation: () => {
    },
    onChangeFontFamily: () => {
    }
};

// ==============================|| CONFIG CONTEXT & PROVIDER ||============================== //

const ConfigContext = createContext(initialState);

function ConfigProvider({children}) {
    const [config, setConfig] = useLocalStorage('mantis-react-js-config', initialState);

    const onChangeContainer = () => {
        setConfig({
            ...config,
            container: !config.container
        });
    };

    const onChangeLocalization = (lang) => {
        setConfig({
            ...config,
            i18n: lang
        });
    };

    const onChangeMode = (mode) => {
        setConfig({
            ...config,
            mode
        });
    };

    const onChangePresetColor = (theme) => {
        setConfig({
            ...config,
            presetColor: theme
        });
    };

    const onChangeDirection = (direction) => {
        setConfig({
            ...config,
            themeDirection: direction
        });
    };

    const onChangeMiniDrawer = (miniDrawer) => {
        setConfig({
            ...config,
            miniDrawer
        });
    };

    const onChangeMenuOrientation = (layout) => {
        setConfig({
            ...config,
            menuOrientation: layout
        });
    };

    const onChangeFontFamily = (fontFamily) => {
        setConfig({
            ...config,
            fontFamily
        });
    };

    return (
        <ConfigContext.Provider
            value={{
                ...config,
                onChangeContainer,
                onChangeLocalization,
                onChangeMode,
                onChangePresetColor,
                onChangeDirection,
                onChangeMiniDrawer,
                onChangeMenuOrientation,
                onChangeFontFamily
            }}
        >
            {children}
        </ConfigContext.Provider>
    );
}

export {ConfigProvider, ConfigContext};
