import {configureStore, applyMiddleware, compose} from '@reduxjs/toolkit';
import {persistReducer, persistStore} from 'redux-persist';
import storage from 'redux-persist/es/storage' // default: localStorage if web, AsyncStorage if react-native
import immutableTransform from 'redux-persist-transform-immutable';
import {createLogger} from 'redux-logger';
import Immutable from 'immutable';

import {routerMiddleware} from 'connected-react-router'
import {reducers} from './reducers/reducers';
import {createStateSyncMiddleware} from "redux-state-sync";
import axiosMiddleware from "./middleware/axios";
import history from './history';

const middleWare = [];
middleWare.push(axiosMiddleware)
middleWare.push(routerMiddleware(history));
middleWare.push(createStateSyncMiddleware({
	whitelist: [],
}))

const config = {
	transforms: [immutableTransform({
		whitelist: ['currentUser']
	})],
	key: 'rootIMS',
	whitelist: ['currentUser'], // state you want to persist
	// blacklist: [''], // state you do not want to persist
	storage,
}

if (process.env.NODE_ENV === `development`) {
	const logger = createLogger({
		stateTransformer: (state) => {
			let newState = {};
			for (let i of Object.keys(state)) {
				if (Immutable.Iterable.isIterable(state[i])) {
					newState[i] = state[i].toJS();
				} else {
					newState[i] = state[i];
				}
			}
			return newState;
		}
	});
	middleWare.push(logger);
}

const store = configureStore({
	reducer: persistReducer(config, reducers),
	middleware: middleWare
});

export const persistor = persistStore(store);

export default store