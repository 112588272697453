export const CHECK_API_CONNECTIVITY = 'CHECK_API_CONNECTIVITY';
export const CHECK_API_CONNECTIVITY_SUCCEEDED = 'CHECK_API_CONNECTIVITY_SUCCEEDED';
export const CHECK_API_CONNECTIVITY_FAILED = 'CHECK_API_CONNECTIVITY_FAILED';

export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGIN_SUCCEEDED = 'USER_LOGIN_SUCCEEDED';
export const USER_LOGIN_FAILED = 'USER_LOGIN_FAILED';

export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_LOGOUT_SUCCEEDED = 'USER_LOGOUT_SUCCEEDED';
export const USER_LOGOUT_FAILED = 'USER_LOGOUT_FAILED';

export const SET_USER_LANGUAGE = 'SET_USER_LANGUAGE';