// @flow
import * as React from 'react';
import {useEffect, useMemo, useRef, useState} from "react";
import {
	Grid, FormControl, FormHelperText, Select, Stack, MenuItem, TextField, Box,
	FormLabel, useTheme, FormControlLabel, Switch, Button, Divider, Typography, IconButton
} from "@mui/material";
import {ErrorMessage} from "formik";
import {TypeInterestRate, TypeInterestRateIds} from "../../../../parameters/parameters";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {CALCULATIONS} from "../../locales/namespaces";
import {ROUTE_PAGE_LOANS_CREATE} from "../../../loans/routes/routes";
import PermissionHOC from "../../../permission/components/permissionHOC/permissionHOC";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {getRouteUrl} from "../../../../helpers/getRouteUrl";
import {replaceDotWithComma, textTranslate} from "../../../../helpers/helpers";
import {NumericFormat} from "react-number-format";
import {CalculationKPI} from "../../partials/calculationKPI";
import ReportCard from "../../partials/reportCard";
import useUserData from "../../../../hooks/user/useUserData";
import ChartIcon from "../../../../assets/images/icons/kpisIcons/chartIcon";
import ActivityIcon from "../../../../assets/images/icons/kpisIcons/activityIcon";
import WalletIcon from "../../../../assets/images/icons/kpisIcons/walletIcon";
import CalendarIcon from "../../../../assets/images/icons/kpisIcons/calendarIcon";
import StarIcon from "../../../../assets/images/icons/kpisIcons/starIcon";
import ArrowDownCircle from "../../../../assets/images/icons/buttonsIcons/arrowDownCircle";
import ArrowDown2 from "../../../../assets/images/icons/arrowDown2";
import CustomPopover from "../popovers/customPopover";
import IconButtonInfoCircle from "../iconButtonInfoCircle/iconButtonInfoCircle";
import InfoCircle from "../../../../assets/images/icons/infoCircle";
import Polygon3Icon from "../../../../assets/images/icons/polygon3Icon";
import '../../components/popovers/customPopover.css'
import {Capacitor} from "@capacitor/core";

const InputsCalculation = (props) => {
	const {t} = useTranslation(CALCULATIONS)
	const {language, decimalSeparator, thousandSeparator} = useUserData()
	const theme = useTheme();
	
	const {
		formik,
		handleCalculationsRealEstateValue,
		handleCalculationsLoanAmount,
		handleInterestType,
		handleCalculateInstallments,
		contributions,
		handleDate,
		location,
		id,
		
		rowsFixed,
		rowsFloat,
		float,
		fixed,
		TotalDuration,
		InstallmentNumber,
		handleInterestedLoanUnAuth,
		handleClick,
		
		handlePopoverId,
		/*handleAnchorElClick,
		openAnchorEl,
		anchorEl,
		handleAnchorElClose,
		handlePopoverId,
		popoverId*/
		isOpenDesc,
		setIsOpenDesc
	} = props
	
	const interestTypeList = useMemo(() => (
		TypeInterestRate.map(item => (
			Capacitor.getPlatform() === 'android' ?
				<option
					key={`TypeInterestRate-${item.id}`}
					value={item.id}
				>
					{textTranslate(language)({
						en: item.DescriptionEn,
						el: item.DescriptionEl
					})}
				</option> :
				<MenuItem
					key={`TypeInterestRate-${item.id}`}
					value={item.id}
				>
					{textTranslate(language)({
						en: item.DescriptionEn,
						el: item.DescriptionEl
					})}
				</MenuItem>
		))
	), [TypeInterestRate, language])
	
	const contributionList = useMemo(() => (
		contributions.map(item => (
			Capacitor.getPlatform() === 'android' ?
				<option
					key={`Contribution-${item.id}`}
					value={item.id}
				>
					{replaceDotWithComma(item.Value.toString(), language)}
				</option> :
				<MenuItem
					key={`Contribution-${item.id}`}
					value={item.id}
				>
					{replaceDotWithComma(item.Value.toString(), language)}
				</MenuItem>
		))), [contributions, language])
	
	
	const totalInterest =
		Number(formik.values.InterestType) === TypeInterestRateIds.Fixed ?
			Number(formik.values.FixedInterestRate) + (contributions.length > 0 && contributions.find(f => f.id === formik.values.Contribution).Value) :
			Number(formik.values.InterestType) === TypeInterestRateIds.Floating ?
				Number(formik.values.Spread) + Number(formik.values.Euribor) + (contributions.length > 0 && contributions.find(f => f.id === formik.values.Contribution).Value)
				: Number(formik.values.FixedInterestRate) + (contributions.length > 0 && contributions.find(f => f.id === formik.values.Contribution).Value)
	
	const floatPopover = Number(formik.values.InterestType) === TypeInterestRateIds.Floating || Number(formik.values.InterestType) === TypeInterestRateIds.Hybrid
	
	/*const [isOpenDesc, setIsOpenDesc] = useState(false);*/
	const [isOpenRev, setIsOpenRev] = useState(false);
	const [isOpenLoan, setIsOpenLoan] = useState(false);
	const [isOpenInterest, setIsOpenInterest] = useState(false);
	const [isOpenTax, setIsOpenTax] = useState(false);
	const [isOpenSpread, setIsOpenSpread] = useState(false);
	const [isOpenEuribor, setIsOpenEuribor] = useState(false);
	const [isOpenLtv, setIsOpenLtv] = useState(false);
	
	const handleNext = () => {
		if (isOpenDesc) {
			setIsOpenDesc(false)
			setIsOpenRev(true)
			handlePopoverId('rev')
		} else if (isOpenRev) {
			setIsOpenRev(false)
			setIsOpenLoan(true)
			handlePopoverId('loan')
		} else if (isOpenLoan) {
			setIsOpenLoan(false)
			setIsOpenInterest(true)
			handlePopoverId('interest')
		} else if (isOpenInterest) {
			setIsOpenInterest(false)
			setIsOpenTax(true)
			handlePopoverId('tax')
		} else if (isOpenTax && floatPopover) {
			setIsOpenTax(false)
			setIsOpenSpread(true)
			handlePopoverId('spread')
		} else if (isOpenTax && !floatPopover) {
			setIsOpenTax(false)
			setIsOpenLtv(true)
			handlePopoverId('ltv')
		} else if (isOpenSpread) {
			setIsOpenSpread(false)
			setIsOpenEuribor(true)
			handlePopoverId('euribor')
		} else if (isOpenEuribor) {
			setIsOpenEuribor(false)
			setIsOpenLtv(true)
			handlePopoverId('ltv')
		}
	}
	
	const popoverRef = useRef(null);
	
	const togglePopover = () => {
		setIsOpenLtv(!isOpenLtv);
	};
	
	const handleClickOutside = (event) => {
		if (popoverRef.current && !popoverRef.current.contains(event.target)) {
			setIsOpenLtv(false);
		}
	};
	
	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);
	
	return (
		<Grid sx={{flexGrow: 1}} container spacing={2}>
			<Grid item xs={12} sm={12} md={6} lg={6}>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={12} md={12} lg={12}>
						<Grid container spacing={3} sx={{mr: 2}}>
							<Grid item xs={12} sm={12} md={12} lg={12}>
								<Stack spacing={1}>
									<FormControl
										variant="standard"
										fullWidth
										error={
											formik.touched["Description"] &&
											Boolean(formik.errors["Description"])
										}
									>
										<CustomPopover
											id={'description'}
											title={t('Scenario description')}
											content={t('Fill in a characteristic title for your calculation (e.g. Buy a holiday home)')}
											numPage={floatPopover ? '1/8' : '1/6'}
											isOpen={isOpenDesc}
											setIsOpen={setIsOpenDesc}
											handleNext={handleNext}
										/>
										<TextField
											name={`Description`}
											value={formik.values.Description}
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											color={"grey"}
											size={'small'}
											fullWidth
											inputProps={{maxLength: 100}}
										/>
										<FormHelperText>
											<ErrorMessage name={"Description"}/>
										</FormHelperText>
									</FormControl>
								</Stack>
							</Grid>
							<Grid item xs={12} sm={12} md={6} lg={6}>
								<Stack spacing={1}>
									<FormControl
										variant="standard"
										fullWidth
										error={
											formik.touched["RealEstateValue"] &&
											Boolean(formik.errors["RealEstateValue"])
										}
									>
										<CustomPopover
											id={'rev'}
											title={t('Property Commercial Value (€)')}
											content={t('The commercial value of a property refers to the value of a property as determined by market conditions in the specific time period.')}
											numPage={floatPopover ? '2/8' : '2/6'}
											isOpen={isOpenRev}
											setIsOpen={setIsOpenRev}
											handleNext={handleNext}
										/>
										<NumericFormat
											value={formik.values.RealEstateValue}
											onValueChange={(values, sourceInfo) => {
												handleCalculationsRealEstateValue({
													target: {
														name: 'RealEstateValue',
														value: values.floatValue ? values.floatValue : '',
													},
												}, 'RealEstateValue')
											}}
											thousandSeparator={thousandSeparator}
											decimalSeparator={decimalSeparator}
											decimalScale={2}
											customInput={TextField}
											
											onBlur={formik.handleBlur}
											name={`RealEstateValue`}
											color={"grey"}
											size={'small'}
											fullWidth
											inputProps={{maxLength: 11, step: 1000}}
											error={formik.touched.RealEstateValue && Boolean(formik.errors.RealEstateValue)}
										/>
										<FormHelperText>
											<ErrorMessage name={"RealEstateValue"}/>
										</FormHelperText>
									</FormControl>
								</Stack>
							</Grid>
							<Grid item xs={12} sm={12} md={6} lg={6}>
								<Stack spacing={1}>
									<FormControl
										variant="standard"
										fullWidth
										error={
											formik.touched["LoanAmount"] &&
											Boolean(formik.errors["LoanAmount"])
										}
									>
										<CustomPopover
											id={'loan'}
											title={t('Loan amount (€)')}
											content={t('The loan amount I wish to request from the bank')}
											numPage={floatPopover ? '3/8' : '3/6'}
											isOpen={isOpenLoan}
											setIsOpen={setIsOpenLoan}
											handleNext={handleNext}
										/>
										<NumericFormat
											value={formik.values.LoanAmount}
											onValueChange={(values, sourceInfo) => {
												handleCalculationsLoanAmount({
													target: {
														name: 'LoanAmount',
														value: values.floatValue ? values.floatValue : '',
													},
												}, 'LoanAmount')
											}}
											thousandSeparator={thousandSeparator}
											decimalSeparator={decimalSeparator}
											decimalScale={2}
											customInput={TextField}
											
											required
											onBlur={formik.handleBlur}
											name={`LoanAmount`}
											color={"grey"}
											size={'small'}
											fullWidth
											inputProps={{maxLength: 11, step: 1000}}
											error={formik.touched.LoanAmount && Boolean(formik.errors.LoanAmount)}
										/>
										<FormHelperText>
											<ErrorMessage name={"LoanAmount"}/>
										</FormHelperText>
									</FormControl>
								</Stack>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12}>
						<Grid container spacing={3} sx={{mr: 2}}>
							<Grid item xs={12} sm={12} md={6} lg={6}>
								<Stack spacing={1}>
									<FormControl
										variant="outlined"
										fullWidth
										error={
											formik.touched["InterestType"] &&
											Boolean(formik.errors["InterestType"])
										}
									>
										<CustomPopover
											id={'interestType'}
											title={t('Interest rate type')}
											content={<>
												<Typography variant={'h5'}
															color={'#fff'}>{t('interest_type')}</Typography>
												<Typography variant={'h5'} color={'#fff'}
															fontWeight={'bold'}>{t('- Fixed interest rate,')}</Typography>
												<Typography variant={'h5'}
															color={'#fff'}>{t('which remains unchanged for an agreed period of time.')}</Typography>
												<Typography variant={'h5'} color={'#fff'}
															fontWeight={'bold'}>{t('- Floating interest rate,')}</Typography>
												<Typography variant={'h5'}
															color={'#fff'}>{t('which is linked to euribor, plus a margin (spread), which is "locked in" when the contract is signed.')}</Typography>
												<Typography variant={'h5'} color={'#fff'}
															fontWeight={'bold'}>{t('- Fixed and Floating,')}</Typography>
												<Typography variant={'h5'}
															color={'#fff'}>{t('which is initially fixed for some years and floating for the remaining duration (e.g. first 5 years fixed and then floating).')}</Typography>
											</>}
											numPage={floatPopover ? '4/8' : '4/6'}
											isOpen={isOpenInterest}
											setIsOpen={setIsOpenInterest}
											handleNext={handleNext}
											isType={true}
										/>
										<Select
											native={Capacitor.getPlatform() === 'android'}
											labelId="InterestType-label"
											autoComplete={"chrome-off"}
											size={"small"}
											name={"InterestType"}
											value={formik.values.InterestType}
											onChange={(event) => {
												handleInterestType(event);
											}}
											onBlur={formik.handleBlur}
											IconComponent={ArrowDown2}
										>
											{interestTypeList}
										</Select>
										<FormHelperText>
											<ErrorMessage name={"InterestType"}/>
										</FormHelperText>
									</FormControl>
								</Stack>
							</Grid>
							<Grid item xs={12} sm={12} md={6} lg={6}>
								<Stack spacing={1}>
									<FormControl
										variant="outlined"
										fullWidth
										error={
											formik.touched["Contribution"] &&
											Boolean(formik.errors["Contribution"])
										}
									>
										<CustomPopover
											id={'tax'}
											title={t('Tax Law.128 (%)')}
											content={t('It is added to the interest rate and attributed to the Greek State. It is generally applied to all mortgages except for a few special exceptions.')}
											numPage={floatPopover ? '5/8' : '5/6'}
											isOpen={isOpenTax}
											setIsOpen={setIsOpenTax}
											handleNext={handleNext}
										/>
										<Select
											native={Capacitor.getPlatform() === 'android'}
											labelId="Contribution-label"
											autoComplete={"chrome-off"}
											size={"small"}
											name={"Contribution"}
											value={formik.values.Contribution}
											onChange={handleCalculateInstallments}
											onBlur={formik.handleBlur}
											IconComponent={ArrowDown2}
										>
											{contributionList}
										</Select>
										<FormHelperText>
											<ErrorMessage name={"Contribution"}/>
										</FormHelperText>
									</FormControl>
								</Stack>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12}>
						{Number(formik.values.InterestType) === TypeInterestRateIds.Floating ? null :
							<Grid container spacing={3} sx={{mr: 2}}>
								<Grid item xs={12} sm={12} md={6} lg={6}>
									<Stack spacing={1}>
										<FormControl
											variant="standard"
											fullWidth
											error={
												formik.touched["FixedInterestRate"] &&
												Boolean(formik.errors["FixedInterestRate"])
											}
										>
											<FormLabel
												htmlFor="FixedInterestRate"
												required>{t('Fixed interest rate (%)')}</FormLabel>
											<NumericFormat
												value={formik.values.FixedInterestRate}
												onValueChange={(values, sourceInfo) => {
													handleCalculateInstallments({
														target: {
															name: 'FixedInterestRate',
															value: values.floatValue ? values.floatValue : '',
														},
													})
												}}
												thousandSeparator={thousandSeparator}
												decimalSeparator={decimalSeparator}
												decimalScale={2}
												customInput={TextField}
												
												required
												onBlur={formik.handleBlur}
												name={`FixedInterestRate`}
												color={"grey"}
												size={'small'}
												fullWidth
												inputProps={{maxLength: 4, step: 1000}}
												error={formik.touched.FixedInterestRate && Boolean(formik.errors.FixedInterestRate)}
											/>
											<FormHelperText>
												<ErrorMessage name={"FixedInterestRate"}/>
											</FormHelperText>
										</FormControl>
									</Stack>
								</Grid>
								<Grid item xs={12} sm={12} md={6} lg={6}>
									<Stack spacing={1}>
										<FormControl
											variant="standard"
											fullWidth
											error={
												formik.touched["FixedDuration"] &&
												Boolean(formik.errors["FixedDuration"])
											}
										>
											<FormLabel
												htmlFor="FixedDuration" required
											>
												{t('Duration (in years)')}
											</FormLabel>
											<NumericFormat
												value={formik.values.FixedDuration}
												onValueChange={(values, sourceInfo) => {
													handleCalculateInstallments({
														target: {
															name: 'FixedDuration',
															value: values.floatValue ? values.floatValue : '',
														},
													})
												}}
												thousandSeparator={thousandSeparator}
												decimalSeparator={decimalSeparator}
												decimalScale={2}
												customInput={TextField}
												
												required
												onBlur={formik.handleBlur}
												name={`FixedDuration`}
												color={"grey"}
												size={'small'}
												fullWidth
												inputProps={{maxLength: 3}}
												error={formik.touched.FixedDuration && Boolean(formik.errors.FixedDuration)}
											/>
											<FormHelperText>
												<ErrorMessage name={"FixedDuration"}/>
											</FormHelperText>
										</FormControl>
									</Stack>
								</Grid>
							</Grid>
						}
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12}>
						{Number(formik.values.InterestType) === TypeInterestRateIds.Fixed ? null :
							<Grid container spacing={3} sx={{mr: 2}}>
								<Grid item xs={12} sm={6} md={3} lg={3}>
									<Stack>
										<FormControl
											variant="standard"
											fullWidth
											error={
												formik.touched["Spread"] &&
												Boolean(formik.errors["Spread"])
											}
										>
											<CustomPopover
												id={'spread'}
												title={t('Spread (%)')}
												content={t('A percentage which is added to the base rate (Euribor) to calculate the total variable rate of your home loan. Essentially, this is the bank s profit margin, which is set by the bank at the beginning of the loan and is unchanged for its entire duration.')}
												numPage={'6/8'}
												isOpen={isOpenSpread}
												setIsOpen={setIsOpenSpread}
												handleNext={handleNext}
												isType={true}
											/>
											<NumericFormat
												value={formik.values.Spread}
												onValueChange={(values, sourceInfo) => {
													handleCalculateInstallments({
														target: {
															name: 'Spread',
															value: values.floatValue ? values.floatValue : '',
														},
													})
												}}
												thousandSeparator={thousandSeparator}
												decimalSeparator={decimalSeparator}
												decimalScale={2}
												customInput={TextField}
												
												required
												onBlur={formik.handleBlur}
												name={`Spread`}
												color={"grey"}
												size={'small'}
												fullWidth
												inputProps={{maxLength: 4}}
												error={formik.touched.Spread && Boolean(formik.errors.Spread)}
											/>
											<FormHelperText>
												<ErrorMessage name={"Spread"}/>
											</FormHelperText>
										</FormControl>
									</Stack>
								</Grid>
								<Grid item xs={12} sm={6} md={3} lg={3}>
									<Stack>
										<FormControl
											variant="standard"
											fullWidth
											error={
												formik.touched["Euribor"] &&
												Boolean(formik.errors["Euribor"])
											}
										>
											<CustomPopover
												id={'euribor'}
												title={t('Euribor (%)')}
												content={t('Euribor is the market reference rate at which credit institutions in the European Union borrow in euros through the wholesale market.')}
												numPage={'7/8'}
												isOpen={isOpenEuribor}
												setIsOpen={setIsOpenEuribor}
												handleNext={handleNext}
											/>
											<TextField
												name={`Euribor`}
												value={replaceDotWithComma(formik.values.Euribor.toString(), language)}
												onChange={handleCalculateInstallments}
												onBlur={formik.handleBlur}
												color={"grey"}
												size={'small'}
												fullWidth
												sx={{
													".Mui-disabled": {
														'WebkitTextFillColor': "#485776",
													},
													"& fieldset": {border: 'none'},
												}}
												inputProps={{maxLength: 100}}
												disabled={true}
												error={formik.touched.Euribor && Boolean(formik.errors.Euribor)}
											/>
											<FormHelperText>
												<ErrorMessage name={"Euribor"}/>
											</FormHelperText>
										</FormControl>
									</Stack>
								</Grid>
								<Grid item xs={12} sm={12} md={6} lg={6}>
									<Stack spacing={1}>
										<FormControl
											variant="standard"
											fullWidth
											error={
												formik.touched["FloatingDuration"] &&
												Boolean(formik.errors["FloatingDuration"])
											}
										>
											<FormLabel
												htmlFor="FloatingDuration"
												required>{t('Duration (in years)')}</FormLabel>
											<NumericFormat
												value={formik.values.FloatingDuration}
												onValueChange={(values, sourceInfo) => {
													handleCalculateInstallments({
														target: {
															name: 'FloatingDuration',
															value: values.floatValue ? values.floatValue : '',
														},
													})
												}}
												thousandSeparator={thousandSeparator}
												decimalSeparator={decimalSeparator}
												decimalScale={2}
												customInput={TextField}
												
												required
												onBlur={formik.handleBlur}
												name={`FloatingDuration`}
												color={"grey"}
												size={'small'}
												fullWidth
												inputProps={{maxLength: 3}}
												error={formik.touched.FloatingDuration && Boolean(formik.errors.FloatingDuration)}
											/>
											<FormHelperText>
												<ErrorMessage name={"FloatingDuration"}/>
											</FormHelperText>
										</FormControl>
									</Stack>
								</Grid>
							</Grid>
						}
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12}>
						<Grid container spacing={3} sx={{mr: 2}}>
							<Grid item xs={12} sm={12} md={5} lg={5}>
								<Stack spacing={1}>
									<FormControl
										variant="standard"
										fullWidth
										error={
											formik.touched["DateInstallment"] &&
											Boolean(formik.errors["DateInstallment"])
										}
									>
										<FormLabel htmlFor="DateInstallment"
												   required>{t('Date of first installment')}</FormLabel>
										<LocalizationProvider dateAdapter={AdapterMoment}>
											<DatePicker
												views={['year', 'month', 'day']}
												openTo="year"
												inputFormat="DD/MM/YYYY"
												value={formik.values.DateInstallment}
												onChange={handleDate}
												onBlur={formik.handleBlur}
												required
												slots={{
													openPickerIcon: CalendarIcon
												}}
												error={formik.touched.DateInstallment && Boolean(formik.errors.DateInstallment)}
												sx={{
													'& .MuiButtonBase-root': {
														color: theme.palette.primary[100],
														background: theme.palette.primary.main,
													},
													'& .MuiSvgIcon-root': {
														order: -1,
														marginRight: theme => theme.spacing(1),
													},
													'& .MuiInputBase-root': {
														flexDirection: 'row-reverse',
													},
												}}
												textField={(params) => (
													<TextField
														{...params}
													/>
												)}
											/>
										</LocalizationProvider>
										<FormHelperText>
											<ErrorMessage name={"DateInstallment"}/>
										</FormHelperText>
									</FormControl>
								</Stack>
							</Grid>
							{(location.pathname === '/calculations/create' || location.pathname === ('/calculations/edit/' + id)) &&
								<Grid item xs={12} sm={12} md={7} lg={7}>
									<Stack spacing={1}>
										<FormControl variant="standard" fullWidth sx={{textAlign: 'right'}}>
											<FormControlLabel
												control={
													<Switch
														checked={formik.values.NotifyFlag}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														size={'small'}
														name={'NotifyFlag'}
													/>
												}
												label={t("I want to receive notifications about my monthly installment")}
												labelPlacement="start"
											/>
										</FormControl>
									</Stack>
								</Grid>
							}
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={12} sm={12} md={6} lg={6} /*sx={{alignSelf: 'flex-end'}}*/>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={6} md={6} lg={6}>
						<ReportCard
							primary={formik.values.Ltv ? replaceDotWithComma(formik.values.Ltv, language) : 0}
							symbol={' %'}
							secondary={
								<Box
									sx={{
										display: 'flex',
										flexDirection: {xs: 'row-reverse', sm: 'row', md: 'row', lg: 'row'},
										justifyContent: 'left'
									}}
								>
									<IconButtonInfoCircle
										id={'ltv'}
										title={t('Loan to Value')}
										onClick={togglePopover}
										isLtv={true}
									/>
									<IconButton
										sx={{maxHeight: '0px', maxWidth: '30px', order: {md: 0, sm: 0, xs: 0}}}
										onClick={togglePopover}
										id={'ltv'}
									>
										<InfoCircle width={'10px'} height={'10px'}/>
									</IconButton>
								</Box>
								/*<CustomPopover
									id={'ltv'}
									title={t('Loan to Value')}
									content={t('This is a percentage that results from Loan amount/ Commercial value of the property')}
									numPage={floatPopover ? '8/8' : '6/6'}
									isOpen={isOpenLtv}
									setIsOpen={setIsOpenLtv}
									handleNext={handleNext}
									isLtv={true}
								/>*/
							}
							color={theme.palette.info.main}
							iconPrimary={ChartIcon}
						/>
						{isOpenLtv &&
							<Box
								sx={{
									position: 'absolute',
									display: 'block',
									zIndex: '1'
								}}
								id={'ltv'}
							>
								<Box
									className="custom-popover-polygon"
									sx={{
										left: {
											xs: '35px !important',
											sm: '135px !important'
										},
										top: {
											xs: '-60px',
											sm: '-50px'
										}
									}}
									ref={popoverRef}
								>
									<Polygon3Icon/>
									<Box className={'custom-popover-content'}>
										<Button
											onClick={() => {
												setIsOpenLtv(false)
											}}
											color={'info'}
											className={'custom-button-x'}
										>
											{'x'}
										</Button>
										<Typography
											variant={'h5'}
											component={'div'}
											sx={{color: '#fff'}}
										>
											{t('This is a percentage that results from Loan amount/ Commercial value of the property')}
										</Typography>
										<Box className={'custom-next-button'}>
											<Typography
												color={theme.palette.secondary[100]}>{floatPopover ? '8/8' : '6/6'}</Typography>
										</Box>
									</Box>
								</Box>
							</Box>
						}
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={6} /*sx={{alignSelf: 'flex-end'}}*/>
						<ReportCard
							primary={totalInterest ? replaceDotWithComma(totalInterest.toFixed(2), language) : 0}
							symbol={' %'}
							secondary={t('Total interest rate')}
							color={theme.palette.info.main}
							iconPrimary={ActivityIcon}
						/>
					</Grid>
					{rowsFixed.length > 0 &&
						(
							<Grid item xs={12} sm={6} md={6} lg={4}>
								<CalculationKPI
									customStyle={{minHeight: '127.84px'}}
									secondary={t('Fixed interest monthly installment')}
									primary={
										<NumericFormat
											thousandSeparator={thousandSeparator}
											decimalSeparator={decimalSeparator}
											displayType="text"
											value={fixed[0].installment ? fixed[0].installment : 0}
										/>
									}
									symbol={' €'}
									iconPrimary={WalletIcon}
									color={theme.palette.info.main}
								/>
							</Grid>
						)
					}
					{rowsFloat.length > 0 &&
						(
							<Grid item xs={12} sm={6} md={6} lg={4}>
								<CalculationKPI
									customStyle={{minHeight: '127.84px'}}
									secondary={t('Monthly floating rate installment')}
									primary={
										<NumericFormat
											thousandSeparator={thousandSeparator}
											decimalSeparator={decimalSeparator}
											displayType="text"
											value={float[0].installment ? float[0].installment : 0}
										/>
									}
									symbol={' €'}
									iconPrimary={WalletIcon}
									color={theme.palette.info.main}
								/>
							</Grid>
						)
					}
					<Grid item xs={12} sm={6} md={6} lg={4}>
						<CalculationKPI
							customStyle={{minHeight: '127.84px'}}
							secondary={t('Total duration (in years)')}
							primary={TotalDuration}
							iconPrimary={CalendarIcon}
							color={theme.palette.info.main}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={4}>
						<CalculationKPI
							customStyle={{height: '170px'}}
							secondary={t('Number of installments')}
							primary={InstallmentNumber}
							iconPrimary={StarIcon} color={theme.palette.info.main}
						/>
					</Grid>
				</Grid>
			</Grid>
			{/* <Divider light variant="middle" sx={{strokeWidth: '1px', stroke: '#D3D5DF'}}/>*/}
			<Grid item xs={12} sm={12} md={12} lg={12}>
				<Divider sx={{mt: '16px', mb: '16px'}}/>
			</Grid>
			<Grid item xs={12} sm={12} md={12} lg={12} /*sx={{alignSelf: 'flex-end'}}*/>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={4} md={4} lg={4}>
					
					</Grid>
					<Grid item xs={12} sm={5} md={5} lg={4}>
						<PermissionHOC requiredPermissions={['view_interested_loan_btn']}>
							<Button
								disableElevation
								component={Link}
								to={getRouteUrl(ROUTE_PAGE_LOANS_CREATE, {}, {
									LoanAmount: formik.values.LoanAmount,
									RealEstateValue: formik.values.RealEstateValue
								})}
								variant="contained"
								color={'info'}
								sx={{
									'@media only screen and (max-width: 350px)': {
										gap: '4px',
										padding: '10px 14px'
									},
									gap: '20px',
									padding: '17px 54px'
								}}
							>
								<ArrowDownCircle width={'50px'}/>{t('I am interested in applying for a loan')}
							</Button>
						</PermissionHOC>
						<PermissionHOC requiredPermissions={['view_interested_loan_btn_anon']}>
							<Button
								disableElevation
								component={Button}
								onClick={handleClick}
								variant="contained"
								color={'info'}
								sx={{
									gap: '20px',
									padding: '17px 54px'
								}}
							>
								<ArrowDownCircle width={'50px'}/>{t('I am interested in applying for a loan')}
							</Button>
						</PermissionHOC>
						{location.pathname === '/fast/calculation' &&
							<Button
								disableElevation
								component={Button}
								onClick={handleInterestedLoanUnAuth}
								variant="contained"
								color={'info'}
								sx={{
									gap: '20px',
									padding: '17px 54px'
								}}
							>
								<ArrowDownCircle width={'50px'}/>{t('I am interested in applying for a loan')}
							</Button>
						}
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	)
}

export default InputsCalculation