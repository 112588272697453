import {useEffect, useState} from "react";
import axios from "axios";
import {API} from "../../../helpers/constants";
import useUserData from "../../../hooks/user/useUserData";
import {handleAxiosErrors, logoutUnauthorizedUser} from "../../../helpers/errorHandling";
import {useTranslation} from "react-i18next";
import {ALERTS} from "../../../locales/components/namespaces";
import {enqueueSnackbar} from "notistack";
import useUserAction from "../../../hooks/user/useUserAction";

const useTeams = () => {
	const {token, isLoggedIn} = useUserData()
	const {userLogout} = useUserAction()
	const {t} = useTranslation(ALERTS)
	const [loading, setLoading] = useState(false)
	const [teams, setTeams] = useState([])
	const [updateRole, setUpdateRole] = useState(false)
	
	/* useEffect(() => {
		 setLoading(true)
		 axios({
			 method: 'get',
			 headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
			 url: `${API}/teams/list`,
		 }).then((response) => {
			 setTeams(response.data)
			 setLoading(false)
		 }).catch(error => {
			 setLoading(false)
			 enqueueSnackbar(handleAxiosErrors(error, t('Failed to get teams list.')), {
					 variant: 'error'
				 }
			 )
			 logoutUnauthorizedUser(error, isLoggedIn, userLogout)
		 })
	 }, [])*/
	
	const store = (newRow) => {
		axios({
			method: 'post',
			headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
			url: `${API}/team/store`,
			data: newRow
		}).then(response => {
			setUpdateRole(!updateRole)
			enqueueSnackbar(t('Success Store'), {
					variant: 'success'
				}
			)
		}).catch(error => {
			enqueueSnackbar(handleAxiosErrors(error, t('Failed to store team!')), {
					variant: 'error'
				}
			)
			logoutUnauthorizedUser(error, isLoggedIn, userLogout)
		})
	}
	
	const update = (newRow, oldRow) => {
		axios({
			method: 'post',
			headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
			url: `${API}/team/update`,
			data: newRow
		}).then(response => {
			setUpdateRole(!updateRole)
			enqueueSnackbar(t('Success Store'), {
					variant: 'success'
				}
			)
		}).catch(error => {
			enqueueSnackbar(handleAxiosErrors(error, t('Failed to update team!')), {
					variant: 'error'
				}
			)
			logoutUnauthorizedUser(error, isLoggedIn, userLogout)
		})
	}
	
	const remove = (id) => {
		axios({
			method: 'post',
			headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
			url: `${API}/team/remove`,
			data: {
				id: id
			}
		}).then(response => {
			setUpdateRole(!updateRole)
			enqueueSnackbar(t('Success Store'), {
					variant: 'success'
				}
			)
		}).catch(error => {
			enqueueSnackbar(handleAxiosErrors(error, t('Failed to delete team')), {
					variant: 'error'
				}
			)
			logoutUnauthorizedUser(error, isLoggedIn, userLogout)
		})
	}
	
	return {
		loading,
		teams,
		store,
		update,
		remove
	}
}

export default useTeams