// @flow
import React from 'react';
import Box from "@mui/material/Box";

const DataGridWrapper = ({sx, width, height, autoHeight, children, bold}) => {
	return (
		<Box
			sx={{
				height: height ?? autoHeight ?? 800,
				width: width ?? '100%',
				minWidth: 200,
				'& .MuiDataGrid-renderingZone': {
					maxHeight: 'none !important',
				},
				'& .MuiDataGrid-cell': {
					lineHeight: 'unset !important',
					maxHeight: 'none !important',
					whiteSpace: 'normal!important',
				},
				".MuiDataGrid-cellContent": {
					fontWeight: bold ? 'bold' : 'none'
				},
				'& .MuiDataGrid-row': {
					maxHeight: 'none !important',
				},
				
				".MuiDataGrid-aggregationColumnHeaderLabel": {
					display: "none"
				}, "& .MuiDataGrid-columnHeaderTitle": {
					fontWeight: 'bold',
					whiteSpace: "normal",
					lineHeight: "normal"
				},
				"& .MuiDataGrid-columnHeader": {
					// Forced to use important since overriding inline styles
					height: "unset !important"
				},
				"& .MuiDataGrid-columnHeaders": {
					// Forced to use important since overriding inline styles
					maxHeight: "168px !important"
				},
				
				...sx
			}}
		>
			{children}
		</Box>
	);
};

export default DataGridWrapper