// @flow
import React, {useState} from 'react';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Button,
	Grid,
	Typography,
	useMediaQuery
} from '@mui/material';
import {Link} from "react-router-dom";
import {getRouteUrl} from "../../../helpers/getRouteUrl";
import {useTranslation} from "react-i18next";
import AlertMessageForSimpleUser
	from "../../../modules/customers/components/alertMessageForSimpleUser/alertMessageForSimpleUser";
import {PAGE_HOME} from "../../../locales/pages/namespaces";
import {ROUTE_PAGE_CALCULATIONS_CREATE} from "../../../modules/calculations/routes/routes";
import IndexListCalculations from "../../../modules/calculations/components/indexList/indexList";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import {ROUTE_PAGE_HOME} from "../../../routers/routes";
import HomeBreadcrumbBlue from "../../../assets/images/icons/homeBreadcrumbBlue";
import ArrowDown2 from "../../../assets/images/icons/arrowDown2";

const AnonymousDashboard = () => {
	const {t} = useTranslation(PAGE_HOME)
	const matchDownSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
	const [expandedCalc, setExpandedCalc] = useState(true);
	const handleAccordionChangeCalc = () => {
		setExpandedCalc(!expandedCalc);
	};
	
	return (
		<Box>
			{!matchDownSM &&
				<Breadcrumb>
					<Breadcrumb.Link iconComponent={HomeBreadcrumbBlue} to={getRouteUrl(ROUTE_PAGE_HOME)}/>
				</Breadcrumb>
			}
			<Box sx={{mt: 2, mb: 2, minHeight: 180}}>
				<AlertMessageForSimpleUser/>
				{/*CALCULATIONS WITHOUT ACCORDION*/}
				{/*  <MainCard
                    title={
                        <Typography variant="h4" fontWeight={400}>
                            {t('My calculations')}
                        </Typography>
                    }
                    secondary={
                        <Button
                            size={"small"}
                            variant={"contained"}
                            color={'info'}
                            component={Link}
                            to={getRouteUrl(ROUTE_PAGE_CALCULATIONS_CREATE)}
                        >
                            {t('Add loan scenario')}
                        </Button>
                    }
                >
                    <Box sx={{mb: 2, mr: 2, ml: 2}}>
                        <IndexListCalculations/>
                    </Box>
                </MainCard>*/}
				<Accordion
					expanded={expandedCalc}
					onChange={handleAccordionChangeCalc}
					square={true} sx={{mt: 2, borderRadius: '12px'}}
				>
					<AccordionSummary
						expandIcon={<ArrowDown2/>}
						aria-controls="panel1a-content"
						id="panel1a-header"
					>
						<Grid container alignItems="center" justifyContent="space-between">
							<Grid item xs={12} sm={6} md={6} lg={6}>
								<Typography variant={'h4'} fontWeight={400} component={'div'}>
									{t('My calculations')}
								</Typography>
							</Grid>
							{expandedCalc && <Grid item>
								<Button
									size={"small"}
									variant={"contained"}
									color={'info'}
									sx={{textAlign: 'center'}}
									component={Link}
									to={getRouteUrl(ROUTE_PAGE_CALCULATIONS_CREATE)}
								>
									{t('Add loan scenario')}
								</Button>
							</Grid>}
						</Grid>
					</AccordionSummary>
					<AccordionDetails sx={{p: 3}}>
						<IndexListCalculations/>
					</AccordionDetails>
				</Accordion>
			</Box>
		</Box>
	)
}

export default AnonymousDashboard