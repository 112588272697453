import {actionCreator} from '../helpers/actionCreator'
import * as ActionTypes from './types'

export * from './currentUser/actions'

export const setUserAlterPassword = actionCreator(ActionTypes.SET_USER_ALTER_PASSWORD)

export const setMenu = actionCreator(ActionTypes.SET_MENU)
export const setMenuActiveItem = actionCreator(ActionTypes.SET_MENU_ACTIVE_ITEM)
export const setMenuActiveId = actionCreator(ActionTypes.SET_MENU_ACTIVE_ID)
export const setMenuActiveComponent = actionCreator(ActionTypes.SET_MENU_ACTIVE_COMPONENT)
export const setDrawerOpen = actionCreator(ActionTypes.SET_DRAWER_OPEN)
export const setDrawerComponentOpen = actionCreator(ActionTypes.SET_DRAWER_COMPONENT_OPEN)

export const googleLogin = actionCreator(ActionTypes.GOOGLE_LOGIN)
export const googleLoginSucceeded = actionCreator(ActionTypes.GOOGLE_LOGIN_SUCCEEDED)
export const googleLoginFailed = actionCreator(ActionTypes.GOOGLE_LOGIN_FAILED)