import React, {useEffect, useRef} from 'react';
import './customPopover.css';
import {Box, Button, IconButton, Typography} from "@mui/material";
import {CALCULATIONS} from "../../locales/namespaces";
import {useTranslation} from "react-i18next";
import {useTheme} from "@mui/material/styles";
import IconButtonInfoCircle from "../iconButtonInfoCircle/iconButtonInfoCircle";
import Polygon3Icon from "../../../../assets/images/icons/polygon3Icon";
import ArrowRightIcon from "../../../../assets/images/icons/buttonsIcons/ArrowRightIcon";
import InfoCircle from "../../../../assets/images/icons/infoCircle";

const CustomPopover = ({id, title, content, numPage, isOpen, setIsOpen, handleNext, isType, isLtv}) => {
	const {t} = useTranslation(CALCULATIONS)
	const theme = useTheme();
	const popoverRef = useRef(null);
	
	const togglePopover = () => {
		setIsOpen(!isOpen);
	};
	
	const handleClickOutside = (event) => {
		if (popoverRef.current && !popoverRef.current.contains(event.target)) {
			setIsOpen(false);
		}
	};
	
	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);
	
	return (
		<Box sx={{
			display: 'flex',
			flexDirection: {xs: 'row-reverse', sm: 'row', md: 'row', lg: 'row'},
			justifyContent: 'left'
		}}>
			<IconButtonInfoCircle
				id={id}
				title={title}
				onClick={togglePopover}
				isLtv={isLtv}
			/>
			<Box className="custom-popover-container" id={id}>
				<IconButton
					sx={{maxHeight: '0px', maxWidth: '30px', order: {md: 0, sm: 0, xs: 0}}}
					onClick={togglePopover}
					id={id}
				>
					<InfoCircle width={'10px'} height={'10px'}/>
				</IconButton>
				{isOpen &&
					<Box
						className="custom-popover-polygon"
						ref={popoverRef}
					>
						<Polygon3Icon/>
						<Box
							className={'custom-popover-content'}
							sx={{
								maxWidth: isType ? '500px' : '300px',
								'@media only screen and (max-width: 1000px)': {
									overflowX: isType && 'scroll',
									maxHeight: isType && '250px'
								},
							}}
						>
							<Button
								onClick={() => {
									setIsOpen(false)
								}}
								color={'info'}
								className={'custom-button-x'}
							>
								{'x'}
							</Button>
							<Typography
								variant={'h5'}
								component={'div'}
								sx={{color: '#fff'}}
							>
								{content}
							</Typography>
							<Box className={'custom-next-button'}>
								{!isLtv && <Button color={'info'} sx={{gap: '20px'}} onClick={handleNext}>
									{t('Next')}<ArrowRightIcon/>
								</Button>}
								<Typography color={theme.palette.secondary[100]}>{numPage}</Typography>
							</Box>
						</Box>
					</Box>
				}
			</Box>
		</Box>
	);
};
export default CustomPopover;