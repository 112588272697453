// @flow
import React from 'react';
import {Grid, Button, List, ListItem, Stack, Typography} from "@mui/material";
import {Link} from "react-router-dom";
import {getRouteUrl} from "../../../../helpers/getRouteUrl";
import {ROUTE_PAGE_USERS_EDIT} from "../../routes/routes";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import {useTranslation} from "react-i18next";
import {USERS} from "../../locales/namespaces";
import {textTranslate} from "../../../../helpers/helpers";
import useUserData from "../../../../hooks/user/useUserData";
import PermissionHOC from "../../../permission/components/permissionHOC/permissionHOC";
import MainCard from "../../../../theme/mantis/components/mainCard";
import DeleteUserAccount from "../deleteUserAccount/deleteUserAccount";

const Profile = (props) => {
    const {id, userData} = props
    const {t} = useTranslation(USERS)
    const {language} = useUserData()
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <MainCard
            title={
                <Typography variant="h4">
                    {t('Information')}
                </Typography>
            }
            secondary={
                <>
                    <Button
                        size={"small"}
                        variant={"contained"}
                        color={'info'}
                        component={Link}
                        sx={{mr: 1}}
                        to={(getRouteUrl(ROUTE_PAGE_USERS_EDIT, {id: id}))}
                    >
                        {t('Edit')}
                    </Button>
                    <Button
                        size={"small"}
                        variant={"outlined"}
                        color={'error'}
                        onClick={handleClickOpen}
                    >
                        {t('Delete account')}
                    </Button>
                </>
            }
        >
            <List sx={{py: 0}}>
                <PermissionHOC requiredPermissions={['edit_cust_isactive', 'view_cust_isactive']}>
                    <ListItem>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                                <Stack spacing={0.5}>
                                    <Typography color="secondary" variant={'h6'}>{t('Active')}</Typography>
                                    <Typography variant={'body'} fontWeight={600}>{userData.isActive === '1' ?
                                        <CheckOutlinedIcon/> : <CloseOutlinedIcon/>}</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Stack spacing={0.5}>
                                    <Typography color="secondary" variant={'h6'}>{t('Origin')}</Typography>
                                    <Typography variant={'body'}
                                                fontWeight={600}>{userData.OriginDescription}</Typography>
                                </Stack>
                            </Grid>
                        </Grid>
                    </ListItem>
                </PermissionHOC>
                <ListItem>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <Stack spacing={0.5}>
                                <Typography color="secondary" variant={'h6'}>{t('Name')}</Typography>
                                <Typography variant={'body'} fontWeight={600}>{userData.LoginName}</Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Stack spacing={0.5}>
                                <Typography color="secondary" variant={'h6'}>{t('User Name')}</Typography>
                                <Typography variant={'body'} fontWeight={600}>{userData.username}</Typography>
                            </Stack>
                        </Grid>
                    </Grid>
                </ListItem>
                <ListItem>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <Stack spacing={0.5}>
                                <Typography color="secondary" variant={'h6'}>{t('E-mail')}</Typography>
                                <Typography variant={'body'} fontWeight={600}>{userData.email}</Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Stack spacing={0.5}>
                                <Typography color="secondary" variant={'h6'}>{t('Nationality')}</Typography>
                                <Typography variant={'body'} fontWeight={600}>{textTranslate(language)({
                                    en: userData.NationalityEn,
                                    el: userData.NationalityEl
                                })}</Typography>
                            </Stack>
                        </Grid>
                    </Grid>
                </ListItem>
                <ListItem>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <Stack spacing={0.5}>
                                <Typography color="secondary" variant={'h6'}>{t('Country')}</Typography>
                                <Typography variant={'body'} fontWeight={600}>{userData.Country}</Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Stack spacing={0.5}>
                                <Typography color="secondary" variant={'h6'}>{t('Currency')}</Typography>
                                <Typography variant={'body'} fontWeight={600}>{userData.Currency}</Typography>
                            </Stack>
                        </Grid>
                    </Grid>
                </ListItem>
                <ListItem>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <Stack spacing={0.5}>
                                <Typography color="secondary" variant={'h6'}>{t('Language')}</Typography>
                                <Typography variant={'body'} fontWeight={600}>{userData.Language}</Typography>
                            </Stack>
                        </Grid>
                    </Grid>
                </ListItem>
            </List>
            <DeleteUserAccount
                open={open}
                handleClose={handleClose}
                id={id}
            />
        </MainCard>
    )
}

export default Profile