// @flow
import React, {Fragment, useMemo} from 'react';
import {useTranslation} from "react-i18next";
import {EDITABLE_MUI_GRID} from "../../locales/components/namespaces";
import {useMUIDataGridCrudOperations} from "./hooks/useMUIDataGridCrudOperations";
import EditGridToolBar from "./partials/editGridToolBar";
import GridRowToolBar from "./partials/gridRowToolBar";
import GridRowEditToolBar from "./partials/gridRowEditToolBar";
import RowConfirmationDialog from "./partials/rowConfirmationDialog";
import {muiGridLocales} from "../../helpers/muiGridLocales";
import {DataGridPremium, GridRowModes} from "@mui/x-data-grid-premium";
import {CustomPagination} from "../dataGridCustom/customPagination";

const EditableMUIDataGrid = (props) => {
	const {
		loading,
		columns,
		data,
		newRowModel,
		store,
		update,
		remove,
		hideEditButton = false,
		hideDeleteButton = false,
		dataGridProOtherProps
	} = props
	const {t} = useTranslation(EDITABLE_MUI_GRID)
	
	const {
		rows,
		rowModesModel,
		//handleRows,
		handleRowModesModel,
		handleEditRowStart,
		handleEditRowStop,
		handleEditRow,
		handleCancelEditRow,
		handleAddNewRow,
		handleSaveRow,
		handleDeleteRow,
		handleDeleteConfirmation,
		handleUpdateRow,
		handleUpdateRowError,
		deleteDialogOpen,
		handleDeleteDialogOpen,
	} = useMUIDataGridCrudOperations({
		initialRows: data,
		newRowModel,
		store,
		update,
		remove
	})
	
	const gridColumns = [
		{
			field: 'actions',
			type: 'actions',
			headerName: t('Actions'),
			width: 100,
			cellClassName: 'actions',
			getActions: (params) => {
				const id = params.id
				const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
				if (isInEditMode) {
					return [
						<GridRowEditToolBar
							id={id}
							handleSaveRow={handleSaveRow}
							handleCancelEditRow={handleCancelEditRow}
						/>
					]
				}
				return [
					<GridRowToolBar
						id={id}
						hideEditButton={hideEditButton}
						hideDeleteButton={hideDeleteButton}
						handleEditRow={handleEditRow}
						handleDeleteRow={handleDeleteRow}
					/>
				]
			},
		},
		...columns,
	]
	
	const disableAddingNewRow = useMemo(() => (Boolean(rows.find(f => f.isNew === true)) || (Object.values(rowModesModel).length > 0 && Boolean(Object.values(rowModesModel).find(f => f.mode === GridRowModes.Edit)))), [data, rows, rowModesModel])
	
	return (
		<Fragment>
			<RowConfirmationDialog
				deleteDialogOpen={deleteDialogOpen}
				handleDeleteDialogOpen={handleDeleteDialogOpen}
				handleDeleteConfirmation={handleDeleteConfirmation}
			/>
			<DataGridPremium
				density={'compact'}
				sx={{bgcolor: 'background.paper'}}
				loading={loading}
				rows={rows}
				columns={gridColumns}
				editMode="row"
				rowModesModel={rowModesModel}
				onRowModesModelChange={handleRowModesModel}
				onRowEditStart={handleEditRowStart}
				onRowEditStop={handleEditRowStop}
				processRowUpdate={handleUpdateRow}
				onProcessRowUpdateError={handleUpdateRowError}
				slots={{
					toolbar: EditGridToolBar,
					pagination: CustomPagination,
				}}
				slotProps={{
					toolbar: {handleAddNewRow, disableAddingNewRow},
				}}
				{...dataGridProOtherProps}
				localeText={muiGridLocales(t)}
			/>
		</Fragment>
	);
}

export default EditableMUIDataGrid