import * as React from "react";
const InfoIcon = (props) => (
    <svg
        width={25}
        height={25}
        style={{marginRight: '32px'}}
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g stroke="#fff" strokeLinecap="round" strokeLinejoin="round">
            <path
                clipRule="evenodd"
                d="M14 1.666c6.81 0 12.333 5.521 12.333 12.333 0 6.811-5.523 12.334-12.334 12.334-6.812 0-12.333-5.523-12.333-12.334 0-6.812 5.521-12.333 12.333-12.333Z"
                strokeWidth={1.645}
            />
            <path d="M13.992 8.938v5.891" strokeWidth={1.645} />
            <path d="M13.993 19.063h.013" strokeWidth={2.194} />
        </g>
    </svg>
);
export default InfoIcon;
