// @flow
import * as React from 'react';
import {ErrorMessage, FormikProvider} from "formik";
import {CUSTOMERS} from "../../locales/namespaces";
import {useTranslation} from "react-i18next";
import {
	Button,
	Typography,
	IconButton,
	InputAdornment,
	Checkbox,
	FormControl,
	FormControlLabel,
	FormHelperText,
	TextField,
	Stack,
	Grid,
	Autocomplete,
	Select,
	MenuItem,
	FormLabel,
	Switch,
	TextareaAutosize,
	useMediaQuery,
	Dialog
} from "@mui/material";
import {getRouteUrl} from "../../../../helpers/getRouteUrl";
import {ROUTE_PAGE_CUSTOMERS_DETAILS, ROUTE_PAGE_CUSTOMERS_INDEX} from "../../routes/routes";
import useCustomerForm from "../../hooks/useCustomerForm";
import Breadcrumb from "../../../../components/breadcrumb/breadcrumb";
import {ROUTE_PAGE_HOME} from "../../../../routers/routes";
import AlertDialog from "../cancelConfirmationDialog/cancelConfirmationDialog";
import PermissionHOC from "../../../permission/components/permissionHOC/permissionHOC";
import {useMemo} from "react";
import {textTranslate} from "../../../../helpers/helpers";
import useUserData from "../../../../hooks/user/useUserData";
import {useHasPermission} from "../../../permission/hooks/useHasPermission";
import MainCard from "../../../../theme/mantis/components/mainCard";
import ContentLoader from "../../../../components/contentLoader/contentLoader";
import {NumericFormat} from "react-number-format";
import Divider from "@mui/material/Divider";
import {useTheme} from "@mui/material/styles";
import ArrowDown2 from "../../../../assets/images/icons/arrowDown2";
import HomeBreadcrumb from "../../../../assets/images/icons/homeBreadcrumb";
import VisibleEyeOff from "../../../../assets/images/icons/login/visibleEyeOff";
import VisibleEyeOn from "../../../../assets/images/icons/login/visibleEyeOn";
import ErrorPopup from "../errorPopup/errorPopup";
import {Capacitor} from "@capacitor/core";
import PrivacyPolicy from "../../../../pages/privacyPolicy/privacyPolicy";

type Props = {
	id: string,
	customer?: Object,
	countries: Array,
	currencies: Array,
	languages: Array,
	nationalities: Array,
	//dateFormats: Array,
	isUser: boolean
}

const Form = (props: Props) => {
	const {t} = useTranslation(CUSTOMERS)
	const {
		id,
		customer,
		countries,
		currencies,
		languages,
		nationalities,
		//dateFormats,
		isUser
	} = props
	const {language, decimalSeparator, thousandSeparator} = useUserData()
	
	const [open, setOpen] = React.useState(false);
	const hasVatAccess = useHasPermission(['edit_cust_vatregno', 'view_cust_vatregno'], {shouldView: true})
	const hasPasswordAccess = useHasPermission(['edit_cust_password', 'view_cust_password'], {shouldView: true})
	//const editCustEmail = useHasPermission(['edit_cust_email'], {shouldView: true})
	const matchDownSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
	
	const handleClickOpen = () => {
		setOpen(true);
	};
	
	const handleClose = () => {
		setOpen(false);
	};
	const [openModal, setOpenModal] = React.useState(false);
	const handleOpenModal = () => setOpenModal(true);
	const handleCloseModal = () => setOpenModal(false);
	
	const {
		formik,
		showPassword,
		handleClickShowPassword,
		handleMouseDownPassword,
		isLoading,
		errorMess,
		closeError
	} = useCustomerForm({
		id,
		customer,
		languages,
		countries,
		currencies,
		nationalities,
		//dateFormats,
		isUser,
		hasVatAccess,
		hasPasswordAccess
	})
	
	const nationalityList = useMemo(() => (
		Capacitor.getPlatform() === 'android' ?
			nationalities.map(item => (
				<option
					key={`nationality-${item.id}`}
					value={item.id}
				>
					{textTranslate(language)({
						en: item.DescriptionEn,
						el: item.Description
					})}
				</option>
			)) :
			nationalities.map(item => (
				<MenuItem
					key={`nationality-${item.id}`}
					value={item.id}
				>
					{textTranslate(language)({
						en: item.DescriptionEn,
						el: item.Description
					})}
				</MenuItem>
			))), [nationalities, language])
	
	const countryList = useMemo(() => (
		countries.map(item => (
			<MenuItem
				key={`country-${item.id}`}
				value={item.id}  // Assuming 'Code' is the appropriate property for the value
			>
				<Stack direction="row" alignItems="center" spacing={1}>
					<img src={item.ImagePath} alt={item.DescriptionEn}
						 style={{width: '35px'}}/> {/* Displaying the country flag */}
					{item.PhoneCode && (
						<Typography>
							{`${item.PhoneCode}`}
						</Typography>
					)}
				</Stack>
			</MenuItem>
		))), [countries, language])
	
	const currenciesList = useMemo(() => (
		Capacitor.getPlatform() === 'android' ?
			currencies.map(item => (
				<option
					key={`currency-${item.id}`}
					value={item.id}
				>
					{item.Description}
				</option>
			)) :
			currencies.map(item => (
				<MenuItem
					key={`currency-${item.id}`}
					value={item.id}
				>
					{item.Description}
				</MenuItem>
			))
	), [currencies, language])
	
	//console.log('countryList',countryList)
	
	const textareaStyle = {
		display: 'flex',
		height: '144px',
		padding: '8px 16px',
		flexDirection: 'column',
		alignItems: 'center',
		gap: '10px',
		alignSelf: 'stretch',
		borderRadius: '8px',
		border: ' 0px solid #CFD3D4',
		background: '#F4F5FA',
		overflow: 'auto',
	};
	
	return !isLoading ? (
		<FormikProvider value={formik}>
			{!matchDownSM &&
				<Breadcrumb>
					<Breadcrumb.Link iconComponent={HomeBreadcrumb} to={getRouteUrl(ROUTE_PAGE_HOME)}/>
					<Breadcrumb.Link
						to={!id ? getRouteUrl(ROUTE_PAGE_CUSTOMERS_INDEX) : getRouteUrl(ROUTE_PAGE_CUSTOMERS_DETAILS, {id: id})}>
						{!id ? t('Customers') : t('Profile')}
					</Breadcrumb.Link>
					<Breadcrumb.Item>
						{!id ? t('Create Customer') : t('Edit Profile')}
					</Breadcrumb.Item>
				</Breadcrumb>
			}
			<MainCard
				title={
					<Typography variant={"h4"}>
						{!id ? t('Create Customer') : t('Edit Profile')}
					</Typography>
				}
				sx={{
					border: '1px solid #0BC1B6',
					mt: 2
				}}
			>
				<Grid sx={{flexGrow: 1, maxWidth: '750px'}} container spacing={2}>
					<PermissionHOC requiredPermissions={['edit_cust_isactive', 'view_cust_isactive']}>
						<Grid item lg={6}>
							<FormControl variant="standard" fullWidth sx={{pb: 1, mt: 1}}>
								<FormControlLabel
									control={
										<Switch
											checked={formik.values.isActive}
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											size={'small'}
											name={'isActive'}
										/>
									}
									label={t('Active')}
									labelPlacement="end"
								/>
							</FormControl>
						</Grid>
					</PermissionHOC>
				</Grid>
				<Grid sx={{flexGrow: 1, maxWidth: '750px'}} container spacing={2}>
					<Grid item lg={6}>
						<Grid container spacing={1} sx={{mr: 2}}>
							{isUser &&
								<Grid item xs={12}>
									<Stack spacing={1}>
										<FormControl variant="standard" fullWidth>
											<FormControlLabel
												control={
													<Checkbox
														checked={formik.values.isUser}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														size={'small'}
														name={'isUser'}
													/>
												}
												label={t('isUser')}
												labelPlacement="end"
											/>
										</FormControl>
									</Stack>
								</Grid>}
							{id &&
								<PermissionHOC requiredPermissions={['edit_cust_code', 'view_cust_code']}>
									<Grid item lg={12}>
										<FormControl
											variant="standard"
											fullWidth
											error={
												formik.touched["Code"] &&
												Boolean(formik.errors["Code"])
											}
										>
											<FormLabel htmlFor="Code">{t('Customer Code')}</FormLabel>
											<TextField
												name={`Code`}
												value={formik.values.Code}
												onBlur={formik.handleBlur}
												color={"grey"}
												size={'small'}
												fullWidth
												sx={{
													".MuiOutlinedInput-input.Mui-disabled": {
														'WebkitTextFillColor': "#B0B6C4",
													},
													"& fieldset": {border: 'none'},
												}}
												inputProps={{maxLength: 100}}
												disabled={true}
												error={formik.touched.Code && Boolean(formik.errors.Code)}
											/>
											<FormHelperText>
												<ErrorMessage name={"Code"}/>
											</FormHelperText>
										</FormControl>
									</Grid>
								</PermissionHOC>
							}
							<Grid item xs={12}>
								<Stack spacing={1}>
									<FormControl
										variant="standard"
										fullWidth
										error={
											formik.touched["CustName"] &&
											Boolean(formik.errors["CustName"])
										}
									>
										<FormLabel htmlFor="CustName" required>
											{t('Full name')}
										</FormLabel>
										<TextField
											id="CustName"
											placeholder={t("Enter name")}
											name={`CustName`}
											value={formik.values.CustName}
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											required
											error={formik.touched.CustName && Boolean(formik.errors.CustName)}
											color={"grey"}
											size={'small'}
											fullWidth
											inputProps={{maxLength: 100}}
										/>
										<FormHelperText>
											<ErrorMessage name={"CustName"}/>
										</FormHelperText>
									</FormControl>
								</Stack>
							</Grid>
							<PermissionHOC requiredPermissions={['view_cust_username', 'edit_cust_username']}>
								<Grid item xs={12}>
									<Stack spacing={1}>
										<FormControl
											variant="standard"
											fullWidth
											error={
												formik.touched["username"] &&
												Boolean(formik.errors["username"])
											}
										>
											<FormLabel htmlFor="username" required>{t('Email (User Name)')}</FormLabel>
											<TextField
												placeholder={t("Enter username")}
												name={`username`}
												value={formik.values.username}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												required
												disabled={Boolean(id)}
												error={formik.touched.username && Boolean(formik.errors.username)}
												color={"grey"}
												size={'small'}
												fullWidth
												inputProps={{maxLength: 100}}
												sx={{
													".MuiOutlinedInput-input.Mui-disabled": {
														'WebkitTextFillColor': "#485776",
													},
													"& fieldset": {border: 'none'},
												}}
											/>
											<FormHelperText>
												<ErrorMessage name={"username"}/>
											</FormHelperText>
										</FormControl>
									</Stack>
								</Grid>
							</PermissionHOC>
							<PermissionHOC requiredPermissions={['edit_cust_email', 'view_cust_email']}>
								<Grid item xs={12}>
									<Stack spacing={1}>
										<FormControl
											variant="standard"
											fullWidth
											error={
												formik.touched["email"] &&
												Boolean(formik.errors["email"])
											}
										>
											<FormLabel htmlFor="email" required>{t('Email')}</FormLabel>
											<TextField
												type={'email'}
												placeholder={t("Enter email")}
												value={formik.values.email}
												name={`email`}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												fullWidth
												//disabled={!editCustEmail}
												autoComplete="on"
												color={"grey"}
												size={'small'}
												required
												error={formik.touched.email && Boolean(formik.errors.email)}
												inputProps={{maxLength: 100}}
											/>
											<FormHelperText>
												<ErrorMessage name={"email"}/>
											</FormHelperText>
										</FormControl>
									</Stack>
								</Grid>
							</PermissionHOC>
							<PermissionHOC requiredPermissions={['edit_cust_email_partner', 'view_cust_email_partner']}>
								<Grid item xs={12}>
									<Stack spacing={1}>
										<FormControl
											variant="standard"
											fullWidth
											error={
												formik.touched["email"] &&
												Boolean(formik.errors["email"])
											}
										>
											<FormLabel htmlFor="email" required>{t('Email')}</FormLabel>
											<TextField
												type={'email'}
												placeholder={t("Enter email")}
												value={formik.values.email}
												name={`email`}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												fullWidth
												//disabled={!editCustEmail}
												autoComplete="on"
												color={"grey"}
												size={'small'}
												required
												error={formik.touched.email && Boolean(formik.errors.email)}
												inputProps={{maxLength: 100}}
											/>
											<FormHelperText>
												<ErrorMessage name={"email"}/>
											</FormHelperText>
										</FormControl>
									</Stack>
								</Grid>
							</PermissionHOC>
							<PermissionHOC
								requiredPermissions={['view_cust_mobilephone_from_partner', 'edit_cust_mobilephone_from_partner']}>
								<Grid item xs={12} sm={5} md={5} lg={5}>
									<Stack spacing={1}>
										{/* <FormControl
                                            variant="standard"
                                            fullWidth
                                            error={
                                                formik.touched["CallingCode"] &&
                                                Boolean(formik.errors["CallingCode"])
                                            }
                                        >
                                            <FormLabel htmlFor="CallingCode" required>{t('Calling Code')}</FormLabel>
                                            <TextField
                                                placeholder={t('e.g.0030')}
                                                value={formik.values.CallingCode}
                                                name={`CallingCode`}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                fullWidth
                                                color={"grey"}
                                                size={'small'}
                                                required
                                                error={formik.touched.CallingCode && Boolean(formik.errors.CallingCode)}
                                                inputProps={{maxLength: 15}}
                                            />
                                            <FormHelperText>
                                                <ErrorMessage name={"CallingCode"}/>
                                            </FormHelperText>
                                        </FormControl>*/}
										<FormControl
											variant="outlined"
											fullWidth
											error={
												formik.touched["PhoneCode"] &&
												Boolean(formik.errors["PhoneCode"])
											}
											sx={{mb: 2}}
										>
											<FormLabel id="PhoneCode" required>{t('Phone Code')}</FormLabel>
											<Select
												labelId="PhoneCode-label"
												autoComplete={"chrome-off"}
												size={"small"}
												name={"PhoneCode"}
												value={formik.values.PhoneCode}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												IconComponent={ArrowDown2}
											>
												{countryList}
											</Select>
											<FormHelperText>
												<ErrorMessage name={"PhoneCode"}/>
											</FormHelperText>
										</FormControl>
									</Stack>
								</Grid>
							</PermissionHOC>
							<PermissionHOC
								requiredPermissions={['view_cust_mobilephone_from_partner', 'edit_cust_mobilephone_from_partner']}>
								<Grid item xs={12} sm={7}>
									<Stack spacing={1}>
										<FormControl
											variant="standard"
											fullWidth
											error={
												formik.touched["mobilePhone"] &&
												Boolean(formik.errors["mobilePhone"])
											}
										>
											<FormLabel htmlFor="mobilePhone" required>{t('Mobile Number')}</FormLabel>
											<TextField
												placeholder={t("6912345678")}
												value={formik.values.mobilePhone}
												name={`mobilePhone`}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												fullWidth
												color={"grey"}
												size={'small'}
												required
												error={formik.touched.mobilePhone && Boolean(formik.errors.mobilePhone)}
												inputProps={{maxLength: 15}}
											/>
											<FormHelperText>
												<ErrorMessage name={"mobilePhone"}/>
											</FormHelperText>
										</FormControl>
									</Stack>
								</Grid>
							</PermissionHOC>
							<PermissionHOC
								requiredPermissions={['view_cust_mobilephone_from_partner', 'edit_cust_mobilephone_from_partner']}>
								<Grid item xs={12}>
									<Stack spacing={1}>
										<FormControl
											variant="standard"
											fullWidth
											error={
												formik.touched["homePhone"] &&
												Boolean(formik.errors["homePhone"])
											}
										>
											<FormLabel htmlFor="homePhone">{t('Fixed telephone number')}</FormLabel>
											<TextField
												placeholder={t("Enter fixed telephone number")}
												name={`homePhone`}
												value={formik.values.homePhone}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												fullWidtht
												color={"grey"}
												size={'small'}
												inputProps={{maxLength: 15}}
											/>
											<FormHelperText>
												<ErrorMessage name={"homePhone"}/>
											</FormHelperText>
										</FormControl>
									</Stack>
								</Grid>
							</PermissionHOC>
							{/*<Grid item xs={12}>
                                    <Stack spacing={1}>
                                        <FormControl
                                            variant="standard"
                                            fullWidth
                                            error={
                                                formik.touched["RequestedAmount"] &&
                                                Boolean(formik.errors["RequestedAmount"])
                                            }
                                        >
                                            <FormLabel htmlFor="RequestedAmount">{t('Loan Amount (€)')}</FormLabel>
                                            <NumericFormat
                                                value={formik.values.RequestedAmount}
                                                onValueChange={(values, sourceInfo) => {
                                                    formik.setFieldValue('RequestedAmount', values.floatValue ? values.floatValue : '')
                                                }}
                                                thousandSeparator={thousandSeparator}
                                                decimalSeparator={decimalSeparator}
                                                decimalScale={2}
                                                customInput={TextField}

                                                id="RequestedAmount"
                                                placeholder={t('Enter loan amount')}
                                                onBlur={formik.handleBlur}
                                                name={`RequestedAmount`}
                                                color={"grey"}
                                                size={'small'}
                                                fullWidth
                                                inputProps={{maxLength: 100, step: 1000}}
                                                error={formik.touched.RequestedAmount && Boolean(formik.errors.RequestedAmount)}
                                            />
                                            <FormHelperText>
                                                <ErrorMessage name={"RequestedAmount"}/>
                                            </FormHelperText>
                                        </FormControl>
                                    </Stack>
                                </Grid>*/}
							<PermissionHOC requiredPermissions={['edit_cust_mobilephone', 'view_cust_mobilephone']}>
								<Grid item xs={12} sm={5} md={5} lg={5}>
									<Stack spacing={1}>
										<FormControl
											variant="outlined"
											fullWidth
											error={
												formik.touched["PhoneCode"] &&
												Boolean(formik.errors["PhoneCode"])
											}
											sx={{mb: 2}}
										>
											<FormLabel id="PhoneCode" required>{t('Phone Code')}</FormLabel>
											<Select
												labelId="PhoneCode-label"
												autoComplete={"chrome-off"}
												size={"small"}
												name={"PhoneCode"}
												value={formik.values.PhoneCode}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												IconComponent={ArrowDown2}
											>
												{countryList}
											</Select>
											<FormHelperText>
												<ErrorMessage name={"PhoneCode"}/>
											</FormHelperText>
										</FormControl>
									</Stack>
								</Grid>
							</PermissionHOC>
							<PermissionHOC requiredPermissions={['edit_cust_mobilephone', 'view_cust_mobilephone']}>
								<Grid item xs={12} sm={7} md={7} lg={7}>
									<Stack spacing={1}>
										<FormControl
											variant="standard"
											fullWidth
											error={
												formik.touched["mobilePhone"] &&
												Boolean(formik.errors["mobilePhone"])
											}
										>
											<FormLabel htmlFor="mobilePhone" required>{t('Mobile Number')}</FormLabel>
											<TextField
												placeholder={t("6912345678")}
												value={formik.values.mobilePhone}
												name={`mobilePhone`}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												fullWidth
												color={"grey"}
												size={'small'}
												required
												error={formik.touched.mobilePhone && Boolean(formik.errors.mobilePhone)}
												inputProps={{maxLength: 15}}
											/>
											<FormHelperText>
												<ErrorMessage name={"mobilePhone"}/>
											</FormHelperText>
										</FormControl>
									</Stack>
								</Grid>
							</PermissionHOC>
							<PermissionHOC requiredPermissions={['edit_cust_mobilephone', 'view_cust_mobilephone']}>
								<Grid item xs={12}>
									<Stack spacing={1}>
										<FormControl
											variant="standard"
											fullWidth
											error={
												formik.touched["homePhone"] &&
												Boolean(formik.errors["homePhone"])
											}
										>
											<FormLabel htmlFor="homePhone">{t('Fixed telephone number')}</FormLabel>
											<TextField
												placeholder={t("Enter fixed telephone number")}
												name={`homePhone`}
												value={formik.values.homePhone}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												fullWidth
												color={"grey"}
												size={'small'}
												inputProps={{maxLength: 15}}
											/>
											<FormHelperText>
												<ErrorMessage name={"homePhone"}/>
											</FormHelperText>
										</FormControl>
									</Stack>
								</Grid>
							</PermissionHOC>
							{/*TODO::Hide vat from customer form*/}
							{/*<PermissionHOC requiredPermissions={['edit_cust_vatregno', 'view_cust_vatregno']}>
                                <Grid item xs={12}>
                                    <Stack spacing={1}>
                                        <FormControl
                                            variant="standard"
                                            fullWidth
                                            error={
                                                formik.touched["vat"] &&
                                                Boolean(formik.errors["vat"])
                                            }
                                        >
                                            <FormLabel htmlFor="vat">{t('Taxpayer identification number')}</FormLabel>
                                            <TextField
                                                placeholder={t("Enter taxpayer identification number")}
                                                value={formik.values.vat}
                                                name={`vat`}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                fullWidth
                                                //required
                                                error={formik.touched.vat && Boolean(formik.errors.vat)}
                                                color={"grey"}
                                                size={'small'}
                                            />
                                            <FormHelperText>
                                                <ErrorMessage name={"vat"}/>
                                            </FormHelperText>
                                        </FormControl>
                                    </Stack>
                                </Grid>
                            </PermissionHOC>*/}
						</Grid>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={6}>
						<Grid container spacing={1}>
							<PermissionHOC requiredPermissions={['view_cust_password', 'edit_cust_password']}>
								<Grid item xs={12}>
									<Stack spacing={1}>
										<FormControl
											fullWidth
											variant="standard"
											error={
												formik.touched["password"] &&
												Boolean(formik.errors["password"])
											}
										>
											<FormLabel htmlFor="password" required={!id}>{t('Password')}</FormLabel>
											<TextField
												placeholder={t("Enter password")}
												type={showPassword ? 'text' : 'password'}
												value={formik.values.password}
												name={`password`}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												fullWidth
												autoComplete="new-password"
												color={"grey"}
												size={'small'}
												required
												error={formik.touched.password && Boolean(formik.errors.password)}
												InputProps={{
													minLength: 7,
													endAdornment:
														<InputAdornment position="end">
															<IconButton
																aria-label="toggle password visibility"
																onClick={handleClickShowPassword}
																onMouseDown={handleMouseDownPassword}
																edge="end"
															>
																{showPassword ? <VisibleEyeOff/> : <VisibleEyeOn/>}
															</IconButton>
														</InputAdornment>
												}}
											/>
											
											<FormHelperText>
												<ErrorMessage name={"password"}/>
											</FormHelperText>
										</FormControl>
									</Stack>
								</Grid>
							</PermissionHOC>
							<PermissionHOC requiredPermissions={['view_cust_password', 'edit_cust_password']}>
								<Grid item xs={12}>
									<Stack spacing={1}>
										<FormControl
											fullWidth
											variant="standard"
											error={
												formik.touched["confirmPassword"] &&
												Boolean(formik.errors["confirmPassword"])
											}
										>
											<FormLabel htmlFor="confirmPassword"
													   required={!id}>{t('Confirm Password')}</FormLabel>
											<TextField
												placeholder={t("Enter confirm password")}
												type={showPassword ? 'text' : 'password'}
												value={formik.values.confirmPassword}
												name={`confirmPassword`}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												fullWidth
												autoComplete="on"
												color={"grey"}
												size={'small'}
												required
												error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
												InputProps={{
													minLength: 7,
													endAdornment:
														<InputAdornment position="end">
															<IconButton
																aria-label="toggle password visibility"
																onClick={handleClickShowPassword}
																onMouseDown={handleMouseDownPassword}
																edge="end"
															>
																{showPassword ? <VisibleEyeOff/> : <VisibleEyeOn/>}
															</IconButton>
														</InputAdornment>
												}}
											
											/>
											<FormHelperText>
												<ErrorMessage name={"confirmPassword"}/>
											</FormHelperText>
										</FormControl>
									</Stack>
								</Grid>
							</PermissionHOC>
							<Grid item xs={12}>
								<Stack spacing={1}>
									<FormControl
										fullWidth
										variant="outlined"
										error={
											formik.touched["country"] &&
											Boolean(formik.errors["country"])
										}
									>
										<FormLabel htmlFor="Country" required>{t('Country')}</FormLabel>
										<Autocomplete
											popupIcon={<ArrowDown2/>}
											value={formik.values.country}
											options={countries}
											isOptionEqualToValue={(opt, val) => {
												return opt.id === val.id || !val
											}}
											getOptionLabel={option => textTranslate(language)({
												en: option.DescriptionEn,
												el: option.DescriptionEl
											})}
											onChange={(event, newValue) => {
												formik.setFieldValue("country", newValue)
											}}
											onBlur={formik.handleBlur}
											size="small"
											fullWidth
											renderInput={(params) => (
												<TextField
													required
													error={formik.touched.country && Boolean(formik.errors.country)}
													placeholder={t("Choose country")}
													size={"small"}
													{...params}
													inputProps={{
														...params.inputProps,
														autoComplete: 'off',
													}}
												/>
											)}
										/>
										<FormHelperText sx={{marginLeft: 0}}>
											<ErrorMessage name={"country"}/>
										</FormHelperText>
									</FormControl>
								</Stack>
							</Grid>
							<Grid item xs={12}>
								<Stack spacing={1}>
									<FormControl
										variant="outlined"
										fullWidth
										error={
											formik.touched["nationality"] &&
											Boolean(formik.errors["nationality"])
										}
										sx={{mb: 2}}
									>
										<FormLabel id="nationality" required>{t('Nationality')}</FormLabel>
										<Select
											native={Capacitor.getPlatform() === 'android'}
											labelId="nationality-label"
											autoComplete={"chrome-off"}
											size={"small"}
											name={"nationality"}
											value={formik.values.nationality}
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											IconComponent={ArrowDown2}
										>
											{nationalityList}
										</Select>
										<FormHelperText>
											<ErrorMessage name={"nationality"}/>
										</FormHelperText>
									</FormControl>
								</Stack>
							</Grid>
							<Grid item xs={12}>
								<Stack spacing={1}>
									<FormControl
										fullWidth
										variant="outlined"
										error={
											formik.touched["language"] &&
											Boolean(formik.errors["language"])
										}
										sx={{mb: 2}}
									>
										<FormLabel htmlFor="language" required>{t('Language')}</FormLabel>
										<Autocomplete
											popupIcon={<ArrowDown2/>}
											options={languages}
											value={formik.values.language}
											isOptionEqualToValue={(opt, val) => {
												return opt.id === val.id || !val
											}}
											getOptionLabel={option => option?.Description}
											onChange={(event, newValue) => {
												formik.setFieldValue("language", newValue)
											}}
											onBlur={formik.handleBlur}
											size="small"
											fullWidth
											renderInput={(params) => (
												<TextField
													//label={t('Language')}
													required
													error={formik.touched.language && Boolean(formik.errors.language)}
													placeholder={t("Choose language")}
													size={"small"}
													{...params}
													inputProps={{
														...params.inputProps,
														autoComplete: 'off',
													}}
												/>
											)}
										/>
										<FormHelperText sx={{marginLeft: 0}}>
											<ErrorMessage name={"language"}/>
										</FormHelperText>
									</FormControl>
								</Stack>
							</Grid>
							<Grid item xs={12}>
								<Stack spacing={1}>
									<FormControl
										variant="outlined"
										fullWidth
										error={
											formik.touched["currency"] &&
											Boolean(formik.errors["currency"])
										}
										sx={{mb: 2}}
									>
										<FormLabel id="currency" required>{t('Currency')}</FormLabel>
										<Select
											native={Capacitor.getPlatform() === 'android'}
											labelId="currency-label"
											autoComplete={"chrome-off"}
											size={"small"}
											name={"currency"}
											value={formik.values.currency}
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											IconComponent={ArrowDown2}
										>
											{currenciesList}
										</Select>
										<FormHelperText>
											<ErrorMessage name={"currency"}/>
										</FormHelperText>
									</FormControl>
								</Stack>
							</Grid>
						</Grid>
						{/*TODO::It will be used as soon as the change to date format that the user has generally selected in the Portal works*/}
						{/*<FormControl
                        variant="outlined"
                        fullWidth
                        error={
                            formik.touched["DateFormatId"] &&
                            Boolean(formik.errors["DateFormatId"])
                        }
                        sx={{mb: 2}}
                    >
                        <FormLabel id="DateFormatId-label">{t('Date Format')}</FormLabel>
                        <Select
                            labelId="DateFormatId-label"
                            autoComplete={"chrome-off"}
                            size={"small"}
                            name={"DateFormatId"}
                            value={formik.values.DateFormatId}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            label={t('Date Format')}
                            defaultValue=""
                        >
                            {dateFormats.map(item => (
                                <MenuItem key={`DateFormatId-${item.id}`}
                                          value={item.id}>{item.Description}</MenuItem>))}
                        </Select>
                        <FormHelperText>
                            <ErrorMessage name={"DateFormatId"}/>
                        </FormHelperText>
                    </FormControl>*/}
						{/*TODO::It will be used as soon as the change to decimal that the user has generally selected in the Portal works*/}
						{/* <FormControl
                        variant="standard"
                        fullWidth
                        error={
                            formik.touched["DecimalChar"] &&
                            Boolean(formik.errors["DecimalChar"])
                        }
                        sx={{mb: 2}}
                    >
                        <TextField
                            type={'text'}
                            label={t('Decimal Character')}
                            value={formik.values.DecimalChar}
                            name={`DecimalChar`}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            fullWidth
                            color={"grey"}
                            size={'small'}
                            required
                            inputProps={{maxLength: 1}}
                        />
                        <FormHelperText>
                            <ErrorMessage name={"DecimalChar"}/>
                        </FormHelperText>
                    </FormControl>*/}
						{/* </Box>*/}
						{/*TODO:: For General App*/}
						{/*<Box
                        sx={{
                            p: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            paddingTop: '10px',
                            paddingLeft: '4px',
                            minWidth: '500px',
                            height: 'calc(100% - 48px)'
                        }}>

                        <FormControl
                            variant="standard"
                            fullWidth
                            error={
                                formik.touched["address"] &&
                                Boolean(formik.errors["address"])
                            }
                            sx={{mb: 2}}
                        >
                            <TextField
                                type={'address'}
                                label={t('Address')}
                                value={formik.values.address}
                                name={`address`}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                fullWidth
                                color={"grey"}
                                size={'small'}
                                required
                                inputProps={{maxLength: 100}}
                            />
                            <FormHelperText>
                                <ErrorMessage name={"address"}/>
                            </FormHelperText>
                        </FormControl>
                        <FormControl
                            variant="standard"
                            fullWidth
                            error={
                                formik.touched["city"] &&
                                Boolean(formik.errors["city"])
                            }
                            sx={{mb: 2}}
                        >
                            <TextField
                                type={'city'}
                                label={t('City')}
                                value={formik.values.city}
                                name={`city`}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                fullWidth
                                color={"grey"}
                                size={'small'}
                                inputProps={{maxLength: 30}}
                            />
                        </FormControl>
                        <FormControl
                            variant="standard"
                            fullWidth
                            error={
                                formik.touched["region"] &&
                                Boolean(formik.errors["region"])
                            }
                            sx={{mb: 3}}
                        >
                            <TextField
                                type={'region'}
                                label={t('Region')}
                                value={formik.values.region}
                                name={`region`}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                fullWidth
                                color={"grey"}
                                size={'small'}
                                inputProps={{maxLength: 30}}
                            />
                        </FormControl>
                        <FormControl
                            variant="standard"
                            fullWidth
                            error={
                                formik.touched["zipCode"] &&
                                Boolean(formik.errors["zipCode"])
                            }
                            sx={{mb: 2}}
                        >
                            <TextField
                                type={'zipCode'}
                                label={t('Zip Code')}
                                value={formik.values.zipCode}
                                name={`zipCode`}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                fullWidth
                                color={"grey"}
                                size={'small'}
                                inputProps={{maxLength: 6}}
                            />
                        </FormControl>
                    </Box>*/}
					</Grid>
					<Grid item xs={12} lg={12}>
						<Grid container spacing={1}>
							<PermissionHOC
								requiredPermissions={['view_cust_mobilephone_from_partner', 'edit_cust_mobilephone_from_partner']}>
								<Grid item xs={12}>
									<Stack spacing={2}>
										<FormControl
											variant="standard"
											fullWidth
											error={
												formik.touched["RequestedAmount"] &&
												Boolean(formik.errors["RequestedAmount"])
											}
										>
											<FormLabel htmlFor="RequestedAmount">{t('Loan Amount (€)')}</FormLabel>
											<NumericFormat
												value={formik.values.RequestedAmount}
												onValueChange={(values, sourceInfo) => {
													formik.setFieldValue('RequestedAmount', values.floatValue ? values.floatValue : '')
												}}
												thousandSeparator={thousandSeparator}
												decimalSeparator={decimalSeparator}
												decimalScale={2}
												customInput={TextField}
												
												id="RequestedAmount"
												placeholder={t('Enter loan amount')}
												onBlur={formik.handleBlur}
												name={`RequestedAmount`}
												color={"grey"}
												size={'small'}
												fullWidth
												inputProps={{maxLength: 100, step: 1000}}
												error={formik.touched.RequestedAmount && Boolean(formik.errors.RequestedAmount)}
											/>
											<FormHelperText>
												<ErrorMessage name={"RequestedAmount"}/>
											</FormHelperText>
										</FormControl>
									</Stack>
								</Grid>
							</PermissionHOC>
							<PermissionHOC requiredPermissions={['view_cust_comments', 'edit_cust_comments']}>
								<Grid item xs={12}>
									<Stack spacing={1}>
										<FormLabel htmlFor="Comments">{t('Comments')}</FormLabel>
										<TextareaAutosize
											aria-label="maximum height"
											maxRows={4}
											value={formik.values.Comments}
											onChange={formik.handleChange}
											name={'Comments'}
											style={textareaStyle}
											size={'small'}
										/>
									</Stack>
								</Grid>
							</PermissionHOC>
						</Grid>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12}>
						<Grid container spacing={1}>
							<PermissionHOC
								requiredPermissions={['view_cust_isInformed']}>
								<Grid item xs={12} sm={12} md={12} lg={12}>
									<FormControl
										variant="standard"
										fullWidth
										error={
											formik.touched["isInformed"] &&
											Boolean(formik.errors["isInformed"])
										}
										sx={{pb: 1, mt: 4}}
									>
										<FormControlLabel required control=
											{
												<Switch
													checked={formik.values.isInformed}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													size={'small'}
													name={'isInformed'}
												/>
											} label={
											<Typography sx={{
												fontSize: '12px',
												fontStyle: 'normal',
												fontWeight: 600,
												lineHeight: 'normal'
											}}>
												{t('I agree with the')}{' '}
												<Button
													onClick={handleOpenModal}
													sx={{
														fontSize: '12px',
														fontStyle: 'normal',
														fontWeight: 600,
														lineHeight: 'normal',
														textDecorationLine: 'underline',
														m: 0,
														p: 0
													}}
												>
													{t('Terms and Policies')}
												</Button>
											</Typography>
										} labelPlacement={"end"}
										/>
										<FormHelperText>
											<ErrorMessage name={"isInformed"}/>
										</FormHelperText>
									</FormControl>
								</Grid>
								<Dialog onClose={handleCloseModal} open={openModal}>
									<PrivacyPolicy isModal={true} handleCloseModal={handleCloseModal}/>
								</Dialog>
							</PermissionHOC>
							<PermissionHOC
								requiredPermissions={['view_cust_mobilephone_from_partner', 'edit_cust_mobilephone_from_partner']}>
								<Grid item xs={12}>
									<Stack spacing={1}>
										<FormControl variant="standard" fullWidth sx={{pb: 1}}>
											<FormControlLabel
												sx={{
													".Mui-disabled": {
														'WebkitTextFillColor': "#485776",
													},
												}}
												control={
													<Switch
														disabled={true}
														// checkedIcon={formik.values.isSendEmail === true ?
														//     <CheckSwitchIcon/> : <UncheckSwitchIcon/>}
														checked={formik.values.isSendEmail}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														size={'small'}
														name={'isSendEmail'}
													/>
												}
												label={t('Send an informative email to the customer')}
												labelPlacement="end"
											/>
										</FormControl>
									</Stack>
								</Grid>
								<Grid item xs={12} sm={12} md={12} lg={12}>
									<FormControl
										variant="standard"
										fullWidth sx={{pb: 1}}
										error={
											formik.touched["isInformed"] &&
											Boolean(formik.errors["isInformed"])
										}
									>
										<FormControlLabel
											required
											control={
												<Switch
													checked={formik.values.isInformed}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													size={'small'}
													name={'isInformed'}
												/>
											}
											label={t('The customer has consented to the processing of his data')}
											labelPlacement="end"
										/>
										<FormHelperText>
											<ErrorMessage name={"isInformed"}/>
										</FormHelperText>
									</FormControl>
								</Grid>
							</PermissionHOC>
						</Grid>
					</Grid>
				</Grid>
				<Grid container sx={{flexGrow: 1}} spacing={2}>
					<Grid item xs={12} sm={12} md={12} lg={12}>
						<Divider sx={{mb: '16px'}}/>
					</Grid>
					{/* <Divider color={theme.palette.primary[20]} sx={{zIndex: 5}}/>*/}
					<Grid item xs={12} sm={12} md={12} lg={12}
						  sx={{
							  display: 'flex',
							  gap: '30px',
							  mb: '16px',
							  '@media (max-width: 400px)': {
								  flexDirection: 'column'
							  }
						  }}>
						<Button
							size={"small"}
							variant={"contained"}
							color={'info'}
							sx={{
								padding: '17px 54px',
								justifyContent: 'center',
								alignItems: 'center'
							}}
							onClick={formik.submitForm}
						>
							{t('Save')}
						</Button>
						<Button
							size={"small"}
							variant={"contained"}
							color={"error"}
							onClick={handleClickOpen}
							sx={{
								padding: '17px 54px',
								justifyContent: 'center',
								alignItems: 'center'
							}}
						>
							{t('Cancel')}
						</Button>
					</Grid>
				</Grid>
				<AlertDialog
					open={open}
					handleClose={handleClose}
					id={id}
				/>
			</MainCard>
			{errorMess && <ErrorPopup message={errorMess} onClose={closeError}/>}
		</FormikProvider>
	) : (
		<ContentLoader
			withBackDrop={true}
			useCircularLoader={true}
			loading={isLoading}
			message={t("Loading... Please don't close the browser before the process ends.")}
		/>
	)
}
export default Form