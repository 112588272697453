import * as Namespaces from './namespaces'

const locales = {
	[Namespaces.NAVIGATION]: {
		el: {
			'Home': 'Αρχική',
			'Logout': 'Έξοδος',
			'Users': 'Χρήστες',
			'Account settings': 'Ρυθμίσεις Λογαριασμού',
			'PAGE NOT FOUNT': 'Η ΣΕΛΙΔΑ ΔΕΝ ΒΡΕΘΗΚΕ',
			'User': 'Χρήστης',
			'Customer': 'Πελάτης',
			'Customers': 'Πελάτες',
			'Questionnaire': 'Ερωτηματολόγιο',
			'Countries': 'Χώρες',
			'Customer Status': 'Κατάσταση Πελάτη',
			'Loan Status': 'Κατάσταση Δανείου',
			'Loan Purpose': 'Σκοπός δανείου',
			'Documents': 'Έγγραφα',
			'Groups': 'Ομάδες',
			'Loans': 'Δάνεια',
			'Documents Associations': 'Συσχέτιση Εγγράφων',
			'Roles': 'Ρόλοι',
			'Rights': 'Δικαιώματα',
			'Rights and Roles': 'Δικαιώματα και Ρόλοι',
			'Origin': 'Προέλευση',
			'My Profile': 'Το Προφίλ μου',
			'Loans Requests': 'Αιτήματα δανείων',
			'Parameters': 'Παράμετροι',
			'Failed to get notifications.': 'Αδυναμία φόρτωσης ειδοποιήσεων.',
			'Nationalities': 'Εθνικότητες',
			'Bank Spread': 'Τραπεζικό spread',
			'Notifications': 'Ειδοποιήσεις',
			'Updates': 'Ενημερώσεις',
			'My Loans': 'Τα Δάνειά μου',
			'Mortgage Loan': 'Στεγαστικό Δάνειο',
			"Installment Calculation": 'Υπολογισμός Δόσης',
			"Installment payment notice": 'Ειδοποίηση πληρωμής δόσης',
			'Loan Calculation': 'Υπολογισμός Δανείου',
			"Interest Rate": "Επιτόκιο",
			'There are no new updates available!': 'Δεν υπάρχουν νέες διαθέσιμες ενημερώσεις!',
			'Menu': 'Μενού',
			'Calculation variables': 'Μεταβλητές υπολογισμών',
			"Documents Types": 'Τύποι εγγράφων',
			"Dark Mode": "Νυχτερινή λειτουργία",
			"Light Mode": "Φωτεινή λειτουργία",
			"Locales": "Ρυθμίσεις τοποθεσίας",
			"Loan": "Δάνειο",
			"Calculation": "Υπολογισμός",
			"Calculations": "Υπολογισμοί",
			"Profile": "Προφίλ",
			"My calculations": "Υπολογισμοί μου",
			"My loans": "Δάνειά μου",
			'Privacy policy': 'Πολιτική απορρήτου',
			'Documents/ Credentials': 'Έγγραφα/ Δικαιολογητικά',
			'documents': 'Έγγραφα/ Δικαιολογητικά',
			"Installment payment": "Πληρωμή δόσης",
			"Question from IMS": "Ερώτημα από IMS",
			"Change loan application status": "Αλλαγή κατάστασης δανείου",
			"Questionnaires": "Ερωτηματολόγια",
			'Update for pending': 'Ενημέρωση για εκκρεμότητα',
			'Support': 'Υποστήριξη',
			'User settings': 'Ρυθμίσεις χρηστών',
			'Loan settings': 'Ρυθμίσεις δανείων',
			'Customer settings': 'Ρυθμίσεις πελατών'
		},
		en: {
			'Home': 'Home',
			'Logout': 'Logout',
			'Users': 'Users',
			'Account settings': 'Account settings',
			'PAGE NOT FOUNT': 'PAGE NOT FOUNT',
			'User': 'User',
			'documents': 'Documents'
		},
	},
	[Namespaces.ALERTS]: {
		el: {
			'Failed to get country list.': 'Αδυναμία φόρτωσης λίστας χωρών.',
			'Failed to delete country!': 'Αποτυχία διαγραφής χώρας!',
			'Country deleted successfully!': 'Επιτυχής διαγραφή χώρας!',
			'Failed to update country!': 'Αποτυχία ενημέρωσης χώρας!',
			'Country updated successfully!': 'Επιτυχής ενημέρωση χώρας!',
			'Failed to store new country!': 'Αποτυχία καταχώρισης χώρας!',
			'New country added successfully!': 'Επιτυχής προσθήκη χώρας!',
			
			'Failed to get documents associations list.': 'Αδυναμία φόρτωσης λίστας συσχετίσεων εγγράφων.',
			'Failed to delete documents association!': 'Αποτυχία διαγραφής συσχέτισης εγγράφων!',
			'Documents association deleted successfully!': 'Επιτυχής διαγραφή συσχέτισης εγγράφων!',
			'Failed to update documents association!': 'Αποτυχία ενημέρωσης συσχέτισης εγγράφων!',
			'Documents association updated successfully!': 'Επιτυχής ενημέρωση συσχέτισης εγγράφων!',
			'Failed to store new documents association!': 'Αποτυχία καταχώρισης συσχέτισης εγγράφων!',
			'New documents association added successfully!': 'Επιτυχής προσθήκη συσχέτισης εγγράφων!',
			'Failed to get currencies list.': 'Αδυναμία φόρτωσης λίστας νομισμάτων.',
			'Failed to get language list.': 'Αδυναμία φόρτωσης λίστας γλωσσών.',
			'Failed to get user origin list.': 'Αδυναμία φόρτωσης λίστας προελεύσεων χρηστών.',
			'Failed to get user details.': 'Αδυναμία φόρτωσης στοιχείων χρήστη.',
			'Failed to get customer details.': 'Αδυναμία φόρτωσης στοιχείων πελάτη.',
			'Failed to get date format list.': 'Αδυναμία φόρτωσης λίστας μορφών ημερομηνίας.',
			'Failed to get documents list.': 'Αδυναμία φόρτωσης λίστας εγγράφων.',
			'Failed to get loan types list.': 'Αδυναμία φόρτωσης λίστας τύπων δανείου.',
			'Failed to get loan statuses list.': 'Αδυναμία φόρτωσης λίστας καταστάσεων δανείου.',
			'Failed to delete document!': 'Αποτυχία διαγραφής εγγράφου!',
			'Document deleted successfully!': 'Επιτυχής διαγραφή εγγράφου!',
			'Failed to update document!': 'Αποτυχία ενημέρωσης εγγράφου!',
			'Document updated successfully!': 'Επιτυχής ενημέρωση εγγράφου!',
			'Failed to store new document!': 'Αποτυχία καταχώρισης εγγράφου!',
			'New document added successfully!': 'Επιτυχής προσθήκη εγγράφου!',
			'Failed to get groups list.': 'Αδυναμία φόρτωσης λίστας ομάδων',
			'Failed to delete group!': 'Αποτυχία διαγραφής ομάδας!',
			'Group deleted successfully!': 'Επιτυχής διαγραφή ομάδας!',
			'Failed to update group!': 'Αποτυχία ενημέρωσης ομάδας!',
			'Group updated successfully!': 'Επιτυχής ενημέρωση ομάδας!',
			'Failed to store new group!': 'Αποτυχία καταχώρισης ομάδας!',
			'New group added successfully!': 'Επιτυχής προσθήκη ομάδας!',
			'Failed to get customer status list.': 'Αδυναμία φόρτωσης λίστας καταστάσεων πελάτη.',
			'Failed to delete customer status!': 'Αποτυχία διαγραφής κατάστασης πελάτη!',
			'Customer status deleted successfully!': 'Επιτυχής διαγραφή κατάστασης πελάτη!',
			'Failed to update customer status!': 'Αποτυχία ενημέρωσης κατάστασης πελάτη!',
			'Customer status updated successfully!': 'Επιτυχής ενημέρωση κατάστασης πελάτη!',
			'Failed to store new customer status!': 'Αποτυχία καταχώρισης κατάστασης πελάτη!',
			'New customer status added successfully!': 'Επιτυχής προσθήκη κατάστασης πελάτης!',
			'Failed to get rights list.': 'Αδυναμία φόρτωσης λίστας δικαιωμάτων.',
			'New rights added successfully!': 'Επιτυχής προσθήκη δικαιώματος!',
			'Failed to store new rights!': 'Αποτυχία καταχώρισης δικαιώματος!',
			'Rights deleted successfully!': 'Επιτυχής διαγραφή δικαιώματος!',
			'Failed to delete rights!': 'Αποτυχία διαγραφής δικαιώματος!',
			'Failed to get rights and roles list.': 'Αδυναμία φόρτωσης λίστας δικαιωμάτων και ρόλων.',
			'New rights and roles added successfully!': 'Επιτυχής προσθήκη δικαιώματος και ρόλου!',
			'Failed to store new rights and roles!': 'Αποτυχία καταχώρισης δικαιώματος και ρόλου!',
			'Rights and roles deleted successfully!': 'Επιτυχής διαγραφή δικαιώματος και ρόλου!',
			'Failed to delete rights and roles!': 'Αποτυχία διαγραφής δικαιώματος και ρόλου!',
			'Failed to update rights and roles!': 'Αποτυχία ενημέρωσης δικαιώματος και ρόλου!',
			'Rights and roles updated successfully!': 'Επιτυχής ενημέρωση δικαιώματος και ρόλου!',
			'New contribution added successfully!': 'Επιτυχής προσθήκη εισφοράς!',
			'Failed to store new contribution!': 'Αποτυχία καταχώρισης εισφοράς!',
			'Contribution deleted successfully!': 'Επιτυχής διαγραφή εισφοράς!',
			'Failed to delete contribution!': 'Αποτυχία διαγραφής εισφοράς!',
			'Interest Rates Updated Successfully!': 'Επιτυχής ενημέρωση επιτοκίων!',
			'Failed to get interest rates.': 'Αδυναμία φόρτωσης επιτοκίων.',
			'Unauthorized': 'Ο χρήστης δεν έχει πρόσβαση στην ιστοσελίδα.',
			'Failed to get notifications.': 'Αδυναμία φόρτωσης ειδοποιήσεων.',
			'Duplicate Entry': 'Υπάρχει ήδη αυτή η εγγραφή',
			'Not Found': 'Δεν βρέθηκε',
			
			'Failed to get loan details.': 'Αδυναμία φόρτωσης λεπτομερειών δανείου.',
			'Failed to get details.': 'Αδυναμία φόρτωσης λεπτομερειών.',
			'Failed to get contribution.': 'Αδυναμία φόρτωσης εισφοράς.',
			'Failed to get nationalities list.': 'Αδυναμία φόρτωσης λίστας εθνικοτήτων.',
			
			'Failed to get roles list.': 'Αδυναμία φόρτωσης λίστας ρόλων.',
			'Failed to store role!': 'Αποτυχία αποθήκευσης ρόλου!',
			'Failed to delete role!': 'Αποτυχία διαγραφής ρόλου!',
			'Success Store': 'Επιτυχής αποθήκευση',
			'Failed to update role!': 'Αποτυχία ενημέρωσης ρόλου!',
			'Failed to get origins list.': 'Αποτυχία φόρτωσης λίστας προελεύσεων!',
			'Failed to get calculation details.': 'Αποτυχία φόρτωσης λεπτομερειών υπολογισμού.',
			'Repayment deleted successfully!': 'Η αποπληρωμή αποθηκεύτηκε με επιτυχία!',
			'Failed to delete repayment!': 'Αποτυχία διαγραφής αποπληρωμής!',
			'Document type added successfully!': 'Επιτυχής προσθήκη τύπου εγγράφου!',
			'Failed to store new document type!': 'Αποτυχία αποθήκευσης τύπου εγγράφου!',
			'Document type updated successfully!': 'Επιτυχής ενημέρωση τύπου εγγράφου!',
			'Failed to update document type!': 'Αποτυχία ενημέρωσης τύπου εγγράφου!',
			'Document type deleted successfully!': 'Ο τύπος εγγράφου αποθηκεύτηκε με επιτυχία!',
			'Failed to delete document type!': 'Αποτυχία διαγραφής τύπου εγγράφου!',
			'Value must be a positive number.': 'Η τιμή πρέπει να είναι θετικός αριθμός.',
			'Required': 'Υποχρεωτικό',
			'Loading...': 'Φόρτωση...',
			"Loading... Please don't close the browser before the process ends.": "Φόρτωση... Μην κλείσετε το πρόγραμμα περιήγησης πριν τελειώσει η διαδικασία.",
			'Description updated successfully!': 'Η περιγραφή ενημερώθηκε με επιτυχία!',
			'Failed to update description!': 'Αδυναμία φόρτωσης περιγραφής!',
			'Origin updated successfully!': 'Η προέλευση ενημερώθηκε με επιτυχία!',
			'Failed to update origin!': 'Αδυναμία φόρτωσης προέλευσης!',
			'Loan type updated successfully!': 'Ο σκοπός δανείου ενημερώθηκε με επιτυχία!',
			'Failed to update loan type!': 'Αδυναμία φόρτωσης σκοπού δανείου!',
			'Loan status updated successfully!': 'Η κατάσταση δανείου ενημερώθηκε με επιτυχία!',
			'Failed to update loan status!': 'Αδυναμία φόρτωσης κατάστασης δανείου!',
			'The document type is used!': ' Ο τύπος εγγράφου χρησιμοποιείται!'
		},
		en: {},
	},
	[Namespaces.FILES]: {
		el: {
			'Download': 'Λήψη',
			'Edit': 'Επεξεργασία',
			'Delete': 'Διαγραφή',
			'Description': 'Περιγραφή',
			'Save': 'Αποθήκευση',
			'Cancel': 'Ακύρωση',
			'Uploading': 'Μεταφόρτωση',
			'No description yet': 'Χωρίς περιγραφή',
			'Uploaded File(s)': 'Ανεβασμένα Αρχεία',
			"Drag 'n' drop some files here, or click to select files": 'Σύρετε και αποθέστε μερικά αρχεία εδώ ή κάντε κλικ για να επιλέξετε αρχεία',
			'Upload File': 'Ανέβασμα Αρχείου',
			'Import from excel': 'Εισαγωγή από Excel',
			'import_excel_msg': 'Εάν θέλετε να προσθέσετε περισσότερα από ένα άτομα γρήγορα, μπορείτε να ανεβάσετε ένα αρχείο <strong>Excel</strong>.<br/><ul><li>Το αρχείο excel πρέπει να ακολουθεί τη δομή που περιγράφεται παρακάτω.</li><li>Βεβαιωθείτε ότι οι στήλες σας είναι σε σειρά όπως στον πίνακα του παραδείγματος.</li><li>Για τη διευκόλυνσή σας, μπορείτε επίσης να κάνετε λήψη του κενού προτύπου excel.</li></ul>',
			'Phone': 'ΤΗΛΕΦΩΝΟ',
			'Download excel template': 'Κατέβασμα προτύπου excel',
			'Import selected file': 'Εισαγωγή επιλεγμένου αρχείου',
			"At least one file needs to be here": 'Τουλάχιστον ένα αρχείο πρέπει να είναι εδώ',
			//'import_gdpr_msg': 'Ανεβάστε το αρχείο GDPR με τα στοιχεία σας'
			'import_gdpr_msg': 'Για να μπορέσουμε να επεξεργαστούμε τα στοιχεία σας χρειαζόμαστε υπογεγραμμένο το άρχειο σχετικά με τους κανόνες <strong>GDPR</strong>.<br/>'
			/*<ul><li>Μπορείτε να ανεβάσετε το αρχείο GDPR με τα στοιχεία σας εδώ είτε να το δώσετε αυτοπροσώπος σε κάποιον σύμβουλο της IMS είτε να του το αποστείλετε με email.</li> <li>Για τη διευκόλυνση σας, μπορείτε να κατεβάσετε παρακάτω το αρχείο που πρέπει να συμπληρώσετε.</li><li>Περισσότερες πληροφορίες σχετικά με τους κανόνες GDPR μπορείτε να βρείτε στην αρχική σελίδα και στο site μας.</li><li>Η αποδοχή της δήλωσης απορρήτου είναι υποχρεωτική για να προχωρήσετε.</li></ul>'*/,
			'Download GDPR file': 'Κατέβασμα GDPR αρχείου',
			'The excel file must follow the structure described in the template excel file that you can download from ': 'Το αρχείο excel πρέπει να ακολουθεί τη δομή που περιγράφεται στο πρότυπο αρχείο Excel που μπορείτε να κατεβάσετε από ',
			'Drag and drop some files here': 'Σύρετε και αποθέστε μερικά αρχεία εδώ',
			'here': 'εδώ',
			'Upload Files': 'Ανέβασμα αρχείων'
		},
		en: {},
	},
	[Namespaces.EDITABLE_MUI_GRID]: {
		el: {
			'Add': 'Προσθήκη',
			'Actions': 'Ενέργειες',
			'Press Yes to confirm entry deletion.': 'Πατήστε ΝΑΙ για να επιβεβαιώσετε τη διαγραφή της εγγραφής.',
			'Delete Entry Confirmation.': 'Επιβεβαίωση Διαγραφής.',
			'no': 'Όχι',
			'yes': 'Ναι',
			noRowsLabel: 'Δεν υπάρχουν καταχωρίσεις',
			noResultsOverlayLabel: 'Δεν βρέθηκαν αποτελέσματα.',
			toolbarDensity: 'Ύψος σειράς',
			toolbarDensityLabel: 'Ύψος σειράς',
			toolbarDensityCompact: 'Συμπαγής',
			toolbarDensityStandard: 'Προκαθορισμένο',
			toolbarDensityComfortable: 'Πλατύ',
			toolbarColumns: 'Στήλες',
			toolbarColumnsLabel: 'Επιλέξτε στήλες',
			toolbarFilters: 'Φίλτρα',
			toolbarFiltersLabel: 'Εμφάνιση φίλτρων',
			toolbarFiltersTooltipHide: 'Απόκρυψη φίλτρων',
			toolbarFiltersTooltipShow: 'Εμφάνιση φίλτρων',
			toolbarFiltersTooltipActive: (count) =>
				count !== 1 ? `${count} ενεργά φίλτρα` : `${count} ενεργό φίλτρο`,
			toolbarQuickFilterPlaceholder: 'Αναζήτηση...',
			toolbarQuickFilterLabel: 'Αναζήτηση',
			toolbarQuickFilterDeleteIconLabel: 'Καθαρισμός',
			toolbarExport: 'Εξαγωγή',
			toolbarExportLabel: 'Εξαγωγή',
			toolbarExportCSV: 'Λήψη ως CSV',
			toolbarExportPrint: 'Εκτύπωση',
			toolbarExportExcel: 'Κατέβασμα ως Excel',
			columnsPanelTextFieldLabel: 'Εύρεση στήλης',
			columnsPanelTextFieldPlaceholder: 'Επικεφαλίδα στήλης',
			columnsPanelDragIconLabel: 'Αναδιάταξη στήλης',
			columnsPanelShowAllButton: 'Προβολή όλων',
			columnsPanelHideAllButton: 'Απόκρυψη όλων',
			filterPanelAddFilter: 'Προσθήκη φίλτρου',
			filterPanelRemoveAll: 'Αφαίρεση όλων',
			filterPanelDeleteIconLabel: 'Διαγραφή',
			filterPanelLogicOperator: 'Λογικός τελεστής',
			filterPanelOperator: 'Τελεστές',
			filterPanelOperatorAnd: 'και',
			filterPanelOperatorOr: 'ή',
			filterPanelColumns: 'Στήλες',
			filterPanelInputLabel: 'Τιμή',
			filterPanelInputPlaceholder: 'Τιμή φίλτρου',
			filterOperatorContains: 'περιέχει',
			filterOperatorEquals: 'ισούται',
			filterOperatorStartsWith: 'ξεκινάει με',
			filterOperatorEndsWith: 'τελειώνει με',
			filterOperatorIs: 'είναι',
			filterOperatorNot: 'δεν είναι',
			filterOperatorAfter: 'είναι μετά',
			filterOperatorOnOrAfter: 'είναι ίσο ή μετά',
			filterOperatorBefore: 'είναι πριν',
			filterOperatorOnOrBefore: 'είναι ίσο ή πριν',
			filterOperatorIsEmpty: 'είναι κενό',
			filterOperatorIsNotEmpty: 'δεν είναι κενό',
			filterOperatorIsAnyOf: 'είναι οποιοδήποτε από',
			filterValueAny: 'οποιοδήποτε',
			filterValueTrue: 'αληθής',
			filterValueFalse: 'ψευδής',
			columnMenuLabel: 'Μενού',
			columnMenuShowColumns: 'Εμφάνιση στηλών',
			columnMenuManageColumns: 'Διαχείριση στηλών',
			columnMenuFilter: 'Φίλτρο',
			columnMenuHideColumn: 'Απόκρυψη',
			columnMenuUnsort: 'Απενεργοποίηση ταξινόμησης',
			columnMenuSortAsc: 'Ταξινόμηση σε αύξουσα σειρά',
			columnMenuSortDesc: 'Ταξινόμηση σε φθίνουσα σειρά',
			columnHeaderFiltersTooltipActive: (count) =>
				count !== 1 ? `${count} ενεργά φίλτρα` : `${count} ενεργό φίλτρο`,
			columnHeaderFiltersLabel: 'Εμφάνιση φίλτρων',
			columnHeaderSortIconLabel: 'Ταξινόμηση',
			footerRowSelected: (count) =>
				count !== 1
					? `${count.toLocaleString()} επιλεγμένες γραμμές`
					: `${count.toLocaleString()} επιλεγμένη γραμμή`,
			footerTotalRows: 'Σύνολο Γραμμών:',
			footerTotalVisibleRows: (visibleCount, totalCount) =>
				`${visibleCount.toLocaleString()} από ${totalCount.toLocaleString()}`,
			checkboxSelectionHeaderName: 'Επιλογή πλαισίου ελέγχου',
			checkboxSelectionSelectAllRows: 'Επιλέξτε όλες τις σειρές',
			checkboxSelectionUnselectAllRows: 'Καταργήστε την επιλογή όλων των σειρών',
			checkboxSelectionSelectRow: 'Επιλέξτε σειρά',
			checkboxSelectionUnselectRow: 'Καταργήστε την επιλογή σειράς',
			booleanCellTrueLabel: 'Ναι',
			booleanCellFalseLabel: 'Όχι',
			actionsCellMore: 'περισσότερα',
			pinToLeft: 'Καρφιτσώστε αριστερά',
			pinToRight: 'Καρφιτσώστε δεξιά',
			unpin: 'Ξεκαρφίτσωμα',
			treeDataGroupingHeaderName: 'Ομαδοποίησης Δεδομένων ',
			treeDataExpand: 'Ανάπτυξη',
			treeDataCollapse: 'Σύμπτυξη ',
			groupingColumnHeaderName: 'Ομαδοποίησης στηλών',
			groupColumn: name => `Ομαδοποίηση κατά ${name}`,
			unGroupColumn: name => `Διακοπή ομαδοποίησης κατά ${name}`,
			detailPanelToggle: 'Εναλλαγή πίνακα λεπτομερειών',
			expandDetailPanel: 'Ανάπτυξη',
			collapseDetailPanel: 'Σύμπτυξη',
			rowReorderingHeaderName: 'Αναδιάταξη σειρών',
			aggregationMenuItemHeader: 'Συσσωμάτωση',
			aggregationFunctionLabelSum: 'άθροισμα',
			aggregationFunctionLabelAvg: 'μέσος όρος',
			aggregationFunctionLabelMin: 'ελάχ.',
			aggregationFunctionLabelMax: 'μέγ.',
			aggregationFunctionLabelSize: 'μέγεθος',
			headerFilterOperatorContains: 'Περιέχει',
			headerFilterOperatorEquals: 'Ισούται',
			headerFilterOperatorStartsWith: 'Ξεκινάει με',
			headerFilterOperatorEndsWith: 'Τελειώνει με',
			headerFilterOperatorIs: 'Είναι',
			headerFilterOperatorNot: 'Δεν είναι',
			headerFilterOperatorAfter: 'είναι μετά',
			headerFilterOperatorOnOrAfter: 'είναι ίσο ή μετά',
			headerFilterOperatorBefore: 'είναι πριν',
			headerFilterOperatorOnOrBefore: 'είναι ίσο ή πριν',
			headerFilterOperatorIsEmpty: 'είναι κενό',
			headerFilterOperatorIsNotEmpty: 'δεν είναι κενό',
			headerFilterOperatorIsAnyOf: 'είναι οποιοδήποτε από',
			'headerFilterOperator=': 'Equals',
			'headerFilterOperator!=': 'Not equals',
			'headerFilterOperator>': 'Greater than',
			'headerFilterOperator>=': 'Greater than or equal to',
			'headerFilterOperator<': 'Less than',
			'headerFilterOperator<=': 'Less than or equal to',
			// Columns management text
			columnsManagementSearchTitle: 'Αναζήτηση',
			columnsManagementNoColumns: 'Χωρίς στήλες',
			columnsManagementShowHideAllText: 'Εμφάνιση/Απόκρυψη όλων',
		},
		en: {
			'no': 'No',
			'yes': 'Yes',
			noRowsLabel: 'No entries found',
			noResultsOverlayLabel: 'No results found.',
			toolbarDensity: 'Row height',
			toolbarDensityLabel: 'Row height',
			toolbarDensityCompact: 'Compact',
			toolbarDensityStandard: 'Standard',
			toolbarDensityComfortable: 'Comfortable',
			toolbarColumns: 'Columns',
			toolbarColumnsLabel: 'Select columns',
			toolbarFilters: 'Filters',
			toolbarFiltersLabel: 'Show filters',
			toolbarFiltersTooltipHide: 'Hide filters',
			toolbarFiltersTooltipShow: 'Show filters',
			toolbarFiltersTooltipActive: (count) =>
				count !== 1 ? `${count} active filters` : `${count} active filter`,
			toolbarQuickFilterPlaceholder: 'Search...',
			toolbarQuickFilterLabel: 'Search',
			toolbarQuickFilterDeleteIconLabel: 'Clear',
			toolbarExport: 'Export',
			toolbarExportLabel: 'Export',
			toolbarExportCSV: 'Download as CSV',
			toolbarExportPrint: 'Print',
			toolbarExportExcel: 'Download as Excel',
			columnsPanelTextFieldLabel: 'Find column',
			columnsPanelTextFieldPlaceholder: 'Column header',
			columnsPanelDragIconLabel: 'Reorder column',
			columnsPanelShowAllButton: 'Show all',
			columnsPanelHideAllButton: 'Hide all',
			filterPanelAddFilter: 'Add filter',
			filterPanelRemoveAll: 'Remove all',
			filterPanelDeleteIconLabel: 'Delete',
			filterPanelLogicOperator: 'Logic operator',
			filterPanelOperator: 'Operators',
			filterPanelOperatorAnd: 'and',
			filterPanelOperatorOr: 'or',
			filterPanelColumns: 'Columns',
			filterPanelInputLabel: 'Value',
			filterPanelInputPlaceholder: 'Filter value',
			filterOperatorContains: 'contains',
			filterOperatorEquals: 'equals',
			filterOperatorStartsWith: 'starts with',
			filterOperatorEndsWith: 'ends with',
			filterOperatorIs: 'is',
			filterOperatorNot: 'is not',
			filterOperatorAfter: 'is after',
			filterOperatorOnOrAfter: 'is on or after',
			filterOperatorBefore: 'is before',
			filterOperatorOnOrBefore: 'is on or before',
			filterOperatorIsEmpty: 'is empty',
			filterOperatorIsNotEmpty: 'is not empty',
			filterOperatorIsAnyOf: 'is any of',
			filterValueAny: 'any',
			filterValueTrue: 'true',
			filterValueFalse: 'false',
			columnMenuLabel: 'Menu',
			columnMenuShowColumns: 'Show columns',
			columnMenuManageColumns: 'Manage columns',
			columnMenuFilter: 'Filter',
			columnMenuHideColumn: 'Hide',
			columnMenuUnsort: 'Disable sorting',
			columnMenuSortAsc: 'Sort ascending',
			columnMenuSortDesc: 'Sort descending',
			columnHeaderFiltersTooltipActive: (count) =>
				count !== 1 ? `${count} active filters` : `${count} active filter`,
			columnHeaderFiltersLabel: 'Show filters',
			columnHeaderSortIconLabel: 'Sort',
			footerRowSelected: (count) =>
				count !== 1
					? `${count.toLocaleString()} selected rows`
					: `${count.toLocaleString()} selected row`,
			footerTotalRows: 'Total Rows:',
			footerTotalVisibleRows: (visibleCount, totalCount) =>
				`${visibleCount.toLocaleString()} of ${totalCount.toLocaleString()}`,
			checkboxSelectionHeaderName: 'Checkbox selection',
			checkboxSelectionSelectAllRows: 'Select all rows',
			checkboxSelectionUnselectAllRows: 'Unselect all rows',
			checkboxSelectionSelectRow: 'Select row',
			checkboxSelectionUnselectRow: 'Unselect row',
			booleanCellTrueLabel: 'Yes',
			booleanCellFalseLabel: 'No',
			actionsCellMore: 'more',
			pinToLeft: 'Pin to left',
			pinToRight: 'Pin to right',
			unpin: 'Unpin',
			treeDataGroupingHeaderName: 'Data Grouping',
			treeDataExpand: 'Expand',
			treeDataCollapse: 'Collapse',
			groupingColumnHeaderName: 'Grouping Columns',
			groupColumn: (name) => `Group by ${name}`,
			unGroupColumn: (name) => `Stop grouping by ${name}`,
			detailPanelToggle: 'Detail panel toggle',
			expandDetailPanel: 'Expand',
			collapseDetailPanel: 'Collapse',
			rowReorderingHeaderName: 'Row Reordering',
			aggregationMenuItemHeader: 'Aggregation',
			aggregationFunctionLabelSum: 'Sum',
			aggregationFunctionLabelAvg: 'Avg',
			aggregationFunctionLabelMin: 'Min',
			aggregationFunctionLabelMax: 'Max',
			aggregationFunctionLabelSize: 'Size',
			headerFilterOperatorContains: 'Contains',
			headerFilterOperatorEquals: 'Equals',
			headerFilterOperatorStartsWith: 'Starts with',
			headerFilterOperatorEndsWith: 'Ends with',
			headerFilterOperatorIs: 'Is',
			headerFilterOperatorNot: 'Is not',
			headerFilterOperatorAfter: 'Is after',
			headerFilterOperatorOnOrAfter: 'Is on or after',
			headerFilterOperatorBefore: 'Is before',
			headerFilterOperatorOnOrBefore: 'Is on or before',
			headerFilterOperatorIsEmpty: 'Is empty',
			headerFilterOperatorIsNotEmpty: 'Is not empty',
			headerFilterOperatorIsAnyOf: 'Is any of',
			'headerFilterOperator=': 'Ισούται',
			'headerFilterOperator!=': 'Δεν ισούται',
			'headerFilterOperator>': 'Μεγαλύτερο από',
			'headerFilterOperator>=': 'Μεγαλύτερο ή ίσο με',
			'headerFilterOperator<': 'Μικρότερο από',
			'headerFilterOperator<=': 'Μικρότερο ή ίσο με',
			// Columns management text
			columnsManagementSearchTitle: 'Search',
			columnsManagementNoColumns: 'No columns',
			columnsManagementShowHideAllText: 'Show/Hide All',
		},
	},
	[Namespaces.AXIOS_ERROR_MESSAGE]: {
		el: {
			'More': 'Περισσότερα..',
			'Less': 'Λιγότερα',
			'Unauthorized': 'Ο χρήστης δεν έχει πρόσβαση στην ιστοσελίδα.',
			'Failed to get notifications.': 'Αδυναμία φόρτωσης ειδοποιήσεων.',
			'Duplicate Entry': 'Υπάρχει ήδη αυτή η εγγραφή',
			'Not Found': 'Δεν βρέθηκε',
			errorMessage: 'Μήνυμα',
			'Message': 'Μήνυμα',
			moreMessage: '',
			'Code': 'Κωδικός',
			'Status': 'Status',
		},
		en: {
			'More': 'More..',
		}
	},
	[Namespaces.COPY_TO_CLIPBOARD]: {
		el: {
			'Copy': 'Αντιγραφή',
			'Copied': 'Αντιγράφηκε',
		},
		en: {}
	}, [Namespaces.IMPORT_ALERT]: {
		el: {
			"Success": 'Επιτυχία',
			"Imported successfully.": 'Η εισαγωγή έγινε με επιτυχία.',
			"Error": "Σφάλμα",
			"Import failed, check description below for more details.": "Η εισαγωγή απέτυχε, ελέγξτε την περιγραφή παρακάτω για περισσότερες λεπτομέρειες.",
			"Warning": "Προειδοποίηση",
			"Import process finished with errors. Some data could not be imported check description for more details.": "Η διαδικασία εισαγωγής ολοκληρώθηκε με σφάλματα. Δεν ήταν δυνατή η εισαγωγή ορισμένων δεδομένων, ελέγξτε την περιγραφή για περισσότερες λεπτομέρειες.",
			"Info": "Πληροφορίες",
			"Nothing to do here.": "Δεν υπάρχει κάτι να γίνει εδώ."
		},
		en: {}
	},
}

export default locales
