// @flow
import * as React from 'react';
import {Fragment} from "react";
import {useParams} from "react-router-dom";
import {withPermissionHOC} from "../../../permission/components/permissionHOC/permissionHOC";
import Form from "../../components/form/form";
import useLoan from "../../hooks/useLoan";
import useType from "../../hooks/useType";
import useOriginLoan from "../../hooks/useOriginLoan";

const Create = () => {
    let {id} = useParams()
    const {type} = useType()
    const {originLoan} = useOriginLoan()
    const {
        loan,
        questionnaire,
        loading
    } = useLoan({id})

    return !loading && (
        <Fragment>
            <Form
                id={id}
                loan={loan}
                type={type}
                originLoan={originLoan}
                questionnaire={questionnaire}
            />
        </Fragment>
    )
}

export default withPermissionHOC(Create, ['access_loan_create', 'access_loan_edit'])