import React from "react";
import { Dialog, DialogContent, DialogTitle, Button, DialogActions, Grid } from "@mui/material";
import DangerTriangleIcon from "../../../../assets/images/dangerTriangleIcon";
import { useTranslation } from "react-i18next";
import { CUSTOMERS } from "../../locales/namespaces";

const ErrorPopup = ({ message, onClose }) => {
	const { t } = useTranslation(CUSTOMERS)
	
	return (
		<Dialog open={true} onClose={onClose}>
			<div style={{
				display: 'flex',
				padding: '5px 3.652px 4.751px 3.667px',
				justifyContent: 'center',
				alignItems: 'center',
				flexShrink: 0,
				paddingTop: '18px'
			}}>
				<DangerTriangleIcon/>
			</div>
			<Grid
				sx={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					paddingLeft: '24px',
					paddingRight: '24px'
				}}
			>
				<DialogTitle sx={{ textAlign: 'center', fontSize: '20px' }}>{t('Unable to register customer')}</DialogTitle>
				
				<DialogContent sx={{ textAlign: 'center', fontSize: '14px','& .MuiTypography-root': { color: '#485776' } }}>
					{message}
				</DialogContent>
			</Grid>
			<DialogActions
				sx={{
					justifyContent: 'center',
					mb: '31px'
				}}
			>
				<Button
					onClick={onClose}
					size={"small"}
					variant={"contained"}
					color={'error'}
				>
					ΟΚ
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ErrorPopup;