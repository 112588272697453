import Countries from '../pages/countries/countries'
import CustomerStatus from '../pages/customerStatus/customerStatus'
import Documents from '../pages/documents/documents'
import Groups from '../pages/groups/groups'
import LoanStatus from '../pages/loanStatus/loanStatus'
import LoanType from '../pages/loanType/loanType'
import DocsAssociations from '../pages/docsAssociations/docsAssociations'
import Rights from '../pages/rights/rights'
import RightsRoles from '../pages/rightsRoles/rightsRoles'
import Roles from '../pages/roles/roles'
import Origins from "../pages/origins/origins";
import {routeCreator} from '../../../helpers/routeCreator'
import Nationalities from "../pages/nationalities/nationalities";
import InterestRates from "../pages/interestRates/interestRates";
import DocumentsTypes from "../pages/documentsTypes/documentsTypes";
import Questionnaires from "../pages/questionnaires/questionnaires";

export const ROUTE_PAGE_PARAMETERS_COUNTRIES = routeCreator(Countries, '/parameters/countries', 'Countries', 'page.countries', {exact: true})
export const ROUTE_PAGE_PARAMETERS_NATIONALITIES = routeCreator(Nationalities, '/parameters/nationalities', 'Nationalities', 'page.nationalities', {exact: true})
export const ROUTE_PAGE_PARAMETERS_CUSTOMER_STATUS = routeCreator(CustomerStatus, '/parameters/cust/status', 'CustomerStatus', 'page.custStatus', {exact: true})
export const ROUTE_PAGE_PARAMETERS_DOCUMENTS = routeCreator(Documents, '/parameters/documents', 'Documents', 'page.documents', {exact: true})
export const ROUTE_PAGE_PARAMETERS_GROUPS = routeCreator(Groups, '/parameters/groups', 'Groups', 'page.groups', {exact: true})
export const ROUTE_PAGE_PARAMETERS_LOAN_STATUS = routeCreator(LoanStatus, '/parameters/loan/status', 'LoanStatus', 'page.loanStatus', {exact: true})
export const ROUTE_PAGE_PARAMETERS_LOAN_TYPE = routeCreator(LoanType, '/parameters/loan/type', 'LoanType', 'page.loanType', {exact: true})
export const ROUTE_PAGE_PARAMETERS_DOCS_ASSOCIATIONS = routeCreator(DocsAssociations, '/parameters/docs/associations', 'DocsAssociations', 'page.docsAssociations', {exact: true})
export const ROUTE_PAGE_PARAMETERS_RIGHTS = routeCreator(Rights, '/parameters/rights', 'Rights', 'page.rights', {exact: true})
export const ROUTE_PAGE_PARAMETERS_RIGHTS_ROLES = routeCreator(RightsRoles, '/parameters/rights/roles', 'RightsRoles', 'page.rightsRoles', {exact: true})
export const ROUTE_PAGE_PARAMETERS_ROLES = routeCreator(Roles, '/parameters/roles', 'Roles', 'page.roles', {exact: true})
export const ROUTE_PAGE_PARAMETERS_ORIGINS = routeCreator(Origins, '/parameters/origins', 'Origins', 'page.origins', {exact: true})
export const ROUTE_PAGE_PARAMETERS_INTEREST_RATE = routeCreator(InterestRates, '/parameters/interest/rate', 'InterestRates', 'page.interestRates', {exact: true})
export const ROUTE_PAGE_PARAMETERS_DOCUMENTS_TYPES = routeCreator(DocumentsTypes, '/parameters/documents/types', 'DocumentsTypes', 'page.documentsTypes', {exact: true})
export const ROUTE_PAGE_PARAMETERS_QUESTIONNAIRES = routeCreator(Questionnaires, '/parameters/questionnaires', 'Questionnaires', 'page.questionnaires', {exact: true})


const router = {
    ROUTE_PAGE_PARAMETERS_COUNTRIES,
    ROUTE_PAGE_PARAMETERS_NATIONALITIES,
    ROUTE_PAGE_PARAMETERS_CUSTOMER_STATUS,
    ROUTE_PAGE_PARAMETERS_DOCUMENTS,
    ROUTE_PAGE_PARAMETERS_GROUPS,
    ROUTE_PAGE_PARAMETERS_LOAN_STATUS,
    ROUTE_PAGE_PARAMETERS_LOAN_TYPE,
    ROUTE_PAGE_PARAMETERS_DOCS_ASSOCIATIONS,
    ROUTE_PAGE_PARAMETERS_RIGHTS,
    ROUTE_PAGE_PARAMETERS_RIGHTS_ROLES,
    ROUTE_PAGE_PARAMETERS_ROLES,
    ROUTE_PAGE_PARAMETERS_ORIGINS,
    ROUTE_PAGE_PARAMETERS_INTEREST_RATE,
    ROUTE_PAGE_PARAMETERS_DOCUMENTS_TYPES,
    ROUTE_PAGE_PARAMETERS_QUESTIONNAIRES
}

export default router