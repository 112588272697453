// ==============================|| OVERRIDES - TYPOGRAPHY ||============================== //

import {useTheme} from "@mui/material";

export default function Typography() {
    const theme = useTheme();

    return {
        MuiTypography: {
            styleOverrides: {
                gutterBottom: {
                    marginBottom: 12
                },
                root: {
                    color: '#485776'
                        //theme.palette.primary[100]
                }
            },
        }
    };
}
