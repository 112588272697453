import * as React from "react";
const HeadPhonesNative = (props) => (
    <svg
        width={20}
        height={19}
        viewBox="0 0 19 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g
            stroke="#485776"
            strokeWidth={1.466}
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path d="M2.885 13.917V9.52a6.596 6.596 0 1 1 13.191 0v4.397" />
            <path d="M16.076 14.65a1.466 1.466 0 0 1-1.466 1.465h-.733a1.466 1.466 0 0 1-1.465-1.465v-2.2a1.466 1.466 0 0 1 1.465-1.466h2.199v3.665Zm-13.191 0a1.466 1.466 0 0 0 1.465 1.465h.733A1.466 1.466 0 0 0 6.55 14.65v-2.2a1.466 1.466 0 0 0-1.466-1.466H2.885v3.665Z" />
        </g>
    </svg>
);
export default HeadPhonesNative;