import * as React from "react";
const StarIcon = (props) => (
    <svg
        width={27}
        height={25}
        viewBox="0 0 30 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            clipRule="evenodd"
            d="m16.656 1.992 2.742 5.476c.268.538.787.91 1.388.997l6.132.883c1.515.219 2.118 2.055 1.021 3.107l-4.434 4.26a1.805 1.805 0 0 0-.53 1.613l1.046 6.016c.257 1.488-1.326 2.623-2.68 1.919L15.86 23.42a1.872 1.872 0 0 0-1.72 0l-5.48 2.842c-1.355.704-2.938-.431-2.68-1.92l1.046-6.015a1.805 1.805 0 0 0-.531-1.613l-4.434-4.26c-1.097-1.052-.494-2.888 1.021-3.107l6.132-.883a1.845 1.845 0 0 0 1.39-.997l2.74-5.476c.678-1.354 2.635-1.354 3.312 0Z"
            stroke="#485776"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
export default StarIcon;