import React from "react";
import {Divider, Toolbar, Typography, Box} from "@mui/material";
import DataGridWrapper from "../../../../components/dataGridWrapper/dataGridWrapper";
import {useTranslation} from "react-i18next";
import {CALCULATIONS} from "../../locales/namespaces";
import {muiGridLocales} from "../../../../helpers/muiGridLocales";
import StyledDataGrid from '../styleDataGrid/styleDataGrid'
import {UnsortedIcon} from "../../../../assets/images/icons/sortIcon";

const FloatTable = ({rowsFloat, columns}) => {
    const {t} = useTranslation(CALCULATIONS)
    return (
        <Box className={'customer-index'}>
            <Toolbar disableGutters variant={"dense"}>
                <Typography variant="bodyMedium" gutterBottom sx={{ml: 2}}>
                    {t('Float rate Installments')}
                </Typography>
            </Toolbar>
            <Divider sx={{mb: 2}}/>
            <DataGridWrapper
                sx={{
                    mt: 1,
                    height: '400px',
                }}
                bold
            >
                <StyledDataGrid
                    getRowClassName={(params) => {
                        return (
                            params.row.isRepayment === 1 ? `super-app-theme--${params.row.isRepayment}` :
                                params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                        )
                    }}
                    disableColumnMenu={true} rows={rowsFloat} columns={columns}
                    sx={{
                        ".MuiDataGrid-aggregationColumnHeaderLabel": {
                            display: "none"
                        },
                        '.MuiDataGrid-cellContent': {
                            fontWeight: 400
                        },
                        "& .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer": {
                            visibility: "visible",
                            width: "auto"
                        },
                        "& .MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeader--sorted) .MuiDataGrid-sortIcon": {
                            opacity: 0.5
                        },
                    }}
                    slots={{
                        columnUnsortedIcon: UnsortedIcon,
                    }}
                    localeText={muiGridLocales(t)}
                />
            </DataGridWrapper>
        </Box>
    )
}

export default FloatTable