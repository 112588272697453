// material-ui
import {alpha, styled} from '@mui/material/styles';
import {Box} from '@mui/material';

// third-party
import SimpleBar from 'simplebar-react';
import {BrowserView, MobileView} from 'react-device-detect';

// root style
const RootStyle = styled(BrowserView)({
    flexGrow: 1,
    height: '100%',
    overflow: 'hidden'
});

// scroll bar wrapper
const SimpleBarStyle = styled(SimpleBar)(({theme}) => ({
    maxHeight: '100%',
    '& .simplebar-scrollbar': {
        '&:before': {
            backgroundColor: alpha(theme.palette.grey[500], 0.48)
        },
        '&.simplebar-visible:before': {
            opacity: 1
        }
    },
    '& .simplebar-track.simplebar-vertical': {
        width: 10
    },
    '& .simplebar-track.simplebar-horizontal .simplebar-scrollbar': {
        height: 6
    },
    '& .simplebar-mask': {
        zIndex: 'inherit'
    }
}));

// ==============================|| SIMPLE SCROLL BAR  ||============================== //

export default function SimpleBarScroll({children, sx, ...other}) {
    return (
        <>
            <RootStyle>
                <SimpleBarStyle clickOnTrack={false} sx={sx} {...other}>
                    {children}
                </SimpleBarStyle>
            </RootStyle>
            <MobileView>
                <Box sx={{overflowX: 'auto', ...sx}} {...other}>
                    {children}
                </Box>
            </MobileView>
        </>
    );
}
//
// SimpleBarScroll.propTypes = {
//     children: PropTypes.node,
//     sx: PropTypes.object
// };


/*
// material-ui
import {alpha, styled} from '@mui/material/styles';
import {Box} from '@mui/material';

// third-party
import SimpleBar from 'simplebar-react';
import {BrowserView, MobileView} from 'react-device-detect';
import bgSideBarImage from "../../../../assets/images/ims-bg-SideBar.jpg";
import AuthBackgroundSideBarImage from "../../../../assets/images/components/authBackgroundSideBar";

/!*const styles = theme => ({
    drawerPaper: {
        backgroundImage: `url(${bgSideBarImage})`

    },
})*!/

// root style
const RootStyle = styled(BrowserView)(({theme}) => ({
    // flexGrow: 1,
    // height: '100%',
    // overflow: 'hidden',
    flexGrow: 1,
    height: '100%',
    overflow: 'hidden',
    //position: 'relative',
   /!* backgroundImage: `url('../../../../assets/images/ims-bg-SideBar.jpg')`, // Add this line to set the background image
    backgroundSize: 'cover', // Adjust the background size property as needed
    backgroundPosition: 'center', // Adjust the background position property as needed,
    drawerPaper: {
        backgroundImage: `url(${bgSideBarImage})`

    },*!/
}));

// scroll bar wrapper
const SimpleBarStyle = styled(SimpleBar)(({theme}) => ({
    maxHeight: '100%',
    '& .simplebar-scrollbar': {
        '&:before': {
            backgroundColor: alpha(theme.palette.grey[500], 0.48),
        },
        '&.simplebar-visible:before': {
            opacity: 1,
        },
    },
    '& .simplebar-track.simplebar-vertical': {
        width: 10,
    },
    '& .simplebar-track.simplebar-horizontal .simplebar-scrollbar': {
        height: 6,
    },
    '& .simplebar-mask': {
        zIndex: 'inherit',
    },
   /!* maxHeight: '100%',
    position: 'absolute', // Position the scrollbar absolutely within the container
    // top: 0,
    // left: 0,
    // width: '100%',
    // height: '100%',
    '& .simplebar-scrollbar': {
        '&:before': {
            backgroundColor: alpha(theme.palette.grey[500], 0.48),
        },
        '&.simplebar-visible:before': {
            opacity: 1,
        },
    },
    '& .simplebar-track.simplebar-vertical': {
        width: 10,
    },
    '& .simplebar-track.simplebar-horizontal .simplebar-scrollbar': {
        height: 6,
    },
    '& .simplebar-mask': {
        zIndex: 'inherit',
    },*!/
}));

// ==============================|| SIMPLE SCROLL BAR  ||============================== //

export default function SimpleBarScroll({children, sx, ...other}) {
    return (
       /!* <>
            <RootStyle>
                <SimpleBarStyle clickOnTrack={false} sx={sx} {...other}>
                    {children}
                </SimpleBarStyle>
            </RootStyle>
            <MobileView>
                <Box sx={{overflowX: 'auto', ...sx}} {...other}>
                    {children}
                </Box>
            </MobileView>
        </>*!/
        <RootStyle>
            {/!* Background Image *!/}
           {/!* <AuthBackgroundSideBarImage />*!/}

            {/!* Scrollbar *!/}
            <SimpleBarStyle clickOnTrack={false} sx={sx} {...other}>
                {children}
            </SimpleBarStyle>

            {/!* Mobile View *!/}
            <MobileView>
                <Box sx={{ overflowX: 'auto', ...sx }} {...other}>
                    {children}
                </Box>
            </MobileView>
        </RootStyle>
    );
}
//
// SimpleBarScroll.propTypes = {
//     children: PropTypes.node,
//     sx: PropTypes.object
// };
*/
