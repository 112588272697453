// @flow 
import React from 'react';
import {Typography, Box} from "@mui/material";
import {useTranslation} from "react-i18next";
import {AXIOS_ERROR_MESSAGE} from "../../locales/components/namespaces";
// import ExpandMore from "@mui/icons-material/ExpandMore";
// import ExpandLess from "@mui/icons-material/ExpandLess";
// import CopyToClipboard from "../copyToClipboard/copyToClipboard";

type Props = {};

const AxiosErrorMessage = ({customMessage, code, errorCode, errorMessage, responseMessage}: Props) => {
    //const [displayMore, setDisplayMore] = useState(false)
    const {t} = useTranslation(AXIOS_ERROR_MESSAGE)
    let moreMessage = ``
    if (code) moreMessage += `${t('Code')}: ${code} `
    if (errorCode) moreMessage += `${t('Status')}: ${errorCode} `
    if (errorMessage) moreMessage += `${t('Message')}: ${errorMessage}`
    return (
        <Box>
            <Typography
                variant={'body2'}
                color={'#ffffff'}
            >
                {customMessage}
            </Typography>
            {/*{moreMessage && (
                <>
                    <Button
                        sx={{
                            p: 0,
                            m: 0
                        }}
                        size={'small'}
                        endIcon={displayMore ? <ExpandLess/> : <ExpandMore/>}
                        onClick={() => setDisplayMore(!displayMore)}
                    >
                        {displayMore ? t('Less') : t('More')}
                    </Button>
                    {displayMore && (
                        <CopyToClipboard
                            text={moreMessage}
                        />
                    )}
                </>
            )}*/}
        </Box>
    );
};

export default AxiosErrorMessage