import useUserData from "../../../hooks/user/useUserData";
import {useTranslation} from "react-i18next";
import {ALERTS} from "../../../locales/components/namespaces";
import {useEffect, useState} from "react";
import {API} from "../../../helpers/constants";
import {enqueueSnackbar} from "notistack";
import {handleAxiosErrors, logoutUnauthorizedUser} from "../../../helpers/errorHandling";
import axios from "axios";
import moment from "moment";
import useUserAction from "../../../hooks/user/useUserAction";

const useCalculation = ({id}) => {
    const {token, isLoggedIn} = useUserData()
    const {userLogout} = useUserAction()
    const {t} = useTranslation(ALERTS)
    const [loading, setLoading] = useState(false)
    const [calculations, setCalculations] = useState({})

    useEffect(() => {
        setLoading(false)
        if (id) {
            axios({
                method: 'post',
                data: {CalculationId: id},
                headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
                url: `${API}/calculation/edit/data`,
            }).then(response => {
                let data = response.data
                if (data.calculations && data.calculations.length > 0) {
                    const calculations = data.calculations[0]
                    setCalculations({
                        RealEstateValue: Number(calculations.RealEstateValue),
                        LoanAmount: Number(calculations.RequestedAmount),
                        InterestType: calculations.TypeId,
                        FixedInterestRate: Number(calculations.FixedRate),
                        FixedDuration: Number(calculations.FixedDuration),
                        Euribor: Number(calculations.Euribor),
                        Spread: Number(calculations.Spread),
                        FloatingDuration: Number(calculations.FloatDuration),
                        ContributionId: calculations.ContributionId,
                        InstallmentNumber: calculations.InstallmentNumber,
                        Description: calculations.Description,
                        DateInstallment: moment(calculations.StartDt),
                        isMonthly: calculations.isMonthly === '1' ? 'isMonthly' : 'isYearly',
                        isHidePreviousInstallments: calculations.HidePrevious === '1',
                        NotifyFlag: calculations.NotifyFlag === '1',
                    })
                }
                setLoading(false)
            }).catch(error => {
                setLoading(false)
                enqueueSnackbar(handleAxiosErrors(error, t('Failed to get calculation details.')), {
                        variant: 'error'
                    }
                )
                logoutUnauthorizedUser(error, isLoggedIn, userLogout)
            })
        }
    }, [id])

    return {
        id,
        loading,
        calculations
    }
}
export default useCalculation