// @flow
import React, {useMemo} from 'react';
import {useTranslation} from "react-i18next";
import {PARAMETERS} from "../../locales/namespaces";
import useRoles from "../../../users/hooks/useRoles";
import DataGridWrapper from "../../../../components/dataGridWrapper/dataGridWrapper";
import EditableMUIDataGrid from "../../../../components/editableMUIDataGrid/editableMUIDataGrid";
import {withPermissionHOC} from "../../../permission/components/permissionHOC/permissionHOC";
import {Typography} from "@mui/material";
import MainCard from "../../../../theme/mantis/components/mainCard";
import {GridEditInputCell} from "@mui/x-data-grid-premium";
import EditableMUICellValidation from "../../../../components/editableMUICellValidation/editableMUICellValidation";
import ContentLoader from "../../../../components/contentLoader/contentLoader";

type Props = {
	circularLoader?: boolean
}

const Roles = (props: Props) => {
	const {t} = useTranslation(PARAMETERS)
	const {
		loading,
		roles,
		store,
		remove
	} = useRoles()
	
	const {circularLoader} = props
	
	const newRowModel = {
		Description: ''
	}
	
	const columns = useMemo(() => [
		{
			field: 'Description',
			headerName: t('Description'),
			minWidth: 160,
			flex: 1,
			editable: true,
			preProcessEditCellProps: (params) => {
				const inputValue = params.props.value.trim(); // Trim whitespace
				const inputMinLength = params.props.value.length < 3;
				const inputMaxLength = params.props.value.length > 50;
				if (inputValue === '') {
					return {...params.props, error: t('Required')};
				} else if (inputMinLength) {
					return {...params.props, error: t('Characters must be more than 3')};
				} else if (inputMaxLength) {
					return {...params.props, error: t('Length must be smaller than 50.')};
				}
				return {...params.props, error: null}; // No error
			},
			renderEditCell: (params) => {
				return <EditableMUICellValidation {...params}>
					<GridEditInputCell {...params} />
				</EditableMUICellValidation>
			},
		},
	], [roles, t])
	
	return !loading ? (
		<MainCard
			title={
				<Typography variant="h5" gutterBottom>
					{t('Roles')}
				</Typography>
			}
			sx={{mt: 2}}
		>
			<DataGridWrapper
				sx={{
					mt: 1,
					maxWidth: '600px',
					height: '400px'
				}}
			>
				<EditableMUIDataGrid
					loading={loading}
					data={roles}
					columns={columns}
					newRowModel={newRowModel}
					store={store}
					remove={remove}
					hideEditButton={true}
					dataGridProOtherProps={{
						disableColumnMenu: true
					}}
				/>
			</DataGridWrapper>
		</MainCard>
	) : (
		<ContentLoader
			loading={loading}
			useCircularLoader={circularLoader}
		/>
	)
}

export default withPermissionHOC(Roles, ['access_parameters'])