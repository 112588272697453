// @flow
import * as React from 'react';
import {ErrorMessage, FormikProvider} from "formik";
import {PAGE_LOGIN} from "../../locales/pages/namespaces";
import {useTranslation} from "react-i18next";
import {
	Button,
	FormControl,
	FormHelperText,
	Grid,
	Stack,
	Link,
	TextField,
	Typography,
	IconButton, useTheme,
	InputAdornment, useMediaQuery
} from "@mui/material";
import {Google} from "@mui/icons-material";
import useSignUpForm from './useSignUpForm'
import {Link as RouterLink, useHistory} from 'react-router-dom';
import useUserData from "../../hooks/user/useUserData";
import AuthWrapper from "../../theme/mantis/sections/auth/authWrapper";
import {getRouteUrl} from "../../helpers/getRouteUrl";
import {ROUTE_PAGE_HOME, ROUTE_PAGE_LOGIN} from "../../routers/routes";
import {Capacitor} from "@capacitor/core";
import {GoogleLogin} from "@react-oauth/google";
import {GoogleAuth} from "@codetrix-studio/capacitor-google-auth";
import useUserAction from "../../hooks/user/useUserAction";
import EmailIcon from "../../assets/images/icons/login/emailIcon";
import PasswordIcon from "../../assets/images/icons/login/passwordIcon";
import VisibleEyeOff from "../../assets/images/icons/login/visibleEyeOff";
import VisibleEyeOn from "../../assets/images/icons/login/visibleEyeOn";
import ArrowDownCircle from "../../assets/images/icons/buttonsIcons/arrowDownCircle";
import {ThemeMode} from "../../theme/mantis/config";
import {Fragment} from "react";
import {enqueueSnackbar} from "notistack";
import {handleAxiosErrors} from "../../helpers/errorHandling";

type Props = {
	id: string,
	isUser: boolean
}

const SignUp = (props: Props) => {
	const {t} = useTranslation(PAGE_LOGIN)
	const history = useHistory()
	const {
		id,
		isUser
	} = props
	const {loading, language} = useUserData()
	const theme = useTheme();
	const {googleLogin} = useUserAction()
	const matchDownMD = useMediaQuery((theme) => theme.breakpoints.down('md'));
	
	const {formik, showPassword, handleClickShowPassword, handleMouseDownPassword} = useSignUpForm({
		id,
		isUser
	})
	
	// TODO::Not working. Retry after publishing native apps to stores.
	async function googleNativeLogin() {
		const result = await GoogleAuth.signIn();
		console.info('result', result);
		if (result) googleLogin({
			credential: result?.authentication?.idToken,
			clientId: '',
			select_by: ''
		})
		history.push(getRouteUrl(ROUTE_PAGE_HOME))
	}
	
	return (
		<AuthWrapper>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Fragment>
						<style>
							{`input:-webkit-autofill {-webkit-box-shadow: 0 0 0 1000px ${theme.palette.mode === ThemeMode.LIGHT ? theme.palette.primary.main : '#1E1E1E'} inset !important;}`}
						</style>
						<FormikProvider value={formik}>
							<Grid container spacing={3}>
								<Grid item xs={12}>
									<Stack spacing={1}>
										<FormControl
											variant="standard"
											fullWidth
											error={
												formik.touched["email"] &&
												Boolean(formik.errors["email"])
											}
											sx={{mb: 2}}
										>
											{/*<FormLabel htmlFor="email" required>{t('Email')}</FormLabel>*/}
											<TextField
												placeholder={t("Email")}
												type={'email'}
												value={formik.values.email}
												name={`email`}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												fullWidth
												autoComplete="on"
												color={"grey"}
												size={'small'}
												required
												error={formik.touched.email && Boolean(formik.errors.email)}
												inputProps={{maxLength: 100}}
												InputProps={{
													startAdornment: (
														<InputAdornment
															position="start"
															sx={{bgColor: '#FFFFFF'}}>
															<EmailIcon/>
														</InputAdornment>
													),
												}}
											/>
											<FormHelperText>
												<ErrorMessage name={"email"}/>
											</FormHelperText>
										</FormControl>
									</Stack>
								</Grid>
								<Grid item xs={12}>
									<Stack spacing={1}>
										<FormControl
											fullWidth
											error={
												formik.touched["password"] &&
												Boolean(formik.errors["password"])
											}
											sx={{mb: 2}}
										>
											{/*<FormLabel htmlFor="password" required>{t('Password')}</FormLabel>*/}
											<TextField
												placeholder={t('Create a Password')}
												type={showPassword ? 'text' : 'password'}
												value={formik.values.password}
												name={`password`}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												fullWidth
												autoComplete="new-password"
												color={"grey"}
												size={'small'}
												required
												error={formik.touched.password && Boolean(formik.errors.password)}
												InputProps={{
													startAdornment: (
														<InputAdornment
															position="start"
														>
															<PasswordIcon/>
														</InputAdornment>
													),
													minLength: 7,
													endAdornment:
														<InputAdornment position="end">
															<IconButton
																aria-label="toggle password visibility"
																onClick={handleClickShowPassword}
																onMouseDown={handleMouseDownPassword}
																edge="end"
																color={theme.palette.primary['100']}
															>
																{showPassword ? <VisibleEyeOff/> : <VisibleEyeOn/>}
															</IconButton>
														</InputAdornment>
												}}
											/>
											<FormHelperText>
												<ErrorMessage name={"password"}/>
											</FormHelperText>
										</FormControl>
									</Stack>
								</Grid>
								<Grid item xs={12}>
									<Stack spacing={1}>
										<FormControl
											fullWidth
											error={
												formik.touched["confirmPassword"] &&
												Boolean(formik.errors["confirmPassword"])
											}
											sx={{mb: 2}}
										>
											<TextField
												placeholder={t('Confirm your Password')}
												type={showPassword ? 'text' : 'password'}
												value={formik.values.confirmPassword}
												name={`confirmPassword`}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												fullWidth
												autoComplete="on"
												color={"grey"}
												size={'small'}
												required
												error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
												InputProps={{
													startAdornment: (
														<InputAdornment
															position="start"
														>
															<PasswordIcon/>
														</InputAdornment>
													),
													minLength: 7,
													endAdornment:
														<InputAdornment position="end">
															<IconButton
																aria-label="toggle password visibility"
																onClick={handleClickShowPassword}
																onMouseDown={handleMouseDownPassword}
																edge="end"
																color={theme.palette.primary['100']}
															>
																{showPassword ? <VisibleEyeOff/> : <VisibleEyeOn/>}
															</IconButton>
														</InputAdornment>
												}}
											/>
											<FormHelperText>
												<ErrorMessage name={"confirmPassword"}/>
											</FormHelperText>
										</FormControl>
									</Stack>
								</Grid>
								 <Grid item xs={12}>
									{Capacitor.isNativePlatform() ? (
										<Button onClick={googleNativeLogin}>
											<Google/>{t("Sign in with Google")}
										</Button>
									) : (
										<GoogleLogin
											size="large"
											//shape="circle"
											width={matchDownMD ? '280px' : '345px'}
											onSuccess={credentialResponse => {
												googleLogin(credentialResponse)
												history.push(getRouteUrl(ROUTE_PAGE_HOME))
											}}
											onError={(error) => {
												enqueueSnackbar(handleAxiosErrors(error, t('Something went wrong!')), {
														variant: 'error'
													}
												)
											}}
											useOneTap
											theme={'filled_white'}
											locale={language}
										/>
									)}
								</Grid>
								<Grid item xs={12} /*marginTop={'48px'} marginBottom={'48px'}*/ textAlign={'center'}>
									<Typography variant="h5" color={theme.palette.black[30]} component="span">
										{t('Already have an account?')}{' '}
										<Link
											component={RouterLink}
											variant="h5"
											to={getRouteUrl(ROUTE_PAGE_LOGIN)}
											underline="hover"
											color="textPrimary"
										>
											{t('Sign In')}
										</Link>
									</Typography>
								</Grid>
								<Grid item xs={12} textAlign={'center'}>
									<Button
										disableElevation
										disabled={loading}
										//fullWidth
										sx={{gap: '20px', p: '17px 54px'}}
										size={"small"}
										variant={"contained"}
										color={'info'}
										type="submit"
										onClick={formik.submitForm}
									>
										<ArrowDownCircle/>{t('Sign up')}
									</Button>
								</Grid>
							</Grid>
						</FormikProvider>
					</Fragment>
				</Grid>
			</Grid>
		</AuthWrapper>
	)
}
export default SignUp
