// @flow
import React, {useMemo, useState} from 'react';
import {useTranslation} from "react-i18next";
import DataGridWrapper from "../../../../../components/dataGridWrapper/dataGridWrapper";
import {DataGridPremium} from '@mui/x-data-grid-premium';
import {USERS} from "../../../locales/namespaces";
// import axios from "axios";
// import {API} from "../../../../../helpers/constants";
// import {enqueueSnackbar} from "notistack";
// import {handleAxiosErrors} from "../../../../../helpers/errorHandling";
import useUserData from "../../../../../hooks/user/useUserData";
import {muiGridLocales} from "../../../../../helpers/muiGridLocales";

const HistoryLoginTable = (props) => {
    const {t} = useTranslation(USERS)
    const {token} = useUserData()
    const [loading, setLoading] = useState(false)
    const [historyLogin, setHistoryLogin] = useState([])


    /*  useEffect(() => {
          setLoading(true)
          axios({
              method: 'get',
              headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
              url: `${API}/historyLogin/list`,
          }).then((response) => {
              setHistoryLogin(response.data)
              setLoading(false)
          }).catch(error => {
              setLoading(false)
              enqueueSnackbar(handleAxiosErrors(error, t('Failed to get logins list.')), {
                      variant: 'error'
                  }
              )
              logoutUnauthorizedUser(error, isLoggedIn, userLogout)
          })
      }, [])*/


    const columns = useMemo(() => [
        {
            field: 'Description',
            headerName: t('Description'),
            minWidth: 160,
            flex: 1,
            editable: false,
        },
    ], [historyLogin, t])

    return (
        <DataGridWrapper
            sx={{
                mt: 1,
                //maxWidth: '800px',
                height: '400px'
            }}
        >
            <DataGridPremium
                sx={{
                    bgcolor: 'background.paper', ".MuiDataGrid-aggregationColumnHeaderLabel": {
                        display: "none"
                    }
                }}
                columns={columns}
                density={'compact'}
                rows={historyLogin}
                /*pagination
                page={page - 1}
                pageSize={pageSize}
                paginationMode='server'
                rowCount={Number(total)}
                rowsPerPageOptions={[5, 10, 15, 20, 50, 100]}
                onPageChange={onPageChange}
                onPageSizeChange={onPageSizeChange}
                sortingMode="server"
                onSortModelChange={(sortModel) => onSortModelChange(sortModel)}
                filterMode='server'
                onFilterModelChange={onFilterModelChange}*/
                loading={loading}
                localeText={muiGridLocales(t)}
                disableColumnMenu={true}
                // disableAggregation={true}
                // disableRowGrouping={true}
                // disableColumnFilter={true}
            />
        </DataGridWrapper>
    );
}

export default HistoryLoginTable