import * as React from "react";
const CancelNotiIcon = (props) => (
    <svg
        width={9}
        height={9}
        viewBox="0 0 9 9"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="m7.394 1.003-6.39 6.39m6.391.003L1 1"
            stroke="#fff"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
export default CancelNotiIcon;