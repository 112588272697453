import * as React from "react";
const DeleteIcon = (props) => (
    <svg
        width={19}
        height={21}
        viewBox="0 0 19 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M16.324 8.194s-.543 6.735-.858 9.572c-.15 1.355-.987 2.149-2.358 2.174-2.61.047-5.221.05-7.83-.005-1.318-.027-2.141-.831-2.288-2.162-.317-2.862-.857-9.58-.857-9.58m15.575-3.228H.75m13.69.001a1.648 1.648 0 0 1-1.615-1.324l-.243-1.216a1.28 1.28 0 0 0-1.237-.95H7.112a1.28 1.28 0 0 0-1.237.95l-.243 1.216a1.648 1.648 0 0 1-1.615 1.324"
            stroke="#fff"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
export default DeleteIcon;