import React from 'react';
import bgImage from '../ims-auth-bg.jpg';

const imageStyle = {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: -1,
};

function AuthBackgroundImage() {
    return (
        <div style={{width: '100%', height: '100%', position: 'absolute'}}>
            <img src={bgImage} alt="bgImage" style={imageStyle}/>
        </div>
    );
}

export default AuthBackgroundImage;