import * as React from "react";
const RotateIcon = (props) => (
    <svg
        width={37}
        height={37}
        viewBox="0 0 37 37"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g
            stroke="#485776"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path d="M35 6.727v9h-9" />
            <path d="M31.235 23.227a13.5 13.5 0 1 1-3.18-14.04L35 15.727" />
        </g>
    </svg>
);
export default RotateIcon;