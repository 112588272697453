import * as React from "react";
const XIcon = (props) => (
	<svg
		width={19}
		height={19}
		viewBox="0 0 19 19"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="m14.25 4.75-9.5 9.5m0-9.5 9.5 9.5"
			stroke="#CC5F5F"
			strokeWidth={1.583}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);
export default XIcon;