import * as React from "react";
const CalculatorNative = (props) => (
    <svg
        width={20}
        height={19}
        viewBox="0 0 14 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g
            stroke="#485776"
            strokeWidth={1.092}
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path d="M3.333 7.067h-.7m3.147 0h-.7m3.147 0h-.7m3.147 0h-.699M3.333 9.203h-.7m3.147 0h-.7m3.147 0h-.7m3.147 0h-.699M3.333 11.34h-.7m3.147 0h-.7m3.147 0h-.7m3.147 0h-.699m2.272-6.409H1.06" />
            <path
                clipRule="evenodd"
                d="M9.386.837 4.01.84C2.08.852.885 2.145.885 4.117v6.548c0 1.983 1.204 3.28 3.151 3.28l5.376-.002c1.93-.012 3.126-1.305 3.126-3.278V4.117c0-1.982-1.205-3.28-3.152-3.28Z"
            />
        </g>
    </svg>
);
export default CalculatorNative;