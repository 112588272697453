// @flow
import React, {useEffect, useMemo, useState} from 'react';
import {useTranslation} from "react-i18next";
import DataGridWrapper from "../../../../components/dataGridWrapper/dataGridWrapper";
import {DataGridPremium} from '@mui/x-data-grid-premium';
import {LOANS} from "../../../loans/locales/namespaces";
import {muiGridLocales} from "../../../../helpers/muiGridLocales";
import {textTranslate} from "../../../../helpers/helpers";
import useUserData from "../../../../hooks/user/useUserData";

const DocumentsGrid = ({row}) => {
    const {t} = useTranslation(LOANS)
    const {language} = useUserData()

    const loanDocuments = row.Documents

    /*const newRelId = ()=>{
        return loanDocuments.map( document => {
            return <div>{document.Description}</div>
        })
    }*/

    const columns = useMemo(
        () => [
            {
                field: 'Description',
                headerName: t('Description'),
                flex: 1,
            },
            /* {
                 field: 'DocId',
                 headerName: 'Doc Id',
                 flex:1,
             },*/
            {
                field: 'OriginalFileName',
                headerName: t('File Name'),
                flex: 1,
            },
            {
                field: 'StatusEl',
                headerName: t('Loan status'),
                flex: 1,
                renderCell: ({row}) => (textTranslate(language)({en: row.StatusEn, el: row.StatusEl}))
            },
        ],
        [loanDocuments],
    );

    return (
        <DataGridWrapper
            sx={{
                mt: 1,
                maxWidth: '900px',
                height: '200px',
                ml: 5
            }}
        >
            <DataGridPremium
                sx={{
                    bgcolor: 'background.paper', ".MuiDataGrid-aggregationColumnHeaderLabel": {
                        display: "none"
                    }
                }}
                density={'compact'}
                columns={columns}
                rows={loanDocuments}
                getRowId={(row) => row.id}
                localeText={muiGridLocales(t)}
            />
        </DataGridWrapper>
    );
}


export default DocumentsGrid;