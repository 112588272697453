// ==============================|| OVERRIDES - DRAWER ||============================== //

export default function Drawer(theme) {
    return {
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    // TODO::Change menu
                    // background: theme.palette.primary['900'],
                    // color: theme.palette.primary['lighter'],
                },
            }
        }
    };
}
