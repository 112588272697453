// material-ui
import {Container, Link, Stack, Typography, useMediaQuery} from '@mui/material';
import {useTranslation} from "react-i18next";
import {PAGE_HOME} from "../../../../locales/pages/namespaces";
import {useTheme} from "@mui/material/styles";
import {getRouteUrl} from "../../../../helpers/getRouteUrl";
import {Link as RouterLink} from "react-router-dom";
import {ROUTE_PAGE_PRIVACY_POLICY, ROUTE_PAGE_TERMS_CONDITIONS} from "../../../../routers/routes";
import {Capacitor} from "@capacitor/core";

// ==============================|| FOOTER - AUTHENTICATION ||============================== //

const AuthFooter = ({inLogin}) => {
    const matchDownSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const {t} = useTranslation(PAGE_HOME)
    const theme = useTheme();
    const color = theme.palette.primary[100]
    return (
        <Container maxWidth="xl">
            <Stack
                direction={matchDownSM ? 'column' : 'row'}
                justifyContent={matchDownSM ? 'center' : 'space-between'}
                spacing={2}
                textAlign={matchDownSM ? 'center' : 'inherit'}
            >
                <Typography variant="h5" color={color} component="span" sx={{zIndex: 1}}>
                    {t('This site is protected by')}{' '}
                    <Link
                        component={RouterLink}
                        variant="caption"
                        to={getRouteUrl(ROUTE_PAGE_PRIVACY_POLICY)}
                        target={!Capacitor.isNativePlatform() && "_blank"}
                        underline="hover"
                        color="textPrimary"
                        fontWeight={700}
                        sx={{
                            fontSize: '14px',
                            fontStyle: 'normal',
                            lineHeight: '22px'
                        }}
                    >
                        {t('Privacy policy')}
                    </Link>
                </Typography>
                <Stack direction={matchDownSM ? 'column' : 'row'} spacing={matchDownSM ? 1 : 3}
                       textAlign={matchDownSM ? 'center' : 'inherit'}>
                    <Typography
                        variant="h5"
                        fontWeight={700}
                        color={color}
                        component={Link}
                        href={getRouteUrl(ROUTE_PAGE_TERMS_CONDITIONS)}
                        target={!Capacitor.isNativePlatform() && "_blank"}
                        underline="hover"
                        sx={{zIndex: 1}}
                    >
                        {t('Terms & Conditions')}
                    </Typography>
                    {/*  <Link
                        variant="subtitle2"
                        color={color}
                        component={RouterLink}
                        to={getRouteUrl(ROUTE_PAGE_PRIVACY_POLICY)}
                        //href={getRouteUrl(ROUTE_PAGE_PRIVACY_POLICY)}
                        target="_blank"
                        underline="hover"
                    >
                        {t('Privacy policy')}
                    </Link>
                    <Typography
                        variant="subtitle2"
                        color={color}
                        component={Link}
                        href="https://codedthemes.com"
                        target="_blank"
                        underline="hover"
                    >
                        CA Privacy Notice
                    </Typography>*/}
                </Stack>
            </Stack>
        </Container>
    );
};

export default AuthFooter;