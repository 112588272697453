// @flow
import React from 'react';
import {Link} from "react-router-dom";
import {getRouteUrl} from "../../../../helpers/getRouteUrl";
import {ROUTE_PAGE_CUSTOMERS_EDIT} from "../../routes/routes";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import {useTranslation} from "react-i18next";
import {CUSTOMERS} from "../../locales/namespaces";
import PermissionHOC from "../../../permission/components/permissionHOC/permissionHOC";
import {textTranslate} from "../../../../helpers/helpers";
import useUserData from "../../../../hooks/user/useUserData";
import MainCard from "../../../../theme/mantis/components/mainCard";
import {
	Stack,
	Button,
	Typography,
	Grid,
	List,
	ListItem
} from '@mui/material';
import DeleteAccountDialog from "../deleteAccountDialog/deleteAccountDialog";

const Profile = (props) => {
	const {id, customer, loans} = props
	const {t} = useTranslation(CUSTOMERS)
	const {language} = useUserData()
	
	const [open, setOpen] = React.useState(false);
	
	const handleClickOpen = () => {
		setOpen(true);
	};
	
	const handleClose = () => {
		setOpen(false);
	};
	
	return (
		<MainCard
			title={
				<Typography variant="h4">
					{t('Information')}
				</Typography>
			}
			secondary={
				<>
					{loans && loans.length === 0 && <PermissionHOC requiredPermissions={['access_customer_edit']}>
						<Button
							color="info"
							size={"small"}
							variant={"contained"}
							component={Link}
							sx={{mr: 1}}
							to={getRouteUrl(ROUTE_PAGE_CUSTOMERS_EDIT, {id: id})}
						>
							{t('Edit')}
						</Button>
					</PermissionHOC>}
					{loans && loans.length > 0 && <PermissionHOC requiredPermissions={['view_cust_edit_admin']}>
						<Button
							color="info"
							size={"small"}
							variant={"contained"}
							component={Link}
							sx={{mr: 1}}
							to={getRouteUrl(ROUTE_PAGE_CUSTOMERS_EDIT, {id: id})}
						>
							{t('Edit')}
						</Button>
					</PermissionHOC>}
					<PermissionHOC requiredPermissions={['view_delete_account_btn']}>
						{customer.hasLoan === '0' &&
							<Button
								size={"small"}
								variant={"contained"}
								color={'error'}
								onClick={handleClickOpen}
							>
								{t('Delete account')}
							</Button>
						}
					</PermissionHOC>
				</>
			}
		>
			<List sx={{py: 0}}>
				<PermissionHOC requiredPermissions={['edit_cust_isactive', 'view_cust_isactive']}>
					<ListItem>
						<Grid container spacing={1}>
							<Grid item xs={12} md={4}>
								<Stack spacing={0.5}>
									<Typography color="secondary" variant={'h6'}>{t('Active')}</Typography>
									<Typography variant={'body'} fontWeight={600}>{customer.isActive ?
										<CheckOutlinedIcon/> : <CloseOutlinedIcon/>}</Typography>
								</Stack>
							</Grid>
							{/*<PermissionHOC requiredPermissions={['view_cust_originid']}>*/}
							<Grid item xs={12} md={4}>
								<Stack spacing={0.5}>
									<Typography color="secondary"
												variant={'h6'}>{t('Origin')}</Typography>
									<Typography variant={'body'} fontWeight={600}>{customer.Origin ? customer.Origin : '-'}</Typography>
								</Stack>
							</Grid>
							{/*</PermissionHOC>*/}
							{/*<PermissionHOC requiredPermissions={['view_cust_erpcode']}>*/}
							<Grid item xs={12} md={4}>
								<Stack spacing={0.5}>
									<Typography color="secondary" variant={'h6'}>{t('ERP Code')}</Typography>
									<Typography variant={'body'} fontWeight={600}>{customer.ErpCode ? customer.ErpCode : '-'}</Typography>
								</Stack>
							</Grid>
							{/*</PermissionHOC>*/}
						</Grid>
					</ListItem>
					{/*</PermissionHOC>
				<PermissionHOC requiredPermissions={['view_cust_code']}>*/}
					<ListItem>
						<Grid container spacing={1}>
							
							<Grid item xs={12} md={4}>
								<Stack spacing={0.5}>
									<Typography color="secondary" variant={'h6'}
									>{t('Customer Code')}</Typography>
									<Typography variant={'body'} fontWeight={600}>{customer.Code ? customer.Code : '-'}</Typography>
								</Stack>
							</Grid>
							{/*<PermissionHOC requiredPermissions={['view_cust_email']}>*/}
							<Grid item xs={12} md={8}>
								<Stack spacing={0.5}>
									<Typography color="secondary" variant={'h6'}>{t('Email')}</Typography>
									<Typography variant={'body'} fontWeight={600}>{customer.email ? customer.email : '-'}</Typography>
								</Stack>
							</Grid>
							{/*</PermissionHOC>*/}
						</Grid>
					</ListItem>
				</PermissionHOC>
				<ListItem>
					<Grid container spacing={1}>
						<Grid item xs={12} md={4}>
							<Stack spacing={0.5}>
								<Typography color="secondary" variant={'h6'}>{t('Full name')}</Typography>
								<Typography variant={'body'} fontWeight={600}>{customer.CustName ? customer.CustName : '-'}</Typography>
							</Stack>
						</Grid>
						<PermissionHOC requiredPermissions={['edit_cust_vatregno', 'view_cust_vatregno']}>
							<Grid item xs={12} md={8}>
								<Stack spacing={0.5}>
									<Typography color="secondary"
												variant={'h6'}>{t('Taxpayer identification number')}</Typography>
									<Typography variant={'body'} fontWeight={600}>{customer.VatRegNo ? customer.VatRegNo : '-'}</Typography>
								</Stack>
							</Grid>
						</PermissionHOC>
						<PermissionHOC requiredPermissions={['view_cust_email_partner']}>
							<Grid item xs={12} md={8}>
								<Stack spacing={0.5}>
									<Typography color="secondary" variant={'h6'}>{t('Email')}</Typography>
									<Typography variant={'body'} fontWeight={600}>{customer.email ? customer.email : '-'}</Typography>
								</Stack>
							</Grid>
						</PermissionHOC>
					</Grid>
				</ListItem>
				<ListItem>
					<Grid container spacing={1}>
						<PermissionHOC requiredPermissions={['view_cust_username']}>
							<Grid item xs={12} md={4}>
								<Stack spacing={0.5}>
									<Typography color="secondary" variant={'h6'}>{t('Email (User Name)')}</Typography>
									<Typography variant={'body'} fontWeight={600}>{customer.username ? customer.username : '-'}</Typography>
								</Stack>
							</Grid>
						</PermissionHOC>
					
					</Grid>
				</ListItem>
				<ListItem>
					<Grid container spacing={1}>
						<Grid item xs={12} md={4}>
							<Stack spacing={0.5}>
								<Typography color="secondary"
											variant={'h6'}>{t('Mobile Number')}</Typography>
								<Typography variant={'body'} fontWeight={600}>{customer.MobilePhone ? customer.MobilePhone : '-'}</Typography>
							</Stack>
						</Grid>
						<Grid item xs={12} md={8}>
							<Stack spacing={0.5}>
								<Typography color="secondary"
											variant={'h6'}>{t('Fixed telephone number')}</Typography>
								<Typography variant={'body'} fontWeight={600}>{customer.HomePhone ? customer.HomePhone : '-'}</Typography>
							</Stack>
						</Grid>
					</Grid>
				</ListItem>
				<ListItem>
					<Grid container spacing={1}>
						<Grid item xs={12} md={4}>
							<Stack spacing={0.5}>
								<Typography color="secondary" variant={'h6'}>{t('Country')}</Typography>
								<Typography variant={'body'} fontWeight={600}>
									{textTranslate(language)({
										en: customer.CountryEn,
										el: customer.Country
									})}
								</Typography>
							</Stack>
						</Grid>
						<Grid item xs={12} md={8}>
							<Stack spacing={0.5}>
								<Typography color="secondary" variant={'h6'}>{t('Nationality')}</Typography>
								<Typography variant={'body'} fontWeight={600}>
									{textTranslate(language)({
										en: customer.NationalityEn,
										el: customer.NationalityEl
									})}
								</Typography>
							</Stack>
						</Grid>
					</Grid>
				</ListItem>
				<ListItem>
					<Grid container spacing={1}>
						<Grid item xs={12} md={4}>
							<Stack spacing={0.5}>
								<Typography color="secondary" variant={'h6'}>{t('Language')}</Typography>
								<Typography variant={'body'} fontWeight={600}>{customer.Lang}</Typography>
							</Stack>
						</Grid>
						<Grid item xs={12} md={8}>
							<Stack spacing={0.5}>
								<Typography color="secondary" variant={'h6'}>{t('Currency')}</Typography>
								<Typography variant={'body'} fontWeight={600}>{customer.Currency}</Typography>
							</Stack>
						</Grid>
					</Grid>
				</ListItem>
			</List>
			<DeleteAccountDialog
				open={open}
				handleClose={handleClose}
				id={id}
			/>
		</MainCard>
	);
};

export default Profile