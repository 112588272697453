import * as React from "react";
const DocumentBlueIcon = (props) => (
    <svg
        width={28}
        height={30}
        viewBox="0 0 28 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g
            stroke="#485776"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path d="M19.574 21.787H8.744m10.83-6.277H8.744m4.133-6.268H8.744" />
            <path
                clipRule="evenodd"
                d="m19.862 1.578-11.533.006c-4.14.026-6.704 2.75-6.704 6.905v13.794c0 4.176 2.583 6.91 6.759 6.91l11.534-.004c4.14-.026 6.704-2.751 6.704-6.906V8.489c0-4.176-2.584-6.91-6.76-6.91Z"
            />
        </g>
    </svg>
);
export default DocumentBlueIcon;