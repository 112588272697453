// @flow
import React from 'react';
import Box from "@mui/material/Box";
import {useTranslation} from "react-i18next";
import {WIZARD} from "./locales/namespaces";

type Props = {

};

const Index = (props: Props) => {
	const {t} = useTranslation(WIZARD)
	return (
		<Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: "100%", height: '100%', paddingTop: 5}}>
			{t('Hello Think Plus!')}
			<br/>
			{t('hello')}
		</Box>
	);
};

export default Index