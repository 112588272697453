// ==============================|| OVERRIDES - ALERT TITLE ||============================== //

export default function AccordionActions(theme) {
    return {
        MuiAccordionDetails: {
            styleOverrides: {
                root: {
                    padding: theme.spacing(2),
                    borderTop: `1px solid ${theme.palette.secondary.light}`,
                   /* '& .MuiButtonBase-root': {
                        background: '#931616',
                        borderRadius: '12px',
                        display: 'flex',
                        gap: '18px',
                        boxShadow: 'none'
                    },*/
                }
            }
        }
    };
}
