// project-imports

// ==============================|| PRESET THEME - Theme8 ||============================== //

import {ThemeMode} from "../../config";

const Theme8 = (colors, mode) => {
    const {grey} = colors;
    const greyColors = {
        0: grey[0],
        50: grey[1],
        100: grey[2],
        200: grey[3],
        300: grey[4],
        400: grey[5],
        500: grey[6],
        600: grey[7],
        700: grey[8],
        800: grey[9],
        900: grey[10],
        A50: grey[15],
        A100: grey[11],
        A200: grey[12],
        A400: grey[13],
        A700: grey[14],
        A800: grey[16],
        ims: '#f4f5fa'
    };

    const contrastText = '#fff';

    let primaryColors = [
        '#E3E5EC',
        '#D3D5DF',
        '#C1C6D1',
        '#B0B6C4',
        '#9FA6B6',
        '#8F96A9',
        '#7E869C',
        '#6C778E',
        '#5C6781',
        '#485776',
        '#F4F5FA' //main background?
    ];
    let errorColors = [
        '#F57E77',
        '#CC5F5F',
        '#FDE8E7',
        '#F25E52',
        '#F04134',
        '#EE3B2F',
        '#E92A21'
    ];
    let warningColors = ['#FFF7E0', '#FFC926', '#FFBF00', '#FFB900', '#FFA900'];
    let infoColors = ['#0BC1B6','#0BC1B6','#E0F4F5', '#26B0BA', '#00A2AE', '#009AA7', '#008694'];
    let successColors = ['#E0F5EA', '#26B56E', '#00A854', '#00A04D', '#008D3A'];

    let blackColors = [
        '#BEC0CA',
        '#A6A8B1',
        '#8B8D97',
        '#6E7079',
        '#53545C',
        '#45464E',
        '#37393F',
        '#33343A',
        '#2C2D33',
        '#1C1D22'
    ]
    let blueColors = [
        '#485776','#485776',
    ]

    let actionsColors = [
        '#519C66',
        '#F4A40B',
        '#CC5F5F'
    ]

    if (mode === ThemeMode.DARK) {
        primaryColors = [
            '#1a2524',
            '#173331',
            '#15423e',
            '#11544e',
            '#0b6c63',
            '#058478',
            '#1a9686',
            '#37a996',
            '#59b8a5',
            '#7fc6b6'
        ];
        //errorColors = ['#321d1d', '#7d2e28', '#d13c31', '#e66859', '#f8baaf'];
        errorColors = ['#F57E77', '#CC5F5F', '#321d1d', '#7d2e28', '#d13c31', '#e66859', '#f8baaf'];
        warningColors = ['#342c1a', '#836611', '#dda705', '#e9bf28', '#f8e577'];
        infoColors = ['#0BC1B6', '#1a2628', '#11595f', '#058e98', '#1ea6aa', '#64cfcb'];
        successColors = ['#1a2721', '#115c36', '#05934c', '#1da65d', '#61ca8b'];
    }

    return {
        primary: {
            10: primaryColors[0],
            20: primaryColors[1],
            30: primaryColors[2],
            40: primaryColors[3],
            50: primaryColors[4],
            60: primaryColors[5],
            70: primaryColors[6],
            80: primaryColors[7],
            90: primaryColors[8],
            100: primaryColors[9],
            500: primaryColors[9],
            main: primaryColors[10],
            dark: primaryColors[9],
            ims: '#485776',
            contrastText
        },
        secondary: {
            10: greyColors[0],
            20: greyColors[50],
            30: greyColors[100],
            40: greyColors[200],
            50: greyColors[300],
            60: greyColors[400],
            70: greyColors[500],
            80: greyColors[600],
            90: greyColors[700],
            100: greyColors[800],

            lighter: primaryColors[5],
            main: primaryColors[5], //secondary text , all labels colors?

        },
        black: {
            10: blackColors[0],
            20: blackColors[1],
            30: blackColors[2],
            40: blackColors[3],
            50: blackColors[4],
            60: blackColors[5],
            70: blackColors[6],
            80: blackColors[7],
            90: blackColors[8],
            100: blackColors[9],
        },
        blue: {
            main: blueColors[0],
            dark: blueColors[1],
            contrastText

        },
        actions: {
            green: actionsColors[0],
            warning: actionsColors[1],
            red: actionsColors[2],
        },
        error: {
            main: errorColors[0],
            dark: errorColors[1],
            // lighter: errorColors[0],
            // light: errorColors[1],
            //main: errorColors[2],
            // dark: errorColors[3],
            // darker: errorColors[4],
            contrastText
        },
        warning: {
            lighter: warningColors[0],
            light: warningColors[1],
            main: warningColors[2],
            dark: warningColors[3],
            darker: warningColors[4],
            contrastText: greyColors[100]
        },
        info: {
            main: infoColors[0],
            dark: infoColors[1],
            // lighter: infoColors[0],
            // light: infoColors[1],
            // main: infoColors[2],
            // dark: infoColors[3],
            // darker: infoColors[4],
            contrastText
        },
        success: {
            main: successColors[0],
            dark: successColors[0],
            // lighter: successColors[0],
            // light: successColors[1],
            // main: successColors[2],
            // dark: successColors[3],
            // darker: successColors[4],
            contrastText
        },
        grey: greyColors
    };
};

export default Theme8;
