//@flow
import React, {useEffect} from 'react'
import useUserData from "../../hooks/user/useUserData";
import moment from "moment";
import 'moment/locale/el'
import useUserAction from "../../hooks/user/useUserAction";
import './main.css';
import {AppBar, Box, Toolbar} from '@mui/material';
import UnauthenticatedRouter from "../../routers/unauthenticatedRouter";
import Content from "./content";
import Localization from "../../theme/mantis/layout/mainLayout/header/headerContent/localization";
import Logo from "../../theme/mantis/components/logo";
import { Capacitor } from '@capacitor/core';
// import {IonContent, IonHeader, IonToolbar} from "@ionic/react";

const Main = () => {
	const {getUserPermissions} = useUserAction()
	const {isLoggedIn, language, alterPasswordOnLogin} = useUserData()
	const platform = Capacitor.getPlatform()
	useEffect(() => {
		moment.locale(language);
	}, [language])
	useEffect(() => {
		if (isLoggedIn) {
			getUserPermissions()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getUserPermissions, isLoggedIn])
	
	if (!isLoggedIn || (isLoggedIn && alterPasswordOnLogin)) return (
		<>
		<div>
			{/* <AppBar position={'fixed'} elevation={0}> */}
				{/* <IonHeader className="ion-no-border"> */}
					<Toolbar sx={{background: '#fff', pt: 2.2}}>
					{/* <Toolbar sx={{background: '#fff', pt: platform === 'ios' ? 5 : 2.2}}> */}
						<Logo/>
						<Box sx={{flexGrow: 1}}/>
						<Localization/>
					</Toolbar>
				{/* </IonHeader> */}
			{/* </AppBar> */}
			{/* <IonContent> */}
				{/* <Box pt={8}> */}
					<UnauthenticatedRouter/>
				{/* </Box> */}
			{/* </IonContent> */}
			</div>
		</>
	)
	return <Content/>
}

export default Main