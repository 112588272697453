import * as React from "react";
const VisibleEyeOff = (props) => (
    <svg
        width={17}
        height={17}
        viewBox="0 0 17 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g
            clipPath="url(#a)"
            stroke="#485776"
            strokeWidth={1.2}
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path d="M6.668 2.935a6.08 6.08 0 0 1 1.4-.16c4.667 0 7.334 5.334 7.334 5.334-.405.757-.888 1.47-1.44 2.126m-4.48-.713a2 2 0 1 1-2.827-2.827m5.373 5.374a6.714 6.714 0 0 1-3.96 1.373c-4.666 0-7.333-5.333-7.333-5.333a12.3 12.3 0 0 1 3.373-3.96l7.92 7.92ZM.735.775 15.4 15.442" />
        </g>
        <defs>
            <clipPath id="a">
                <path fill="#fff" d="M.068.109h16v16h-16z" />
            </clipPath>
        </defs>
    </svg>
);
export default VisibleEyeOff;