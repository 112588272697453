// @flow
import React, {useMemo} from 'react';
import {useMuiGridServerSide} from "../../../../hooks/useMuiGridServerSide";
import {DataGridPremium, GridActionsCellItem, GRID_AGGREGATION_ROOT_FOOTER_ROW_ID} from '@mui/x-data-grid-premium';
import {useTranslation} from "react-i18next";
import {USERS} from "../../locales/namespaces";
import {Typography, Box, Button, Divider} from "@mui/material";
import {Link} from 'react-router-dom';
import {getRouteUrl} from "../../../../helpers/getRouteUrl";
import {ROUTE_PAGE_USERS_CREATE, ROUTE_PAGE_USERS_DETAILS, ROUTE_PAGE_USERS_EDIT} from "../../routes/routes";
import EditIcon from '@mui/icons-material/Edit';
import DataGridWrapper from "../../../../components/dataGridWrapper/dataGridWrapper";
import {withPermissionHOC} from "../../../permission/components/permissionHOC/permissionHOC";
import Breadcrumb from "../../../../components/breadcrumb/breadcrumb";
import HomeIcon from "@mui/icons-material/Home";
import {ROUTE_PAGE_HOME} from "../../../../routers/routes";
import useUserData from "../../../../hooks/user/useUserData";
import {muiGridLocales} from "../../../../helpers/muiGridLocales";
import MainCard from "../../../../theme/mantis/components/mainCard";
import {CustomPagination} from "../../../../components/dataGridCustom/customPagination";
import moment from "moment/moment";
import HomeBreadcrumb from "../../../../assets/images/icons/homeBreadcrumb";

type Props = {};

const Index = (props: Props) => {
	const {t} = useTranslation(USERS)
	const url = `user/indexTable/data`
	const {language} = useUserData()
	
	const {
		isLoading,
		data,
		total,
		page,
		pageSize,
		onPaginationModelChange,
		onSortModelChange,
		onFilterModelChange,
		refreshGrid
	} = useMuiGridServerSide({
		url,
		initialPageSize: 50,
		initialSortModel: [{name: 'id', direction: 'descending'}]
	})
	
	const columns = useMemo(() => [
		{
			headerName: t('Edit'),
			field: 'actions',
			type: 'actions',
			getActions: (params) => {
				if (params.id === GRID_AGGREGATION_ROOT_FOOTER_ROW_ID) {
					return [];
				}
				return [
					<GridActionsCellItem
						component={Link}
						to={getRouteUrl(ROUTE_PAGE_USERS_EDIT, {id: params.id})}
						icon={<EditIcon/>}
						label={t("Edit")}>
					</GridActionsCellItem>
				]
			}
		},
		{
			field: 'isActive',
			headerName: t('Active'),
			width: 90, maxWidth: 100, flex: 1,
			type: 'boolean',
			valueGetter: ({value}) => value === '1'
		},
		{
			field: 'LoginName',
			headerName: t('Login Name'),
			minWidth: 160, flex: 1,
			renderCell: (params) => {
				if (params.rowNode.type === 'group') {
					return params.value;
				}
				return (
					<Link to={getRouteUrl(ROUTE_PAGE_USERS_DETAILS, {
						id: params.row.id
					})}>
						{params.value}
					</Link>
				)
			}
		},
		{
			field: 'username',
			headerName: t('User Name'),
			minWidth: 160, flex: 1,
		},
		{
			field: 'OriginDescription',
			headerName: t('Origin'),
			minWidth: 160,
			flex: 1
		},
		{
			field: 'last_login',
			headerName: t('Last Login'),
			minWidth: 160, flex: 1,
			//hide: true
			type: 'date',
			valueFormatter: params => params?.value ? moment(params?.value).format("DD-MM-YYYY , HH:mm") : '',
			aggregable: false,
		}
	], [data, language, refreshGrid])
	
	return (
		<Box>
			<Breadcrumb>
				<Breadcrumb.Link iconComponent={HomeBreadcrumb} to={getRouteUrl(ROUTE_PAGE_HOME)}/>
				<Breadcrumb.Item>
					{t('Users')}
				</Breadcrumb.Item>
			</Breadcrumb>
			<MainCard
				title={
					<Typography variant="h4">
						{t('Users')}
					</Typography>
				}
				secondary={
					<Button
						size={"small"}
						variant={"contained"}
						color={'info'}
						component={Link}
						to={getRouteUrl(ROUTE_PAGE_USERS_CREATE)}
					>
						{t('Add')}
					</Button>
				}
			>
				<Divider sx={{mb: 2}} light/>
				<DataGridWrapper autoHeight>
					<DataGridPremium
						sx={{
							bgcolor: 'background.paper', ".MuiDataGrid-aggregationColumnHeaderLabel": {
								display: "none"
							},
							maxHeight: 650
						}}
						columns={columns}
						density={'compact'}
						rows={data}
						
						pagination
						paginationModel={{page, pageSize}}
						paginationMode='server'
						onPaginationModelChange={onPaginationModelChange}
						rowCount={Number(total)}
						rowsPerPageOptions={[5, 10, 15, 20, 50, 100]}
						sortingMode="server"
						onSortModelChange={(sortModel) => onSortModelChange(sortModel)}
						filterMode='server'
						onFilterModelChange={onFilterModelChange}
						loading={isLoading}
						localeText={muiGridLocales(t)}
						slots={{
							pagination: CustomPagination,
						}}
						disableAggregation={true}
						disableRowGrouping={true}
					/>
				</DataGridWrapper>
			</MainCard>
		</Box>
	);
};

export default withPermissionHOC(Index, ['access_user_index'])