import {useEffect, useState} from "react";
import axios from "axios";
import {API} from "../../../helpers/constants";
import useUserData from "../../../hooks/user/useUserData";
import {useTranslation} from "react-i18next";
import {ALERTS} from "../../../locales/components/namespaces";
import {handleAxiosErrors, logoutUnauthorizedUser} from "../../../helpers/errorHandling";
import {enqueueSnackbar} from "notistack";
import useUserAction from "../../../hooks/user/useUserAction";

const useDateFormats = () => {
    const {token, isLoggedIn} = useUserData()
    const {userLogout} = useUserAction()
    const {t} = useTranslation(ALERTS)
    const [loading, setLoading] = useState(false)
    const [dateFormats, setDateFormats] = useState([])
    useEffect(() => {
        setLoading(true)
        axios({
            method: 'get',
            headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
            url: `${API}/date/list`,
        }).then((response) => {
            setDateFormats(response.data)
            setLoading(false)
        }).catch(error => {
            setLoading(false)
            enqueueSnackbar(handleAxiosErrors(error, t('Failed to get date format list.')), {
                    variant: 'error'
                }
            )
            logoutUnauthorizedUser(error, isLoggedIn, userLogout)
        })
    }, [])
    return {
        loading,
        dateFormats
    }
}

export default useDateFormats