//@flow
import React from 'react';
import {Route, Switch} from "react-router";
import {
	ROUTE_PAGE_LOGIN, ROUTE_PAGE_PRIVACY_POLICY,
	ROUTE_PAGE_TERMS_CONDITIONS,
	ROUTE_PAGE_RESET_PASSWORD,
	ROUTE_PAGE_SIGN_UP,
	ROUTE_PAGE_UNAUTHORIZED_CALCULATION, ROUTE_PAGE_WIZARD
} from "./routes";

const ROUTES = [
	ROUTE_PAGE_RESET_PASSWORD,
	ROUTE_PAGE_SIGN_UP,
	ROUTE_PAGE_UNAUTHORIZED_CALCULATION,
	ROUTE_PAGE_TERMS_CONDITIONS,
	ROUTE_PAGE_PRIVACY_POLICY,
	ROUTE_PAGE_WIZARD,
	ROUTE_PAGE_LOGIN,
]

const UnauthenticatedRouter = () => {
	return (
		<Switch>
			{ROUTES.map(route => {
				const {key, path, exact, component} = route;
				return (
					<Route
						key={key}
						path={path}
						exact={exact}
						component={component}
					/>
				);
			})}
		</Switch>
	);
};

export default UnauthenticatedRouter;