import React, {useEffect, useMemo, useRef, useState} from 'react';
import {Link} from 'react-router-dom';
import axios from "axios";
import {enqueueSnackbar} from "notistack";
import {useTranslation} from "react-i18next";

// material-ui
import {useTheme} from '@mui/material/styles';
import {
	Avatar,
	Badge,
	Box,
	ClickAwayListener,
	List,
	ListItemButton,
	ListItemAvatar,
	ListItemText,
	Paper,
	Popper,
	Typography,
	useMediaQuery
} from '@mui/material';

// project import
import IconButton from "../../../../components/@extended/iconButton";
import Transitions from "../../../../components/@extended/transitions";
import MainCard from "../../../../components/mainCard";
import {API} from "../../../../../../helpers/constants";
import {handleAxiosErrors, logoutUnauthorizedUser} from "../../../../../../helpers/errorHandling";
import {NAVIGATION} from "../../../../../../locales/components/namespaces";
import useUserData from "../../../../../../hooks/user/useUserData";
import useUserAction from "../../../../../../hooks/user/useUserAction";
import {getRouteUrl} from "../../../../../../helpers/getRouteUrl";
import {ROUTE_PAGE_LOANS_DETAILS} from "../../../../../../modules/loans/routes/routes";
import {formatDate} from "../../../../../../helpers/date";
import {ROUTE_PAGE_CALCULATIONS_EDIT} from "../../../../../../modules/calculations/routes/routes";
import NotificationIcon from "../../../../../../assets/images/icons/notificationIcon";
import NotificationGrid from "../../../../../../assets/images/icons/notificationGrid";
import CreditCard from "../../../../../../assets/images/icons/creditCardIcon";
import AlertTriangleIcon from "../../../../../../assets/images/icons/alertTriangleIcon";
import {textTranslate} from "../../../../../../helpers/helpers";
import {Capacitor} from "@capacitor/core";
import {addListeners, getDeliveredNotifications, registerNotifications} from "./mobileNotification";

// sx styles
const avatarSX = {
	width: 36,
	height: 36,
	fontSize: '1rem'
};

const actionSX = {
	mt: '6px',
	ml: 1,
	top: 'auto',
	right: 'auto',
	alignSelf: 'flex-start',
	transform: 'none'
};

// ==============================|| HEADER CONTENT - NOTIFICATION ||============================== //

const Notification = () => {
	const theme = useTheme();
	const matchesXs = useMediaQuery(theme.breakpoints.down('md'));
	const {t} = useTranslation(NAVIGATION)
	const {language} = useUserData()
	const anchorRef = useRef(null);
	const {token, isLoggedIn} = useUserData()
	const {userLogout} = useUserAction()
	const [loading, setLoading] = useState(false)
	const [notifications, setNotifications] = useState([])
	
	const [open, setOpen] = useState(false);
	
	const handleToggle = (event) => {
		if (notifications.length === 0) {
			enqueueSnackbar(t('There are no new updates available!'), {
					variant: 'info'
				}
			)
			handleClose(event)
		} else {
			setOpen((prevOpen) => !prevOpen);
		}
	};
	
	const handleClose = (event) => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}
		setOpen(false);
	};
	
	/* const fetchNotifications = () => {
		 if (isLoggedIn) {
			 setLoading(true);
			 axios({
				 method: 'get',
				 headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
				 url: `${API}/notification/list`,
			 })
				 .then((response) => {
					 setNotifications(response.data);
					 setLoading(false);
					 //if (Capacitor.isNativePlatform()) { displayPushNotification("New Notifications", "You have new notifications!");}
					 //if (Capacitor.isNativePlatform()) { displayPushNotification(notificationData);}
					 //if (Capacitor.isNativePlatform()) { getDeliveredNotifications();}
					 //if (Capacitor.isNativePlatform()) { getDeliveredNotifications(notifications);}
					 if (Capacitor.isNativePlatform()) {
						 addListeners()
						 registerNotifications()
						 getDeliveredNotifications()
					 }
				 })
				 .catch(error => {
					 setLoading(false);
					 enqueueSnackbar(handleAxiosErrors(error, t('Failed to get notifications.')), {
						 variant: 'error',
						 autoHideDuration: 3400
					 });
					 logoutUnauthorizedUser(error, isLoggedIn, userLogout);
				 });
		 }
	 };*/
	const fetchNotifications = () => {
		if (isLoggedIn) {
			setLoading(true);
			axios({
				method: 'get',
				headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
				url: `${API}/notification/list`,
			})
			.then((response) => {
				setNotifications(response.data);
				setLoading(false);
				if (Capacitor.isNativePlatform()) {
					addListeners();
					registerNotifications()
					getDeliveredNotifications()
				}
			})
			.catch(error => {
				setLoading(false);
				if (error.response.status === 401) {
					logoutUnauthorizedUser(error, isLoggedIn, userLogout);
					return null
				} else {
					logoutUnauthorizedUser(error, isLoggedIn, userLogout);
					enqueueSnackbar(handleAxiosErrors(error, t('Failed to get notifications.')), {
						variant: 'error'
					})
				}
				logoutUnauthorizedUser(error, isLoggedIn, userLogout);
			});
		}
	};
	
	useEffect(() => {
		// Fetch notifications initially
		fetchNotifications();
		
		// Set up an interval to fetch notifications every 1 minute (adjust as needed)
		const intervalId = setInterval(() => {
			fetchNotifications();
		}, 60000); // 1 minute in milliseconds
		
		// Cleanup the interval when the component unmounts
		return () => clearInterval(intervalId);
	}, [isLoggedIn, token]);
	
	const read = useMemo(() => (notifications.length), [notifications])
	
	const iconBackColorOpen = '#A6A8B1';
	const iconBackColor = '#F4F5FA';
	
	//Type=1 ερωτήματα από Ims
	//Type=2 πληρωμή δόσης
	//Type=3 ενημέρωση κατάστασης δανείου
	
	return (
		<Box sx={{flexShrink: 0, ml: 0.75}}>
			<IconButton
				sx={{color: 'text.primary', bgcolor: open ? iconBackColorOpen : iconBackColor}}
				aria-label="open profile"
				ref={anchorRef}
				aria-controls={open ? 'profile-grow' : undefined}
				aria-haspopup="true"
				onClick={handleToggle}
			>
				<Badge badgeContent={read} color="info">
					<NotificationIcon/>
				</Badge>
			</IconButton>
			<Popper
				sx={{
					borderRadius: '12px',
					background: '#FFF',
					boxShadow: theme.customShadows.z1,
				}}
				placement={matchesXs ? 'bottom' : 'bottom-end'}
				open={open}
				anchorEl={anchorRef.current}
				role={undefined}
				transition
				disablePortal
				popperOptions={{
					modifiers: [
						{
							name: 'offset',
							options: {
								offset: [matchesXs ? -5 : 0, 9]
							}
						}
					]
				}}
			>
				{({TransitionProps}) => (
					<Transitions type="grow" position={matchesXs ? 'top' : 'top-right'} sx={{overflow: 'hidden'}}
								 in={open} {...TransitionProps}>
						<Paper
							sx={{
								width: '100%',
								minWidth: 285,
								maxWidth: '530px',
								[theme.breakpoints.down('md')]: {
									maxWidth: 285
								}
							}}
						>
							<ClickAwayListener onClickAway={handleClose}>
								<MainCard
									//title={t("Notifications")}
									elevation={0}
									border={false}
									content={false}
								>
									<List
										component="nav"
										sx={{
											p: 0,
											'& .MuiListItemButton-root': {
												py: 0.5,
												'&.Mui-selected': {bgcolor: 'grey.50', color: 'text.primary'},
												'& .MuiAvatar-root': avatarSX,
												'& .MuiListItemSecondaryAction-root': {
													...actionSX,
													position: 'relative'
												}
											},
											overflow: notifications.length > 3 ? 'scroll' : 'hidden',
											overflowX: 'hidden',
											maxHeight: 500
										}}
									>
										{notifications.map((notification) => {
												return (
													<div key={notification.id}>
														<>
															{notification.Type === '1' &&
																<ListItemButton
																	component={Link}
																	to={getRouteUrl((ROUTE_PAGE_LOANS_DETAILS), {id: notification.LoanId})}
																	onClick={handleClose}
																	sx={{
																		display: 'inline-flex',
																		alignItems: 'flex-start',
																		borderBottom: '2px solid #F4A40B',
																		width: '100%'
																	}}
																>
																	<ListItemAvatar>
																		<Avatar
																			sx={{
																				color: '#F4A40B',
																				bgcolor: '#fff',
																				/* '&:hover': {
																					 bgcolor: '#F4F5FA'
																				 }*/
																			}}
																		>
																			<AlertTriangleIcon color={'#F4A40B'}/>
																		</Avatar>
																	</ListItemAvatar>
																	<ListItemText
																		primary={
																			<>
																				<Typography
																					color={'#485776'}
																					sx={{
																						fontSize: '16px',
																						fontStyle: 'normal',
																						fontWeight: 700,
																						lineHeight: 'normal'
																					}}
																				>
																					{t("Update for pending")}
																				</Typography>
																				<Typography component="span"
																							dangerouslySetInnerHTML={{
																								__html: textTranslate(language)({
																									en: notification.BodyEn && notification.BodyEn.length > 100 ? notification.BodyEn.slice(0, 100) + '...' : notification.BodyEn,
																									el: notification.Body && notification.Body.length > 100 ? notification.Body.slice(0, 100) + '...' : notification.Body
																								})
																							}}/>
																			</>
																		}
																		secondary={formatDate(notification.CreatedAt, formatDate.formatTypes.DAY_MONTH_YEAR_HOUR)}
																	/>
																</ListItemButton>
															}
														</>
														<>
															{notification.Type === '2' &&
																<ListItemButton
																	key={notification.id}
																	component={Link}
																	to={getRouteUrl((ROUTE_PAGE_CALCULATIONS_EDIT), {id: notification.CalculationId})}
																	onClick={handleClose}
																	sx={{
																		display: 'inline-flex',
																		alignItems: 'flex-start',
																		borderBottom: '2px solid #485776',
																		width: '100%'
																	}}
																>
																	<ListItemAvatar>
																		<Avatar
																			sx={{
																				color: '#485776',
																				bgcolor: '#fff',
																			}}
																		>
																			<CreditCard color={'#485776'}/>
																		</Avatar>
																	</ListItemAvatar>
																	<ListItemText
																		primary={
																			<>
																				<Typography
																					color={'#485776'}
																					sx={{
																						fontSize: '16px',
																						fontStyle: 'normal',
																						fontWeight: 700,
																						lineHeight: 'normal'
																					}}
																				>
																					{t("Installment payment notice")}
																				</Typography>
																				<Typography component="span"
																							dangerouslySetInnerHTML={{
																								__html: textTranslate(language)({
																									en: notification.BodyEn && notification.BodyEn.length > 100 ? notification.BodyEn.slice(0, 100) + '...' : notification.BodyEn,
																									el: notification.Body && notification.Body.length > 100 ? notification.Body.slice(0, 100) + '...' : notification.Body
																								})
																							}}/>
																			</>
																		}
																		secondary={formatDate(notification.CreatedAt, formatDate.formatTypes.DAY_MONTH_YEAR_HOUR)}
																	/>
																</ListItemButton>
															}
														</>
														<>
															{notification.Type === '3' &&
																<ListItemButton
																	key={notification.id}
																	component={Link}
																	to={getRouteUrl((ROUTE_PAGE_LOANS_DETAILS), {id: notification.LoanId})}
																	onClick={handleClose}
																	sx={{
																		display: 'inline-flex',
																		alignItems: 'flex-start',
																		borderBottom: '2px solid #519C66',
																		width: '100%'
																	}}
																>
																	<ListItemAvatar>
																		<Avatar
																			sx={{
																				color: '#519C66',
																				bgcolor: '#fff',
																			}}
																		>
																			<NotificationGrid color={'#519C66'}/>
																		</Avatar>
																	</ListItemAvatar>
																	<ListItemText
																		primary={
																			<>
																				<Typography
																					color={'#485776'}
																					sx={{
																						fontSize: '16px',
																						fontStyle: 'normal',
																						fontWeight: 700,
																						lineHeight: 'normal'
																					}}
																				>
																					{t("Change loan application status")}
																				</Typography>
																				<Typography component="span"
																							dangerouslySetInnerHTML={{
																								__html: textTranslate(language)({
																									en: notification.BodyEn && notification.BodyEn.length > 100 ? notification.BodyEn.slice(0, 100) + '...' : notification.BodyEn,
																									el: notification.Body && notification.Body.length > 100 ? notification.Body.slice(0, 100) + '...' : notification.Body
																								})
																							}}/>
																			</>
																		}
																		secondary={formatDate(notification.CreatedAt, formatDate.formatTypes.DAY_MONTH_YEAR_HOUR)}
																	/>
																</ListItemButton>
															}
														</>
													</div>
												)
											}
										)}
									</List>
								</MainCard>
							</ClickAwayListener>
						</Paper>
					</Transitions>
				)}
			</Popper>
		</Box>
	);
};

export default Notification;
