// material-ui
import {useMediaQuery, useTheme} from '@mui/material';

// project import
// import NavCard from './navCard';
import Navigation from './navigation';
import SimpleBar from '../../../../components/third-party/simpleBar';
import useMenuData from "../../../../../../hooks/menu/useMenuData";

// ==============================|| DRAWER CONTENT ||============================== //

const DrawerContent = () => {
    const theme = useTheme();
    const matchDownMD = useMediaQuery(theme.breakpoints.down('lg'));
    const {drawerOpen} = useMenuData()
    return (
        <SimpleBar
            sx={{
                '& .simplebar-content': {
                    display: 'flex',
                    flexDirection: 'column'
                }
            }}
        >
            <Navigation/>
            {/*{drawerOpen && !matchDownMD && <NavCard/>}*/}
        </SimpleBar>
    );
};

export default DrawerContent;
