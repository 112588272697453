import {useState} from 'react'
import axios from 'axios'
import {useTranslation} from 'react-i18next'
import {API} from "../../../helpers/constants";
import {enqueueSnackbar} from "notistack";
import {handleAxiosErrors, logoutUnauthorizedUser} from "../../../helpers/errorHandling";
import useUserData from "../../../hooks/user/useUserData";
import useUserAction from "../../../hooks/user/useUserAction";
import {ALERTS} from "../../../locales/components/namespaces";

const useQuestionnaires = () => {
	const {token, isLoggedIn} = useUserData()
	const {userLogout} = useUserAction()
	const {t} = useTranslation(ALERTS)
	const [refreshQuestion, setRefreshQuestion] = useState(false)
	const [refreshChoice, setRefreshChoice] = useState(false)
	
	const updateQuestion = (newRow, oldRow) => {
		axios({
			method: 'post',
			headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
			url: `${API}/parameters/questionnaire/update`,
			data: newRow
		}).then(response => {
			enqueueSnackbar(t('Description updated successfully!'), {
					variant: 'success'
				}
			)
			setRefreshQuestion(!refreshQuestion)
		}).catch(error => {
			setRefreshQuestion(!refreshQuestion)
			enqueueSnackbar(handleAxiosErrors(error, t('Failed to update description!')), {
					variant: 'error'
				}
			)
			logoutUnauthorizedUser(error, isLoggedIn, userLogout)
		})
	}
	
	const updateChoice = (newRow, oldRow) => {
		axios({
			method: 'post',
			headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
			url: `${API}/parameters/options/update`,
			data: newRow
		}).then(response => {
			enqueueSnackbar(t('Description updated successfully!'), {
					variant: 'success'
				}
			)
			setRefreshChoice(!refreshChoice)
		}).catch(error => {
			setRefreshChoice(!refreshChoice)
			enqueueSnackbar(handleAxiosErrors(error, t('Failed to update description!')), {
					variant: 'error'
				}
			)
			logoutUnauthorizedUser(error, isLoggedIn, userLogout)
		})
	}
	
	return {
		updateQuestion,
		refreshQuestion,
		updateChoice,
		refreshChoice
		
	}
}

export default useQuestionnaires