import * as React from "react";
const ArrowRightIcon = (props) => (
    <svg
        width={20}
        height={21}
        viewBox="0 0 20 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g fill="#0BC1B6">
            <path
                opacity={0.4}
                d="M5.916 20.727h8.168c3.539 0 5.916-2.276 5.916-5.665v-8.67C20 3.003 17.623.727 14.084.727H5.916C2.378.727 0 3.004 0 6.393v8.669c0 3.389 2.378 5.665 5.916 5.665Z"
            />
            <path d="m10.855 15.006 3.765-3.748a.773.773 0 0 0 0-1.064l-3.764-3.748a.75.75 0 0 0-1.06 1.064l2.478 2.467H5.918a.75.75 0 1 0 0 1.5h6.356l-2.478 2.466a.749.749 0 0 0-.002 1.061.751.751 0 0 0 1.061.002Z" />
        </g>
    </svg>
);
export default ArrowRightIcon;