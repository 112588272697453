import axios from "axios";
import {API} from "../../helpers/constants";
import {enqueueSnackbar} from "notistack";
import {handleAxiosErrors, logoutUnauthorizedUser} from "../../helpers/errorHandling";
import * as yup from "yup";
import {useFormik} from "formik";
import useUserAction from "../../hooks/user/useUserAction";
import useUserData from "../../hooks/user/useUserData";
import {useTranslation} from "react-i18next";
import {PAGE_HOME} from "../../locales/pages/namespaces";

const useSupportDialog = ({handleClose}) => {
	const {t} = useTranslation(PAGE_HOME)
	const {userLogout} = useUserAction()
	const {token, isLoggedIn} = useUserData()
	
	const onSubmit = (values) => {
		axios({
			method: 'post',
			data: {
				Body: values.Body
			},
			headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
			url: `${API}/support/email`
		}).then(() => {
			enqueueSnackbar(t('Email send successful!'), {
					variant: 'success'
				}
			)
			handleClose()
		}).catch((error) => {
			enqueueSnackbar(handleAxiosErrors(error, t('An error occurred!')), {
					variant: 'error'
				}
			)
			logoutUnauthorizedUser(error, isLoggedIn, userLogout)
		})
	}
	
	const validationSchema = yup.object({
		Body: yup
		.string()
		.required(t('Required')),
		isInformed: yup.boolean().oneOf([true], t("Accepting the privacy statement is mandatory to proceed."))
	})
	
	const formik = useFormik({
		initialValues: {
			Body: '',
			isInformed: false
		},
		validationSchema: validationSchema,
		onSubmit: onSubmit,
	})
	
	return {
		formik
	}
}

export default useSupportDialog;