// @flow
import * as React from 'react';
import FormInterestRates from "./interestRateForm"
import {useMemo} from "react";
import ContentLoader from "../../../../components/contentLoader/contentLoader";
import {withPermissionHOC} from "../../../permission/components/permissionHOC/permissionHOC";
import useInterestRate from "../../hooks/useInterestRate";
import {useTranslation} from "react-i18next";
import {PARAMETERS} from "../../locales/namespaces";
import DataGridWrapper from "../../../../components/dataGridWrapper/dataGridWrapper";
import EditableMUIDataGrid from "../../../../components/editableMUIDataGrid/editableMUIDataGrid";
import Stack from "@mui/material/Stack";
import {Typography} from "@mui/material";
import MainCard from "../../../../theme/mantis/components/mainCard";
import EditableMUICellValidation from "../../../../components/editableMUICellValidation/editableMUICellValidation";
import {GridEditInputCell} from "@mui/x-data-grid-premium";
import {NumericFormat} from "react-number-format";
import useUserData from "../../../../hooks/user/useUserData";

type Props = {
	circularLoader?: boolean
}

const InterestRates = (props: Props) => {
	const {t} = useTranslation(PARAMETERS)
	const {loading, interests, contributions, store, remove} = useInterestRate()
	const {thousandSeparator, decimalSeparator} = useUserData()
	
	const {circularLoader} = props
	
	const newRowModel = {
		Value: '',
	}
	
	const columns = useMemo(() => [
		{
			field: 'isDefault',
			headerName: t('Default'),
			minWidth: 150,
			flex: 1,
			maxWidth: 180,
			editable: true,
			type: 'boolean',
			valueGetter: ({value}) => value === '1'
		},
		{
			field: 'Value',
			headerName: t('Contribution'),
			minWidth: 160,
			flex: 1,
			maxWidth: 220,
			editable: true,
			type: 'number',
			renderCell: ({row}) => {
				return row.Value ? <NumericFormat
					thousandSeparator={thousandSeparator}
					decimalSeparator={decimalSeparator}
					displayType="text"
					value={row.Value}
				/> : 0
			},
			preProcessEditCellProps: (params) => {
				const inputValue = Number(params.props.value);
				if (!inputValue) {
					return {...params.props, error: t('Required')};
				}
				
				//const regex = /^[\d,\.]+$/g;
				//const matchInputValue = inputValue.match(regex);
				
				// if (!matchInputValue) {
				//     return {...params.props, error: t('The field must be number')};
				// }
				
				return {...params.props, error: null}; // No error
			},
			renderEditCell: (params) => {
				return (
					<EditableMUICellValidation {...params}>
						<GridEditInputCell {...params} />
					</EditableMUICellValidation>
				);
			},
		},
	], [contributions, t])
	
	return !loading ? (
		<MainCard
			title={
				<Typography variant="h5" gutterBottom>
					{t('Calculation variables')}
				</Typography>
			}
			sx={{mt: 2}}
		>
			<Stack direction={"row"}>
				<FormInterestRates
					interests={interests}
				/>
				<DataGridWrapper
					sx={{
						mt: 2,
						ml: 4,
						maxWidth: '450px',
						height: '300px'
					}}
				>
					<EditableMUIDataGrid
						loading={loading}
						data={contributions}
						columns={columns}
						newRowModel={newRowModel}
						store={store}
						remove={remove}
						hideEditButton={true}
						dataGridProOtherProps={{
							disableColumnMenu: true,
							// disableAggregation: true,
							// disableRowGrouping: true,
							// disableColumnFilter: true
						}}
					/>
				</DataGridWrapper>
			</Stack>
		</MainCard>
	) : (
		<ContentLoader
			loading={loading}
			useCircularLoader={circularLoader}
		/>
	)
}

export default withPermissionHOC(InterestRates, ['access_parameters'])