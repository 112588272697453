import {forwardRef} from 'react';

// material-ui
import {styled, useTheme} from '@mui/material/styles';
import {Card, CardContent, CardHeader, Divider, Typography} from '@mui/material';

// project import
import React from "react";
import {ThemeMode} from "../config";
import ArrowRight2 from "../../../assets/images/icons/arrowRight2";
import Box from "@mui/material/Box";

// header style
/*const headerSX = {
    p: 2.5,
    '& .MuiCardHeader-action': {m: '0px auto', alignSelf: 'center'},
    '& .MuiTypography-root': {
        fontSize: '20px',
        fontWeight: 400,
    }
};*/
const headerSX = {
    p: 2.5,
    '& .MuiCardHeader-action': {
        m: '0px auto',
        alignSelf: 'center',
        '@media (max-width: 800px)': {
            display: 'flex',
            // // minHeight: 100,
            // justifyContent: "center",
            // alignItems: "center",
            flexDirection: 'column',
            gap: '20px',
            // order: -1, // Move the action to the top on small screens
            // marginTop: '38px', // Add some space between title and action on small screens
        },

    }, '@media (max-width: 800px)': {
        display: 'flex',
        // // minHeight: 100,
        // justifyContent: "center",
        // alignItems: "center",
        gap: '20px',
        flexDirection: 'column'
        // order: -1, // Move the action to the top on small screens
        // marginTop: '38px', // Add some space between title and action on small screens
    },
    '& .MuiTypography-root': {
        fontSize: '20px',
        fontWeight: 400,
    },
};

const IconWrapper = styled('div')({
    borderRadius: '8px',
    background: 'rgba(85, 112, 241, 0.08)',
    display: 'flex',
    width: '36px',
    height: '36px',
    padding: '8px',
    justifyContent: 'center',
    alignItems: 'center',
    transform: 'rotate(360deg)',
});

// ==============================|| CUSTOM - MAIN CARD ||============================== //

const MainCard = forwardRef(
    (
        {
            border = false,
            boxShadow,
            children,
            subheader,
            content = true,
            contentSX = {},
            darkTitle,
            divider = true,
            elevation,
            secondary,
            shadow,
            sx = {},
            title,
            codeHighlight = false,
            codeString,
            modal = false,
            icon,
            withoutArrow,
            ...others
        },
        ref
    ) => {
        const theme = useTheme();
        boxShadow = theme.palette.mode === ThemeMode.DARK ? boxShadow || true : boxShadow;

        return (
            <Card
                elevation={elevation || 0}
                ref={ref}
                {...others}
                sx={{
                    position: 'relative',
                    border: border ? '1px solid' : 'none',
                    borderRadius: '12px',
                    borderColor: theme.palette.mode === ThemeMode.DARK ? theme.palette.divider : theme.palette.grey.A800,
                    boxShadow: boxShadow && (!border || theme.palette.mode === ThemeMode.DARK) ? shadow || theme.customShadows.z1 : 'inherit',
                    ':hover': {
                        boxShadow: boxShadow ? shadow || theme.customShadows.z1 : 'inherit'
                    },
                    ...(codeHighlight && {
                        '& pre': {
                            m: 0,
                            p: '12px !important',
                            fontFamily: theme.typography.fontFamily,
                            fontSize: '0.75rem'
                        }
                    }),
                    ...(modal && {
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: {xs: `calc( 100% - 50px)`, sm: 'auto'},
                        '& .MuiCardContent-root': {
                            overflowY: 'auto',
                            minHeight: 'auto',
                            maxHeight: `calc(100vh - 200px)`
                        }
                    }),
                    ...sx
                }}
            >
                {/* card header and action */}
                {!darkTitle && title && (
                    <CardHeader
                        sx={headerSX}
                        titleTypographyProps={{variant: 'h4'}}
                        title={
                            <Box
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '18px',
                                    alignSelf: 'stretch'
                                }}
                            >
                                {!withoutArrow &&
                                    <IconWrapper>
                                        <ArrowRight2/>
                                    </IconWrapper>
                                }
                                {title}
                            </Box>
                        }
                        action={secondary}
                        subheader={subheader}
                    />
                )}
                {darkTitle && title &&
                    <CardHeader
                        sx={headerSX}
                        title={
                            <Typography variant="h4">{title}</Typography>
                        }
                        action={secondary}
                    />
                }

                {/* content & header divider */}
                {title && divider && <Divider sx={{
                    strokeWidth: '1px',
                    stroke: '#D3D5DF',
                    height: '0px',
                    transform: 'rotate(-0.027deg)',
                    alignSelf: 'stretch'
                }}/>}

                {/* card content */}
                {content && <CardContent sx={contentSX}>{children}</CardContent>}
                {!content && children}

                {/* card footer - clipboard & highlighter  */}
                {codeString && (
                    <>
                        <Divider sx={{borderStyle: 'dashed'}}/>
                        {/*<Highlighter codeString={codeString} codeHighlight={codeHighlight} />*/}
                    </>
                )}
            </Card>
        );
    }
);
//
// MainCard.propTypes = {
//   border: PropTypes.bool,
//   boxShadow: PropTypes.bool,
//   children: PropTypes.node,
//   subheader: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
//   content: PropTypes.bool,
//   contentClass: PropTypes.string,
//   contentSX: PropTypes.object,
//   darkTitle: PropTypes.bool,
//   divider: PropTypes.bool,
//   elevation: PropTypes.number,
//   secondary: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object]),
//   shadow: PropTypes.string,
//   sx: PropTypes.object,
//   title: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object]),
//   modal: PropTypes.bool,
//   codeHighlight: PropTypes.bool,
//   codeString: PropTypes.string
// };

export default MainCard;
