import * as React from "react";
const HomeNative = (props) => (
    <svg
        width={20}
        height={19}
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g fill="#485776">
            <path
                opacity={0.4}
                d="M10.36.109h2.465c1.021 0 1.849.834 1.849 1.864V4.46c0 1.03-.828 1.864-1.849 1.864H10.36A1.856 1.856 0 0 1 8.511 4.46V1.973c0-1.03.827-1.864 1.848-1.864"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.957.109h2.466c1.021 0 1.849.834 1.849 1.864V4.46c0 1.03-.828 1.864-1.849 1.864H1.957A1.856 1.856 0 0 1 .11 4.46V1.973A1.854 1.854 0 0 1 1.957.109Zm0 8.35h2.466c1.021 0 1.849.834 1.849 1.864v2.487a1.857 1.857 0 0 1-1.849 1.864H1.957A1.857 1.857 0 0 1 .11 12.81v-2.487c0-1.03.827-1.864 1.848-1.864Zm10.868 0H10.36a1.856 1.856 0 0 0-1.848 1.864v2.487c0 1.029.827 1.864 1.848 1.864h2.466a1.857 1.857 0 0 0 1.849-1.864v-2.487c0-1.03-.828-1.864-1.849-1.864Z"
            />
        </g>
    </svg>
);
export default HomeNative;
