import * as React from "react";
const SuccessIcon = (props) => (
    <svg
        width={25}
        height={25}
        style={{marginRight: '32px'}}
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g
            stroke="#fff"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path
                clipRule="evenodd"
                d="M19.778 1.666H8.219c-4.028 0-6.553 2.852-6.553 6.888v10.89c0 4.037 2.513 6.889 6.553 6.889h11.558c4.041 0 6.556-2.852 6.556-6.888V8.554c0-4.036-2.515-6.888-6.555-6.888Z"
            />
            <path d="m9.252 13.999 3.165 3.164 6.328-6.328" />
        </g>
    </svg>
);
export default SuccessIcon;