import * as React from "react";
const UsersIcon = (props) => (
    <svg
        width={24}
        height={24}
        viewBox="0 0 36 37"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M34.5 32.228v-3a6 6 0 0 0-4.5-5.805m-4.5 8.804v-3a6 6 0 0 0-6-6h-12a6 6 0 0 0-6 6v3M24 5.423a6 6 0 0 1 0 11.625m-10.5.179a6 6 0 1 0 0-12 6 6 0 0 0 0 12Z"
            stroke="#fff"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
export default UsersIcon;
