// ==============================|| OVERRIDES - ALERT TITLE ||============================== //

export default function Accordion(theme) {
    return {
        MuiAccordion: {
            defaultProps: {
                // disableGutters: true,
                // square: true,
                // elevation: 0
            },
            styleOverrides: {
                root: {
                    "&:before": {
                        backgroundColor: "transparent",
                    },
                    '& .MuiAccordionSummary-root': {
                        background: '#fff',
                        display: 'flex',
                        gap: '8px',
                        boxShadow: 'none',
                        borderRadius: '12px',
                        border: '12px',
                    },

                    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                        transform: 'rotate(0deg)',
                        alignSelf: 'flex-start',
                        marginTop: '16px',
                        '@media (max-width: 1380px)': {
                            alignSelf: 'flex-start',
                            marginTop: '16px'
                        },
                    },
                    '& .MuiAccordionSummary-expandIconWrapper': {
                        borderRadius: '8px',
                        background: 'rgba(85, 112, 241, 0.08)',
                        display: 'flex',
                        width: '36px',
                        height: '36px',
                        padding: '8px',
                        justifyContent: 'center',
                        alignItems: 'center',
                        transform: 'rotate(270deg)',
                        alignSelf: 'flex-start',
                        marginTop: '16px',
                        marginBottom: '16px',
                        '@media (max-width: 1380px)': {
                            alignSelf: 'flex-start',
                            marginTop: '16px',
                            marginBottom: '16px'
                        },
                    },
                }
            }
        }
    };
}
