// @flow
// material-ui
import {Grid, Stack, Typography} from '@mui/material';

// project imports
import MainCard from "../../../theme/mantis/components/mainCard";

// ==============================|| REPORT CARD ||============================== //

const ReportCard = ({primary, secondary, iconPrimary, color, symbol}) => {
    const IconPrimary = iconPrimary;
    const primaryIcon = iconPrimary ? <IconPrimary fontSize="large"/> : null;

    return (
        <MainCard sx={{border: '1px solid #0BC1B6', height: '100%'}}>
            <Grid container //justifyContent="space-between" alignItems="center"
                  sx={{
                      display: 'flex',
                      //height: 145px;
                      //padding: '16px',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      gap: '10px',
                      flex: '1 0 0'
                  }}
            >
                <Grid item xs={12}>
                    <Typography variant="h2" style={{color}}>
                        {primaryIcon}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Stack spacing={1}>
                        <Typography variant="h5" color="secondary">
                            {secondary}
                        </Typography>
                        <Typography variant="h4">{primary}{symbol}</Typography>
                    </Stack>
                </Grid>
            </Grid>
        </MainCard>
    );
};

export default ReportCard;
