// material-ui
import {useTheme} from '@mui/material/styles';
import {useMediaQuery} from '@mui/material';

// project import
import DrawerHeaderStyled from './drawerHeaderStyled';
import useConfig from "../../../../hooks/useConfig";
import {MenuOrientation, ThemeMode} from "../../../../config";
import Logo from "../../../../components/logo";
import {Capacitor} from "@capacitor/core";
import LogoMain from "../../../../components/logo/logoMain";
import useMenuData from "../../../../../../hooks/menu/useMenuData";

// ==============================|| DRAWER HEADER ||============================== //

const DrawerHeader = ({open}) => {
    const theme = useTheme();
    const downLG = useMediaQuery(theme.breakpoints.down('lg'));

    const {menuOrientation} = useConfig();
    const {drawerOpen} = useMenuData()
    const isHorizontal = menuOrientation === MenuOrientation.HORIZONTAL && !downLG;

    return (
        <DrawerHeaderStyled
            theme={theme}
            open={open}
            sx={{
                minHeight: isHorizontal ? 'unset' : '60px',
                width: isHorizontal ? {xs: '100%', lg: '424px'} : 'inherit',
                paddingTop: Capacitor.isNativePlatform() ? 3 : (isHorizontal ? {xs: '10px', lg: '0'} : '8px'),
                paddingBottom: isHorizontal ? {xs: '18px', lg: '0'} : '8px',
                paddingLeft: isHorizontal ? {xs: '24px', lg: '0'} : open ? '24px' : 0,
                backgroundColor: theme.palette.mode === ThemeMode.DARK ? 'inherit' : '#FFFFFF'
            }}
        >
            {!drawerOpen ?
                <Logo isIcon={!open} sx={{width: open ? 'auto' : 35, height: 35}}/>
                :
                <LogoMain/>}
        </DrawerHeaderStyled>
    );
};

// DrawerHeader.propTypes = {
//     open: PropTypes.bool
// };

export default DrawerHeader;
