import {styled} from "@mui/material/styles";
import {DataGridPremium} from "@mui/x-data-grid-premium";
import calculateBackgroundColor from "../../../../helpers/calculateBackgroundColorOdDataGridRow";

const StyledDataGrid = styled(DataGridPremium)(({theme}) => ({
    '& .super-app-theme--1': {
        backgroundColor: calculateBackgroundColor(theme.palette.info.main, theme.palette.mode, 0.7),
        '&:hover': {
            backgroundColor: calculateBackgroundColor(theme.palette.info.main, theme.palette.mode, 0.6),
        },
        '&.Mui-selected': {
            backgroundColor: calculateBackgroundColor(theme.palette.info.main, theme.palette.mode, 0.5),
            '&:hover': {
                backgroundColor: calculateBackgroundColor(theme.palette.info.main, theme.palette.mode, 0.4),
            },
        },
    },
}));

export default StyledDataGrid