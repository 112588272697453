import {useEffect, useState} from "react";
import axios from "axios";
import {API} from "../../../helpers/constants";
import useUserData from "../../../hooks/user/useUserData";
import {useTranslation} from "react-i18next";
import {ALERTS} from "../../../locales/components/namespaces";
import {handleAxiosErrors, logoutUnauthorizedUser} from "../../../helpers/errorHandling";
import {enqueueSnackbar} from "notistack";
import useUserAction from "../../../hooks/user/useUserAction";

const useDocsAssociations = () => {
	const {token, isLoggedIn} = useUserData()
	const {userLogout} = useUserAction()
	const {t} = useTranslation(ALERTS)
	const [loading, setLoading] = useState(false)
	const [docsAssociations, setDocsAssociations] = useState([])
	const [updateDocsAssociations, setUpdateDocsAssociations] = useState(false)
	
	useEffect(() => {
		setLoading(true)
		axios({
			method: 'get',
			headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
			url: `${API}/document/rel/list`,
		}).then((response) => {
			setDocsAssociations(response.data)
			setLoading(false)
		}).catch(error => {
			setLoading(false)
			enqueueSnackbar(handleAxiosErrors(error, t('Failed to get documents associations list.')), {
					variant: 'error'
				}
			)
			logoutUnauthorizedUser(error, isLoggedIn, userLogout)
		})
	}, [updateDocsAssociations])
	
	const store = (newRow) => {
		axios({
			method: 'post',
			headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
			url: `${API}/document/rel/store`,
			data: newRow
		}).then(response => {
			enqueueSnackbar(t('New documents association added successfully!'), {
					variant: 'success'
				}
			)
			setUpdateDocsAssociations(!updateDocsAssociations)
		}).catch(error => {
			enqueueSnackbar(handleAxiosErrors(error, t('Failed to store new documents association!')), {
					variant: 'error'
				}
			)
			logoutUnauthorizedUser(error, isLoggedIn, userLogout)
		})
	}
	
	const update = (newRow, oldRow) => {
		axios({
			method: 'post',
			headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
			url: `${API}/document/rel/update`,
			data: {
				...newRow,
				DocRelId: newRow.id
			}
		}).then(response => {
			enqueueSnackbar(t('Documents association updated successfully!'), {
					variant: 'success'
				}
			)
			setUpdateDocsAssociations(!updateDocsAssociations)
		}).catch(error => {
			enqueueSnackbar(handleAxiosErrors(error, t('Failed to update documents association!')), {
					variant: 'error'
				}
			)
			logoutUnauthorizedUser(error, isLoggedIn, userLogout)
		})
	}
	
	const remove = (id) => {
		axios({
			method: 'post',
			headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
			url: `${API}/document/rel/remove`,
			data: {
				id: id
			}
		}).then(response => {
			enqueueSnackbar(t('Documents association deleted successfully!'), {
					variant: 'success'
				}
			)
			setUpdateDocsAssociations(!updateDocsAssociations)
		}).catch(error => {
			enqueueSnackbar(handleAxiosErrors(error, t('Failed to delete documents association!')), {
					variant: 'error'
				}
			)
			logoutUnauthorizedUser(error, isLoggedIn, userLogout)
		})
	}
	
	return {
		loading,
		docsAssociations,
		store,
		update,
		remove
	}
}

export default useDocsAssociations