import {useState} from "react";
import axios from "axios";
import {API} from "../../../helpers/constants";
import useUserData from "../../../hooks/user/useUserData";
import {handleAxiosErrors, logoutUnauthorizedUser} from "../../../helpers/errorHandling";
import {ALERTS} from "../../../locales/components/namespaces";
import {useTranslation} from "react-i18next";
import {enqueueSnackbar} from "notistack";
import useUserAction from "../../../hooks/user/useUserAction";

const useRightsRoles = ({data, refreshGrid}) => {
	const {token, isLoggedIn} = useUserData()
	const {userLogout} = useUserAction()
	const {t} = useTranslation(ALERTS)
	const [loading, setLoading] = useState(false)
	
	const store = (newRow) => {
		setLoading(true)
		axios({
			method: 'post',
			headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
			url: `${API}/right/role/store`,
			data: newRow
		}).then(() => {
			refreshGrid()
			enqueueSnackbar(t('New rights and roles added successfully!'), {
					variant: 'success'
				}
			)
			setLoading(false)
		}).catch(error => {
			enqueueSnackbar(handleAxiosErrors(error, t('Failed to store new rights and roles!')), {
					variant: 'error'
				}
			)
			setLoading(false)
			logoutUnauthorizedUser(error, isLoggedIn, userLogout)
		})
	}
	
	const update = (newRow, oldRow) => {
		setLoading(true)
		axios({
			method: 'post',
			headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
			url: `${API}/right/role/update`,
			data: newRow
		}).then(() => {
			refreshGrid()
			enqueueSnackbar(t('Rights and roles updated successfully!'), {
					variant: 'success'
				}
			)
			setLoading(false)
		}).catch(error => {
			enqueueSnackbar(handleAxiosErrors(error, t('Failed to update rights and roles!')), {
					variant: 'error'
				}
			)
			setLoading(false)
			logoutUnauthorizedUser(error, isLoggedIn, userLogout)
		})
	}
	
	const remove = (id) => {
		setLoading(true)
		axios({
			method: 'post',
			headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
			url: `${API}/right/role/remove`,
			data: {
				id: id
			}
		}).then(() => {
			refreshGrid()
			enqueueSnackbar(t('Rights and roles deleted successfully!'), {
					variant: 'success'
				}
			)
			setLoading(false)
		}).catch(error => {
			enqueueSnackbar(handleAxiosErrors(error, t('Failed to delete rights and roles!')), {
					variant: 'error'
				}
			)
			setLoading(false)
			logoutUnauthorizedUser(error, isLoggedIn, userLogout)
		})
	}
	
	return {
		loading,
		store,
		update,
		remove
	}
}

export default useRightsRoles