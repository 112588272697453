export const validateAFM = (afm) => {
	const checkAFM = String(afm)
	if (checkAFM.length !== 9) {
		// "Τα ψηφία του ΑΦΜ πρέπει να είναι 9 αριθμοί"
		return false;
	}
	
	if (!/^\d+$/.test(checkAFM)) {
		// "Αυτό που εισάγετε δεν είναι αριθμός"
		return false;
	}
	
	if (checkAFM === '0'.repeat(9)) {
		// "Αυτό που εισάγετε είναι μηδενικός αριθμός (000000000)"
		return false;
	}
	
	const sum = checkAFM
	.substring(0, 8)
	.split('')
	.reduce((s, v, i) => s + (parseInt(v) << (8 - i)), 0);
	
	const calc = sum % 11;
	const d9 = parseInt(checkAFM[8]);
	return calc % 10 === d9;
}