// @flow
import {useDispatch} from 'react-redux'
import * as actions from '../../actions/actions'
import {useCallback} from "react";
import {getCurrentUserPermissions} from "../../modules/permission/actions/actions";
import {GLOBAL_VARS} from "../../App";

const useUserAction = () => {
	
	
	const dispatch = useDispatch()
	
	const checkAPIConnectivity = useCallback(() => {
		dispatch(actions.checkAPIConnectivity())
	}, [dispatch])
	
	const userLogin = useCallback((values) => {
		//console.log('token',GLOBAL_VARS.device_token)
		const bodyFormData = new FormData();
		bodyFormData.append('username', values.username);
		bodyFormData.append('password', values.password);
		bodyFormData.append("device_token", GLOBAL_VARS.device_token);
		dispatch(actions.userLogin(bodyFormData))
	}, [dispatch])
	
	const googleLogin = useCallback((googleApiResponse) => {
		dispatch(actions.googleLogin({
			data: googleApiResponse
		}))
	}, [dispatch])
	
	const userLogout = useCallback(() => {
		const bodyFormData = new FormData();
		bodyFormData.append("device_token", GLOBAL_VARS.device_token);
		dispatch(actions.userLogout(bodyFormData))
	}, [dispatch])
	
	const setUserLanguage = useCallback((language) => {
		dispatch(actions.setUserLanguage(language))
	}, [dispatch])
	
	const setUserAlterPassword = useCallback((alterPassword) => {
		dispatch(actions.setUserAlterPassword(alterPassword))
	}, [dispatch])
	
	const getUserPermissions = useCallback(() => {
		dispatch(getCurrentUserPermissions())
	}, [dispatch])
	
	return {
		checkAPIConnectivity,
		userLogin,
		userLogout,
		setUserLanguage,
		setUserAlterPassword,
		getUserPermissions,
		googleLogin
	}
}

export default useUserAction