import {useEffect, useState} from "react";
import useUserData from "../user/useUserData";
import axios from "axios";
import {API} from "../../helpers/constants";
import {enqueueSnackbar} from "notistack";
import {handleAxiosErrors, logoutUnauthorizedUser} from "../../helpers/errorHandling";
import useUserAction from "../user/useUserAction";
import {useTranslation} from "react-i18next";
import {PAGE_HOME} from "../../locales/pages/namespaces";

const useSyncLeads = () => {
	const {t} = useTranslation(PAGE_HOME)
	const [loading, setLoading] = useState(true)
	const {userId, isLoggedIn, token} = useUserData()
	const {userLogout} = useUserAction()
	
	useEffect(() => {
		if (isLoggedIn) {
			axios({
				method: 'post',
				headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
				url: `${API}/sync/lead/data`,
				data: {
					UserId: userId
				}
			}).then(response => {
				setLoading(false)
			}).catch(error => {
				setLoading(false)
				if (error.response.status === 401) {
					logoutUnauthorizedUser(error, isLoggedIn, userLogout)
					return null
				} else {
					enqueueSnackbar(handleAxiosErrors(error, t('Cases sync failed!')), {
							variant: 'error'
						}
					)
					logoutUnauthorizedUser(error, isLoggedIn, userLogout)
				}
			})
		}
	}, [isLoggedIn]);
	
	return {
		loading
	}
	
}

export default useSyncLeads