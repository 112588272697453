import * as React from 'react';
import {useEffect, useState} from "react";
import axios from "axios";
import {API} from "../../../helpers/constants";
import useUserData from "../../../hooks/user/useUserData";
import {handleAxiosErrors, logoutUnauthorizedUser} from "../../../helpers/errorHandling";
import {ALERTS} from "../../../locales/components/namespaces";
import {useTranslation} from "react-i18next";
import {enqueueSnackbar} from "notistack";
import useUserAction from "../../../hooks/user/useUserAction";

const useInterestRate = () => {
	const {token, isLoggedIn} = useUserData()
	const {userLogout} = useUserAction()
	const {t} = useTranslation(ALERTS)
	const [loading, setLoading] = useState(false)
	const [interests, setInterests] = useState([])
	const [contributions, setContributions] = useState([])
	const [updateContributions, setUpdateContributions] = useState(false)
	
	useEffect(() => {
		setLoading(true)
		axios({
			method: 'post',
			headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
			url: `${API}/interests/edit/data`,
		}).then((response) => {
			setContributions(response.data.contributions)
			let data = response.data
			if (data && data.interests.length > 0) {
				const interests = data.interests[0]
				setInterests({
					Euribor: interests.Euribor,
					Spread: interests.Spread,
					StableInterest: interests.StableInterest,
					RealEstateValue: interests.RealEstateValue,
					LoanAmount: interests.RequestedAmount,
					NotificationDays: interests.NotificationDays
				})
				setLoading(false)
			}
		}).catch(error => {
			setLoading(false)
			enqueueSnackbar(handleAxiosErrors(error, t('Failed to get interest rates.')), {
					variant: 'error'
				}
			)
			logoutUnauthorizedUser(error, isLoggedIn, userLogout)
		})
	}, [updateContributions])
	
	const store = (newRow) => {
		axios({
			method: 'post',
			headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
			url: `${API}/interests/store`,
			data: newRow
		}).then(response => {
			setUpdateContributions(!updateContributions)
			enqueueSnackbar(t('New contribution added successfully!'), {
					variant: 'success'
				}
			)
		}).catch(error => {
			enqueueSnackbar(handleAxiosErrors(error, t('Failed to store new contribution!')), {
					variant: 'error'
				}
			)
			logoutUnauthorizedUser(error, isLoggedIn, userLogout)
		})
	}
	
	const remove = (ContributionId) => {
		axios({
			method: 'post',
			headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
			url: `${API}/interests/remove`,
			data: {
				ContributionId: ContributionId
			}
		}).then(response => {
			setUpdateContributions(!updateContributions)
			enqueueSnackbar(t('Contribution deleted successfully!'), {
					variant: 'success'
				}
			)
		}).catch(error => {
			enqueueSnackbar(handleAxiosErrors(error, t('Failed to delete contribution!')), {
					variant: 'error'
				}
			)
			logoutUnauthorizedUser(error, isLoggedIn, userLogout)
		})
	}
	
	return {
		loading,
		interests,
		contributions,
		store,
		remove
	}
}

export default useInterestRate