import {useEffect, useState} from "react";
import axios from "axios";
import {API} from "../../../helpers/constants";
import useUserData from "../../../hooks/user/useUserData";
import {handleAxiosErrors, logoutUnauthorizedUser} from "../../../helpers/errorHandling";
import {ALERTS} from "../../../locales/components/namespaces";
import {useTranslation} from "react-i18next";
import {enqueueSnackbar} from "notistack";
import useUserAction from "../../../hooks/user/useUserAction";

const useOrigins = () => {
	const {token, isLoggedIn} = useUserData()
	const {userLogout} = useUserAction()
	const {t} = useTranslation(ALERTS)
	const [loading, setLoading] = useState(false)
	const [origins, setOrigins] = useState([])
	const [updateOrigins, setUpdateOrigins] = useState(false)
	
	useEffect(() => {
		setLoading(true)
		axios({
			method: 'get',
			headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
			url: `${API}/loan/origin/list`,
		}).then((response) => {
			setOrigins(response.data)
			setLoading(false)
		}).catch(error => {
			setLoading(false)
			enqueueSnackbar(handleAxiosErrors(error, t('Failed to get origins list.')), {
					variant: 'error'
				}
			)
			logoutUnauthorizedUser(error, isLoggedIn, userLogout)
		})
	}, [updateOrigins])
	
	const update = (newRow, oldRow) => {
		axios({
			method: 'post',
			headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
			url: `${API}/loan/origin/update`,
			data: newRow
		}).then(response => {
			setUpdateOrigins(!updateOrigins)
			enqueueSnackbar(t('Origin updated successfully!'), {
					variant: 'success'
				}
			)
		}).catch(error => {
			enqueueSnackbar(handleAxiosErrors(error, t('Failed to update origin!')), {
					variant: 'error'
				}
			)
			logoutUnauthorizedUser(error, isLoggedIn, userLogout)
		})
	}
	
	return {
		loading,
		origins,
		update
	}
}

export default useOrigins