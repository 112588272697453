import {useFormik} from "formik";
import * as yup from "yup";
import axios from "axios";
import {API} from "../../helpers/constants";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import {CUSTOMERS} from "../../modules/customers/locales/namespaces";
import useUserData from "../../hooks/user/useUserData";
import {getRouteUrl} from "../../helpers/getRouteUrl";
import {useHistory} from "react-router-dom";
import {handleAxiosErrors, logoutUnauthorizedUser} from "../../helpers/errorHandling";
import {enqueueSnackbar} from "notistack";
import useUserAction from "../../hooks/user/useUserAction";
import {ROUTE_PAGE_LOGIN} from "../../routers/routes";

const useSignUpForm = ({customer}) => {
	const {userLogout} = useUserAction()
	const {token, isLoggedIn} = useUserData()
	const history = useHistory()
	const {t} = useTranslation(CUSTOMERS)
	const [showPassword, setShowPassword] = useState(false);
	
	const validationSchema = yup.object({
		password: yup.string()
		.required(t('Required'))
		.matches(/^.{60}$|(^((?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d)|(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[^a-zA-Z\d])|(?=.*?[A-Z])(?=.*?\d)(?=.*?[^a-zA-Z\d])|(?=.*?[a-z])(?=.*?\d)(?=.*?[^a-zA-Z\d])).{7,16}$)/,
			t('Password must be between 7-16 latin characters and contain at least 3 of the following. -Upper case character -Lower case character -Numeric digit -Special character')),
		confirmPassword: yup.string()
		.required(t('Required'))
		.oneOf([yup.ref('password'), null], t('Passwords must match')),
		email: yup.string().required(t('Required')).email(t('Enter a valid email')),
	})
	const onSubmit = (values) => {
		axios({
			method: 'post',
			data: {
				password: values.password,
				email: values.email,
				isActive: values.isActive
			},
			headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
			url: `${API}/registration`
		}).then(() => {
			enqueueSnackbar(t('Registration was successful!') +
				t('Your registration has been completed successfully! To activate your Account, please confirm your email address by clicking on the link sent to your email.'), {
					variant: 'success'
				}
			)
			history.push(getRouteUrl(ROUTE_PAGE_LOGIN));
		}).catch((error) => {
			enqueueSnackbar(handleAxiosErrors(error, t('A user already exists with that email!')), {
					variant: 'error'
				}
			)
			logoutUnauthorizedUser(error, isLoggedIn, userLogout)
		})
	}
	
	const formik = useFormik({
		initialValues: {
			isActive: "0",
			password: customer?.password || '',
			confirmPassword: customer?.password || '',
			email: customer?.email || '',
		},
		validationSchema: validationSchema,
		onSubmit: onSubmit,
	})
	
	const handleClickShowPassword = () => setShowPassword((show) => !show);
	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};
	
	return {
		formik,
		showPassword,
		handleClickShowPassword,
		handleMouseDownPassword
	}
}

export default useSignUpForm

