import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {Link} from "react-router-dom";
import {getRouteUrl} from "../../../../helpers/getRouteUrl";
import {ROUTE_PAGE_CUSTOMERS_DETAILS, ROUTE_PAGE_CUSTOMERS_INDEX} from "../../routes/routes";
import {useTranslation} from "react-i18next";
import {CUSTOMERS} from "../../locales/namespaces";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

type Props = {
    id: string,
    handleClose: Function,
    open: Array
}

export default function AlertDialog(props: Props) {
    const {t} = useTranslation(CUSTOMERS)
    const {
        id,
        handleClose,
        open
    } = props


    return (
        <Box>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <Grid sx={{mt: 2, ml: 2, mr: 2, mb: 1}}>
                    <DialogTitle id="alert-dialog-title">
                        {t("Are you sure you want to cancel the changes?")}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {t("If you press CONFIRM your changes will not be saved.")}
                        </DialogContentText>
                    </DialogContent>
                </Grid>
                <DialogActions>
                    <Button onClick={handleClose}>
                        {t('Cancel')}
                    </Button>
                    <Button size={"small"}
                            variant={"contained"}
                            color={"error"}
                            component={Link}
                            to={!id ? getRouteUrl(ROUTE_PAGE_CUSTOMERS_INDEX) : getRouteUrl(ROUTE_PAGE_CUSTOMERS_DETAILS, {id: id})}
                            autoFocus>
                        {t('Confirm')}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}