// third-party
import {FormattedMessage} from 'react-intl';

// project import
// assets
import {DashboardOutlined, GoldOutlined} from '@ant-design/icons';
import useMenuData from "../../../hooks/menu/useMenuData";
import useMenuAction from "../../../hooks/menu/useMenuAction";
import {useTranslation} from "react-i18next";
import {NAVIGATION} from "../../../locales/components/namespaces";

const icons = {
    dashboard: DashboardOutlined,
    components: GoldOutlined
};

// ==============================|| MENU ITEMS - API ||============================== //

export const Menu = () => {
    const {menu} = useMenuData()
    const {t} = useTranslation(NAVIGATION)

    const SubChildrenLis = (SubChildrenLis) => {
        return SubChildrenLis?.map((subList) => {
            return {
                ...subList,
                title: t(`${subList.title}`),
                icon: icons[subList.icon]
            };
        });
    };

    const itemList = (subList) => {
        let list = {
            ...subList,
            title: t(`${subList.title}`),
            icon: icons[subList.icon]
        };

        if (subList.type === 'collapse') {
            list.children = SubChildrenLis(subList.children);
        }
        return list;
    };

    const withoutMenu = menu?.children?.filter((item) => item.id !== 'no-menu');

    const ChildrenList = withoutMenu?.map((subList) => {
        return itemList(subList);
    });

    return {
        ...menu,
        title: t(`${menu.title}`),
        icon: icons[menu.icon],
        children: ChildrenList
    };
};
