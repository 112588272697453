// @flow
import * as React from 'react';
import {useState} from 'react';
import {PAGE_RESET_PASSWORD} from "../../locales/pages/namespaces";
import {useTranslation} from "react-i18next";
import {ErrorMessage, FormikProvider, useFormik} from "formik";
import * as yup from "yup";
import axios from "axios";
import {API} from "../../helpers/constants";
import {getRouteUrl} from "../../helpers/getRouteUrl";
import {
	Typography,
	Button,
	TextField,
	InputAdornment,
	IconButton,
	Stack,
	FormControl,
	FormHelperText,
	FormLabel, Grid
} from "@mui/material";
import {useHistory} from "react-router-dom";
import {ROUTE_PAGE_LOGIN} from "../../routers/routes";
import moment from "moment";
import useQueryParams from "../../hooks/useQueryParams";
import {handleAxiosErrors, logoutUnauthorizedUser} from "../../helpers/errorHandling";
import useUserAction from "../../hooks/user/useUserAction";
import useUserData from "../../hooks/user/useUserData";
import {enqueueSnackbar} from "notistack";
import AuthWrapper from "../../theme/mantis/sections/auth/authWrapper";
import VisibleEyeOff from "../../assets/images/icons/login/visibleEyeOff";
import VisibleEyeOn from "../../assets/images/icons/login/visibleEyeOn";
import ArrowDownCircle from "../../assets/images/icons/buttonsIcons/arrowDownCircle";

const ResetPassword = () => {
	const {userLogout, setUserAlterPassword} = useUserAction()
	const {loading, alterPasswordOnLogin, userId, isLoggedIn} = useUserData()
	const queryParams = useQueryParams()
	const [recoveryPwdTime, setRecoveryPwdTime] = useState(queryParams.get('recoveryPwdTime') ?? "")
	const [resetTime, setResetTime] = useState(queryParams.get('resetTime') ?? "")
	const resetType = queryParams.get('resetType') ?? ""
	const email = queryParams.get('email') ?? ""
	const [showPassword, setShowPassword] = useState(false);
	
	const history = useHistory()
	const {t} = useTranslation(PAGE_RESET_PASSWORD)
	
	const validationSchema = yup.object({
		oneTimePassword: !alterPasswordOnLogin && !userId ? yup.string().required(t('Required!')).max(6, t('Enter exactly 6 characters.')).min(6, t('Enter exactly 6 characters.')) : yup.string().notRequired(),
		password: yup.string()
		.required(t('Required!'))
		.matches(/^.{60}$|(^((?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d)|(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[^a-zA-Z\d])|(?=.*?[A-Z])(?=.*?\d)(?=.*?[^a-zA-Z\d])|(?=.*?[a-z])(?=.*?\d)(?=.*?[^a-zA-Z\d])).{7,16}$)/,
			t('Password must be between 7-16 latin characters and contain at least 3 of the following. -Upper case character -Lower case character -Numeric digit -Special character')),
		confirmPassword: yup.string()
		.required(t('Required!'))
		.oneOf([yup.ref('password'), null], t('Passwords must match')),
	})
	
	let timeNow = new Date();
	let recoverUntil = moment(recoveryPwdTime).add(resetTime, 'minutes').toDate()
	
	const onSubmit = (values) => {
		if (moment(timeNow).isBefore(recoverUntil) || moment(timeNow).isSame(recoverUntil) || userId) {
			axios({
				method: 'post',
				data: {
					oneTimePassword: values.oneTimePassword,
					password: values.password,
					UserId: userId
				},
				headers: {'Content-Type': 'application/json'},
				url: `${API}/store/new/password`
			}).then(response => {
				setUserAlterPassword(response.data.AlterPassword === 1)
				enqueueSnackbar(t('Password changed successfully!'), {
						variant: 'success',
						onClose: () => {
							// userLogin({email: email, password: values.password});
							history.push(getRouteUrl(ROUTE_PAGE_LOGIN));
						}
					}
				)
			}).catch((error) => {
				enqueueSnackbar(handleAxiosErrors(error, t('Wrong six-digit code!')), {
						variant: 'error'
					}
				)
				logoutUnauthorizedUser(error, isLoggedIn, userLogout)
			})
		} else {
			//userLogout()
			enqueueSnackbar(t('The six digit number has expired!'), {
					variant: 'warning'
				}
			)
		}
	}
	
	const newSixDigitCode = () => {
		axios({
			method: 'get',
			headers: {'Content-Type': 'application/json'},
			url: `${API}/request/new-password?email=${email}`
		}).then(response => {
			setResetTime(response.data.resetTime)
			setRecoveryPwdTime(response.data.recoveryPwdTime)
			enqueueSnackbar(t('Six-digit code send successfully!'), {
					variant: 'success'
				}
			)
		}).catch(error => {
			enqueueSnackbar(handleAxiosErrors(error, t('Failed to send six-digit code!')), {
					variant: 'error'
				}
			)
			logoutUnauthorizedUser(error, isLoggedIn, userLogout)
		})
	}
	
	const formik = useFormik({
		initialValues: {
			email: '',
			oneTimePassword: '',
			password: '',
			confirmPassword: '',
			recoveryPwdTime: email?.recoveryPwdTime,
			resetTime: email?.resetTime,
			resetType: ''
		},
		validationSchema: validationSchema,
		onSubmit: onSubmit
	})
	
	const handleClickShowPassword = () => setShowPassword((show) => !show);
	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};
	
	return (
		<>
			<AuthWrapper>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Typography
							variant={'h4'}
							size={'20px'}
							fontWeight={600}
							color={'textPrimary'}
							textAlign={'center'}
						>
							{t('Reset Password')}
						</Typography>
					</Grid>
					<Grid item xs={12}
						//alignItems={'center'}
						  textAlign={'-webkit-center'}
					>
						{!alterPasswordOnLogin && !userId && (
							<Button
								size={"small"}
								variant={"outlined"}
								color={'blue'}
								onClick={newSixDigitCode}
							>
								{t('Send me new Code')}
							</Button>
						)}
					</Grid>
					<Grid item xs={12}>
						<FormikProvider value={formik}>
							<Grid container spacing={3}>
								<Grid item xs={12}>
									<Stack spacing={1}>
										{resetType !== 'Email' && !alterPasswordOnLogin && !userId &&
											<>
												<FormControl
													variant="standard"
													fullWidth
													error={
														formik.touched["oneTimePassword"] &&
														Boolean(formik.errors["oneTimePassword"])
													}
													sx={{mb: 2}}
												>
													<FormLabel
														htmlFor="oneTimePassword"
														required>{t('Enter the six-digit code you received in the email')}</FormLabel>
													<TextField
														id="outlined-name"
														name={`oneTimePassword`}
														value={formik.values.oneTimePassword}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														required
														error={formik.touched.oneTimePassword && Boolean(formik.errors.oneTimePassword)}
														color={"grey"}
														size={'small'}
														fullWidth
														inputProps={{maxLength: 6}}
													/>
													<FormHelperText>
														<ErrorMessage name={"oneTimePassword"}/>
													</FormHelperText>
												</FormControl>
											</>
										}
									</Stack>
								</Grid>
								<Grid item xs={12}>
									<Stack spacing={1}>
										<FormControl
											variant="standard"
											fullWidth
											error={
												formik.touched["password"] &&
												Boolean(formik.errors["password"])
											}
											sx={{mb: 2}}
										>
											<FormLabel htmlFor="password" required>{t('Password')}</FormLabel>
											<TextField
												type={showPassword ? 'text' : 'password'}
												value={formik.values.password}
												name={`password`}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												fullWidth
												autoComplete="new-password"
												color={"grey"}
												size={'small'}
												required
												error={formik.touched.password && Boolean(formik.errors.password)}
												InputProps={{
													minLength: 7,
													endAdornment:
														<InputAdornment position="end">
															<IconButton
																aria-label="toggle password visibility"
																onClick={handleClickShowPassword}
																onMouseDown={handleMouseDownPassword}
																edge="end"
															>
																{showPassword ? <VisibleEyeOff/> : <VisibleEyeOn/>}
															</IconButton>
														</InputAdornment>
												}}
											/>
											<FormHelperText>
												<ErrorMessage name={"password"}/>
											</FormHelperText>
										</FormControl>
									</Stack>
								</Grid>
								<Grid item xs={12}>
									<Stack spacing={1}>
										<FormControl
											variant="standard"
											fullWidth
											error={
												formik.touched["confirmPassword"] &&
												Boolean(formik.errors["confirmPassword"])
											}
											sx={{mb: 2}}
										>
											<FormLabel htmlFor="confirmPassword"
													   required>{t('Confirm Password')}</FormLabel>
											<TextField
												type={showPassword ? 'text' : 'password'}
												value={formik.values.confirmPassword}
												name={`confirmPassword`}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												fullWidth
												autoComplete="on"
												color={"grey"}
												size={'small'}
												required
												error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
												InputProps={{
													minLength: 7,
													endAdornment:
														<InputAdornment position="end">
															<IconButton
																aria-label="toggle password visibility"
																onClick={handleClickShowPassword}
																onMouseDown={handleMouseDownPassword}
																edge="end"
															>
																{showPassword ? <VisibleEyeOff/> : <VisibleEyeOn/>}
															</IconButton>
														</InputAdornment>
												}}
											/>
											<FormHelperText>
												<ErrorMessage name={"confirmPassword"}/>
											</FormHelperText>
										</FormControl>
									</Stack>
								</Grid>
								<Grid item xs={12} textAlign={'center'}>
									<Button
										sx={{gap: '20px', p: '17px 54px'}}
										disableElevation
										disabled={loading}
										//fullWidth
										size="large"
										color={'blue'}
										variant={'contained'}
										onClick={formik.submitForm}
									>
										<ArrowDownCircle/>{t('Reset Password')}
									</Button>
								</Grid>
							</Grid>
						</FormikProvider>
					</Grid>
				</Grid>
			</AuthWrapper>
		</>
	)
}

export default ResetPassword;
