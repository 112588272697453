//@flow
import React, {useContext, useEffect} from 'react';
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import ContentRouter from "../../routers/contentRouter";
import {TitleContext} from "../../pages/main/content";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from '@mui/material/useMediaQuery';
import useConfig from "../../theme/mantis/hooks/useConfig";
import {MenuOrientation} from "../../theme/mantis/config";
import Header from "../../theme/mantis/layout/mainLayout/header";
import Drawer from "../../theme/mantis/layout/mainLayout/drawer";
import HorizontalBar from "../../theme/mantis/layout/mainLayout/drawer/horizontalBar";
import useMenuAction from "../../hooks/menu/useMenuAction";
import Footer from "../../theme/mantis/layout/mainLayout/footer";
import {Capacitor} from "@capacitor/core";
import NativeQuickActions from "../nativeQuickActions/nativeQuickActions";
import {useHasPermission} from "../../modules/permission/hooks/useHasPermission";

const MenuWithDrawer = () => {
	const {toolbar} = useContext(TitleContext)
	const theme = useTheme();
	const matchDownXL = useMediaQuery(theme.breakpoints.down('xl'));
	const downLG = useMediaQuery(theme.breakpoints.down('lg'));
	const matchDownXS = useMediaQuery(theme.breakpoints.between('xs', 'md'));
	const {miniDrawer, menuOrientation} = useConfig();
	const isHorizontal = menuOrientation === MenuOrientation.HORIZONTAL && !downLG;
	const isAdmin = useHasPermission(['access_mobile_side_menu'], {shouldView: true})
	
	const {setDrawerOpen} = useMenuAction()
	// set media wise responsive drawer
	useEffect(() => {
		if (!miniDrawer) {
			setDrawerOpen(!matchDownXL)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [matchDownXL]);
	
	return (
		<>
			<Box
				sx={{
					display: 'flex',
					width: '100%',
				}}
			>
				{/* HEADER */}
				<Header/>
				{/*{!isHorizontal && (isAdmin || !matchDownXS || !Capacitor.isNativePlatform()) || (Capacitor.isNativePlatform() && isAdmin) ? <Drawer/> : (isAdmin || !matchDownXS || !Capacitor.isNativePlatform()) || (Capacitor.isNativePlatform() && isAdmin)  && <HorizontalBar/>}*/}
				{!isHorizontal && (isAdmin || !matchDownXS) ? <Drawer/> : (isAdmin || !matchDownXS) && <HorizontalBar/>}
				{/* {!isHorizontal && !matchDownXS ? <Drawer/> : !matchDownXS && <HorizontalBar/>}*/}
				{/*{!isHorizontal && isAdmin ? <Drawer/> : isAdmin && <HorizontalBar/>}*/}
				{/* {!isHorizontal ? <Drawer/> : <HorizontalBar/>}
                {!isHorizontal && !matchDownXS ? <Drawer/> : !matchDownXS && <HorizontalBar/>}
                {!isHorizontal && isAdmin ? <Drawer/> : isAdmin && <HorizontalBar/>}*/}
				
				{/* CONTENT*/}
				<Box
					component="main"
					sx={{
						flexGrow: 1,
						p: 2,
						height: "100vh",
						pt: toolbar ? 8 : 2,
						overflowX: 'auto',
					}}
				>
					<Toolbar variant={"dense"}/>
					<ContentRouter/>
					<Footer/>
				</Box>
				{Capacitor.isNativePlatform() && matchDownXS && !isAdmin && <NativeQuickActions/>}
				{(matchDownXS && !isAdmin) && <NativeQuickActions/>}
			</Box>
		</>
	)
}

export default MenuWithDrawer;