//@flow
import React from 'react';
import {Alert, AlertTitle, ListItem, ListItemText, List, Box} from "@mui/material";
import {BulkImportStatus} from "../../../helpers/constants";
import {useTranslation} from "react-i18next";
import {IMPORT_ALERT} from "../../../locales/components/namespaces";

type Props = {
	onClose: Function,
	status: {
		statusCode: number,
		message?: string,
		newItems?: Array<string>,
		errors?: Array<string>,
		t?: Function
	}
}

const mapCodeToInfo = (code, t) => {
	switch (code) {
		case BulkImportStatus.AllSuccessful:
			return {
				severity: "success",
				title: t("Success"),
				message: t("Imported successfully.")
			}
		case BulkImportStatus.AllErrors:
			return {
				severity: "error",
				title: t("Error"),
				message: t("Import failed, check description below for more details.")
			}
		case BulkImportStatus.SucceededWithErrors:
			return {
				severity: "warning",
				title: t("Warning"),
				message: t("Import process finished with errors. Some data could not be imported check description for more details.")
			}
		case BulkImportStatus.NothingToDo:
		default:
			return {
				severity: "info",
				title: t("Info"),
				message: t("Nothing to do here.")
			}
	}
}

const ImportAlert = (props: Props) => {
	const {t} = useTranslation(IMPORT_ALERT)
	const {status, onClose} = props
	if (!Boolean(status)) return null
	const {severity, title, message} = mapCodeToInfo(status.statusCode, t)
	return (
		<Alert
			severity={severity}
			sx={{
				'& .MuiAlert-message': {
					width: '100%'
				}
			}}
			onClose={onClose}
		>
			<AlertTitle> {title}</AlertTitle>
			<Box sx={{mb: 1}}>
				{message}
			</Box>
			<List
				dense
				disablePadding
				sx={{flexGrow: 1, maxHeight: 120, overflow: 'auto'}}
			>
				{status.errors && status.errors.length > 0 && (
					status.errors.map((i, index) =>
						<ListItem divider disablePadding key={i + index}>
							<ListItemText primary={`Line ${i.Line}: ${i.Error}`}/>
						</ListItem>
					)
				)}
			</List>
		</Alert>
	)
}

export default ImportAlert;