import * as React from "react";
const PasswordIcon = (props) => (
    <svg
        width={18}
        height={21}
        viewBox="0 0 18 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round">
            <path
                d="M13.492 7.665V5.518a4.552 4.552 0 0 0-4.55-4.55 4.55 4.55 0 0 0-4.57 4.53v2.167"
                stroke="#6E7079"
            />
            <path
                clipRule="evenodd"
                d="M12.751 19.467h-7.64a3.792 3.792 0 0 1-3.793-3.792v-4.289A3.792 3.792 0 0 1 5.11 7.594h7.641a3.792 3.792 0 0 1 3.792 3.792v4.289a3.792 3.792 0 0 1-3.792 3.792Z"
                stroke="#485776"
            />
            <path d="M8.932 12.42v2.221" stroke="#485776" />
        </g>
    </svg>
);
export default PasswordIcon;