import type {User} from "./types";

export const parseUser: User = (datum, language) => {
    if (!datum) return;
    return {
        id: datum.get('id'),
        username: datum.get('username'),
        loginName: datum.get('loginName'),
        customerId: datum.get('customerId'),
        language: datum.get('language'),
    }
}