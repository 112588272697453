import * as React from "react";

const ArrowRight2 = (props) => (
    <svg
        width={8}
        height={14}
        viewBox="0 0 8 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M1.083 1.167 6.917 7l-5.834 5.833"
            stroke="#485776"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
export default ArrowRight2;