export const TypeInterestRate = [
	{id: 1, DescriptionEn: 'Fixed', DescriptionEl: 'Σταθερό', isDefault: 1},
	{id: 2, DescriptionEn: 'Floating', DescriptionEl: 'Κυμαινόμενο', isDefault: 0},
	{id: 3, DescriptionEn: 'Fixed and Floating', DescriptionEl: 'Σταθερό και κυμαινόμενο', isDefault: 0},
]

export const TypeInterestRateIds = {
	Fixed: 1,
	Floating: 2,
	Hybrid: 3
};