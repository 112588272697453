import * as React from "react";
const FailureIcon = (props) => (
    <svg
        width={25}
        height={25}
        style={{marginRight: '32px'}}
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g
            stroke="#fff"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path d="m17.193 10.791-6.39 6.39m6.391.003-6.395-6.396" />
            <path
                clipRule="evenodd"
                d="M19.778 1.666H8.219c-4.028 0-6.553 2.852-6.553 6.888v10.89c0 4.037 2.513 6.889 6.553 6.889h11.558c4.041 0 6.556-2.852 6.556-6.888V8.554c0-4.036-2.515-6.888-6.555-6.888Z"
            />
        </g>
    </svg>
);
export default FailureIcon;