import Index from '../pages/index'
import Create from "../pages/create/create";
import Edit from "../pages/edit/edit";
import Details from "../pages/details/details"
import {routeCreator} from '../../../helpers/routeCreator'

export const ROUTE_PAGE_CUSTOMERS_INDEX = routeCreator(Index, '/customers', 'Customers', 'page.customers', {exact: true})
export const ROUTE_PAGE_CUSTOMERS_CREATE = routeCreator(Create, '/customer/create', 'Customer Create', 'page.customers.create', {exact: true})
export const ROUTE_PAGE_CUSTOMERS_EDIT = routeCreator(Edit, '/customer/edit/:id', 'Customer Edit', 'page.customers.edit', {exact: true})
export const ROUTE_PAGE_CUSTOMERS_DETAILS = routeCreator(Details, '/customer/details/:id', 'Customer Details', 'page.customers.details', {exact: true})

const router = {
    ROUTE_PAGE_CUSTOMERS_INDEX,
    ROUTE_PAGE_CUSTOMERS_CREATE,
    ROUTE_PAGE_CUSTOMERS_EDIT,
    ROUTE_PAGE_CUSTOMERS_DETAILS
}

export default router