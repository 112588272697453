// @flow
import React, {useMemo} from 'react';
import {useTranslation} from "react-i18next";
import useTeams from "../../../hooks/useTeams"
import DataGridWrapper from "../../../../../components/dataGridWrapper/dataGridWrapper";
import EditableMUIDataGrid from "../../../../../components/editableMUIDataGrid/editableMUIDataGrid";
import {USERS} from "../../../locales/namespaces";

const TeamsTable = (props) => {
	
	const {t} = useTranslation(USERS)
	const {
		loading,
		teams,
		store,
		update,
		remove
	} = useTeams()
	
	const newRowModel = {
		Description: ''
	}
	
	const columns = useMemo(() => [
		{
			field: 'Description',
			headerName: t('Description'),
			minWidth: 160,
			flex: 1,
			editable: true,
			preProcessEditCellProps: (params) => {
				const hasError = params.props.value.length < 3 || params.props.value.length > 50;
				return {...params.props, error: hasError};
			},
			valueParser: (value, params) => {
				return value.toUpperCase();
			},
			type: 'singleSelect',
			valueOptions: teams.map(teams => ({
				value: teams.id,
				label: teams.Description
			})),
			valueFormatter: ({id: rowId, value, field, api}) => {
				let valueToId = value
				const colDef = api.getColumn(field);
				const option = colDef.valueOptions.find(
					({value: optionValue}) => valueToId === optionValue
				);
				return option?.label;
			}
		},
	], [teams, t])
	
	return (
		<DataGridWrapper
			sx={{
				mt: 1,
				height: '400px'
			}}
		>
			<EditableMUIDataGrid
				loading={loading}
				data={teams}
				columns={columns}
				newRowModel={newRowModel}
				store={store}
				update={update}
				remove={remove}
				dataGridProOtherProps={{
					disableColumnMenu: true,
					// disableAggregation: true,
					// disableRowGrouping: true,
					// disableColumnFilter: true
				}}
			/>
		</DataGridWrapper>
	);
}

export default TeamsTable