//@flow
import React, {useMemo} from "react";
import {DataGridPremium} from '@mui/x-data-grid-premium';
import {useTranslation} from "react-i18next";
import {CUSTOMERS} from "../../locales/namespaces";
//import {useMuiGridServerSide} from "../../../../hooks/useMuiGridServerSide";
import DataGridWrapper from "../../../../components/dataGridWrapper/dataGridWrapper";
import {muiGridLocales} from "../../../../helpers/muiGridLocales";

const LoginHistory = () => {
    const {t} = useTranslation(CUSTOMERS)
    //const url = `customer/indexTable/data`

    /* const {
         isLoading,
         data,
         refreshGrid
     } = useMuiGridServerSide({
         //url,
         initialSortModel: [{name: 'id', direction: 'descending'}]
     })*/

    const columns = useMemo(() => [
        {
            field: 'dateLogin',
            headerName: t('Date Login'),
            type: 'date',
            width: 160,
            editable: true,
            aggregable: false,
        },
        {
            field: 'hourLogin',
            headerName: t('Hour Login'),
            minWidth: 180, flex: 1,
            type: 'dateTime',
            aggregable: false,
        },
        {
            field: 'failedLogin',
            headerName: t('Failed Login'),
            type: 'boolean',
            minWidth: 160, flex: 1,
        },
        {
            field: 'failedMessage',
            headerName: t('Failed Login Message'),
            minWidth: 160, flex: 1,
        },
    ], [/*data,refreshGrid*/])
    return (
        <DataGridWrapper
            sx={{
                mt: 1,
                //maxWidth: '800px',
                height: '400px'
            }}
        >
            <DataGridPremium
                sx={{
                    bgcolor: 'background.paper', ".MuiDataGrid-aggregationColumnHeaderLabel": {
                        display: "none"
                    }
                }}
                columns={columns}
                density={'compact'}
                rows={[]}
                //loading={isLoading}
                localeText={muiGridLocales(t)}
                disableAggregation={true}
                disableRowGrouping={true}
            />
        </DataGridWrapper>
    )
}
export default LoginHistory;