export * from "./currentUser/types"

export const SET_USER_ALTER_PASSWORD = 'SET_USER_ALTER_PASSWORD'

export const SET_MENU = 'SET_MENU'
export const SET_MENU_ACTIVE_ITEM = 'SET_MENU_ACTIVE_ITEM'
export const SET_MENU_ACTIVE_ID = 'SET_MENU_ACTIVE_ID'
export const SET_MENU_ACTIVE_COMPONENT = 'SET_MENU_ACTIVE_COMPONENT'
export const SET_DRAWER_OPEN = 'SET_DRAWER_OPEN'
export const SET_DRAWER_COMPONENT_OPEN = 'SET_DRAWER_COMPONENT_OPEN'

export const GOOGLE_LOGIN = 'GOOGLE_LOGIN'
export const GOOGLE_LOGIN_SUCCEEDED = 'GOOGLE_LOGIN_SUCCEEDED'
export const GOOGLE_LOGIN_FAILED = 'GOOGLE_LOGIN_FAILED'