export const textTranslate = (language) => (item = {en: '', el: ''}) => {
	return item[language]
}

export function replaceDotWithComma(text, language) {
	if (language === 'el') {
		// Replace all occurrences of '.' with ','
		return text !== null && text.replace(/\./g, ',');
	}
	// If the language is not 'el', return the original text
	return text;
}

export const toCamelCase = (string) => {
	if (!string) return ''
	const newStringArray = []
	const stringArray = string.split(' ')
	for (let stringPart of stringArray) {
		const firstChar = stringPart[0]
		const afterFirstChar = stringPart.substring(1, stringPart.length)
		newStringArray.push(firstChar.toUpperCase() + afterFirstChar.toLocaleLowerCase())
	}
	return newStringArray.join(' ')
}

