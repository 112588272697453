// ==============================|| SWITCH - SIZE STYLE ||============================== //

import checkIcon from "../../../../assets/images/check.png";
import switchX from "../../../../assets/images/switchX.png";

function getSizeStyle(size) {
    switch (size) {
        case 'small':
            // return {width: 28, height: 16, base: 12, thumb: 10, trackRadius: 8};
            return {width: 40, height: 20, base: 15, thumb: 20, trackRadius: 10};
            // return {width: 40, height: 15, base: 15, thumb: 20, trackRadius: 10};
        case 'large':
            return {width: 60, height: 28, base: 32, thumb: 22, trackRadius: 24};
        case 'medium':
        default:
            return {width: 44, height: 22, base: 22, thumb: 16, trackRadius: 16};
    }
}

function switchStyle(theme, size) {
    const sizes = getSizeStyle(size);

    return {
        width: sizes.width,
        height: sizes.height,
        '& .MuiSwitch-switchBase': {
            // padding: 3,
           padding: 1,
           /* '&.Mui-checked': {
                transform: `translateX(${sizes.base}px)`
            }*/
            '&.Mui-checked': {
                '& .MuiSwitch-thumb': {
                    backgroundColor: '#0BC1B6',
                },
                //color: '#fff',
                /* backgroundColor: '#0BC1B6',*/
                transform: 'translateX(22px)',
                '& .MuiSwitch-thumb:before': {
                    backgroundImage: `url(${checkIcon})`,

                },
                '& + .MuiSwitch-track': {
                    opacity: 1,
                    backgroundColor: '#A7DEDE',
                },
            },
        },
        '& .MuiSwitch-thumb': {
            backgroundColor: '#BEC0CA',
            // width: 20,
            // height: 20,
            '&::before': {
                content: "''",
                position: 'absolute',
                width: '100%',
                height: '100%',
                left: 0,
                top: 0,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundImage: `url(${switchX})`,
            },
        },
        '& .MuiSwitch-track': {
            opacity: 1,
            backgroundColor: '#E3E5EC',
            borderRadius: 20 / 2,
            // height: '12px',
            // width: '50px'
        },
        /*'& .MuiSwitch-thumb': {
            width: sizes.thumb,
            height: sizes.thumb,

            '& .MuiSvgIcon-root': {
                // width:'5px',
                // backgroundColor: theme.palette.secondary[100],
                // background: theme.palette.secondary[100],
                // color: theme.palette.secondary[100],

                //position: 'absolute',
                // top: -24, // Adjust this value to position the icon
                // left: '50%',
                // transform: 'translateX(-50%)',
                // color: '#fff', // Change the color of the icon
            },

        },*/
      /*  '& .MuiSwitch-track': {
            borderRadius: sizes.trackRadius,
            '& .MuiSvgIcon-root': {}
        }*/
    };
}

// ==============================|| OVERRIDES - TAB ||============================== //

export default function Switch(theme) {
    return {
        MuiSwitch: {
            styleOverrides: {
                track: {
                    opacity: 1,
                    backgroundColor: theme.palette.primary[10],
                    boxSizing: 'border-box'
                },
                thumb: {
                    borderRadius: '50%',
                    transition: theme.transitions.create(['width'], {
                        duration: 200
                    })
                },
                switchBase: {
                    '&.Mui-checked': {
                        color: theme.palette.secondary[100],
                        '& + .MuiSwitch-track': {
                            opacity: 1,
                            backgroundColor: theme.palette.secondary[40]
                        },
                        '&.Mui-disabled': {
                            color: theme.palette.background.paper
                        },
                        /*'& .MuiSwitch-thumb:before': {
                            backgroundImage: `<CheckSwitchIcon/>`,
                        },*/
                    },
                    '&.Mui-disabled': {
                        color: theme.palette.background.paper,
                        '+.MuiSwitch-track': {
                            opacity: 0.3
                        }
                    }
                },
                root: {
                    color: theme.palette.text.primary,
                    padding: 0,
                    margin: 8,
                    display: 'flex',
                    '& ~ .MuiFormControlLabel-label': {
                        margin: 6
                    },
                    ...switchStyle(theme, 'medium')
                },
                sizeLarge: {...switchStyle(theme, 'large')},
                sizeSmall: {
                    ...switchStyle(theme, 'small')
                }
            }
        }
    };
}
