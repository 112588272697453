import {useMemo} from 'react';

// material-ui
import {Box, useMediaQuery} from '@mui/material';

// project import
import Profile from './profile';
import Localization from './localization';
import Notification from './notification';
import {MenuOrientation} from "../../../../config";
import DrawerHeader from "../../drawer/drawerHeader";
import useConfig from "../../../../hooks/useConfig";
import useUserData from "../../../../../../hooks/user/useUserData";
import MobileSection from "./mobileSection";
import PermissionHOC from "../../../../../../modules/permission/components/permissionHOC/permissionHOC";

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
    const {i18n, menuOrientation} = useConfig();
    const {isLoggedIn} = useUserData()
    const downSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const localization = useMemo(() => <Localization/>, []);

    return (
        <>
            {menuOrientation === MenuOrientation.HORIZONTAL && !downSM && <DrawerHeader open={true}/>}
            {!downSM && <Box sx={{width: '100%', ml: {xs: 0, md: 1}}}/>}
            {localization}
            {downSM && <Box sx={{width: '100%', ml: 1}}/>}
            {isLoggedIn &&
                <PermissionHOC requiredPermissions={['view_notifications_btn']}>
                    <Notification/>
                </PermissionHOC>
            }
            <Profile/>
            {/* {downSM && <MobileSection/>}*/}
            {/* {menuOrientation === MenuOrientation.HORIZONTAL && !downSM && <DrawerHeader open={true}/>}
            {!downSM && <Box sx={{width: '100%', ml: {xs: 0, md: 1}}}/>}
            {!downSM && localization}
            {downSM && <Box sx={{width: '100%', ml: 1}}/>}
            {isLoggedIn &&
                <PermissionHOC requiredPermissions={['view_notifications_btn']}>
                    <Notification/>
                </PermissionHOC>
            }
            {!downSM && <Profile/>}
            {downSM && <MobileSection/>}*/}
        </>
    );
};

export default HeaderContent;
