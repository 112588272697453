import * as React from "react";
const Search = (props) => (
    <svg
        width={21}
        height={22}
        viewBox="0 0 21 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g
            stroke="#485776"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <circle cx={9.766} cy={10.219} r={8.989} />
            <path d="m16.018 16.938 3.524 3.515" />
        </g>
    </svg>
);
export default Search;