import * as React from "react";
const HomeBreadcrumbBlue = (props) => (
    <svg
        width={14}
        height={14}
        viewBox="0 0 14 14"
        fill="#A6A8B1"
        //fill={theme.palette.black[20]}
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M5.096 12.855V10.81c0-.518.42-.939.943-.942h1.916c.524 0 .949.422.949.942v2.039c0 .45.365.814.818.818h1.307A2.307 2.307 0 0 0 12.658 13c.432-.429.675-1.01.675-1.616V5.577c0-.49-.219-.954-.597-1.268L8.296.783a2.077 2.077 0 0 0-2.64.047L1.312 4.31a1.65 1.65 0 0 0-.644 1.268v5.802a2.296 2.296 0 0 0 2.304 2.288h1.277a.821.821 0 0 0 .582-.236.81.81 0 0 0 .242-.576h.024Z"
            fill="#485776"
        />
    </svg>
);
export default HomeBreadcrumbBlue;