import * as React from "react";
const CheckIcon = (props) => (
    <svg
        width={12}
        height={13}
        viewBox="0 0 12 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="m10 3.18-5.5 5.5L2 6.18"
            stroke="#F4F5FA"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
export default CheckIcon;