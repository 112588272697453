import requestHandler from "./api/api";
import * as Actions from "./actions/actions";
import * as ActionTypes from "./actions/types";
import reducer from "./reducer/reducer";
import router from './routes/routes'
import locales from './locales/locales'

export const UsersModule = {
    name: "users",
    actions: Actions,
    types: ActionTypes,
    // reducer: reducer,
    api: requestHandler,
    routes: router,
    locales: locales,
}