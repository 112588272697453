import axios from "axios";
import {API} from "../../../helpers/constants";
import {useEffect, useState} from "react";
import useUserData from "../../../hooks/user/useUserData";
import {handleAxiosErrors, logoutUnauthorizedUser} from "../../../helpers/errorHandling";
import {useTranslation} from "react-i18next";
import {ALERTS} from "../../../locales/components/namespaces";
import {enqueueSnackbar} from "notistack";
import useUserAction from "../../../hooks/user/useUserAction";

const useCustomer = ({id}) => {
    const {token, isLoggedIn} = useUserData()
    const {userLogout} = useUserAction()
    const {t} = useTranslation(ALERTS)
    const [customer, setCustomer] = useState({})
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
        if (id) {
            axios({
                method: 'post',
                data: {CustId: id},
                headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
                url: `${API}/customer/edit/data`,
            }).then(response => {
                let data = response.data
                if (data && data.customer.length > 0) {
                    const customer = data.customer[0]
                    setCustomer({
                        isActive: customer.isActive === '1',
                        isUser: customer.isUser === '1',
                        CustName: customer.CustName,
                        username: customer.username,
                        password: customer.password,
                        Code: customer.Code,
                        ErpCode: customer.ErpCode,
                        ErpId: customer.ErpId,
                        vat: customer.VatRegNo,
                        confirmPassword: customer.confirmPassword,
                        email: customer.email,
                        mobilePhone: customer.MobilePhone,
                        CurrencyId: customer.CurrencyId,
                        LangId: customer.LangId,
                        CountryId: customer.CountryId,
                        NationalityId: customer.NationalityId,
                        homePhone: customer.HomePhone,
                        CallingCode: customer.CallingCode,
                        Comments: customer.Comments,
                        RequestedAmount: customer.RequestedAmount,

                        //TODO:: It will be used as soon as the change to decimal that the user has generally selected in the Portal works
                        //DateFormatId: customer.DateFormatId,
                        //DecimalChar: customer.DecimalChar,
                    })
                    setLoading(false)
                }
            }).catch(error => {
                setLoading(false)
                enqueueSnackbar(handleAxiosErrors(error, t('Failed to get customer details.')), {
                        variant: 'error'
                    }
                )
                logoutUnauthorizedUser(error, isLoggedIn, userLogout)
            })
        }
    }, [id])

    return {
        id,
        loading,
        customer
    }
}

export default useCustomer;
