import * as React from 'react';
import {useMemo} from "react";
import {useTranslation} from "react-i18next";
import {LOANS} from "../../locales/namespaces";
import DataGridWrapper from "../../../../components/dataGridWrapper/dataGridWrapper";
import {muiGridLocales} from "../../../../helpers/muiGridLocales";
import MainCard from "../../../../theme/mantis/components/mainCard";
import moment from "moment";
import {DataGridPremium} from "@mui/x-data-grid-premium";
import {Grid, Typography, styled, useMediaQuery} from "@mui/material";
import NotificationGrid from "../../../../assets/images/icons/notificationGrid";
import AlertTriangleIcon from "../../../../assets/images/icons/alertTriangleIcon";
import {UnsortedIcon} from "../../../../assets/images/icons/sortIcon";
import {Capacitor} from "@capacitor/core";
import {useTheme} from "@mui/material/styles";

const StyledDataGrid = styled(DataGridPremium)(({theme}) => ({
	'& .super-app-theme--1': {
		borderBottom: '2px solid #F4A40B',
		background: '#FFF',
	},
	'& .super-app-theme--3': {
		borderBottom: '2px solid #519C66',
		background: '#FFF',
	},
}));

const NotesTable = ({loanNotes}) => {
	const {t} = useTranslation(LOANS)
	const theme = useTheme();
	const matchBetweenXSSM = useMediaQuery(theme.breakpoints.between('xs', 'sm'));
	
	const columns = useMemo(() => [
		{
			field: 'Body',
			headerName: t('Updates'),
			minWidth: 200, flex: 1,
			renderCell: ({row}) => {
				const stripHtmlTags = (html) => {
					const doc = new DOMParser().parseFromString(html, 'text/html');
					return doc.body.textContent || "";
				};
				const strippedBody = stripHtmlTags(row.Body);
				return (
					(row.Type === "3") ?
						<Grid container
							  sx={{
								  display: 'inline-flex',
								  alignItems: 'flex-start',
								  mt: '15px',
								  mb: '20px'
							  }}
						>
							<Grid item xs={3}>
								<NotificationGrid
									color={'#519C66'}
								/>
							</Grid>
							<Grid item xs={9}>
								<Typography
									color={'#485776'}
									sx={{
										fontSize: Capacitor.isNativePlatform() ? '14px' : '16px',
										fontStyle: 'normal',
										fontWeight: Capacitor.isNativePlatform() ? 600 : 700,
										lineHeight: 'normal'
									}}
								>
									{t("Change loan application status")}
								</Typography>
								<Typography component="span" fontSize={Capacitor.isNativePlatform() && '12px'}>
									{strippedBody}
								</Typography>
							</Grid>
						</Grid> :
						<Grid container
							  sx={{
								  display: 'inline-flex',
								  alignItems: 'flex-start',
								  mt: '15px',
								  mb: '20px'
							  }}
						>
							<Grid item xs={3}>
								<AlertTriangleIcon
									color={'#F4A40B'}
								/>
							</Grid>
							<Grid item xs={9}>
								<Typography
									color={'#485776'}
									sx={{
										fontSize: Capacitor.isNativePlatform() ? '14px' : '16px',
										fontStyle: 'normal',
										fontWeight: Capacitor.isNativePlatform() ? 600 : 700,
										lineHeight: 'normal'
									}}
								>
									{t("Update for pending")}
								</Typography>
								<Typography component="span" fontSize={Capacitor.isNativePlatform() && '12px'}>
									{strippedBody}
								</Typography>
							</Grid>
						</Grid>
				)
			}
		},
		{
			field: 'CreatedAt',
			headerName: t('Sent'),
			minWidth: 100, maxWidth: matchBetweenXSSM ? 100 : 140, flex: 1,
			valueFormatter: params =>
				moment(params?.value).format("DD/MM/YY h a"),
		},
	
	], [loanNotes, t])
	
	return (
		<MainCard
			contentSX={{p: 0}}
			sx={{
				mb: '32px',
			}}
		>
			<DataGridWrapper
				sx={{
					height: '500px',
				}}
			>
				<StyledDataGrid
					rows={loanNotes}
					columns={columns}
					getRowClassName={(params) => {
						// console.log('params.rows', params.rows)
						return (`super-app-theme--${params.row.Type}`)
					}}
					sx={{
						bgcolor: 'background.paper', ".MuiDataGrid-aggregationColumnHeaderLabel": {
							display: "none"
						},
						".MuiDataGrid-cellContent": {
							fontSize: '0.875rem'
						}
					}}
					density={'compact'}
					//localeText={muiGridLocales(t)}
					localeText={{...muiGridLocales(t), noRowsLabel: t('There are no updates.')}}
					disableAggregation={true}
					disableRowGrouping={true}
					disableColumnMenu={true}
					slots={{
						columnUnsortedIcon: UnsortedIcon,
					}}
				/>
			</DataGridWrapper>
		</MainCard>
	)
}

export default NotesTable