import * as React from "react";
const ShowIcon = (props) => (
    <svg
        width={20}
        height={17}
        viewBox="0 0 20 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g
            fillRule="evenodd"
            clipRule="evenodd"
            stroke="#fff"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path d="M13.16 8.78a3.162 3.162 0 1 1-6.322-.002 3.162 3.162 0 0 1 6.323.001Z" />
            <path d="M9.998 16.08c3.808 0 7.291-2.737 9.252-7.301-1.961-4.564-5.444-7.302-9.252-7.302h.004c-3.808 0-7.291 2.738-9.252 7.302 1.961 4.564 5.444 7.302 9.252 7.302h-.004Z" />
        </g>
    </svg>
);
export default ShowIcon;