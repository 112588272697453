// @flow
import React from 'react';
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import {useTranslation} from "react-i18next";
import {EDITABLE_MUI_GRID} from "../../../locales/components/namespaces";
import {GridToolbarContainer} from "@mui/x-data-grid-premium";

type Props = {
	addNewRow: Function
};

const EditGridToolBar = (props: Props) => {
	const {handleAddNewRow, disableAddingNewRow} = props
	const {t} = useTranslation(EDITABLE_MUI_GRID)
	return (
		<GridToolbarContainer>
			<Button
				disabled={disableAddingNewRow}
				color="primary"
				startIcon={<AddIcon/>}
				onClick={handleAddNewRow}
			>
				{t('Add')}
			</Button>
		</GridToolbarContainer>
	);
};

export default EditGridToolBar