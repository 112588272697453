import {Box, useTheme} from '@mui/material';
import AuthBackgroundImage from "../../../../../assets/images/components/authBackgroundImage";

// ==============================|| AUTH BLUR BACK SVG ||============================== //

const AuthBackground = () => {
    const theme = useTheme();
    return (
        <Box
            sx={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                overflow: 'hidden',

                // position: 'absolute',
                // filter: 'blur(18px)',
                // zIndex: -1,
                // bottom: 0,
                // right: 0
                // transform: theme.direction === ThemeDirection.RTL ? 'rotate(180deg)' : 'inherit'
            }}
        >
            <AuthBackgroundImage/>
        </Box>
    );
};

export default AuthBackground;