import React, {useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {CALCULATIONS} from "../../locales/namespaces";
import {
    Box,
    IconButton,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody
} from "@mui/material";
import moment from "moment";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {calculateInstallments} from "../../helpers/calculations";
import RepaymentsForm from "../repaymentsForm/repaymentsForm";
import useUserData from "../../../../hooks/user/useUserData";
import {NumericFormat} from "react-number-format";

const RepaymentsList = ({
                            repayments,
                            handleRemoveRepayment,
                            handleResults,
                            calculationData,
                            contributions,
                            updateRepayments,
                            handleUpdateRepayments
                        }) => {
    const {t} = useTranslation(CALCULATIONS);
    const {thousandSeparator, decimalSeparator} = useUserData()

    const [openPopup, setOpenPopup] = useState(false);
    const [selectedItemId, setSelectedItemId] = useState(null);
    const [repaymentsEdit, setRepaymentsEdit] = useState([])

    const calculateAfterDelete = (id) => {
        handleResults(calculateInstallments(
            0,
            calculationData.FixedDuration,
            calculationData.FloatingDuration,
            calculationData.FixedInterestRate,
            Number(calculationData.Spread) + Number(calculationData.Euribor),
            contributions.find(f => f.id === calculationData.Contribution).Value,
            calculationData.LoanAmount,
            calculationData.isMonthly === 'isMonthly',
            calculationData.isHidePreviousInstallments,
            moment(calculationData.DateInstallment).format('MM/DD/YYYY'),
            [...repayments.filter(repayment => repayment.id !== id)].map(repayment => ({
                Amount: Number(repayment.Amount),
                NewRate: Number(repayment.NewRate),
                Repayment_dt: moment(repayment.PaymentDt).format('MM/DD/YYYY')
            })),
            [],
            [],
            0,
            0,
            0,
            0,
            [...repayments.filter(repayment => repayment.id !== id)].map(repayment => ({
                Amount: Number(repayment.Amount),
                NewRate: Number(repayment.NewRate),
                Repayment_dt: moment(repayment.PaymentDt).format('MM/DD/YYYY')
            }))
        ));
    }
    const handleDelete = (id) => {
        handleRemoveRepayment(id, calculateAfterDelete);
    };

    // Function to open the popup and set the selected item ID
    const onOpenRepayments = (item) => {
        setSelectedItemId(item.id);
        setOpenPopup(true);
        setRepaymentsEdit({
            Amount: Number(item.Amount),
            NewRate: Number(item.NewRate),
            Repayment_dt: moment(item.PaymentDt),
            id: item.id
        })
    };

    // Function to close the popup
    const onCloseRepayments = () => {
        setSelectedItemId(null);
        setOpenPopup(false);
    };

    const rows = useMemo(
        () =>
            repayments.map((item) => (
                <TableRow hover key={item.id}>
                    <TableCell sx={{pl: 3}} component="th" scope="row">
                        {moment(item.PaymentDt).format("MM/YYYY")}
                    </TableCell>
                    <TableCell align="right">{
                        <NumericFormat
                            thousandSeparator={thousandSeparator}
                            decimalSeparator={decimalSeparator}
                            displayType="text"
                            value={item.Amount ? Number(item.Amount).toFixed(2) : '-'}
                        />
                    }
                    </TableCell>
                    <TableCell align="right">
                        <IconButton onClick={() => onOpenRepayments(item)} size={"small"}>
                            <EditIcon/>
                        </IconButton>
                    </TableCell>
                    <TableCell align="right">
                        <IconButton onClick={() => handleDelete(item.id)} color={"error"} size={"small"}>
                            <DeleteIcon/>
                        </IconButton>
                    </TableCell>
                </TableRow>
            )),

        [repayments, handleDelete, repaymentsEdit]
    );

    return (
        <Box sx={{maxWidth: 800, pt: 1, pb: 1}}>
            <TableContainer>
                <Table sx={{minWidth: 350}} aria-label="repayments table">
                    <TableHead>
                        <TableRow
                            sx={{
                                '.MuiTableRow-root .MuiTableRow-hover': {
                                    background: 'red'
                                }
                            }}>
                            <TableCell>{t("Date of repayment")}</TableCell>
                            <TableCell align="right">{t("Repayment amount (€)")}</TableCell>
                            <TableCell align="right"></TableCell>
                            <TableCell align="right"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>{rows}</TableBody>
                </Table>
            </TableContainer>
            {openPopup && (
                <RepaymentsForm
                    RepaymentId={selectedItemId}
                    open={openPopup}
                    onClose={onCloseRepayments}
                    handleResults={handleResults}
                    calculationData={calculationData}
                    contributions={contributions}
                    repayments={repayments}
                    updateRepayments={updateRepayments}
                    handleUpdateRepayments={handleUpdateRepayments}
                    repaymentsEdit={repaymentsEdit}
                />
            )}
        </Box>
    );
};

export default RepaymentsList;

