//@flow
import React from 'react';
import MenuWithDrawer from "../../components/menuWithDrawer/menuWithDrawer";

export const TitleContext = React.createContext(null)

const Content = () => {
	const [title, setTitle] = React.useState(null)
	const [toolbarBottom, setToolbarBottom] = React.useState(null)
	const [menuNavigation, setMenuNavigation] = React.useState(null)
	return (
		<TitleContext.Provider
			value={{
				title: title,
				setTitle: setTitle,
				toolbar: toolbarBottom,
				setToolbar: setToolbarBottom,
				menuNavigation,
				setMenuNavigation
			}}
		>
			<MenuWithDrawer/>
		</TitleContext.Provider>
	)
}

export default Content;
