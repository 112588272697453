import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {useTranslation} from "react-i18next";
import {CALCULATIONS} from "../../locales/namespaces";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import axios from "axios";
import {API} from "../../../../helpers/constants";
import {enqueueSnackbar} from "notistack";
import {handleAxiosErrors, logoutUnauthorizedUser} from "../../../../helpers/errorHandling";
import useUserData from "../../../../hooks/user/useUserData";
import useUserAction from "../../../../hooks/user/useUserAction";

type Props = {
	id: string,
	handleCloseReset: Function,
	handleResetForm: Function,
	openReset: Array
}

export default function AlertDialogForResetForm(props: Props) {
	const {t} = useTranslation(CALCULATIONS)
	const {token, isLoggedIn} = useUserData()
	const {userLogout} = useUserAction()
	const {
		id,
		handleCloseReset,
		handleResetForm,
		openReset,
		updateRepayments,
		handleUpdateRepayments
	} = props
	
	const handleDeleteRepayments = () => {
		axios({
			method: 'post',
			headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
			url: `${API}/repayment/reset`,
			data: {
				CalculationId: id
			}
		}).then(() => {
			enqueueSnackbar(t('Repayments deleted successfully!'), {
					variant: 'success'
				}
			)
			handleResetForm()
			handleUpdateRepayments(!updateRepayments)
			handleCloseReset()
		}).catch(error => {
			enqueueSnackbar(handleAxiosErrors(error, t('Failed to delete repayments!')), {
					variant: 'error'
				}
			)
			logoutUnauthorizedUser(error, isLoggedIn, userLogout)
		})
	}
	
	return (
		<Box>
			<Dialog
				open={openReset}
				onClose={handleCloseReset}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<Grid sx={{mt: 2, ml: 2, mr: 2, mb: 1}}>
					<DialogTitle id="alert-dialog-title">
						{t("Are you sure you want to reset the calculation?")}
					</DialogTitle>
					<DialogContent>
						<DialogContentText id="alert-dialog-description">
							{t("If you press CONFIRM your repayments will be deleted.")}
						</DialogContentText>
					</DialogContent>
				</Grid>
				<DialogActions>
					<Button onClick={handleCloseReset}>
						{t('Cancel')}
					</Button>
					<Button size={"small"}
							variant={"contained"}
							color={"error"}
							onClick={handleDeleteRepayments}
							autoFocus>
						{t('Confirm')}
					</Button>
				</DialogActions>
			</Dialog>
		</Box>
	);
}