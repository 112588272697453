import {useMemo} from 'react';

// material-ui
import {CssBaseline, StyledEngineProvider} from '@mui/material';
import {createTheme, ThemeProvider} from '@mui/material/styles';

// project import
import Palette from './palette';
import Typography from './typography';
import CustomShadows from './shadows';
import componentsOverride from './overrides';
import useConfig from "../hooks/useConfig";

// ==============================|| DEFAULT THEME - MAIN  ||============================== //

export default function ThemeCustomization({children}) {
    const {themeDirection, mode, presetColor, fontFamily} = useConfig();

    const theme = useMemo(() => Palette(mode, presetColor), [mode, presetColor]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const themeTypography = useMemo(() => Typography(fontFamily), [fontFamily]);
    const themeCustomShadows = useMemo(() => CustomShadows(theme), [theme]);

    const themeOptions = useMemo(
        () => ({
            breakpoints: {
                values: {
                    xs: 0,
                    sm: 768,
                    md: 1024,
                    lg: 1266,
                    xl: 1440
                }
            },
            direction: themeDirection,
            mixins: {
                toolbar: {
                    minHeight: 60,
                    paddingTop: 8,
                    paddingBottom: 8
                }
            },
            palette: theme.palette,
            customShadows: themeCustomShadows,
            typography: themeTypography
        }),
        [themeDirection, theme, themeTypography, themeCustomShadows]
    );

    const themes = createTheme(themeOptions);
    themes.components = componentsOverride(themes);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes}>
                <CssBaseline/>
                {children}
            </ThemeProvider>
        </StyledEngineProvider>
    );
}