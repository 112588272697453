import React from "react";
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend, ResponsiveContainer
} from "recharts";
import {useTranslation} from "react-i18next";
import {CALCULATIONS} from "../../../locales/namespaces";
import './linesChart.css'
import moment from "moment";
import useUserData from "../../../../../hooks/user/useUserData";
import {NumericFormat} from "react-number-format";

const CustomTooltip = ({active, payload, t}) => {
    const {thousandSeparator, decimalSeparator} = useUserData()
    if (active && payload && payload.length) {
        const {remainingAmount, sumInterest, repayment, Date} = payload[0].payload;
        
        const remaining = Math.max(remainingAmount, 0).toFixed(2)
        const interest = Math.max(sumInterest, 0).toFixed(2)
        const repay = Math.max(repayment, 0).toFixed(2)
        
        return (
            <div className="custom-tooltip">
                <p className="label">
                    {t('Payment date')} <span>{moment(Date, 'YYYY').format('YYYY')}</span></p>
                <div className="tooltip-content">
                    <p className="remainingAmount">{t('Remaining Principal')}:
                        <span>
                             <NumericFormat
                                 thousandSeparator={thousandSeparator}
                                 decimalSeparator={decimalSeparator}
                                 displayType="text"
                                 value={remaining ? remaining : ''}
                             />
                        </span>
                    </p>
                    <p className="sumInterest">{t('Total Interest')}:
                        <span>
                            <NumericFormat
                                thousandSeparator={thousandSeparator}
                                decimalSeparator={decimalSeparator}
                                displayType="text"
                                value={interest ? interest : ''}
                            />
                        </span>
                    </p>
                    <p className="repayment">{t('Total principal repayment')}:
                        <span>
                             <NumericFormat
                                 thousandSeparator={thousandSeparator}
                                 decimalSeparator={decimalSeparator}
                                 displayType="text"
                                 value={repay ? repay : ''}
                             />
                        </span>
                    </p>
                </div>
            </div>
        );
    }
    return null;
};

export default function LinesChart({results, FinalTable}) {
    const {t} = useTranslation(CALCULATIONS)
    const rateData = results && FinalTable ? FinalTable.map(item => ({
        name: item.AA,
        remainingAmount: item.RemainingAmount,
        sumInterest: item.SumInterest,
        repayment: item.Repayment,
        Date: item.Date
    })) : []
    
    return (
        <ResponsiveContainer width="100%" height={300}>
            <LineChart
                data={rateData}
                margin={{
                    top: 20,
                    right: 30,
                    left: 40,
                    bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="8 8"/>
                <XAxis
                    dataKey="name"
                    tick={{
                        stroke: '#485776',
                        fontSize: '12px',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: 'normal',
                        letterSpacing: '0.12px'
                    }}
                    tickSize={14}
                    tickLine={false}
                    axisLine={false}
                />
                <YAxis
                    tick={{
                        stroke: '#485776',
                    }}
                    tickSize={14}
                    tickLine={false}
                    axisLine={false}
                    tickMargin={5}
                />
                <Tooltip content={<CustomTooltip t={t}/>}/>
                <Legend verticalAlign="bottom" wrapperStyle={{lineHeight: "30px"}} iconType={'circle'} iconSize={8}/>
                <Line dataKey="remainingAmount" stroke="#8884d8" name={t('Remaining Principal')} activeDot={{r: 3}}/>
                <Line dataKey="sumInterest" stroke="#d26b63" name={t('Total Interest')} activeDot={{r: 3}}/>
                <Line dataKey="repayment" stroke="#4db6ac" name={t('Total principal repayment')} activeDot={{r: 3}}/>
            </LineChart>
        </ResponsiveContainer>
    );
}
