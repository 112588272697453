// @flow
import React, {useMemo} from 'react';
import {useTranslation} from "react-i18next";
import DataGridWrapper from "../../../../../components/dataGridWrapper/dataGridWrapper";
import EditableMUIDataGrid from "../../../../../components/editableMUIDataGrid/editableMUIDataGrid";
import {USERS} from "../../../locales/namespaces";
import useUserRoles from "../../../hooks/useUserRoles";

const RoleTable = (props) => {
	const {id, roles} = props
	const {t} = useTranslation(USERS)
	const {
		loading,
		userRoles,
		store,
		remove
	} = useUserRoles(id)
	
	const columns = useMemo(() => [
		{
			field: 'PortalRoleId',
			headerName: t('Description'),
			minWidth: 160,
			flex: 1,
			editable: true,
			type: 'singleSelect',
			preProcessEditCellProps: (params) => {
				const hasError = !params.props.value.length
				return {...params.props, error: hasError}
			},
			valueOptions: roles.map(roles => ({
				value: roles.id,
				label: roles.Description
			})),
		},
	], [roles, t])
	
	return (
		<DataGridWrapper
			sx={{
				mt: 1,
				maxWidth: '1000px',
				height: '330px'
			}}
		>
			<EditableMUIDataGrid
				loading={loading}
				data={userRoles}
				columns={columns}
				store={store}
				remove={remove}
				hideEditButton={true}
				dataGridProOtherProps={{
					disableColumnMenu: true,
					// disableAggregation: true,
					// disableRowGrouping: true,
					// disableColumnFilter: true
				}}
			/>
		</DataGridWrapper>
	);
}

export default RoleTable