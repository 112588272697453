import {useMemo} from 'react';

// material-ui
import {useTheme} from '@mui/material/styles';
import {AppBar, Toolbar, useMediaQuery} from '@mui/material';

// project import
import AppBarStyled from './appBarStyled';

// assets
import {MenuFoldOutlined, MenuUnfoldOutlined} from '@ant-design/icons';
import {MenuOrientation, ThemeMode} from "../../../config";
import IconButton from "../../../components/@extended/iconButton";
import useConfig from "../../../hooks/useConfig";
import HeaderContent from "./headerContent";
import useMenuAction from "../../../../../hooks/menu/useMenuAction";
import useMenuData from "../../../../../hooks/menu/useMenuData";
import {Capacitor} from "@capacitor/core";
import PermissionHOC from "../../../../../modules/permission/components/permissionHOC/permissionHOC";
import {useHasPermission} from "../../../../../modules/permission/hooks/useHasPermission";
import {IonHeader} from "@ionic/react";

// ==============================|| MAIN LAYOUT - HEADER ||============================== //

const Header = () => {
	const {drawerOpen} = useMenuData()
	const {setDrawerOpen} = useMenuAction()
	const theme = useTheme();
	const downLG = useMediaQuery(theme.breakpoints.down('lg'));
	const {menuOrientation} = useConfig();
	
	const isHorizontal = menuOrientation === MenuOrientation.HORIZONTAL && !downLG;
	
	// header content
	const headerContent = useMemo(() => <HeaderContent/>, []);
	
	const iconBackColorOpen = theme.palette.mode === ThemeMode.DARK ? 'grey.200' : theme.palette.primary.main;
	const iconBackColor = theme.palette.mode === ThemeMode.DARK ? 'background.default' : theme.palette.primary.main;
	const isAdmin = useHasPermission(['access_mobile_side_menu'], {shouldView: true})
	
	// common header
	const mainHeader = (
		// <IonHeader className="ion-no-border">
			<Toolbar
				sx={{
					pt: Capacitor.isNativePlatform() ? 0 : 'auto'
				}}
			>
				{isAdmin ? (
					// null
					<IconButton
						aria-label="open drawer"
						onClick={() => setDrawerOpen(!drawerOpen)}
						edge="start"
						color="secondary"
						variant="light"
						sx={{
							color: 'text.primary',
							bgcolor: drawerOpen ? iconBackColorOpen : iconBackColor,
							ml: /*{xs: 0, lg: -2}*/ drawerOpen ? 10 : {xs: 0, sm: 10, md: 10, lg: 2},
							border: '1px solid #485776',
							'&:hover': {
								background: '#E3E5EC'
							}
						}}
					>
						{!drawerOpen ? <MenuUnfoldOutlined/> : <MenuFoldOutlined/>}
					</IconButton>
				) : null}
				{headerContent}
			</Toolbar>
		// </IonHeader>
	)
	
	// app-bar params
	const appBar = {
		position: 'fixed',
		// color: 'inherit',
		elevation: 0,
		sx: {
			//borderBottom: `1px solid ${theme.palette.divider}`,
			zIndex: 1200,
			width: isHorizontal ? '100%' : drawerOpen ? 'calc(100% - 200px)' : {xs: '100%', lg: 'calc(100% - 60px)'},
			backgroundColor: theme.palette.mode === ThemeMode.DARK ? 'inherit' : theme.palette.primary.main
		}
	};
	
	return (
		<>
			{!downLG ? (
				<AppBarStyled open={drawerOpen} {...appBar}>
					{mainHeader}
				</AppBarStyled>
			) : (
				<AppBar {...appBar}>{mainHeader}</AppBar>
			)}
		</>
	);
};

export default Header;
