import * as React from "react";
const CalculatorBlueIcon = (props) => (
    <svg
        width={18}
        height={20}
        viewBox="0 0 18 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g
            stroke="#485776"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path d="M4.36 9.555H3.4m4.32 0h-.96m4.321 0h-.96m4.32 0h-.96M4.36 12.488H3.4m4.32 0h-.96m4.321 0h-.96m4.32 0h-.96M4.36 15.421H3.4m4.32 0h-.96m4.321 0h-.96m4.32 0h-.96m3.121-8.799H1.24" />
            <path
                clipRule="evenodd"
                d="m12.673 1-7.382.004C2.64 1.02 1 2.796 1 5.504v8.992C1 17.218 2.653 19 5.326 19l7.382-.003C15.358 18.98 17 17.204 17 14.496V5.504C17 2.782 15.346 1 12.673 1Z"
            />
        </g>
    </svg>
);
export default CalculatorBlueIcon;
