import Index from "../pages/index";
import Create from "../pages/create/create";
//import Details from "../pages/details/details"
import {routeCreator} from '../../../helpers/routeCreator'

export const ROUTE_PAGE_CALCULATIONS_INDEX = routeCreator(Index, '/calculations', 'Calculations', 'page.calculations', {exact: true})
export const ROUTE_PAGE_CALCULATIONS_CREATE = routeCreator(Create, '/calculations/create', 'Loan Create', 'page.calculations.create', {exact: true})
export const ROUTE_PAGE_CALCULATIONS_EDIT = routeCreator(Create, '/calculations/edit/:id', 'Loan Edit', 'page.calculations.edit', {exact: true})
//export const ROUTE_PAGE_CALCULATIONS_DETAILS = routeCreator(Details, '/calculations/details/:id', 'Loan Details', 'page.calculations.details', {exact: true})

const router = {
    ROUTE_PAGE_CALCULATIONS_INDEX,
    ROUTE_PAGE_CALCULATIONS_CREATE,
    ROUTE_PAGE_CALCULATIONS_EDIT,
    //ROUTE_PAGE_CALCULATIONS_DETAILS
}

export default router