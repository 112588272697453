// @flow
import React from 'react';
import {
    Box,
    Button,
    Typography,
    useMediaQuery
} from "@mui/material";
import {useTranslation} from "react-i18next";
import {CALCULATIONS} from "../../locales/namespaces";
import {getRouteUrl} from "../../../../helpers/getRouteUrl";
import Breadcrumb from "../../../../components/breadcrumb/breadcrumb";
import {ROUTE_PAGE_HOME} from "../../../../routers/routes";
import {Link} from "react-router-dom";
import {
    ROUTE_PAGE_CALCULATIONS_CREATE
} from "../../routes/routes";
import IndexListCalculations from "../../components/indexList/indexList";
import {withPermissionHOC} from "../../../permission/components/permissionHOC/permissionHOC";
import MainCard from "../../../../theme/mantis/components/mainCard";
import HomeBreadcrumb from "../../../../assets/images/icons/homeBreadcrumb";

const Index = () => {
    const {t} = useTranslation(CALCULATIONS)
    const matchDownSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));

    return (
        <Box>
            {!matchDownSM &&
                <Breadcrumb>
                    <Breadcrumb.Link iconComponent={HomeBreadcrumb} to={getRouteUrl(ROUTE_PAGE_HOME)}/>
                    <Breadcrumb.Item>
                        {t('Calculations')}
                    </Breadcrumb.Item>
                </Breadcrumb>
            }
            <MainCard
                sx={{mt: 2}}
                title={
                    <Typography variant="h5">
                        {t('Calculations')}
                    </Typography>
                }
                secondary={
                    <Button
                        size={"small"}
                        variant={"contained"}
                        component={Link}
                        color={'info'}
                        to={getRouteUrl(ROUTE_PAGE_CALCULATIONS_CREATE)}
                    >
                        {t('Add')}
                    </Button>
                }
            >
                <IndexListCalculations/>
            </MainCard>
        </Box>
    );
};

export default withPermissionHOC(Index, ['access_calculations_index'])