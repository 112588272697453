import * as React from 'react';
import {useEffect, useLayoutEffect, useMemo, useState} from 'react';

// material-ui
import {useTheme} from '@mui/material/styles';
import {Box, Typography, useMediaQuery} from '@mui/material';
import {
	ArticleOutlined,
	CreditScoreOutlined,
	DashboardOutlined,
	FlagOutlined,
	Groups2Outlined,
	SettingsOutlined,
	SupportOutlined,
} from '@mui/icons-material';

// project import
import NavGroup from './navGroup';
import useConfig from "../../../../../hooks/useConfig";
import {HORIZONTAL_MAX_ITEM, MenuOrientation} from "../../../../../config";
import {Menu} from "../../../../../menu-items/dashboard";
import {getRouteUrl} from "../../../../../../../helpers/getRouteUrl";
import {ROUTE_PAGE_HOME} from "../../../../../../../routers/routes";

import {ROUTE_PAGE_USERS_INDEX} from "../../../../../../../modules/users/routes/routes";
import {ROUTE_PAGE_CUSTOMERS_INDEX} from "../../../../../../../modules/customers/routes/routes";
import {ROUTE_PAGE_LOANS_INDEX} from "../../../../../../../modules/loans/routes/routes";
import {ROUTE_PAGE_CALCULATIONS_INDEX} from "../../../../../../../modules/calculations/routes/routes";
import {useTranslation} from "react-i18next";
import {NAVIGATION} from "../../../../../../../locales/components/namespaces";
import useUserAction from "../../../../../../../hooks/user/useUserAction";
import useUserData from "../../../../../../../hooks/user/useUserData";
import useMenuData from "../../../../../../../hooks/menu/useMenuData";
import {
	ROUTE_PAGE_PARAMETERS_COUNTRIES,
	ROUTE_PAGE_PARAMETERS_CUSTOMER_STATUS,
	ROUTE_PAGE_PARAMETERS_DOCS_ASSOCIATIONS,
	ROUTE_PAGE_PARAMETERS_DOCUMENTS,
	ROUTE_PAGE_PARAMETERS_DOCUMENTS_TYPES,
	ROUTE_PAGE_PARAMETERS_INTEREST_RATE,
	ROUTE_PAGE_PARAMETERS_LOAN_STATUS,
	ROUTE_PAGE_PARAMETERS_LOAN_TYPE,
	ROUTE_PAGE_PARAMETERS_NATIONALITIES,
	ROUTE_PAGE_PARAMETERS_ORIGINS,
	ROUTE_PAGE_PARAMETERS_QUESTIONNAIRES,
	ROUTE_PAGE_PARAMETERS_RIGHTS,
	ROUTE_PAGE_PARAMETERS_RIGHTS_ROLES,
	ROUTE_PAGE_PARAMETERS_ROLES
} from "../../../../../../../modules/parameters/routes/routes";
import NavItem from "./navItem";
import HomeIcon from "../../../../../../../assets/images/icons/homeIcon";
import HomeBlueIcon from "../../../../../../../assets/images/icons/menuIcons/homeBlueIcon";
import CalculatorBlueIcon from "../../../../../../../assets/images/icons/menuIcons/calculatorBlueIcon";
import CalculatorIcon from "../../../../../../../assets/images/icons/menuIcons/calculatorIcon";
import UsersIcon from "../../../../../../../assets/images/icons/usersIcon";
import LogoutIcon from "../../../../../../../assets/images/icons/logout";
import BankBlueIcon from "../../../../../../../assets/images/icons/menuIcons/bankBlueIcon";
import UsersBlueIcon from "../../../../../../../assets/images/icons/menuIcons/usersBlueIcon";
import BankIcon from "../../../../../../../assets/images/icons/menuIcons/bankIcon";
import HeadphonesBlueIcon from "../../../../../../../assets/images/icons/menuIcons/headphonesBlueIcon";
import HeadphonesIcon from "../../../../../../../assets/images/icons/menuIcons/headPhonesIcon";
import CircleBlueIcon from "../../../../../../../assets/images/icons/menuIcons/circleBlueIcon";
import CircleIcon from "../../../../../../../assets/images/icons/menuIcons/circleIcon";
import SupportDialog from "../../../../../../../pages/supportDialog/supportDialog";
import Users3BlueIcon from "../../../../../../../assets/images/icons/menuIcons/users3BlueIcon";
import Users3Icon from "../../../../../../../assets/images/icons/menuIcons/users3Icon";

const Navigation = () => {
	const theme = useTheme();
	const downLG = useMediaQuery(theme.breakpoints.down('lg'));
	const {menuOrientation} = useConfig();
	const {drawerOpen} = useMenuData()
	const [selectedItems, setSelectedItems] = useState('');
	const [selectedLevel, setSelectedLevel] = useState(0);
	const [menuItems, setMenuItems] = useState({items: []});
	const [open, setOpen] = React.useState(false);
	const [selectedValue, setSelectedValue] = React.useState([]);
	
	const {t} = useTranslation(NAVIGATION)
	const {userLogout} = useUserAction()
	const {customerId, userId, language} = useUserData()
	const {i18n} = useTranslation()
	useEffect(() => {
		i18n.changeLanguage(language)
	}, [i18n, language])
	
	const menuItem = useMemo(() => {
			return {
				items: [
					{
						icon: DashboardOutlined,
						id: 'group-dashboard',
						title: '',
						type: 'group',
						children: [
							{
								url: getRouteUrl(ROUTE_PAGE_HOME),
								title: t("Home"),
								icon: HomeBlueIcon,
								hoverIcon: HomeIcon,
								hoverMessage: t("Home"),
								id: 'home',
								type: 'item',
								breadcrumbs: false,
							},
							{
								url: getRouteUrl(ROUTE_PAGE_CUSTOMERS_INDEX),
								title: t("Customers"),
								icon: UsersBlueIcon,
								hoverIcon: UsersIcon,
								hoverMessage: t("Customers"),
								permissions: ['access_customer_index'],
								id: 'customers',
								type: 'item',
								breadcrumbs: false,
							},
							{
								url: getRouteUrl(ROUTE_PAGE_LOANS_INDEX),
								//title: customerId ? t("My loans") : t("Loans Requests"),
								title: t('Loans'),
								icon: BankBlueIcon,
								hoverIcon: BankIcon,
								//hoverMessage: customerId ? t("My loans") : t("Loans Requests"),
								hoverMessage: t("Loans"),
								permissions: ['access_loan_index'],
								id: 'loan-request',
								type: 'item',
								breadcrumbs: false,
							},
							{
								url: getRouteUrl(ROUTE_PAGE_USERS_INDEX),
								title: t("Users"),
								icon: Users3BlueIcon,
								hoverIcon: Users3Icon,
								hoverMessage: t("Users"),
								permissions: ['access_user_index'],
								id: 'users',
								type: 'item',
								breadcrumbs: false,
							},
							{
								url: getRouteUrl(ROUTE_PAGE_CALCULATIONS_INDEX),
								//title: customerId ? t("My calculations") : t("Calculations"),
								title: t("Installment Calculation"),
								icon: CalculatorBlueIcon,
								hoverIcon: CalculatorIcon,
								//hoverMessage: customerId ? t("My calculations") : t("Calculations"),
								hoverMessage: t("Installment Calculation"),
								permissions: ['access_calculations_index'],
								id: 'loan-calculation',
								type: 'item',
								breadcrumbs: false,
							},
							/*{
								url: customerId ? getRouteUrl((ROUTE_PAGE_CUSTOMERS_DETAILS), {id: customerId}) : getRouteUrl((ROUTE_PAGE_USERS_DETAILS), {id: userId}),
								title: t("My Profile"),
								icon: PersonOutlined,
								hoverMessage: t("My Profile"),
								permissions: ['access_customer_details'],
								id: 'my-profile',
								type: 'item',
								breadcrumbs: false,
							},*/
						]
					},
					{
						icon: SettingsOutlined,
						id: 'parameters',
						title: t('Parameters'),
						type: 'group',
						permissions: ['access_parameters'],
						children: [
							{
								icon: FlagOutlined,
								id: 'locales',
								title: t('Locales'),
								type: 'collapse',
								permissions: ['access_parameters'],
								children: [
									{
										url: getRouteUrl(ROUTE_PAGE_PARAMETERS_COUNTRIES),
										title: t("Countries"),
										icon: CircleBlueIcon,
										hoverIcon: CircleIcon,
										hoverMessage: t("Countries"),
										permissions: ['access_parameters'],
										id: 'countries',
										type: 'item',
										breadcrumbs: false,
										child: true
									},
									{
										url: getRouteUrl(ROUTE_PAGE_PARAMETERS_NATIONALITIES),
										title: t("Nationalities"),
										icon: CircleBlueIcon,
										hoverIcon: CircleIcon,
										hoverMessage: t("Nationalities"),
										permissions: ['access_parameters'],
										id: 'nationalities',
										type: 'item',
										breadcrumbs: false,
										child: true
									},
								]
							},
							{
								icon: Groups2Outlined,
								id: 'user-settings',
								title: t('User settings'),
								type: 'collapse',
								permissions: ['access_parameters'],
								children: [
									{
										url: getRouteUrl(ROUTE_PAGE_PARAMETERS_ROLES),
										title: t("Roles"),
										icon: CircleBlueIcon,
										hoverIcon: CircleIcon,
										hoverMessage: t("Roles"),
										permissions: ['access_parameters'],
										id: 'roles',
										type: 'item',
										breadcrumbs: false,
										child: true
									},
									{
										url: getRouteUrl(ROUTE_PAGE_PARAMETERS_RIGHTS),
										title: t("Rights"),
										icon: CircleBlueIcon,
										hoverIcon: CircleIcon,
										hoverMessage: t("Rights"),
										permissions: ['access_parameters'],
										id: 'rights',
										type: 'item',
										breadcrumbs: false,
										child: true
									},
									{
										url: getRouteUrl(ROUTE_PAGE_PARAMETERS_RIGHTS_ROLES),
										title: t("Rights and Roles"),
										icon: CircleBlueIcon,
										hoverIcon: CircleIcon,
										hoverMessage: t("Rights and Roles"),
										permissions: ['access_parameters'],
										id: 'rights-roles',
										type: 'item',
										breadcrumbs: false,
										child: true
									},
									// TODO::Δεν ξέρουμε ακόμα αν θα χρησιμοποιηθεί
									// {
									//     url: getRouteUrl(ROUTE_PAGE_PARAMETERS_GROUPS),
									//     title: t("Groups"),
									//     icon: GroupOutlined,
									//     hoverMessage: t("Groups"),
									//     permissions: ['access_parameters'],
									//     id: 'groups',
									//     type: 'item',
									//     breadcrumbs: false,
									// },
								]
							},
							{
								icon: CreditScoreOutlined,
								id: 'loan-settings',
								title: t('Loan settings'),
								type: 'collapse',
								permissions: ['access_parameters'],
								children: [
									{
										url: getRouteUrl(ROUTE_PAGE_PARAMETERS_LOAN_STATUS),
										title: t("Loan Status"),
										icon: CircleBlueIcon,
										hoverIcon: CircleIcon,
										hoverMessage: t("Loan Status"),
										permissions: ['access_parameters'],
										id: 'loan-status',
										type: 'item',
										breadcrumbs: false,
										child: true
									},
									{
										url: getRouteUrl(ROUTE_PAGE_PARAMETERS_LOAN_TYPE),
										title: t("Loan Purpose"),
										icon: CircleBlueIcon,
										hoverIcon: CircleIcon,
										hoverMessage: t("Loan Purpose"),
										permissions: ['access_parameters'],
										id: 'loan-type',
										type: 'item',
										breadcrumbs: false,
										child: true
									},
									{
										url: getRouteUrl(ROUTE_PAGE_PARAMETERS_ORIGINS),
										title: t("Origin"),
										icon: CircleBlueIcon,
										hoverIcon: CircleIcon,
										hoverMessage: t("Origin"),
										permissions: ['access_parameters'],
										id: 'origin',
										type: 'item',
										breadcrumbs: false,
										child: true
									},
									{
										url: getRouteUrl(ROUTE_PAGE_PARAMETERS_INTEREST_RATE),
										title: t("Calculation variables"),
										icon: CircleBlueIcon,
										hoverIcon: CircleIcon,
										hoverMessage: t("Calculation variables"),
										permissions: ['access_parameters'],
										id: 'calculation-variables',
										type: 'item',
										breadcrumbs: false,
										child: true
									},
									{
										url: getRouteUrl(ROUTE_PAGE_PARAMETERS_QUESTIONNAIRES),
										title: t("Questionnaires"),
										icon: CircleBlueIcon,
										hoverIcon: CircleIcon,
										hoverMessage: t("Questionnaires"),
										permissions: ['access_parameters'],
										id: 'questionnaires',
										type: 'item',
										breadcrumbs: false,
										child: true
									}
								]
							},
							{
								icon: ArticleOutlined,
								id: 'documents-settings',
								title: t('documents'),
								type: 'collapse',
								permissions: ['access_parameters'],
								children: [
									{
										url: getRouteUrl(ROUTE_PAGE_PARAMETERS_DOCUMENTS),
										title: t("Documents"),
										icon: CircleBlueIcon,
										hoverIcon: CircleIcon,
										hoverMessage: t("Documents"),
										permissions: ['access_parameters'],
										id: 'documents',
										type: 'item',
										breadcrumbs: false,
										child: true
									},
									{
										url: getRouteUrl(ROUTE_PAGE_PARAMETERS_DOCS_ASSOCIATIONS),
										title: t("Documents Associations"),
										icon: CircleBlueIcon,
										hoverIcon: CircleIcon,
										hoverMessage: t("Documents Associations"),
										permissions: ['access_parameters'],
										id: 'document-associations',
										type: 'item',
										breadcrumbs: false,
										child: true
									},
									{
										url: getRouteUrl(ROUTE_PAGE_PARAMETERS_DOCUMENTS_TYPES),
										title: t("Documents Types"),
										icon: CircleBlueIcon,
										hoverIcon: CircleIcon,
										hoverMessage: t("Documents Types"),
										permissions: ['access_parameters'],
										id: 'document-types',
										type: 'item',
										breadcrumbs: false,
										child: true
									}
								]
							},
							{
								icon: SupportOutlined,
								id: 'customers-settings',
								title: t('Customer settings'),
								type: 'collapse',
								permissions: ['access_parameters'],
								children: [
									{
										url: getRouteUrl(ROUTE_PAGE_PARAMETERS_CUSTOMER_STATUS),
										title: t("Customer Status"),
										icon: CircleBlueIcon,
										hoverIcon: CircleIcon,
										hoverMessage: t("Customer Status"),
										permissions: ['access_parameters'],
										id: 'customer-status',
										type: 'item',
										breadcrumbs: false,
										child: true
									}
								]
							}
						]
					}
				]
			}
		}
		, [t])
	
	useEffect(() => {
		handlerMenuItem();
		// eslint-disable-next-line
	}, []);
	
	let getMenu = Menu();
	const handlerMenuItem = () => {
		const isFound = menuItem.items.some((element) => {
			return element.id === 'group-dashboard';
		});
		
		if (getMenu?.id !== undefined && !isFound) {
			menuItem.items.splice(0, 0, getMenu);
			setMenuItems(menuItem);
		}
	};
	
	useLayoutEffect(() => {
		setMenuItems(menuItem);
	}, [menuItem]);
	
	const isHorizontal = menuOrientation === MenuOrientation.HORIZONTAL && !downLG;
	
	const lastItem = isHorizontal ? HORIZONTAL_MAX_ITEM : null;
	let lastItemIndex = menuItems.items.length - 1;
	let remItems = [];
	let lastItemId;
	
	//  first it checks menu item is more than giving HORIZONTAL_MAX_ITEM after that get lastItemid by giving horizontal max
	// item, and it sets horizontal menu by giving horizontal max item lastly slice menuItem from array and set into remItems
	
	if (lastItem && lastItem < menuItems.items.length) {
		lastItemId = menuItems.items[lastItem - 1].id;
		lastItemIndex = lastItem - 1;
		remItems = menuItems.items.slice(lastItem - 1, menuItems.items.length).map((item) => ({
			title: item.title,
			elements: item.children,
			icon: item.icon
		}));
	}
	
	const navGroups = menuItems.items.slice(0, lastItemIndex + 1).map((item) => {
		switch (item.type) {
			case 'group':
				return (
					<NavGroup
						key={item.id}
						setSelectedItems={setSelectedItems}
						setSelectedLevel={setSelectedLevel}
						selectedLevel={selectedLevel}
						selectedItems={selectedItems}
						lastItem={lastItem}
						remItems={remItems}
						lastItemId={lastItemId}
						item={item}
					/>
				);
			default:
				debugger
				return (
					<Typography key={item.id} variant="h6" color="error" align="center">
						Fix - Navigation Group
					</Typography>
				);
		}
	})
	
	const handleClickOpen = () => {
		setOpen(true);
	};
	
	const handleClose = (value) => {
		setOpen(false);
		setSelectedValue(value);
	};
	
	return (
		<Box
			sx={{
				pt: drawerOpen ? (isHorizontal ? 0 : 2) : 0,
				'& > ul:first-of-type': {mt: 0},
				display: isHorizontal ? {xs: 'block', lg: 'flex'} : 'block'
			}}
		>
			{navGroups}
			<Box
				sx={{
					position: "fixed",
					bottom: 0,
					//float: 'bottom'
					ml: drawerOpen ? '30px' : -1
				}}
			>
				<NavItem
					item={{
						url: '',
						title: t("Support"),
						icon: HeadphonesBlueIcon,
						hoverIcon: HeadphonesIcon,
						hoverMessage: t("Support"),
						id: 'support',
						type: 'item',
						breadcrumbs: false,
						permissions: ['access_support'],
					}}
					onClickAction={() => {
						handleClickOpen()
					}}
					level={1}
					support={true}
				/>
				<NavItem
					item={{
						url: '',
						title: t("Logout"),
						icon: LogoutIcon,
						hoverIcon: LogoutIcon,
						hoverMessage: t("Logout"),
						id: 'logout',
						type: 'item',
						breadcrumbs: false,
					}}
					onClickAction={userLogout}
					level={1}
					logout={true}
				/>
			</Box>
			<SupportDialog
				selectedValue={selectedValue}
				open={open}
				onClose={handleClose}
			/>
		</Box>
	);
};

export default Navigation;
