// @flow
import React from 'react';
import {useTranslation} from "react-i18next";
import {PAGE_HOME} from "../../../locales/pages/namespaces";
import MainCard from "../../../theme/mantis/components/mainCard";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import HomeBreadcrumbBlue from "../../../assets/images/icons/homeBreadcrumbBlue";
import {getRouteUrl} from "../../../helpers/getRouteUrl";
import {ROUTE_PAGE_HOME} from "../../../routers/routes";
import {Box} from "@mui/material";

const AdminDashboard = () => {
	const {t} = useTranslation(PAGE_HOME)
	
	return (
		<Box>
			<Breadcrumb>
				<Breadcrumb.Link iconComponent={HomeBreadcrumbBlue} to={getRouteUrl(ROUTE_PAGE_HOME)}/>
			</Breadcrumb>
			<MainCard title={t('Admin Dashboard')} sx={{mt: 1}}></MainCard>
		</Box>
	)
}

export default AdminDashboard