import * as React from "react";
// material-ui
//import {useTheme} from '@mui/material/styles';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoIconDark from 'assets/images/logo-icon-dark.svg';
 * import logoIcon from 'assets/images/logo-icon.svg';
 * import { ThemeMode } from 'config';
 *
 */

// ==============================|| LOGO ICON SVG ||============================== //

const LogoIcon = (props) => (
    <svg
        width={35}
        height={35}
        viewBox="0 0 49 43"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M33.764 24.064 18.311 8.06a1.41 1.41 0 0 0-1.017-.437c-.367 0-.738.145-1.018.437L.823 24.064a1.527 1.527 0 0 0 0 2.11L16.276 42.18a1.403 1.403 0 0 0 2.037 0l15.453-16.005a1.527 1.527 0 0 0 0-2.11m-6.27 1.857-9.571 9.911a.87.87 0 0 1-1.26 0l-9.57-9.911a.95.95 0 0 1 0-1.31l9.57-9.909a.868.868 0 0 1 1.26 0l9.571 9.91a.95.95 0 0 1 0 1.309Zm9.711-3.821c-.357 0-.714-.128-.985-.383l-10.07-9.54a1.276 1.276 0 0 1 0-1.87L36.221.77c.263-.25.614-.387.985-.387h.002c.372 0 .72.137.984.39l10.07 9.537a1.275 1.275 0 0 1 0 1.87l-10.07 9.539a1.424 1.424 0 0 1-.984.385l-.002-.002Zm0-20.858a.489.489 0 0 0-.342.136l-10.068 9.537a.442.442 0 0 0-.002.65l10.068 9.54a.506.506 0 0 0 .684 0l10.07-9.54a.445.445 0 0 0 0-.65L37.548 1.38a.484.484 0 0 0-.34-.136Z"
            fill="#485776"
        />
    </svg>
);
export default LogoIcon;
