import {Link as RouterLink} from 'react-router-dom';

// material-ui
import {Link, Stack, Typography, useTheme} from '@mui/material';
import {Capacitor} from "@capacitor/core";
import {useTranslation} from "react-i18next";
import {PAGE_HOME} from "../../../../locales/pages/namespaces";
import ImsLink from "../../../../pages/home/dashboards/components/imsLink/imsLink";
import React from "react";
import {getRouteUrl} from "../../../../helpers/getRouteUrl";
import {ROUTE_PAGE_PRIVACY_POLICY, ROUTE_PAGE_TERMS_CONDITIONS} from "../../../../routers/routes";
import useMediaQuery from "@mui/material/useMediaQuery";

const Footer = () => {
    const theme = useTheme();
    const {t} = useTranslation(PAGE_HOME)
    const matchDownXS = useMediaQuery(theme.breakpoints.between('xs', 'md'));
    /* const {miniDrawer, menuOrientation} = useConfig();
	 const isHorizontal = menuOrientation === MenuOrientation.HORIZONTAL && !downLG;*/
    //const isAdmin = useHasPermission(['access_mobile_side_menu'], {shouldView: true})
    
    return (
        <Stack
            direction={{xs: 'column', sm: 'row', md: 'row', lg: 'row', xl: 'row'}}
            justifyContent="space-between"
            alignItems="center"
            sx={{
                p: '24px 16px 0px',
                mt: 'auto',
                mb: Capacitor.isNativePlatform() || matchDownXS ? 10 : 1
            }}
            spacing={1.5}
        >
         <Stack></Stack>
           {/* <Typography variant="h5">&copy; {t('IMS FC All rights reserved')}</Typography>*/}
            <Stack spacing={1.5} direction={{xs: 'column', sm: 'row', md: 'row', lg: 'row', xl: 'row'}}
                   justifyContent="space-between" alignItems="center">
                <ImsLink inFooter={true}/>
                <Link component={RouterLink} to={getRouteUrl(ROUTE_PAGE_PRIVACY_POLICY)} variant="h5"
                      color="textPrimary">
                    {t('Privacy policy')}
                </Link>
                <Link component={RouterLink} to={getRouteUrl(ROUTE_PAGE_TERMS_CONDITIONS)} /*target={!Capacitor.isNativePlatform() && "_blank"}*/
                      variant="h5" color="textPrimary">
                    {t('Terms & Conditions')}
                </Link>
                <Typography variant="h5">IMS FC &copy; Copyright</Typography>
            </Stack>
        </Stack>
    );
}
export default Footer;
