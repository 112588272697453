// material-ui
import {useTheme} from '@mui/material/styles';
import {Box, Button} from '@mui/material';

// project import
import MainCard from "../../components/mainCard";
import LogoLogin from "../../components/logo/logoLogin";
import Typography from "@mui/material/Typography";
import {useTranslation} from "react-i18next";
import {PAGE_LOGIN} from "../../../../locales/pages/namespaces";
import {useLocation} from "react-router";
import {getRouteUrl} from "../../../../helpers/getRouteUrl";
import {ROUTE_PAGE_LOGIN} from "../../../../routers/routes";
import ArrowLeftIcon from "../../../../assets/images/icons/buttonsIcons/arrowLeftIcon";
import React from "react";
import useUserAction from "../../../../hooks/user/useUserAction";
import {useHistory} from "react-router-dom";

// ==============================|| AUTHENTICATION - CARD WRAPPER ||============================== //

const AuthCard = ({children, ...other}) => {
	const theme = useTheme();
	const location = useLocation()
	const {t} = useTranslation(PAGE_LOGIN)
	const {userLogout} = useUserAction()
	const history = useHistory()
	
	const isSignUp = location.pathname === '/signup'
	
	return (
		<MainCard
			sx={{
				maxWidth: {xs: 400, lg: 425},
				margin: {xs: 2.5, md: isSignUp ? 4 : 6},
				'& > *': {
					flexGrow: 1,
					flexBasis: '50%'
				}
			}}
			content={false}
			{...other}
			border={false}
		>
			{(location.pathname === '/reset') ?
				<Button
					color={'blue'}
					onClick={() => {
						userLogout()
						history.push(getRouteUrl(ROUTE_PAGE_LOGIN))
					}}
				>
					<ArrowLeftIcon/>
				</Button>
				: null}
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					gap: '30px'
				}}
			>
				<LogoLogin
					style={{
						marginTop: 50,
						
					}}
					width={'5.958rem'}
					height={'5.257rem'}
				/>
				{(location.pathname === '/signup') ?
					<div style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						gap: '8px'
					}}>
						<Typography variant={'h4'} size={'20px'} fontWeight={600} color={'textPrimary'}>
							{t('Get Started with IMS')}
						</Typography>
						<Typography variant={'h5'} color={theme.palette.black[30]}>
							{t('Create your free account')}
						</Typography>
					</div> : (location.pathname !== '/reset') &&
					<Typography variant={'h5'} color={theme.palette.black[30]}>
						{t('Login to your account')}
					</Typography>
				}
			</div>
			<Box sx={{p: {xs: 2, sm: 3, md: 4, xl: 5}}}>{children}</Box>
		</MainCard>
	);
};

export default AuthCard;