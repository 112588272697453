import * as React from "react";
const BankNative = (props) => (
    <svg
        width={20}
        height={19}
        viewBox="0 0 20 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M12.036 1.283 18.77 6.11l-17.477.01 6.705-4.836c1.18-.84 2.858-.84 4.038 0Z"
            stroke="#485776"
            strokeWidth={1.092}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M5.663 9.966v4.37m4.37-4.37v4.37m4.369-4.37v4.37M1.844 18.13h16.377"
            stroke="#485776"
            strokeWidth={1.092}
            strokeLinecap="round"
        />
    </svg>
);
export default BankNative;