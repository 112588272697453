import Index from '../pages/index'
import Create from "../pages/create/create";
import Details from "../pages/details/details"
import { routeCreator } from '../../../helpers/routeCreator'

export const ROUTE_PAGE_LOANS_INDEX = routeCreator(Index, '/loans', 'Loans', 'page.loans', {exact: true})
export const ROUTE_PAGE_LOANS_CREATE = routeCreator(Create, '/loans/create', 'Loan Create', 'page.loans.create', {exact: true})
export const ROUTE_PAGE_LOANS_EDIT = routeCreator(Create, '/loans/edit/:id', 'Loan Edit', 'page.loans.edit', {exact: true})
export const ROUTE_PAGE_LOANS_DETAILS = routeCreator(Details, '/loans/details/:id', 'Loan Details', 'page.loans.details', {exact: true})

const router = {
    ROUTE_PAGE_LOANS_INDEX,
    ROUTE_PAGE_LOANS_CREATE,
    ROUTE_PAGE_LOANS_EDIT,
    ROUTE_PAGE_LOANS_DETAILS
}

export default router