import React from "react";
import history from './store/history'
import {ConnectedRouter} from 'connected-react-router'
import {loadModules} from "./store/loadModule";
import {PermissionsModule} from "./modules/permission";
import Main from "./pages/main/main";
import {UsersModule} from "./modules/users";
import {CustomersModule} from "./modules/customers";
import {ParametersModule} from "./modules/parameters";
import {LoansModule} from "./modules/loans";
import {CalculationsModule} from "./modules/calculations";
import {closeSnackbar, SnackbarProvider} from 'notistack';
import {MaterialDesignContent} from 'notistack'
import {styled} from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import ThemeCustomization from "./theme/mantis/themes";
import ScrollTop from "./theme/mantis/components/scrollTop";
import CancelNotiIcon from "./assets/images/icons/snackBar/cancelNotiIcon";
import SuccessIcon from "./assets/images/icons/snackBar/successIcon";
import FailureIcon from "./assets/images/icons/snackBar/failureIcon";
import WarningIcon from "./assets/images/icons/snackBar/warningIcon";
import InfoIcon from "./assets/images/icons/snackBar/infoIcon";
import {PushNotifications} from '@capacitor/push-notifications';
import {Capacitor} from "@capacitor/core";
//import './App.css'
// import '@ionic/react/css/core.css';
// import {IonApp, IonPage, setupIonicReact} from '@ionic/react';

// setupIonicReact();

export const ENABLED_MODULES = loadModules([PermissionsModule, UsersModule, CustomersModule, ParametersModule, LoansModule, CalculationsModule])
export const ROUTES = ENABLED_MODULES.loadRoutes()

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
	'&.notistack-MuiContent-success': {
		backgroundColor: '#519C66',
		fontWeight: 'bold',
		color: '#ffffff'
	},
	'&.notistack-MuiContent-warning': {
		backgroundColor: '#F4A40B',
		fontWeight: 'bold',
		color: '#ffffff'
	},
	'&.notistack-MuiContent-error': {
		backgroundColor: '#CC5F5F',
		fontWeight: 'bold',
		color: '#ffffff'
	},
	'&.notistack-MuiContent-info': {
		backgroundColor: '#0BC1B6'
	},
}));

const GLOBAL_VARS = {
	device_token: ""
}

const addListeners = async () => {
	if (Capacitor.isNativePlatform()) {
		PushNotifications.removeAllListeners();
		
		await PushNotifications.addListener('registration', token => {
			//console.info('Registration token: ', token.value);
			GLOBAL_VARS.device_token = token.value;
		});
		
		await PushNotifications.addListener('registrationError', err => {
			//console.error('Registration error: ', err.error);
			alert(err.error);
		});
		
		await PushNotifications.addListener('pushNotificationReceived', notification => {
			//console.log('Push notification received: ', notification);
		});
		
		await PushNotifications.addListener('pushNotificationActionPerformed', notification => {
			//console.log('Push notification action performed', notification.actionId, notification.inputValue);
		});
	}
}
addListeners();

// const setStatusBarStyleDark = async () => {
// 	await StatusBar.setStyle({style: Style.Dark})
// }

// setStatusBarStyleDark().then(res => console.log('here', res)).catch(e => console.log('e', e))

// StatusBar.setBackgroundColor('white').then((res) => {
// 	console.log('stebackcolsucces',res)
// }).catch(error => console.log('setback col err',error))

if (Capacitor.isNativePlatform()) {
	PushNotifications.requestPermissions().then(result => {
		if (result.receive === 'granted') {
			// Register with Apple / Google to receive push via APNS/FCM
			PushNotifications.register();
		} else {
			// Show some error
		}
	});
}

function App() {
	return (
		<ThemeCustomization>
			<>
				<SnackbarProvider
					iconVariant={{
						success: <SuccessIcon/>,
						error: <FailureIcon/>,
						warning: <WarningIcon/>,
						info: <InfoIcon/>,
					}}
					maxSnack={8}
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'right',
					}}
					Components={{
						success: StyledMaterialDesignContent,
						error: StyledMaterialDesignContent,
						warning: StyledMaterialDesignContent,
						info: StyledMaterialDesignContent
					}}
					dense={true}
					autoHideDuration={3400}
					preventDuplicate={true}
					action={(snackbarId) => (
						<IconButton
							onClick={() => closeSnackbar(snackbarId)}
							sx={{
								color: '#FFFFFF'
							}}
						>
							<CancelNotiIcon/>
						</IconButton>
					)}
				>
					<ConnectedRouter history={history}>
						<ScrollTop>
							{/* <div className="main-ios-content"> */}
							{/* <IonApp> */}
								<Main/>
							{/* </IonApp> */}
							{/* </div> */}
						</ScrollTop>
					</ConnectedRouter>
				</SnackbarProvider>
			</>
		</ThemeCustomization>
	)
}

export {GLOBAL_VARS};
export default App
