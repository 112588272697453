import React from "react";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend, ResponsiveContainer
} from "recharts";
import {useTranslation} from "react-i18next";
import {CALCULATIONS} from "../../../locales/namespaces";
import './barsChart.css'
import moment from "moment";
import useUserData from "../../../../../hooks/user/useUserData";
import {NumericFormat} from "react-number-format";

const CustomTooltip = ({active, payload, t}) => {
    const {thousandSeparator, decimalSeparator} = useUserData()
    if (active && payload && payload.length) {
        const {sumInterest, repayment, Date} = payload[0].payload;
        
        const interest = Math.max(sumInterest, 0).toFixed(2)
        const repay = Math.max(repayment, 0).toFixed(2)
        return (
            <div className="custom-tooltip">
                <p className="label">
                    {t('Payment date')} <span>{moment(Date, 'YYYY').format('YYYY')}</span></p>
                <div className="tooltip-content">
                    <p className="interest">{t("Interest")}:
                        <span>
                            <NumericFormat
                                thousandSeparator={thousandSeparator}
                                decimalSeparator={decimalSeparator}
                                displayType="text"
                                value={interest ? interest : ''}
                            />
                        </span>
                    </p>
                    <p className="repayment">{t('Total principal repayment')}:
                        <span>
                            <NumericFormat
                                thousandSeparator={thousandSeparator}
                                decimalSeparator={decimalSeparator}
                                displayType="text"
                                value={repay ? repay : ''}
                            />
                        </span>
                    </p>
                </div>
            </div>
        );
    }
    return null;
};

export default function BarsChart({results, FinalTable}) {
    const {t} = useTranslation(CALCULATIONS)
    const rateData = results && FinalTable ? FinalTable.map(item => ({
        name: item.AA,
        sumInterest: item.SumInterest,
        repayment: item.Repayment,
        Date: item.Date
    })) : []
    
    return (
        <ResponsiveContainer width="100%" height={350}>
            <BarChart
                data={rateData}
                margin={{
                    top: 20,
                    right: 30,
                    left: 40,
                    bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="8 8"/>
                <XAxis
                    dataKey="name"
                    tick={{
                        stroke: '#485776',
                        fontSize: '12px',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: 'normal',
                        letterSpacing: '0.12px'
                    }}
                    tickSize={14}
                    tickLine={false}
                    axisLine={false}
                />
                <YAxis
                    tick={{
                        stroke: '#485776',
                    }}
                    tickSize={14}
                    tickLine={false}
                    axisLine={false}
                    tickMargin={5}
                />
                <Tooltip content={<CustomTooltip t={t}/>}/>
                <Legend verticalAlign="bottom" wrapperStyle={{lineHeight: "30px"}} iconType={'circle'} iconSize={8}/>
                <Bar barSize={20} dataKey="sumInterest" stackId={'a'} fill="#485776" name={t('Total Interest')}/>
                <Bar barSize={20} dataKey="repayment" stackId={'a'} fill="#4db6ac"
                     name={t('Total principal repayment')}/>
            </BarChart>
        </ResponsiveContainer>
    );
}
