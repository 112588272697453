// ==============================|| OVERRIDES - DATA GRID ||============================== //

export default function DataGrid(theme) {
    return {
        MuiDataGrid: {
            styleOverrides: {
                cell: {
                    borderBottom: `2px solid ${theme.palette.primary['lighter']}`,
                },
                columnHeaders: {
                    background: '#fff',
                    fontSize: '14px',
                    fontWeight: 400,
                    lineHeight: '22px',
                    columnHeaderTitle: {
                        fontSize: '14px',
                        fontWeight: 400,
                        lineHeight: '22px',
                    }
                },
                root: {
                    '& .MuiDataGrid-columnHeaderTitle':
                        {
                            fontSize: '14px',
                            fontWeight: 400,
                            lineHeight: '22px',
                        },
                    '& .MuiDataGrid-footerContainer': {
                        height: '40px',
                        borderTop: '1px solid #E1E2E9'
                    },
                   /* '& .MuiSvgIcon-root': {
                        color: theme.palette.black[30]
                    },*/
                    '& .MuiDataGrid-booleanCell[data-value="false"]':{
                        color: theme.palette.black[30]
                    },
                    '& .MuiTablePagination-selectLabel': {
                        color: theme.palette.black[20],
                        alignSelf: 'stretch',
                        //marginLeft: 'auto',
                        marginLeft: 0,
                    },
                    /*'& .MuiInputBase-root': {
                        color: theme.palette.black[30],
                        background: 'rgba(94, 99, 102, 0.08)',
                        borderRadius: '8px',

                        display: 'inline-flex',
                        height: '23px',
                        width: '65px',
                        padding: '0px 11px',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                        flexShrink: 0,

                    },*/
                    '& .MuiSelect-select': {
                        display: 'flex',
                        alignItems: 'center',
                    },
                    '& .MuiTablePagination-displayedRows': {
                        color: '#666666',
                        fontSize: '14px',
                        fontWeight: 400,
                        lineHeight: '22px'
                    },
                    /*'& .MuiDataGrid-panelFooter': {
                        background: 'red',
                        color: 'red'
                    },
                    '& .MuiDataGrid-panelWrapper': {
                        color: 'red',
                        background: 'blue',
                        '& .MuiDataGrid-panelFooter': {
                            color: 'red',
                            background: 'blue',
                            '& .MuiButtonBase-root': {
                                color: 'red',
                                background: 'blue',
                            }
                        }
                    },*/

                },
                /*  columnHeader: {
                      borderColor: /!*theme.palette.divider*!/'red'
                  },*/
                /* toolbar: {
                     borderBottom: `2px solid ${theme.palette.primary['lighter']}`,
                     background: '#941717', // Set toolbar background color
                     padding: '8px', // Add padding to the toolbar
                 },
                 toolbarContainer: {
                     display: 'flex',
                     alignItems: 'center',
                     justifyContent: 'space-between',
                     gap: '16px', // Add gap between items in the toolbar
                 },
                 toolbarTitle: {
                     fontSize: '1.2rem', // Customize the font size of the toolbar title
                 },
                 toolbarButton: {
                     // Add styles for toolbar buttons if needed
                 },*/
            },
        },
    };
    /*{
        MuiDataGrid: {
            styleOverrides: {
                cell: {
                    borderBottom: `2px solid ${theme.palette.primary['lighter']}`,
                },
                /!*row: {
                    background: '#F7F7F7'
                },*!/
                row: (params) => {
                    console.log(params.indexRelativeToCurrentPage)
                    return(params.indexRelativeToCurrentPage % 2 === 0 ? 'rgba(57,93,211,0.49)' : '#7c1b1b')
                },
                columnHeaders: {
                    background: '#fff'
                },
                columnHeader: {
                    borderColor: theme.palette.divider
                },
                /!*columnHeaderTitle: {
                    fontSize: '14px',
                    //fontFamily: 'Mona',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '22px'
                },*!/
            },
        },
    };*/

}

/*export default function DataGrid(theme) {
    console.log(theme.palette)
    return {
        MuiDataGrid: {
            styleOverrides: {
                cell: {
                    borderBottom: `2px solid ${theme.palette.primary['lighter']}`,
                },
                row: (props) => ({
                    background: props.index % 2 === 0 ? '#ffffff' : '#F7F7F7',
                }),
                columnHeaders: {
                    background: '#fff'
                },
                columnHeader: {
                    borderColor: theme.palette.divider
                },
            },
        },
    };
}*/
