import * as React from "react";
const ArrowDown2 = (props) => (
    <svg
        width={16}
        height={10}
        viewBox="0 0 16 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="m15 1.5-7 7-7-7"
            stroke="#485776"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
export default ArrowDown2;