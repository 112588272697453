// ==============================|| CUSTOM FUNCTION - COLORS ||============================== //

const getColors = (theme, color) => {
    // console.log('theme.palette.secondary',theme.palette.secondary)
    // console.log('theme.palette.black', theme.palette.black)
    // console.log('theme.palette.actions', theme.palette.actions)
    // console.log('theme.palette.primary',theme.palette.primary)
    // console.log('theme.palette.primary!!',theme.palette.primary[100])
    //console.log('theme.palette.error', theme.palette.error)
    // console.log('theme.palette.warning',theme.palette.warning)
    // console.log('theme.palette.info',theme.palette.info)
    // console.log('theme.palette.success',theme.palette.success)
    // console.log('theme.palette.primary',theme.palette.primary)
    switch (color) {
        case 'secondary':
            return theme.palette.secondary;
        case 'error':
            return theme.palette.error;
        case 'warning':
            return theme.palette.warning;
        case 'info':
            return theme.palette.info;
        case 'success':
            return theme.palette.success;
        case 'black':
            return theme.palette.black;
        case 'blue':
            return theme.palette.blue;
        case 'actions':
            return theme.palette.actions;
        default:
            return theme.palette.primary;
    }
};

export default getColors;
