import React from "react";
import PermissionHOC from "../../../permission/components/permissionHOC/permissionHOC";
import {getRouteUrl} from "../../../../helpers/getRouteUrl";
import {ROUTE_PAGE_CUSTOMERS_EDIT} from "../../routes/routes";
import {useTranslation} from "react-i18next";
import {CUSTOMERS} from "../../locales/namespaces";
import useUserData from "../../../../hooks/user/useUserData";
import InfoIcon from "../../../../assets/images/icons/snackBar/infoIcon";
import {Typography, Stack, Link, Alert, AlertTitle} from "@mui/material";

const AlertMessageForSimpleUser = () => {
	const {t} = useTranslation(CUSTOMERS)
	const {customerId} = useUserData()
	
	return (
		<PermissionHOC requiredPermissions={['view_msg_access_lead_create']}>
				<Alert
					severity="info"
					sx={{
						p: '3.666 3.668 3.668 3.666',
						background: '#0BC1B6',
						borderRadius: '12px'
					}}
				>
					<AlertTitle
						color={'#fff'}
						sx={{
							display: 'flex',
							padding: '3.666px 3.668px 3.668px 3.666px',
							alignItems: 'center',
							flexShrink: 0
						}}
					>
						<InfoIcon/>{t("Info")}
					</AlertTitle>
					<Stack
						direction={{xs: 'column', sm: 'column', md: 'row', lg: 'row', xl: 'row'}}
						spacing={1}
					>
						<Typography color={'#fff'}>
							{t("Submitting a home loan request requires completing your profile information.")}-
						</Typography>
						<strong>
							<PermissionHOC requiredPermissions={['access_customer_edit']}>
								<Link
									size={"small"}
									color={'#485776'}
									variant={"contained"}
									underline="hover"
									href={getRouteUrl(ROUTE_PAGE_CUSTOMERS_EDIT, {id: customerId})}
								>
									{t('Edit your information!')}
								</Link>
							</PermissionHOC>
						</strong>
					</Stack>
				</Alert>
		</PermissionHOC>
	)
}

export default AlertMessageForSimpleUser