// @flow
import React, {useMemo, useState} from 'react';
import {useTranslation} from "react-i18next";
import {PARAMETERS} from "../../locales/namespaces";
import useDocsAssociations from "../../hooks/useDocsAssociations";
import DataGridWrapper from "../../../../components/dataGridWrapper/dataGridWrapper";
import EditableMUIDataGrid from "../../../../components/editableMUIDataGrid/editableMUIDataGrid";
import useLoanStatus from "../../hooks/useLoanStatus";
import {withPermissionHOC} from "../../../permission/components/permissionHOC/permissionHOC";
import useDocuments from "../../hooks/useDocuments";
import {textTranslate} from "../../../../helpers/helpers";
import useUserData from "../../../../hooks/user/useUserData";
import {Typography} from "@mui/material";
import MainCard from "../../../../theme/mantis/components/mainCard";
import ContentLoader from "../../../../components/contentLoader/contentLoader";
/*
import {Select} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
*/

type Props = {
	circularLoader?: boolean
}

const DocsAssociations = (props: Props) => {
	const {t} = useTranslation(PARAMETERS)
	const {language} = useUserData()
	const {
		loading,
		docsAssociations,
		store,
		update,
		remove
	} = useDocsAssociations()
	
	const {circularLoader} = props
	const [updateDocuments, setUpdateDocuments] = useState(false)
	const handleUpdateDocuments = (updateDocuments) => setUpdateDocuments(updateDocuments)
	
	const {loanStatus} = useLoanStatus()
	const {documents} = useDocuments({updateDocuments, handleUpdateDocuments})
	
	
	const newRowModel = {
		isDomestic: true,
		isRequired: true,
		Description: '',
		DescriptionEn: '',
		StatusId: '',
		DocTypeId: ''
	}
	
	const columns = useMemo(() => [
		{
			field: 'isDomestic',
			headerName: t('Resident of Greece'),
			minWidth: 160,
			flex: 1,
			maxWidth: 160,
			editable: true,
			type: 'boolean',
			valueGetter: ({value}) => value === '1'
		},
		{
			field: 'isRequired',
			headerName: t('Required'),
			minWidth: 150,
			flex: 1,
			maxWidth: 150,
			editable: true,
			type: 'boolean',
			valueGetter: ({value}) => value === '1'
		},
		/*{
			field: 'Description',
			headerName: t('Documents'),
			minWidth: 160,
			flex: 1,
			editable: true,
		},*/
		{
			field: 'DocTypeId',
			headerName: t('Documents'),
			minWidth: 200,
			flex: 1,
			editable: true,
			preProcessEditCellProps: (params) => {
				const hasError = !params.props.value.length
				return {...params.props, error: hasError}
			},
			type: 'singleSelect',
			valueOptions: documents.map(documents => ({
				value: documents.id,
				label: textTranslate(language)({
					en: documents.Description && documents.Description.slice(0, 100),
					el: documents.Description && documents.Description.slice(0, 100)
				})
				//documents.Description.slice(0, 100),
			})),
			/* renderEditCell: (params) => (
				 <Select
					 //native
					 sx={{minWidth: 200}}
					 //value={params.value}
					 //SelectProps={{ style: { minWidth: 200 } }}
					 // other props...
				 >
					 {/!*{documents.map((document) => (<option key={document.id} value={document.id}>{document.Description}</option>))}*!/}
					 {documents.map(document => (
						 <MenuItem sx={{maxWidth: 100}} key={`document-${document.id}`} value={document.id}>{document.Description}</MenuItem>))}
				 </Select>
			 ),
			 renderCell: ({row}) => (row.Description)*/
		},
		{
			field: 'StatusId',
			headerName: t('Loan Status'),
			minWidth: 160,
			flex: 1,
			editable: true,
			preProcessEditCellProps: (params) => {
				const hasError = !params.props.value.length
				return {...params.props, error: hasError}
			},
			type: 'singleSelect',
			valueOptions: loanStatus.map(loanStatus => ({
				value: loanStatus.id,
				label: textTranslate(language)({en: loanStatus.DescriptionEn, el: loanStatus.DescriptionEl}),
			})),
		},
	], [docsAssociations, loanStatus, t, language, documents])
	
	return !loading ? (
		<MainCard
			title={
				<Typography variant="h5" gutterBottom>
					{t('Documents Associations')}
				</Typography>
			}
			sx={{mt: 2}}
		>
			<DataGridWrapper
				sx={{
					mt: 1,
					maxWidth: '850px',
				}}
			>
				<EditableMUIDataGrid
					loading={loading}
					data={docsAssociations}
					columns={columns}
					newRowModel={newRowModel}
					store={store}
					update={update}
					remove={remove}
				/>
			</DataGridWrapper>
		</MainCard>
	) : (
		<ContentLoader
			loading={loading}
			useCircularLoader={circularLoader}
		/>
	)
}

export default withPermissionHOC(DocsAssociations, ['access_parameters'])