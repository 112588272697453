import * as React from 'react';
import {
	Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
	Box, Grid, Divider
} from '@mui/material';
import {Trans, useTranslation} from "react-i18next";
import {CUSTOMERS} from "../../locales/namespaces";
import axios from "axios";
import {API} from "../../../../helpers/constants";
import {enqueueSnackbar} from "notistack";
import {handleAxiosErrors, logoutUnauthorizedUser} from "../../../../helpers/errorHandling";
import useUserData from "../../../../hooks/user/useUserData";
import {InfoCircleOutlined} from "@ant-design/icons";
import useUserAction from "../../../../hooks/user/useUserAction";
import {useHasPermission} from "../../../permission/hooks/useHasPermission";
import {getRouteUrl} from "../../../../helpers/getRouteUrl";
import {useHistory} from "react-router-dom";
import {ROUTE_PAGE_CUSTOMERS_INDEX} from "../../routes/routes";
import DangerTriangleIcon from "../../../../assets/images/dangerTriangleIcon";

type Props = {
	id: string,
	handleClose: Function,
	open: Array
}

export default function DeleteAccountDialog(props: Props) {
	const {t} = useTranslation(CUSTOMERS)
	const {userLogout} = useUserAction()
	const history = useHistory()
	
	const {token, isLoggedIn} = useUserData()
	const {
		id,
		handleClose,
		open
	} = props
	
	const isCustomer = useHasPermission(['logout_after_delete_cust'], {shouldView: true})
	
	const handleDelete = () => {
		axios({
			method: 'post',
			headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
			url: `${API}/customer/remove`,
			data: {
				id: id
			}
		}).then(response => {
			enqueueSnackbar(t('Your account deleted successfully!'), {
					variant: 'success'
				}
			)
			handleClose()
			if (isCustomer) {
				userLogout()
			} else {
				history.push(getRouteUrl(ROUTE_PAGE_CUSTOMERS_INDEX))
			}
		}).catch(error => {
			enqueueSnackbar(handleAxiosErrors(error, t('The account cannot be deleted because there is a registered loan.')), {
					variant: 'error'
				}
			)
			handleClose()
			logoutUnauthorizedUser(error, isLoggedIn, userLogout)
		})
	}
	
	return (
		<Box>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<div style={{
					display: 'flex',
					padding: '5px 3.652px 4.751px 3.667px',
					justifyContent: 'center',
					alignItems: 'center',
					flexShrink: 0,
					paddingTop: '18px'
				}}>
					<DangerTriangleIcon/>
				</div>
				{isCustomer ?
					<Grid
						sx={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							paddingLeft: '24px',
							paddingRight: '24px'
						}}
					>
						<DialogTitle sx={{textAlign: 'center', fontSize: '20px'}} id="alert-dialog-title">
							<Trans
								i18nKey={'delete_account'}
								ns={CUSTOMERS}
							>
								Are you sure you want to DELETE your account?<br/>
							</Trans>
						</DialogTitle>
						<Divider/>
						<DialogContent sx={{textAlign: 'center', fontSize: '14px'}}>
							<DialogContentText id="alert-dialog-description">
								<Trans
									i18nKey={'info_delete'}
									ns={CUSTOMERS}
								>
									If you press confirm all your saved data and account will be
									deleted.<br/>
								</Trans>
							</DialogContentText>
						</DialogContent>
					</Grid> :
					<Grid
						sx={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							paddingLeft: '24px',
							paddingRight: '24px'
						}}
					>
						<DialogTitle sx={{textAlign: 'center', fontSize: '20px'}} id="alert-dialog-title">
							<Trans
								i18nKey={'delete_account_for_admin'}
								ns={CUSTOMERS}
							>
								Are you sure you want to DELETE this account?<br/>
							</Trans>
						</DialogTitle>
						<DialogContent sx={{textAlign: 'center', fontSize: '14px'}}>
							<DialogContentText id="alert-dialog-description">
								<InfoCircleOutlined/>
								<Trans
									i18nKey={'info_delete_for_admin'}
									ns={CUSTOMERS}
								>
									If you press confirm all the saved data and account for this customer will be
									deleted.<br/>
								</Trans>
							</DialogContentText>
						</DialogContent>
					</Grid>
				}
				<DialogActions
					sx={{
						justifyContent: 'center',
						mb: '31px'
					}}
				>
					<Button
						size={"small"}
						variant={"contained"}
						color={'error'}
						component={Button}
						onClick={handleDelete}
						autoFocus
					>
						{t('Confirm')}
					</Button>
					<Button
						variant={'outlined'}
						color={'blue'}
						size={"small"}
						onClick={handleClose}
					>
						{t('Cancel')}
					</Button>
				</DialogActions>
			</Dialog>
		</Box>
	);
}