// @flow
import * as React from 'react';
import {ErrorMessage} from "formik";
import {
	Checkbox,
	Grid,
	InputLabel,
	Select,
	Stack,
	FormControl,
	FormHelperText,
	TextField,
	MenuItem,
	Autocomplete,
	useTheme
} from "@mui/material";
import {textTranslate} from "../../../../helpers/helpers";
import useUserData from "../../../../hooks/user/useUserData";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {NumericFormat} from "react-number-format";
import CalendarIcon from "../../../../assets/images/icons/kpisIcons/calendarIcon";
import ArrowDown2 from "../../../../assets/images/icons/arrowDown2";
import {Capacitor} from "@capacitor/core";

type Props = {
	id: string,
	loan?: Object,
}

const QuestionnaireForm = (props: Props) => {
	const {language, decimalSeparator, thousandSeparator} = useUserData()
	const theme = useTheme();
	
	const {
		id,
		formik,
		choices
	} = props
	
	return (
		<Grid container spacing={1}>
			
			{choices.map((choice,index) => {
				const {id, name, inputType, label, value, dropdownOptions, required} = choice
				switch (inputType) {
					case 'year':
						return (
							<Grid item xs={12} key={id}>
								<Stack spacing={1}>
									<InputLabel
										htmlFor="name"
										//variant={'h6'}
										sx={{
											color: theme.palette.primary[100]
										}}
									>{label}</InputLabel>
									<FormControl
										key={`${id}-${name}`}
										variant="standard"
										fullWidth
										error={
											formik.touched[name] &&
											Boolean(formik.errors[name])
										}
										sx={{mb: 2}}
									>
										<LocalizationProvider dateAdapter={AdapterMoment}>
											<DatePicker
												views={['year']}
												value={formik.values[name]}
												onChange={(newValue) => formik.setFieldValue([name], newValue)}
												slots={{
													openPickerIcon: CalendarIcon
												}}
												slotProps={{textField: {variant: 'outlined'}}}
												sx={{
													'& .MuiButtonBase-root': {
														color: theme.palette.primary[100],
														background: theme.palette.primary.main,
													},
													'& .MuiSvgIcon-root': {
														order: -1,
														marginRight: theme => theme.spacing(1),
													},
													'& .MuiInputBase-root': {
														flexDirection: 'row-reverse',
													},
												}}
												size={'small'}
											/>
										</LocalizationProvider>
										<FormHelperText>
											<ErrorMessage name={name}/>
										</FormHelperText>
									</FormControl>
								</Stack>
							</Grid>
						)
					case 'number':
						return (
							<Grid item xs={12} key={id}>
								<Stack spacing={1}>
									<InputLabel
										htmlFor="name"
										//variant={'h6'}
										sx={{
											color: theme.palette.primary[100]
										}}
									>{label}</InputLabel>
									<FormControl
										key={`${id}-${name}`}
										variant="standard"
										fullWidth
										error={
											formik.touched[name] &&
											Boolean(formik.errors[name])
										}
										sx={{mb: 2}}
									>
										{/*<TextField
                                            name={name}
                                            onChange={formik.handleChange}
                                            value={formik.values[name]}
                                            type={'number'}
                                            onBlur={formik.handleBlur}
                                            fullWidth
                                            color={"grey"}
                                            size={'small'}
                                        />*/}
										<NumericFormat
											value={formik.values[name]}
											onValueChange={(values, sourceInfo) => {
												formik.setFieldValue([name], values.floatValue ? values.floatValue : '')
											}}
											thousandSeparator={thousandSeparator}
											decimalSeparator={decimalSeparator}
											decimalScale={2}
											customInput={TextField}
											
											onBlur={formik.handleBlur}
											name={name}
											color={"grey"}
											size={'small'}
											fullWidth
										/>
										<FormHelperText>
											<ErrorMessage name={name}/>
										</FormHelperText>
									</FormControl>
								
								</Stack>
							</Grid>
						)
					case 'select':
						return (
							<Grid item xs={12} key={id}>
								<Stack spacing={1}>
									<InputLabel
										id={`${label}-select-label`}
										//variant={'h6'}
										sx={{
											color: theme.palette.primary[100]
										}}
									>{label}</InputLabel>
									<FormControl
										key={`${id}-${name}`}
										variant="outlined"
										fullWidth
										error={
											formik.touched[name] &&
											Boolean(formik.errors[name])
										}
										sx={{mb: 2}}
									>
										<Select
											native={Capacitor.getPlatform() === 'android'}
											labelId={`${label}-select-label`}
											autoComplete={"chrome-off"}
											size={'small'}
											name={name}
											value={formik.values[name]}
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											IconComponent={ArrowDown2}
										>
											{Capacitor.getPlatform() === 'android' ?
												<>
													<option value={''}></option>
													{dropdownOptions.map((option) => (
														<option key={option.OptionId} value={option.OptionId}>
															{textTranslate(language)({
																en: option.DescriptionEn,
																el: option.DescriptionEl
															})}
														</option>
													))}
												</> :
												dropdownOptions.map((option) => (
													<MenuItem key={option.OptionId} value={option.OptionId}>
														{textTranslate(language)({
															en: option.DescriptionEn,
															el: option.DescriptionEl
														})}
													</MenuItem>
												))
											}
										</Select>
										<FormHelperText>
											<ErrorMessage name={name}/>
										</FormHelperText>
									</FormControl>
								</Stack>
							</Grid>
						)
					case 'autocomplete':
						return (
							<Grid item xs={12} key={id}>
								<Stack spacing={1}>
									<Autocomplete
										multiple
										popupIcon={<ArrowDown2/>}
										options={dropdownOptions}
										disableCloseOnSelect
										isOptionEqualToValue={(opt, val) => {
											return opt.OptionId === val.OptionId || !val
										}}
										value={formik.values[name]}
										onChange={(event, newValue, reason) => {
											formik.setFieldValue([name], newValue)
										}}
										getOptionLabel={option => textTranslate(language)({
											en: option.DescriptionEn,
											el: option.DescriptionEl
										})}
										renderOption={(props, option, {selected}) => (
											<li {...props}>
												<Checkbox
													// icon={<CheckBoxOutlineBlankIcon fontSize="small"/>}
													// checkedIcon={<CheckBoxIcon fontSize="small"/>}
													style={{marginRight: 8}}
													checked={selected}
												/>
												{textTranslate(language)({
													en: option.DescriptionEn,
													el: option.DescriptionEl
												})}
											</li>
										)}
										renderInput={(params) => (
											<>
												<InputLabel
													htmlFor="name"
													//variant={'h6'}
													sx={{
														color: theme.palette.primary[100]
													}}
												>{label}</InputLabel>
												<FormControl
													key={`${id}-${name}`}
													variant="standard"
													fullWidth
													error={
														formik.touched[name] &&
														Boolean(formik.errors[name])
													}
													sx={{mb: 2}}
												>
													<TextField {...params} />
													<FormHelperText>
														<ErrorMessage name={name}/>
													</FormHelperText>
												</FormControl>
											</>
										)}
										size={'small'}
									/>
								</Stack>
							</Grid>
						)
					default:
						return (
							<Grid item xs={12} key={id}>
								<Stack spacing={1}>
									<InputLabel
										htmlFor="name"
										//variant={'h6'}
										sx={{
											color: theme.palette.primary[100]
										}}
									>{label}</InputLabel>
									<FormControl
										key={`${id}-${name}`}
										variant="standard"
										fullWidth
										error={
											formik.touched[name] &&
											Boolean(formik.errors[name])
										}
										sx={{mb: 2}}
									>
										<TextField
											multiline
											name={name}
											onChange={formik.handleChange}
											value={formik.values[name]}
											onBlur={formik.handleBlur}
											fullWidth
											color={"grey"}
											inputProps={{maxLength: 150}}
											size={'small'}
										/>
										<FormHelperText>
											<ErrorMessage name={name}/>
										</FormHelperText>
									</FormControl>
								</Stack>
							</Grid>
						)
				}
			})}
		
		</Grid>
	)
}
export default QuestionnaireForm
