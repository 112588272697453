// @flow
import React, {useMemo} from 'react';
import {Box, Typography} from "@mui/material";
import {useMuiGridServerSide} from "../../../../hooks/useMuiGridServerSide";
import {useTranslation} from "react-i18next";
import {PARAMETERS} from "../../locales/namespaces";
import DataGridWrapper from "../../../../components/dataGridWrapper/dataGridWrapper";
import {withPermissionHOC} from "../../../permission/components/permissionHOC/permissionHOC";
import EditableMUIDataGrid from "../../../../components/editableMUIDataGrid/editableMUIDataGrid";
import useDocumentsTypes from "../../hooks/useDocumentsTypes";
import MainCard from "../../../../theme/mantis/components/mainCard";
import EditableMUICellValidation from "../../../../components/editableMUICellValidation/editableMUICellValidation";
import {GridEditInputCell} from "@mui/x-data-grid-premium";
import ContentLoader from "../../../../components/contentLoader/contentLoader";


const DocumentsTypes = () => {
	const {t} = useTranslation(PARAMETERS)
	const url = `document/types/list`
	
	const {
		isLoading,
		data,
		total,
		page,
		pageSize,
		onPaginationModelChange,
		onSortModelChange,
		onFilterModelChange,
		refreshGrid
	} = useMuiGridServerSide({
		url,
		initialPageSize: 50,
		initialSortModel: [{name: 'id', direction: 'descending'}]
	})
	
	const {
		store,
		remove,
		update
	} = useDocumentsTypes({
		data,
		refreshGrid
	})
	
	const newRowModel = {
		Description: '',
		sortDescription: '',
		sortDescriptionEn: '',
		DescriptionEn: ''
	}
	
	const columns = useMemo(() => [
		{
			field: 'sortDescription',
			headerName: t('Sort Description EL'),
			width: 100, flex: 1,
			editable: true,
			preProcessEditCellProps: (params) => {
				const inputValue = params.props.value;
				if (inputValue === '') {
					return {...params.props, error: t('Required')};
				}
				return {...params.props, error: null}; // No error
			},
			renderEditCell: (params) => {
				return <EditableMUICellValidation {...params}>
					<GridEditInputCell {...params} />
				</EditableMUICellValidation>
			},
		},
		{
			field: 'sortDescriptionEn',
			headerName: t('Sort Description EN'),
			width: 100, flex: 1,
			editable: true,
			preProcessEditCellProps: (params) => {
				const inputValue = params.props.value;
				if (inputValue === '') {
					return {...params.props, error: t('Required')};
				}
				return {...params.props, error: null}; // No error
			},
			renderEditCell: (params) => {
				return <EditableMUICellValidation {...params}>
					<GridEditInputCell {...params} />
				</EditableMUICellValidation>
			},
		},
		{
			field: 'Description',
			headerName: t('Description EL'),
			flex: 1,
			editable: true,
			preProcessEditCellProps: (params) => {
				const inputValue = params.props.value;
				if (inputValue === '') {
					return {...params.props, error: t('Required')};
				}
				return {...params.props, error: null}; // No error
			},
			renderEditCell: (params) => {
				return <EditableMUICellValidation {...params}>
					<GridEditInputCell {...params} />
				</EditableMUICellValidation>
			},
		},
		{
			field: 'DescriptionEn',
			headerName: t('Description EN'),
			flex: 1,
			editable: true,
			preProcessEditCellProps: (params) => {
				const inputValue = params.props.value;
				if (inputValue === '') {
					return {...params.props, error: t('Required')};
				}
				return {...params.props, error: null}; // No error
			},
			renderEditCell: (params) => {
				return <EditableMUICellValidation {...params}>
					<GridEditInputCell {...params} />
				</EditableMUICellValidation>
			},
		}
	], [refreshGrid, data, t])
	
	return (
		<MainCard
			title={
				<Typography variant="h5" gutterBottom>
					{t('Documents Types')}
				</Typography>
			}
			sx={{mt: 2}}
		>
			<DataGridWrapper>
				<EditableMUIDataGrid
					data={data}
					columns={columns}
					loading={isLoading}
					newRowModel={newRowModel}
					store={store}
					remove={remove}
					update={update}
					dataGridProOtherProps={{
						pagination: true,
						paginationModel: {page, pageSize},
						paginationMode: 'server',
						onPaginationModelChange: onPaginationModelChange,
						rowCount: Number(total),
						rowsPerPageOptions: [5, 10, 15, 20, 50, 100],
						sortingMode: "server",
						onSortModelChange: (sortModel) => onSortModelChange(sortModel),
						filterMode: 'server',
						onFilterModelChange: onFilterModelChange,
						disableAggregation: true,
						disableRowGrouping: true,
					}}
				/>
			</DataGridWrapper>
		</MainCard>
	)
}

export default withPermissionHOC(DocumentsTypes, ['access_parameters'])