import {toCamelCase} from "../../../helpers/helpers";

export const mapLoan = (loan) => {
    if (!loan) return
    return {
        ...loan,
        StatusEn: loan.StatusEn /*&& toCamelCase(loan.StatusEn)*/,
        StatusEl: loan.StatusEl /*&& toCamelCase(loan.StatusEl)*/
    }
}
