import moment from "moment";
import {formatDate} from "../../../helpers/date";

export const getChoiceName = (choiceId) => {
    let name = ''
    switch (choiceId) {
        case '35':
            name = 'estateAmount'
            break;
        case '36':
            name = 'bedrooms'
            break;
        case '37':
            name = 'parking'
            break;
        case '38':
            name = 'floor'
            break;
        case '39':
            name = 'warehouse'
            break;
        case '40':
            name = 'estateType'
            break;
        case '41':
            name = 'region'
            break;
        case '42':
            name = 'constructionYear'
            break;
        case '43':
            name = 'squareMeters'
            break;
        case '44':
            name = 'questionnaireComments'
            break;
        case '45':
            name = 'suburbs'
            break;
        default:
            break;
    }
    return name
}

export const getInputType = (typeId) => {
    let inputType = ''
    switch (typeId) {
        case '1':
            inputType = 'text'
            break;
        case '2':
            inputType = 'year'
            break;
        case '3':
            inputType = 'number'
            break;
        case '4':
            inputType = 'select'
            break;
        case '5':
            inputType = 'autocomplete'
            break;
        default:
            inputType = 'text'
            break;
    }
    return inputType
}

export const getValue = (inputType, value, dropdownOptions) => {
    let inputValue = value
    switch (inputType) {
        case 'year':
            inputValue = value ? moment(value, 'YYYY') : moment()
            break;
        case 'number':
            inputValue = value ? value : 0
            break;
        case 'select':
            inputValue = value ? value : ''
            break;
        case 'autocomplete':
            inputValue = value ? dropdownOptions.filter(f => value.split(',').includes(f.OptionId)) : []
            break;
        default:
            inputValue = value ? value : ''
            break;
    }
    return inputValue
}

export const parseChoiceValue = (name, value) => {
    let parsedValue = value
    switch (name) {
        case 'constructionYear':
            parsedValue = value === null ? '' : formatDate(value, formatDate.formatTypes.DATE_ONLY_FILTER)
            break;
        case 'bedrooms':
        case 'floor':
        case 'suburbs':
        case 'estateType':
            parsedValue = value.length > 0 ? value.map(selection => selection.OptionId).join(',') : ''
            break;
        default:
            break;
    }
    return parsedValue
}