import React, {useContext, useEffect, useMemo, useState} from 'react'
import axios from "axios";
import {useTranslation} from "react-i18next";
import {QuestionnaireContext} from "./questionnaires";
import {PARAMETERS} from "../../locales/namespaces";
import {API} from "../../../../helpers/constants";
import EditableMUIDataGrid from "../../../../components/editableMUIDataGrid/editableMUIDataGrid";
import {CustomPagination} from "../../../../components/dataGridCustom/customPagination";
import DataGridWrapper from "../../../../components/dataGridWrapper/dataGridWrapper";
import useUserData from "../../../../hooks/user/useUserData";
import {enqueueSnackbar} from "notistack";
import {handleAxiosErrors, logoutUnauthorizedUser} from "../../../../helpers/errorHandling";
import useUserAction from "../../../../hooks/user/useUserAction";
import EditableMUICellValidation from "../../../../components/editableMUICellValidation/editableMUICellValidation";
import {GridEditInputCell} from "@mui/x-data-grid-premium";

const Questions = () => {
	const {
		data,
		setData,
		questionRowSelection,
		setQuestionRowSelection,
		setQuestionId,
		setDataChoices,
		updateQuestion,
		refreshQuestion,
		refreshChoice,
		setChoiceRowSelection,
		setChoiceId
	} = useContext(QuestionnaireContext);
	
	const {t} = useTranslation(PARAMETERS);
	const {token, isLoggedIn} = useUserData()
	const {userLogout} = useUserAction()
	
	const [selectionModel, setSelectionModel] = useState([]);
	const [previousSelection, setPreviousSelection] = useState([]);
	const [loading, setLoading] = useState(false)
	
	const newRowModel = {
		Description: ''
	}
	
	useEffect(() => {
		axios({
			method: 'get',
			headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
			url: `${API}/parameters/questionnaire/list`,
		}).then((res) => {
			const data = res.data
			setData(data)
		}).catch((error) => {
			setLoading(false)
			enqueueSnackbar(handleAxiosErrors(error, t('Failed to get question list.')), {
					variant: 'error'
				}
			)
			logoutUnauthorizedUser(error, isLoggedIn, userLogout)
		});
	}, [setData, t, refreshQuestion]);
	
	const getChoicesData = (selection) => {
		setQuestionRowSelection(selection); // Set the clicked row as the selected row
		axios({
			method: 'get',
			headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
			url: `${API}/parameters/options/list/${selection}`,
			
		}).then((res) => {
			const data = res.data;
			setDataChoices(data);
			setQuestionId(data)
		}).catch((error) => {
			setLoading(false)
			enqueueSnackbar(handleAxiosErrors(error, t('Failed to get choice list.')), {
					variant: 'error'
				}
			)
			logoutUnauthorizedUser(error, isLoggedIn, userLogout)
		});
	}
	
	useEffect(() => {
		if (questionRowSelection) {
			getChoicesData(questionRowSelection)
		}
	}, [questionRowSelection, refreshChoice])
	
	const columns = useMemo(() => [
		{
			field: 'DescriptionEl',
			headerName: t('Description EL'),
			minWidth: 160,
			flex: 1,
			editable: true,
			preProcessEditCellProps: (params) => {
				const inputValue = params.props.value;
				if (inputValue === '') {
					return {...params.props, error: t('Required')};
				}
				return {...params.props, error: null}; // No error
			},
			renderEditCell: (params) => {
				return <EditableMUICellValidation {...params}>
					<GridEditInputCell {...params} />
				</EditableMUICellValidation>
			},
		},
		{
			field: 'DescriptionEn',
			headerName: t('Description EN'),
			minWidth: 160,
			flex: 1,
			editable: true,
			preProcessEditCellProps: (params) => {
				const inputValue = params.props.value;
				if (inputValue === '') {
					return {...params.props, error: t('Required')};
				}
				return {...params.props, error: null}; // No error
			},
			renderEditCell: (params) => {
				return <EditableMUICellValidation {...params}>
					<GridEditInputCell {...params} />
				</EditableMUICellValidation>
			},
		},
	], [t])
	
	const handleSelectionModelChange = (newSelection) => {
		const newSelectionSet = newSelection.filter((element) => !previousSelection.includes(element));
		setSelectionModel(newSelectionSet)
		setPreviousSelection(newSelectionSet)
		if (newSelectionSet[0] === newSelectionSet[1]) {
			setDataChoices([]);
			setQuestionRowSelection(null); // Reset selectedRowId
			setChoiceRowSelection(null); // Reset selectedRowId
			setChoiceId(null)
		} else {
			setQuestionRowSelection(newSelectionSet[0])
		}
	};
	
	return (
		
		<DataGridWrapper
			autoHeight
			sx={{
				p: 1,
				maxWidth: '600px'
			}}
		>
			<EditableMUIDataGrid
				sx={{maxHeight: 600}}
				loading={loading}
				data={data}
				columns={columns}
				newRowModel={newRowModel}
				update={updateQuestion}
				hideDeleteButton={true}
				dataGridProOtherProps={{
					onRowSelectionModelChange: handleSelectionModelChange,
					rowSelectionModel: selectionModel,
					disableColumnMenu: true,
					slots: {
						toolbar: false,
						pagination: CustomPagination,
					}
				}}
			/>
		</DataGridWrapper>
	)
}

export default Questions;
