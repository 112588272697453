import {styled} from "@mui/material/styles";
import Tooltip, {tooltipClasses} from "@mui/material/Tooltip";
import React from "react";


const StyledTooltip = styled(({className, ...props}) => (
	<Tooltip {...props} classes={{popper: className}}>{props.children}</Tooltip>
))(({theme}) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: theme.palette.error.main,
		color: theme.palette.error.contrastText,
	},
}));

function EditableMUICellValidation(props) {
	const {error} = props;
	
	return (
		<StyledTooltip open={!!error} title={error}>
			{props.children}
		</StyledTooltip>
	);
}

export default EditableMUICellValidation;