import {useEffect, useState} from "react";
import axios from "axios";
import {API} from "../../../helpers/constants";
import useUserData from "../../../hooks/user/useUserData";
import {handleAxiosErrors, logoutUnauthorizedUser} from "../../../helpers/errorHandling";
import {useTranslation} from "react-i18next";
import {ALERTS} from "../../../locales/components/namespaces";
import {enqueueSnackbar} from "notistack";
import useUserAction from "../../../hooks/user/useUserAction";

const useUserRoles = (id) => {
	const {token, isLoggedIn} = useUserData()
	const {userLogout} = useUserAction()
	const {t} = useTranslation(ALERTS)
	const [loading, setLoading] = useState(false)
	const [userRoles, setUserRoles] = useState([])
	const [updateUserRole, setUpdateUserRole] = useState(false)
	
	useEffect(() => {
		setLoading(true)
		axios({
			method: 'get',
			headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
			url: `${API}/role/user/list?UserId=${id}`,
		}).then((response) => {
			const userRoles = response.data
			setUserRoles(userRoles)
			setLoading(false)
		}).catch(error => {
			setLoading(false)
			enqueueSnackbar(handleAxiosErrors(error, t('Failed to get roles list.')), {
					variant: 'error'
				}
			)
			logoutUnauthorizedUser(error, isLoggedIn, userLogout)
		})
	}, [updateUserRole])
	
	const store = (newRow) => {
		axios({
			method: 'post',
			headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
			url: `${API}/role/user/store`,
			data: {
				PortalRoleId: newRow.PortalRoleId,
				UserId: id
			}
		}).then(response => {
			setUpdateUserRole(!updateUserRole)
			enqueueSnackbar(t('Success Store'), {
					variant: 'success'
				}
			)
			setLoading(false)
		}).catch(error => {
			enqueueSnackbar(handleAxiosErrors(error, t('Failed to store role!')), {
					variant: 'error'
				}
			)
			setUpdateUserRole(!updateUserRole)
			setLoading(false)
			logoutUnauthorizedUser(error, isLoggedIn, userLogout)
		})
	}
	
	const remove = (id) => {
		axios({
			method: 'post',
			headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
			url: `${API}/role/user/remove`,
			data: {
				id: id
			}
		}).then(response => {
			setUpdateUserRole(!updateUserRole)
			enqueueSnackbar(t('Success Store'), {
					variant: 'success'
				}
			)
			
		}).catch(error => {
			enqueueSnackbar(handleAxiosErrors(error, t('Failed to delete role!'), {
						variant: 'error'
					}
				)
			)
			logoutUnauthorizedUser(error, isLoggedIn, userLogout)
		})
	}
	
	return {
		loading,
		userRoles,
		store,
		remove,
	}
}

export default useUserRoles