import * as React from "react";
const Polygon3Icon = (props) => (
    <svg
        width={19}
        height={21}
        viewBox="0 0 17 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
            background: 'rgba(244,245,250,0.05)'
            //flexShrink: 0,
            //position: 'relative'
        }}
        {...props}
    >
        <path
            d="M0 2.918C0 1.088 2.255.22 3.483 1.577l12.493 13.808c1.164 1.286.251 3.342-1.483 3.342H2a2 2 0 0 1-2-2V2.918Z"
            fill="#485776"
        />
    </svg>
);
export default Polygon3Icon;
