import {styled} from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import checkIcon from "../../../../assets/images/check.png";
import switchX from "../../../../assets/images/switchX.png";

const StyleSwitch = styled(Switch)(({theme}) => ({
    padding: 7,
    '& .MuiSwitch-switchBase': {

        margin: 1,
        padding: 0,
        transform: 'translateX(6px)',
        '&.Mui-checked': {
            '& .MuiSwitch-thumb': {
                backgroundColor: '#0BC1B6',
            },
            transform: 'translateX(20px)',
            '& .MuiSwitch-thumb:before': {
                backgroundImage: `url(${checkIcon})`,

            },
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: '#A7DEDE',

            },
        },
    },
    '& .MuiSwitch-thumb': {
        backgroundColor: '#BEC0CA',
        width: 20,
        height: 20,
        '&::before': {
            content: "''",
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundImage: `url(${switchX})`,
        },
    },
    '& .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#E3E5EC',
        height: '12px'
    },
}));

export default StyleSwitch