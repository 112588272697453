import {useEffect, useState} from "react";
import axios from "axios";
import {API} from "../../../helpers/constants";
import useUserData from "../../../hooks/user/useUserData";
import {useTranslation} from "react-i18next";
import {ALERTS} from "../../../locales/components/namespaces";
import {handleAxiosErrors, logoutUnauthorizedUser} from "../../../helpers/errorHandling";
import {enqueueSnackbar} from "notistack";
import useUserAction from "../../../hooks/user/useUserAction";

const useDocuments = ({updateDocuments, handleUpdateDocuments}) => {
	const {token, isLoggedIn} = useUserData()
	const {userLogout} = useUserAction()
	const {t} = useTranslation(ALERTS)
	const [loading, setLoading] = useState(false)
	const [documents, setDocuments] = useState([])
	const [uploading, setUploading] = useState(false);
	
	useEffect(() => {
		setLoading(true)
		axios({
			method: 'get',
			headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
			url: `${API}/document/list`,
		}).then((response) => {
			setDocuments(response.data)
			setLoading(false)
		}).catch(error => {
			enqueueSnackbar(handleAxiosErrors(error, t('Failed to get documents list.')), {
					variant: 'error'
				}
			)
			setLoading(false)
			logoutUnauthorizedUser(error, isLoggedIn, userLogout)
		})
	}, [updateDocuments])
	
	const store = (newRow) => {
		const formData = new FormData();
		formData.append("file", newRow.file);
		formData.append("LoanRelId", newRow.LoanRelId);
		formData.append("LoanId", newRow.LoanId);
		setUploading(true);
		
		axios({
			method: "post",
			headers: {
				"Content-Type": "multipart/form-data",
				Authorization: `Bearer ${token}`,
			},
			url: `${API}/document/store`,
			data: formData,
		}).then((response) => {
			handleUpdateDocuments(!updateDocuments)
			enqueueSnackbar(t("New document added successfully!"), {
				variant: "success",
			});
			setUploading(false)
		}).catch((error) => {
			enqueueSnackbar(handleAxiosErrors(error, t("Failed to store new document!")), {
				variant: "error"
			});
			handleUpdateDocuments(!updateDocuments)
			setLoading(false)
			setUploading(false)
			logoutUnauthorizedUser(error, isLoggedIn, userLogout)
		})
	};
	
	const update = (newRow, oldRow) => {
		axios({
			method: 'post',
			headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
			url: `${API}/document/update`,
			data: newRow
		}).then(response => {
			handleUpdateDocuments(!updateDocuments)
			enqueueSnackbar(t('Document updated successfully!'), {
					variant: 'success'
				}
			)
		}).catch(error => {
			handleUpdateDocuments(!updateDocuments)
			enqueueSnackbar(handleAxiosErrors(error, t('Failed to update document!')), {
					variant: 'error'
				}
			)
			logoutUnauthorizedUser(error, isLoggedIn, userLogout)
		})
	}
	
	const remove = (id) => {
		axios({
			method: 'post',
			headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
			url: `${API}/document/remove`,
			data: {
				id: id
			}
		}).then(response => {
			handleUpdateDocuments(!updateDocuments)
			enqueueSnackbar(t('Document deleted successfully!'), {
					variant: 'success'
				}
			)
		}).catch(error => {
			handleUpdateDocuments(!updateDocuments)
			enqueueSnackbar(handleAxiosErrors(error, t('Failed to delete document!',)), {
					variant: 'error'
				}
			)
			logoutUnauthorizedUser(error, isLoggedIn, userLogout)
		})
	}
	
	
	return {
		loading,
		documents,
		uploading,
		store,
		update,
		remove
	}
}

export default useDocuments