import * as React from "react";
const WalletIcon = (props) => (
    <svg
        width={29}
        height={27}
        viewBox="0 0 31 29"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g
            stroke="#485776"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path d="M29.458 18.321h-6.072a4.04 4.04 0 0 1-4.038-4.037 4.039 4.039 0 0 1 4.038-4.037h6.072m-5.385 3.945h-.467" />
            <path
                clipRule="evenodd"
                d="M8.621 1.227h12.966a7.872 7.872 0 0 1 7.871 7.871v10.766a7.872 7.872 0 0 1-7.871 7.871H8.62a7.872 7.872 0 0 1-7.87-7.871V9.098a7.872 7.872 0 0 1 7.871-7.871Z"
            />
            <path d="M7.553 8.033h8.098" />
        </g>
    </svg>
);
export default WalletIcon;