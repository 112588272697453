//@flow
import {createSelector} from 'reselect'
import {useSelector} from "react-redux";
import {parseUser} from "../../types/user/parse";

const selectLoading = createSelector(
	(state) => state.currentUser,
	(item) => item.get('loading')
)

const selectIsLoggedIn = createSelector(
	(state) => state.currentUser,
	(item) => item.get('isLoggedIn')
)

const selectToken = createSelector(
	(state) => state.currentUser,
	(item) => item.get('token')
)

const selectAlterPasswordOnLogin = createSelector(
	(state) => state.currentUser,
	(item) => item.get('alterPasswordOnLogin')
)

const selectUserId = createSelector(
	(state) => state.currentUser,
	(item) => item.getIn(['data', 'id'])
)

const selectLoginName = createSelector(
	(state) => state.currentUser,
	(item) => item.getIn(['data', 'loginName'])
)

const selectUserName = createSelector(
	(state) => state.currentUser,
	(item) => item.getIn(['data', 'username'])
)

const selectCustomerId = createSelector(
	(state) => state.currentUser,
	(item) => item.getIn(['data', 'customerId'])
)

const selectErrorLogin = createSelector(
	(state) => state.currentUser,
	(item) => item.get('errorLogin')
)

const selectErrorConnectivity = createSelector(
	(state) => state.currentUser,
	(item) => item.get('errorConnectivity')
)

const selectLanguage = createSelector(
	(state) => state.currentUser,
	(item) => item.get('language')
)

const selectUserEmail = createSelector(
	(state) => state.currentUser,
	(item) => item.get('user_email')
)

const selectDecimalSeparator = createSelector(
	(state) => state.currentUser,
	(item) => item.get('decimalSeparator')
)

const selectThousandSeparator = createSelector(
	(state) => state.currentUser,
	(item) => item.get('thousandSeparator')
)

const selectData = createSelector(
	[
		(state) => state.currentUser.get('data'),
		(state) => state.currentUser.get('language'),
	],
	(item, language) => parseUser(item, language)
)

const useUserData = () => {
	const loading = useSelector(selectLoading)
	const isLoggedIn = useSelector(selectIsLoggedIn)
	const token = useSelector(selectToken)
	const alterPasswordOnLogin = useSelector(selectAlterPasswordOnLogin)
	const userId = useSelector(selectUserId)
	const loginName = useSelector(selectLoginName)
	const username = useSelector(selectUserName)
	const customerId = useSelector(selectCustomerId)
	const errorLogin = useSelector(selectErrorLogin)
	const errorConnectivity = useSelector(selectErrorConnectivity)
	const language = useSelector(selectLanguage)
	const user_email = useSelector(selectUserEmail)
	const thousandSeparator = useSelector(selectThousandSeparator)
	const decimalSeparator = useSelector(selectDecimalSeparator)
	const data = useSelector(selectData)
	return {
		loading,
		isLoggedIn,
		token,
		alterPasswordOnLogin,
		userId,
		loginName,
		username,
		customerId,
		errorLogin,
		errorConnectivity,
		language,
		thousandSeparator,
		decimalSeparator,
		data,
		user_email
	}
}

export default useUserData