import axios from "axios";
import {API, BulkImportStatus} from "../../../helpers/constants";
import {enqueueSnackbar} from "notistack";
import {handleAxiosErrors, logoutUnauthorizedUser} from "../../../helpers/errorHandling";
import * as yup from "yup";
import {useFormik} from "formik";
import useUserData from "../../../hooks/user/useUserData";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import {FILES} from "../../../locales/components/namespaces";
import useUserAction from "../../../hooks/user/useUserAction";

export const useCustomerImport = () => {
    const {token, isLoggedIn} = useUserData()
    const {userLogout} = useUserAction()
    const {t} = useTranslation(FILES)
    const [importStatus, setImportStatus] = useState(null)
    const [loading, setLoading] = useState(false)

    const onSubmitFile = (values) => {
        setLoading(true)
        axios({
            method: 'post',
            data: {
                file: values.files[0]
            },
            headers: {'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${token}`},
            url: `${API}/customer/upload`
        }).then(response => {
            const {Errors, ids} = response.data.results
            const hasSuccess = ids && ids.length > 0
            const hasErrors = Errors && Errors.length > 0
            let statusCode = (hasSuccess && !hasErrors) ? BulkImportStatus.AllSuccessful :
                (!hasSuccess && hasErrors) ? BulkImportStatus.AllErrors :
                    (hasSuccess && hasErrors) ? BulkImportStatus.SucceededWithErrors :
                        BulkImportStatus.NothingToDo
            setImportStatus({statusCode: statusCode, newItems: ids, errors: Errors})
            formik.resetForm()
            setLoading(false)
        }).catch((error) => {
            enqueueSnackbar(handleAxiosErrors(error, t('Failed to upload file.')), {
                    variant: 'error'
                }
            )
            setLoading(false)
            logoutUnauthorizedUser(error, isLoggedIn, userLogout)
        })
    }

    const validationSchema = yup.object({
        files: yup
            .array()
            .min(1)
            .required(t("At least one file needs to be here"))
    })

    const formik = useFormik({
        initialValues: {
            files: []
        },
        validationSchema: validationSchema,
        onSubmit: onSubmitFile,
    })

    const onFileChange = (val) => {
        formik.setFieldValue('files', val)
    }

    const handleImportStatus = (newImportStatus) => setImportStatus(newImportStatus)

    return {
        loading,
        formik,
        importStatus,
        onFileChange,
        handleImportStatus
    }
}