// @flow
import React, {useMemo} from 'react';
import {useTranslation} from "react-i18next";
import {PARAMETERS} from "../../locales/namespaces";
import useGroups from "../../hooks/useGroups";
import DataGridWrapper from "../../../../components/dataGridWrapper/dataGridWrapper";
import EditableMUIDataGrid from "../../../../components/editableMUIDataGrid/editableMUIDataGrid";
import {withPermissionHOC} from "../../../permission/components/permissionHOC/permissionHOC";
import ContentLoader from "../../../../components/contentLoader/contentLoader";

const Groups = () => {
    const {t} = useTranslation(PARAMETERS)
    const {
        loading,
        groups,
        store,
        update,
        remove
    } = useGroups()

    const newRowModel = {
        Code: '',
        Description: ''
    }

    const columns = useMemo(() => [
        {
            field: 'Code',
            headerName: t('Code'),
            minWidth: 160,
            flex: 1,
            maxWidth: 220,
            editable: true,
            preProcessEditCellProps: (params) => {
                const hasError = params.props.value.length < 1 || params.props.value.length > 15
                return {...params.props, error: hasError};
            },
        },
        {
            field: 'Description',
            headerName: t('Description'),
            minWidth: 160,
            flex: 1,
            editable: true,
            preProcessEditCellProps: (params) => {
                const hasError = params.props.value.length < 3 || params.props.value.length > 50;
                return {...params.props, error: hasError};
            },
        },
    ], [groups, t])

    return !loading ? (
        <DataGridWrapper
            sx={{
                mt: 1,
                maxWidth: '600px'
            }}
        >
            <EditableMUIDataGrid
                loading={loading}
                data={groups}
                columns={columns}
                newRowModel={newRowModel}
                store={store}
                update={update}
                remove={remove}
            />
        </DataGridWrapper>
    ) : (
        <ContentLoader
            loading={loading}
        />
    )
}

export default withPermissionHOC(Groups, ['access_parameters']);