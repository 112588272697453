import React from 'react'
import ReactDOM from 'react-dom/client'
import './locales/i18n'
import {Provider} from 'react-redux'
import store, {persistor} from './store/store'
import {PersistGate} from 'redux-persist/es/integration/react'
import App from './App'
import {LicenseInfo} from '@mui/x-license-pro';
import {ConfigProvider} from "./theme/mantis/contexts/configContext";
import 'simplebar/dist/simplebar.css';
import {GoogleOAuthProvider} from "@react-oauth/google";

LicenseInfo.setLicenseKey('165e31ea195f48fc18e36a0a5d5b4310Tz05MTU1NyxFPTE3NDg2MTg3MzAwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y');

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <React.StrictMode>
        <GoogleOAuthProvider clientId='815430805890-lf8sivq7saavvg11s25s76e93egr5s3l.apps.googleusercontent.com'>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <ConfigProvider>
                            <App/>
                    </ConfigProvider>
                </PersistGate>
            </Provider>
        </GoogleOAuthProvider>
    </React.StrictMode>
)
