// @flow
import * as React from 'react';
import useCalculationForm from "../../hooks/useCalculationForm";
import {FormikProvider} from "formik";
import Breadcrumb from "../../../../components/breadcrumb/breadcrumb";
import {getRouteUrl} from "../../../../helpers/getRouteUrl";
import {ROUTE_PAGE_HOME, ROUTE_PAGE_LOGIN} from "../../../../routers/routes";
import {useTranslation} from "react-i18next";
import {CALCULATIONS} from "../../locales/namespaces";
import {ROUTE_PAGE_CALCULATIONS_INDEX} from "../../routes/routes";
import {
	Box, Button, FormControl, FormControlLabel,
	Stack, RadioGroup, Radio, Toolbar, Divider, Typography, AccordionSummary, Accordion,
	AccordionDetails, useMediaQuery, Grid, useTheme
} from "@mui/material";
import {Link, useHistory} from "react-router-dom";
import LinesChart from "../charts/linesChart/linesChart";
import BarsChart from "../charts/barsChart/barsChart";
import DataGridWrapper from "../../../../components/dataGridWrapper/dataGridWrapper";
import {useEffect, useState} from "react";
import RepaymentsForm from "../repaymentsForm/repaymentsForm";
import RepaymentsList from "../repaymentsList/repaymentsList";
import moment from "moment";
import FixedTable from "../tables/fixedTable";
import FloatTable from "../tables/floatTable";
import {calculateInstallments} from "../../helpers/calculations";
import AlertDialogForResetForm from "../deleteRepaymentsResetFormDialog/deleteRepaymentsResetFormDialog";
import {muiGridLocales} from "../../../../helpers/muiGridLocales";
import {useLocation} from "react-router";
import InputsCalculation from '../inputsCalculation/inputsCalculation'
import StyledDataGrid from '../styleDataGrid/styleDataGrid'
import useUserData from "../../../../hooks/user/useUserData";
import {enqueueSnackbar} from "notistack";
import ContentLoader from "../../../../components/contentLoader/contentLoader";
import {NumericFormat} from "react-number-format";
import HomeBreadcrumb from "../../../../assets/images/icons/homeBreadcrumb";
import HelpCircleIcon from "../../../../assets/images/icons/buttonsIcons/helpCircleIcon";
import AlertMessageForSimpleUser
	from "../../../customers/components/alertMessageForSimpleUser/alertMessageForSimpleUser";
import PlusIcon from "../../../../assets/images/icons/buttonsIcons/plusIcon";
import {UnsortedIcon} from "../../../../assets/images/icons/sortIcon";
import StyleSwitch from "../styleSwitch/styleSwitch";
import ArrowDown2 from "../../../../assets/images/icons/arrowDown2";
import usePopover from "../../hooks/usePopover";
import RotateIcon from "../../../../assets/images/icons/rotateIcon";
import XIcon from "../../../../assets/images/icons/xIcon";

type Props = {
	id: string,
	calculations?: Object,
	contributions: Array,
	interests: Array
}

const CalculationForm = (props: Props) => {
	const {t} = useTranslation(CALCULATIONS)
	const location = useLocation()
	const {decimalSeparator, thousandSeparator} = useUserData()
	const [openRepayments, setOpenRepayments] = useState(false);
	//const [expanded, setExpanded] = useState(false);
	const [expandedIn, setExpandedIn] = useState(true);
	const [expandedInTables, setExpandedInTables] = useState(true);
	const [expandedInCharts, setExpandedInCharts] = useState(true);
	const [openReset, setOpenReset] = useState(false);
	
	const history = useHistory()
	const [popoverId, setPopoverId] = useState('')
	const [isOpenDesc, setIsOpenDesc] = useState(false);
	
	const {
		id,
		calculations,
		interests,
		contributions,
		repayments,
		updateRepayments,
		handleUpdateRepayments,
		handleRemoveRepayment
	} = props
	
	const {
		formik,
		results,
		TotalDuration,
		InstallmentNumber,
		handleDate,
		handleResults,
		handleInterestType,
		handleCalculateInstallments,
		handleCalculationsLoanAmount,
		handleCalculationsRealEstateValue,
		handleResetForm,
		isLoading
	} = useCalculationForm({
		id,
		calculations,
		interests,
		contributions,
		repayments
	})
	
	const {
		handleAnchorElClick,
		handleAnchorElClose,
		openAnchorEl,
		anchorEl
	} = usePopover()
	
	const isMobile = useMediaQuery('(max-width:800px)');
	const matchDownSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
	const matchDownMd = useMediaQuery((theme) => theme.breakpoints.down('md'));
	
	const columns = [
		{field: 'id', hide: true, headerName: t('N/A'), width: 110},
		{field: 'Date', headerName: t('Payment date'), width: 200},
		{
			field: 'installment',
			headerName: formik.values.isMonthly === 'isMonthly' ? t('Monthly Installment') : t('Yearly Installment'),
			width: 160,
			renderCell: ({row}) => {
				return row.installment ? <NumericFormat
					thousandSeparator={thousandSeparator}
					decimalSeparator={decimalSeparator}
					displayType="text"
					value={row.installment}
				/> : 0
			}
		},
		{
			field: 'interest', headerName: t('Interest'), width: 120,
			renderCell: ({row}) => {
				return row.interest ? <NumericFormat
					thousandSeparator={thousandSeparator}
					decimalSeparator={decimalSeparator}
					displayType="text"
					value={row.interest}
				/> : 0
			}
		},
		{
			field: 'creolysio', headerName: t('Amortization'), width: 150,
			renderCell: ({row}) => {
				return row.creolysio ? <NumericFormat
					thousandSeparator={thousandSeparator}
					decimalSeparator={decimalSeparator}
					displayType="text"
					value={row.creolysio}
				/> : 0
			}
		},
		{
			field: 'remainingAmount', headerName: t('Remaining Principal'), width: 220,
			
			renderCell: ({row}) => {
				return row.remainingAmount ? <NumericFormat
					thousandSeparator={thousandSeparator}
					decimalSeparator={decimalSeparator}
					displayType="text"
					value={row.remainingAmount}
				/> : 0
			}
		},
		{
			field: 'sumInterest', headerName: t('Total Interest'), width: 180,
			renderCell: ({row}) => {
				return row.sumInterest ? <NumericFormat
					thousandSeparator={thousandSeparator}
					decimalSeparator={decimalSeparator}
					displayType="text"
					value={row.sumInterest}
				/> : 0
			}
		},
		{
			field: 'repayment', headerName: t('Total principal repayment'), width: 250,
			renderCell: ({row}) => {
				return row.repayment ? <NumericFormat
					thousandSeparator={thousandSeparator}
					decimalSeparator={decimalSeparator}
					displayType="text"
					value={row.repayment}
				/> : 0
			}
		},
	];
	
	const columnsMobile = [
		{field: 'id', hide: true, headerName: t('N/A'), width: 80},
		{field: 'Date', headerName: t('Payment date'), width: 150},
		{
			field: 'installment',
			headerName: formik.values.isMonthly === 'isMonthly' ? t('Monthly Installment') : t('Yearly Installment'),
			width: 120,
			renderCell: ({row}) => {
				return row.installment ? <NumericFormat
					thousandSeparator={thousandSeparator}
					decimalSeparator={decimalSeparator}
					displayType="text"
					value={row.installment}
				/> : 0
			}
		},
		{
			field: 'remainingAmount', headerName: t('Remaining Principal'), width: 150,
			renderCell: ({row}) => {
				return row.remainingAmount ? <NumericFormat
					thousandSeparator={thousandSeparator}
					decimalSeparator={decimalSeparator}
					displayType="text"
					value={row.remainingAmount}
				/> : 0
			}
		},
		{
			field: 'sumInterest', headerName: t('Total Interest'), width: 120,
			renderCell: ({row}) => {
				return row.sumInterest ? <NumericFormat
					thousandSeparator={thousandSeparator}
					decimalSeparator={decimalSeparator}
					displayType="text"
					value={row.sumInterest}
				/> : 0
			}
		},
		{
			field: 'repayment', headerName: t('Total principal repayment'), width: 160,
			renderCell: ({row}) => {
				return row.repayment ? <NumericFormat
					thousandSeparator={thousandSeparator}
					decimalSeparator={decimalSeparator}
					displayType="text"
					value={row.repayment}
				/> : 0
			}
		},
	];
	
	const rowsFixed = results && results.FixedTable ? results.FixedTable.map((row) => ({
		id: row.AA,
		creolysio: Math.max(row.Creolysio, 0).toFixed(2),
		installment: Math.max(row.Installment, 0).toFixed(2),
		interest: Math.max(row.Interest, 0).toFixed(2),
		remainingAmount: Math.max(row.RemainingAmount, 0).toFixed(2),
		repayment: Math.max(row.Repayment, 0).toFixed(2),
		sumInterest: Math.max(row.SumInterest, 0).toFixed(2),
		Date: row.Date,
		isRepayment: row.isRepayment
	})) : [];
	
	const rowsFloat = results && results.FloatTable ? results.FloatTable.map((row) => ({
		id: row.AA,
		creolysio: Math.max(row.Creolysio, 0).toFixed(2),
		installment: Math.max(row.Installment, 0).toFixed(2),
		interest: Math.max(row.Interest, 0).toFixed(2),
		remainingAmount: Math.max(row.RemainingAmount, 0).toFixed(2),
		repayment: Math.max(row.Repayment, 0).toFixed(2),
		sumInterest: Math.max(row.SumInterest, 0).toFixed(2),
		Date: row.Date,
		isRepayment: row.isRepayment
	})) : [];
	
	/*const handleAccordionChange = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	};*/
	const handleAccordionChangeIn = () => {
		setExpandedIn(!expandedIn);
	};
	const handleAccordionChangeInTables = () => {
		setExpandedInTables(!expandedInTables);
	};
	const handleAccordionChangeInCharts = () => {
		setExpandedInCharts(!expandedInCharts);
	};
	
	const onCloseRepayments = () => {
		setOpenRepayments(false)
	}
	
	const handleClickOpenReset = () => {
		setOpenReset(true);
	};
	
	const handleCloseReset = () => {
		setOpenReset(false);
	};
	
	const mapRepayments = repayments.map(repayment => ({
		Amount: Number(repayment.Amount),
		NewRate: Number(repayment.NewRate),
		Repayment_dt: moment(repayment.PaymentDt).format('MM/DD/YYYY')
	}))

//call calculateInstallments with isMonthly === false, so I have the data in yearly mode for diagrams
	const chartData = calculateInstallments(
		0,
		formik.values.FixedDuration,
		formik.values.FloatingDuration,
		formik.values.FixedInterestRate,
		Number(formik.values.Spread) + Number(formik.values.Euribor),
		contributions.find(f => f.id === formik.values.Contribution)?.Value,
		formik.values.LoanAmount,
		false,
		formik.values.isHidePreviousInstallments,
		moment(formik.values.DateInstallment).format('MM/DD/YYYY'),
		[...mapRepayments],
		[],
		[],
		0,
		0,
		0,
		0,
		[...mapRepayments])
	
	const kpiData = calculateInstallments(
		1,
		formik.values.FixedDuration,
		formik.values.FloatingDuration,
		formik.values.FixedInterestRate,
		Number(formik.values.Spread) + Number(formik.values.Euribor),
		contributions.find(f => f.id === formik.values.Contribution)?.Value,
		formik.values.LoanAmount,
		true,
		formik.values.isHidePreviousInstallments,
		moment(formik.values.DateInstallment).format('MM/DD/YYYY'),
		[...mapRepayments],
		[],
		[],
		0,
		0,
		0,
		0,
		[...mapRepayments])
	
	const fixed = kpiData && kpiData.FixedTable ? kpiData.FixedTable.map((row) => ({
		id: row.AA,
		creolysio: Math.max(row.Creolysio, 0).toFixed(2),
		installment: Math.max(row.Installment, 0).toFixed(2),
		interest: Math.max(row.Interest, 0).toFixed(2),
		remainingAmount: Math.max(row.RemainingAmount, 0).toFixed(2),
		repayment: Math.max(row.Repayment, 0).toFixed(2),
		sumInterest: Math.max(row.SumInterest, 0).toFixed(2),
		Date: row.Date,
		isRepayment: row.isRepayment
	})) : [];
	
	const float = kpiData && kpiData.FloatTable ? kpiData.FloatTable.map((row) => ({
		id: row.AA,
		creolysio: Math.max(row.Creolysio, 0).toFixed(2),
		installment: Math.max(row.Installment, 0).toFixed(2),
		interest: Math.max(row.Interest, 0).toFixed(2),
		remainingAmount: Math.max(row.RemainingAmount, 0).toFixed(2),
		repayment: Math.max(row.Repayment, 0).toFixed(2),
		sumInterest: Math.max(row.SumInterest, 0).toFixed(2),
		Date: row.Date,
		isRepayment: row.isRepayment
	})) : [];
	
	//concat data for Calculations Diagrams to show them in one diagram
	const FinalTable = (chartData.FixedTable?.concat(chartData.FloatTable)) || [];
	
	const handleClick = () => {
		//When the anonymous user clicks the button to show interest for a loan request
		enqueueSnackbar(<AlertMessageForSimpleUser/>, {
				variant: 'info',
				hideIconVariant: true,
				autoHideDuration: 4000,
			}
		)
		
	}
	
	const handleInterestedLoanUnAuth = () => {
		enqueueSnackbar(t('To apply for a loan you must have an account.'), {
				variant: 'info'
			}
		)
		history.push(getRouteUrl(ROUTE_PAGE_LOGIN))
	}
	
	const handlePopoverId = (id) => {
		setPopoverId(id)
	}
	useEffect(() => {
		handleAnchorElClick(popoverId)
	}, [popoverId])
	
	useEffect(() => {
		if (matchDownMd) {
			const element = document.getElementById(popoverId)
			if (element) {
				element.scrollIntoView({
					behavior: 'smooth',
					block: 'center',
				});
			}
		}
	}, [popoverId]);
	
	const theme = useTheme();
	const matchBetweenXSSM = useMediaQuery(theme.breakpoints.between('xs', 'sm'));
	
	
	return !isLoading ? (
			<FormikProvider value={formik}>
				{(location.pathname === '/calculations/create' || location.pathname === ('/calculations/edit/' + id)) &&
					!matchDownSM &&
					<Breadcrumb>
						<Breadcrumb.Link iconComponent={HomeBreadcrumb} to={getRouteUrl(ROUTE_PAGE_HOME)}/>
						<Breadcrumb.Link to={getRouteUrl(ROUTE_PAGE_CALCULATIONS_INDEX)}>
							{t('Calculations')}
						</Breadcrumb.Link>
						<Breadcrumb.Item>
							{!id ? t('Loan criteria') : t('Loan criteria')}
						</Breadcrumb.Item>
					</Breadcrumb>
				}
				{location.pathname !== '/fast/calculation' && <Divider light sx={{mt: 1, mb: 2}}/>}
				<Box sx={{display: 'flex', flexDirection: 'column'}}>
					<Accordion
						expanded={expandedIn}
						onChange={handleAccordionChangeIn}
						square={true} sx={{borderRadius: '12px', border: '1px solid #0BC1B6', mb: '32px'}}
					>
						<AccordionSummary
							expandIcon={<ArrowDown2/>}
							aria-controls="panel1a-content"
							id="panel1a-header"
						>
							{expandedIn && matchBetweenXSSM ?
								<Grid container spacing={3}>
									<Grid item xs={12} sm={12} md={12} lg={6}>
										<Typography variant={'h4'} fontWeight={400} component={'div'}>
											{t("Loan criteria")}
										</Typography>
									</Grid>
									{(location.pathname === '/calculations/create' || location.pathname === ('/calculations/edit/' + id)) ?
										<Grid item sx={{pl: 0, ml: 0}}>
											<Button
												size={'small'}
												variant={'outlined'}
												color={'warning'}
												id="help-button"
												sx={{p: 1, minWidth: '45px', mr: 0.5, mt: 0.5}}
												onClick={(event) => {
													event.stopPropagation();
													setIsOpenDesc(true)
													handlePopoverId('description')
												}}
											>
												<HelpCircleIcon/>
											</Button>
											<Button
												size={"small"}
												variant={"outlined"}
												color={'blue'}
												sx={{p: 1, minWidth: '45px', mr: 0.5, mt: 0.5}}
												onClick={(event) => {
													event.stopPropagation()
													if (id && repayments.length > 0) {
														handleClickOpenReset()
													} else {
														handleResetForm()
													}
												}}
											>
												<RotateIcon width={19} height={19}/>
											</Button>
											<Button
												size={"small"}
												variant={"contained"}
												sx={{p: 1, mr: 0.5, mt: 0.5}}
												color={'info'}
												onClick={(event) => {
													event.stopPropagation()
													formik.submitForm()
												}}
											>
												{t('Save')}
											</Button>
											<Button
												size={"small"}
												variant={"outlined"}
												color={"error"}
												sx={{p: 1, minWidth: '45px', mt: 0.5}}
												component={Link}
												to={getRouteUrl(ROUTE_PAGE_CALCULATIONS_INDEX)}
											>
												<XIcon/>
											</Button>
										</Grid>
										:
										location.pathname === '/fast/calculation' &&
										<div
											style={{
												display: 'flex',
												flexDirection: 'row',
												alignItems: 'flex-end',
											}}
										>
											<Button
												size={"small"}
												variant={"outlined"}
												color={'blue'}
												onClick={id && repayments.length > 0 ? handleClickOpenReset : handleResetForm}
												sx={{float: 'right'}}
											>
												{t('Reset')}
											</Button>
										</div>}
								</Grid>
								:
								<Grid container alignItems="center" justifyContent="space-between">
									<Grid item xs={12} sm={12} md={12} lg={6}>
										<Typography variant={'h4'} fontWeight={400} component={'div'}>
											{t('Loan criteria')}
										</Typography>
									</Grid>
									{expandedIn && <Grid item>
										{(location.pathname === '/calculations/create' || location.pathname === ('/calculations/edit/' + id)) ?
											<div
												style={{
													display: 'flex',
													flexDirection: 'row'
												}}
											>
												<Grid container spacing={1}>
													<Grid item>
														<Button
															size={'small'}
															variant={'outlined'}
															color={'warning'}
															sx={{gap: '8px', minWidth: '150px'}}
															id="help-button"
															// aria-describedby={openAnchorEl ? 'description' : undefined}*
															onClick={(event) => {
																event.stopPropagation();
																setIsOpenDesc(true)
																handlePopoverId('description')
																// handleAnchorElClick('description');
																// handlePopoverId('description')
																// setExpandedIn(true)
															}}
														>
															<HelpCircleIcon/>{t('Help')}
														</Button>
													</Grid>
													<Grid item>
														<Button
															size={"small"}
															variant={"outlined"}
															color={'blue'}
															sx={{minWidth: '150px'}}
															onClick={(event) => {
																event.stopPropagation()
																if (id && repayments.length > 0) {
																	handleClickOpenReset()
																} else {
																	handleResetForm()
																}
															}}
														>
															{t('Reset')}
														</Button>
													</Grid>
													<Grid item>
														<Button
															size={"small"}
															variant={"contained"}
															color={'info'}
															sx={{minWidth: '150px'}}
															onClick={(event) => {
																event.stopPropagation()
																formik.submitForm()
															}}
														>
															{t('Save')}
														</Button>
													</Grid>
													<Grid item>
														<Button
															size={"small"}
															variant={"contained"}
															color={"error"}
															component={Link}
															to={getRouteUrl(ROUTE_PAGE_CALCULATIONS_INDEX)}
															sx={{minWidth: '150px'}}
														>
															{t('Close')}
														</Button>
													</Grid>
												</Grid>
											</div> :
											location.pathname === '/fast/calculation' &&
											<div
												style={{
													display: 'flex',
													flexDirection: 'row',
													alignItems: 'flex-end',
												}}
											>
												<Button size={"small"} variant={"outlined"} color={'blue'}
														onClick={id && repayments.length > 0 ? handleClickOpenReset : handleResetForm}
														sx={{float: 'right'}}>
													{t('Reset')}
												</Button>
											</div>}
									</Grid>}
								</Grid>}
							
							
							{/*<Grid container alignItems="center" justifyContent="space-between">
								<Grid item xs={12} sm={12} md={12} lg={6}>
									<Typography variant={'h4'} fontWeight={400} component={'div'}>
										{(location.pathname === '/calculations/create' || location.pathname === ('/calculations/edit/' + id)) ?
											<Typography variant={"h4"} component={'div'} fontWeight={400}>
												{!id ? t("Loan criteria") : (formik.values.Description ? calculations.Description : t("Loan criteria"))}
											</Typography> :
											location.pathname === '/fast/calculation' &&
											<Typography variant={"h4"} component={'div'} fontWeight={400}>
												{t('Loan criteria')}
											</Typography>}
									</Typography>
								</Grid>
								<Grid item>
									{(location.pathname === '/calculations/create' || location.pathname === ('/calculations/edit/' + id)) ?
										<div
											style={{
												display: 'flex',
												flexDirection: 'row'
											}}
										>
											<Grid container spacing={1}>
												<Grid item>
													<Button
														size={'small'}
														variant={'outlined'}
														color={'warning'}
														sx={{gap: '8px', minWidth: '150px'}}
														id="help-button"
														// aria-describedby={openAnchorEl ? 'description' : undefined}*
														onClick={(event) => {
															event.stopPropagation();
															setIsOpenDesc(true)
															handlePopoverId('description')
															// handleAnchorElClick('description');
															// handlePopoverId('description')
															// setExpandedIn(true)
														}}
													>
														<HelpCircleIcon/>{t('Help')}
													</Button>
												</Grid>
												<Grid item>
													<Button
														size={"small"}
														variant={"outlined"}
														color={'blue'}
														sx={{minWidth: '150px'}}
														onClick={(event) => {
															event.stopPropagation()
															if (id && repayments.length > 0) {
																handleClickOpenReset()
															} else {
																handleResetForm()
															}
														}}
													>
														{t('Reset')}
													</Button>
												</Grid>
												<Grid item>
													<Button
														size={"small"}
														variant={"contained"}
														color={'info'}
														sx={{minWidth: '150px'}}
														onClick={(event) => {
															event.stopPropagation()
															formik.submitForm()
														}}
													>
														{t('Save')}
													</Button>
												</Grid>
												<Grid item>
													<Button
														size={"small"}
														variant={"contained"}
														color={"error"}
														component={Link}
														to={getRouteUrl(ROUTE_PAGE_CALCULATIONS_INDEX)}
														sx={{minWidth: '150px'}}
													>
														{t('Close')}
													</Button>
												</Grid>
											</Grid>
										</div> :
										location.pathname === '/fast/calculation' &&
										<div
											style={{
												display: 'flex',
												flexDirection: 'row',
												alignItems: 'flex-end',
											}}
										>
											<Button size={"small"} variant={"outlined"} color={'blue'}
													onClick={id && repayments.length > 0 ? handleClickOpenReset : handleResetForm}
													sx={{float: 'right'}}>
												{t('Reset')}
											</Button>
										</div>}
								</Grid>
							</Grid>*/}
						</AccordionSummary>
						<AccordionDetails>
							<InputsCalculation
								formik={formik}
								handleCalculationsRealEstateValue={handleCalculationsRealEstateValue}
								handleCalculationsLoanAmount={handleCalculationsLoanAmount}
								handleInterestType={handleInterestType}
								handleCalculateInstallments={handleCalculateInstallments}
								contributions={contributions}
								handleDate={handleDate}
								location={location}
								id={id}
								
								rowsFixed={rowsFixed}
								rowsFloat={rowsFloat}
								float={float}
								fixed={fixed}
								TotalDuration={TotalDuration}
								InstallmentNumber={InstallmentNumber}
								handleInterestedLoanUnAuth={handleInterestedLoanUnAuth}
								handleClick={handleClick}
								
								handleAnchorElClick={handleAnchorElClick}
								openAnchorEl={openAnchorEl}
								anchorEl={anchorEl}
								handleAnchorElClose={handleAnchorElClose}
								popoverId={popoverId}
								handlePopoverId={handlePopoverId}
								
								isOpenDesc={isOpenDesc}
								setIsOpenDesc={setIsOpenDesc}
							
							/>
						</AccordionDetails>
					</Accordion>
					<AlertDialogForResetForm
						openReset={openReset}
						handleCloseReset={handleCloseReset}
						id={id}
						handleResetForm={handleResetForm}
						updateRepayments={updateRepayments}
						handleUpdateRepayments={handleUpdateRepayments}
					/>
					{id &&
						<Accordion square={true} sx={{mb: '32px', borderRadius: '12px'}}>
							<AccordionSummary
								expandIcon={<ArrowDown2/>}
								aria-controls="panel1a-content"
								id="panel1a-header"
							>
								<Typography variant={'h4'} component={'div'} fontWeight={400}>{t('Repayments')}</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<Button
									color={'info'}
									variant={'contained'}
									size={'small'}
									sx={{
										gap: '8px',
									}}
									onClick={() => setOpenRepayments(true)}
								>
									<PlusIcon/>{t("Repayment")}
								</Button>
								<RepaymentsForm
									id={id}
									open={openRepayments}
									onClose={onCloseRepayments}
									handleResults={handleResults}
									calculationData={formik.values}
									contributions={contributions}
									repayments={repayments}
									updateRepayments={updateRepayments}
									handleUpdateRepayments={handleUpdateRepayments}
								/>
								<RepaymentsList
									repayments={repayments}
									handleRemoveRepayment={handleRemoveRepayment}
									handleResults={handleResults}
									calculationData={formik.values}
									contributions={contributions}
									updateRepayments={updateRepayments}
									handleUpdateRepayments={handleUpdateRepayments}
								/>
							</AccordionDetails>
						</Accordion>
					}
					{isMobile ?
						<Accordion
							/*expanded={expanded === 'panel1'}
								   onChange={handleAccordionChange('panel1')}*/
							expanded={expandedInTables} onChange={handleAccordionChangeInTables}
							square={true} sx={{mb: '32px', borderRadius: '12px'}}
						>
							<AccordionSummary
								expandIcon={<ArrowDown2/>}
								aria-controls="panel1a-content"
								id="panel1a-header"
							>
								<Typography variant={'h4'} component={'div'}
											fontWeight={400}>{t('Loan Calculation Tables')}</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<Box sx={{mt: 1, mb: 1, ml: 1}}>
									<Stack direction={'column'}>
										<FormControl variant="standard" fullWidth>
											<RadioGroup
												row
												aria-labelledby="row-radio-buttons-group-label"
												name="isMonthly"
												value={formik.values.isMonthly}
												onChange={handleCalculateInstallments}
											>
												<FormControlLabel
													value="isYearly"
													control={
														<Radio
															color={'info'}
															size={'small'}
														/>
													}
													label={t("Yearly")}
												/>
												<FormControlLabel
													value="isMonthly"
													control={
														<Radio
															color={'info'}
															size={'small'}
														/>
													}
													label={t("Monthly")}
												/>
											</RadioGroup>
										</FormControl>
										<Box>
											<FormControl variant="standard" fullWidth sx={{mt: 2}}>
												<FormControlLabel
													control={
														<StyleSwitch
															checked={formik.values.isHidePreviousInstallments}
															onChange={handleCalculateInstallments}
															onBlur={formik.handleBlur}
															size={'medium'}
															name={'isHidePreviousInstallments'}
														/>
													}
													label={t('Hide previous installments')}
													labelPlacement="end"
												/>
											</FormControl>
										</Box>
									</Stack>
									<Stack
										direction={{xs: 'column', sm: 'column', md: 'column', lg: 'column', xl: 'row'}}
										spacing={3}>
										{rowsFixed.length > 0 &&
											(
												<Box className={'customer-index'}>
													<Divider sx={{mb: 2}} light/>
													<Toolbar disableGutters variant={"dense"}>
														<Typography variant="bodyMedium" gutterBottom sx={{ml: 2}}>
															{t('Fixed rate Installments')}
														</Typography>
													</Toolbar>
													<Divider sx={{mb: 2}} light/>
													<DataGridWrapper
														sx={{
															mt: 1,
															height: '400px'
														}}
													>
														<StyledDataGrid
															getRowClassName={(params) => {
																return (
																	params.row.isRepayment === 1 ? `super-app-theme--${params.row.isRepayment}` :
																		params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
																)
															}}
															disableColumnMenu={true}
															sx={{
																".MuiDataGrid-aggregationColumnHeaderLabel": {
																	display: "none",
																	'.MuiDataGrid-cellContent': {
																		fontWeight: 400
																	}
																},
																"& .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer": {
																	visibility: "visible",
																	width: "auto"
																},
																"& .MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeader--sorted) .MuiDataGrid-sortIcon": {
																	opacity: 0.5
																},
															}}
															slots={{
																columnUnsortedIcon: UnsortedIcon,
															}}
															rows={rowsFixed}
															columns={columnsMobile}
															localeText={muiGridLocales(t)}
														/>
													</DataGridWrapper>
												</Box>
											)
										}
										{rowsFloat.length > 0 &&
											(
												<Box className={'customer-index'}>
													<Divider sx={{mb: 2}} light/>
													<Toolbar disableGutters variant={"dense"}>
														<Typography variant="bodyMedium" gutterBottom sx={{ml: 2}}>
															{t('Float rate Installments')}
														</Typography>
													</Toolbar>
													<Divider sx={{mb: 2}} light/>
													<DataGridWrapper
														sx={{
															mt: 1,
															height: '400px'
														}}
													>
														<StyledDataGrid
															getRowClassName={(params) => {
																return (
																	params.row.isRepayment === 1 ? `super-app-theme--${params.row.isRepayment}` :
																		params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
																)
															}}
															disableColumnMenu={true} rows={rowsFloat}
															columns={columnsMobile}
															sx={{
																".MuiDataGrid-aggregationColumnHeaderLabel": {
																	display: "none"
																},
																'.MuiDataGrid-cellContent': {
																	fontWeight: 400
																},
																"& .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer": {
																	visibility: "visible",
																	width: "auto"
																},
																"& .MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeader--sorted) .MuiDataGrid-sortIcon": {
																	opacity: 0.5
																},
															}}
															slots={{
																columnUnsortedIcon: UnsortedIcon,
															}}
															localeText={muiGridLocales(t)}
														/>
													</DataGridWrapper>
												</Box>
											)
										}
									</Stack>
								</Box>
							</AccordionDetails>
						</Accordion> :
						<Accordion
							square={true} sx={{mb: '32px', borderRadius: '12px'}}
							// expanded={expanded === 'panel2'} onChange={handleAccordionChange('panel2')}
							expanded={expandedInTables} onChange={handleAccordionChangeInTables}
						>
							<AccordionSummary
								expandIcon={<ArrowDown2/>}
								aria-controls="panel1a-content"
								id="panel1a-header"
							>
								<Typography variant={'h4'} component={'div'}
											fontWeight={400}>{t('Loan Calculation Tables')}</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<Box sx={{mt: 1, mb: 1, ml: 1}}>
									<Stack direction={{xs: 'column', sm: 'row', md: 'row', lg: 'row', xl: 'row'}}
										   spacing={1}>
										
										<FormControl variant="standard" fullWidth sx={{pb: 1, mt: 4}}>
											<Stack
												direction={{xs: 'column', sm: 'row', md: 'row', lg: 'row', xl: 'row'}}>
												<RadioGroup
													row
													aria-labelledby="row-radio-buttons-group-label"
													name="isMonthly"
													value={formik.values.isMonthly}
													onChange={handleCalculateInstallments}
												>
													<FormControlLabel
														value="isYearly"
														control={
															<Radio
																size={'small'}
															/>
														}
														label={t("Yearly")}
													/>
													<FormControlLabel
														value="isMonthly"
														control={
															<Radio
																size={'small'}
															/>
														}
														label={t("Monthly")}
													/>
												</RadioGroup>
												<FormControlLabel
													control={
														<StyleSwitch
															checked={formik.values.isHidePreviousInstallments}
															onChange={handleCalculateInstallments}
															onBlur={formik.handleBlur}
															size={'medium'}
															name={'isHidePreviousInstallments'}
														/>
													}
													label={t('Hide previous installments')}
													labelPlacement="end"
												/>
											</Stack>
										</FormControl>
									</Stack>
									<Stack
										justifyContent="space-between"
										direction={{
											xs: 'column',
											sm: 'column',
											md: 'column',
											lg: 'column',
											xl: 'column'
										}}
										spacing={1}>
										{rowsFixed.length > 0 && rowsFloat.length > 0 &&
											(
												<Box width={{
													lg: '100%',
													xl: '100%'
												}}>
													<Divider sx={{mb: 2}} light/>
													<FixedTable rowsFixed={rowsFixed} columns={columns}/>
												</Box>
											)
										}
										{rowsFixed.length > 0 && rowsFloat.length <= 0 &&
											(
												<Box width={{
													lg: '100%',
													xl: '100%'
												}}>
													<Divider sx={{mb: 2}} light/>
													<FixedTable rowsFixed={rowsFixed} columns={columns}/>
												</Box>
											)
										}
										{rowsFloat.length > 0 && rowsFixed.length > 0 &&
											(
												<Box width={{
													lg: '100%',
													xl: '100%'
												}}>
													<Divider sx={{mb: 2}} light/>
													<FloatTable rowsFloat={rowsFloat} columns={columns}/>
												</Box>
											)
										}
										{rowsFloat.length > 0 && rowsFixed.length <= 0 &&
											(
												<Box width={{
													lg: '100%',
													xl: '100%'
												}}>
													<Divider sx={{mb: 2}} light/>
													<FloatTable rowsFloat={rowsFloat} columns={columns}/>
												</Box>
											)
										}
									</Stack>
								</Box>
							</AccordionDetails>
						</Accordion>
					}
					{(rowsFixed.length > 0 || rowsFloat.length > 0) &&
						(
							<Accordion
								square={true} sx={{mb: '32px', borderRadius: '12px'}}
								//expanded={expanded === 'panel3'} onChange={handleAccordionChange('panel3')}
								expanded={expandedInCharts} onChange={handleAccordionChangeInCharts}
							>
								<AccordionSummary
									expandIcon={<ArrowDown2/>}
									aria-controls="panel1a-content"
									id="panel1a-header"
								>
									<Typography variant={'h4'} component={'div'}
												fontWeight={400}>{t('Calculation Diagrams')}</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<Box>
										<Stack
											sx={{
												mt: '40px'
											}}
											direction={{
												xs: 'column',
												sm: 'column',
												md: 'column',
												lg: 'column',
												xl: 'column'
											}}
										>
											<Box sx={{ml: 1, mb: 3}}>
												<LinesChart results={chartData} FinalTable={FinalTable}/>
											</Box>
											<Box sx={{ml: 1, mb: 3}}>
												<BarsChart results={chartData} FinalTable={FinalTable}/>
											</Box>
										</Stack>
									</Box>
								</AccordionDetails>
							</Accordion>
						)
					}
				</Box>
			</FormikProvider>
		) :
		(
			<ContentLoader
				withBackDrop={true}
				useCircularLoader={true}
				loading={isLoading}
				message={t("Loading... Please don't close the browser before the process ends.")}
			/>
		)
}

export default CalculationForm
