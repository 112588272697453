import * as React from "react";
const UploadCloudIcon = (props) => (
    <svg
        width={60}
        height={60}
        viewBox="0 0 60 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g
            stroke="#485776"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path d="M40 40 30 30 20 40m10-10v22.5" />
            <path d="M50.976 45.974a12.5 12.5 0 0 0-5.975-23.475h-3.15a20 20 0 1 0-34.35 18.25" />
            <path d="M40 40 30 30 20 40" />
        </g>
    </svg>
);
export default UploadCloudIcon;