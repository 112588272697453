// @flow
import * as React from 'react';
import {ErrorMessage, FormikProvider} from "formik";
import {LOANS} from "../../locales/namespaces";
import {useTranslation} from "react-i18next";
import {
	Button, Typography, TextField, FormControl, FormControlLabel,
	FormHelperText, FormLabel, Select, Stack, Grid, Divider, MenuItem,
	TextareaAutosize, Switch, useMediaQuery, Checkbox, Dialog
} from "@mui/material";
import {getRouteUrl} from "../../../../helpers/getRouteUrl";
import {ROUTE_PAGE_LOANS_INDEX} from "../../routes/routes";
import useLoanForm from "../../hooks/useLoanForm";
import QuestionnaireForm from "../questionnaireForm/questionnaireForm";
import Breadcrumb from "../../../../components/breadcrumb/breadcrumb";
import {ROUTE_PAGE_HOME} from "../../../../routers/routes";
import {useEffect, useMemo, useState} from "react";
import MainCard from "../../../../theme/mantis/components/mainCard";
import {textTranslate} from "../../../../helpers/helpers";
import useUserData from "../../../../hooks/user/useUserData";
import ContentLoader from "../../../../components/contentLoader/contentLoader";
import {NumericFormat} from "react-number-format";
import HomeBreadcrumb from "../../../../assets/images/icons/homeBreadcrumb";
import ArrowDown2 from "../../../../assets/images/icons/arrowDown2";
import {Link as RouterLink} from "react-router-dom";
import useQueryParams from "../../../../hooks/useQueryParams";
import {Capacitor} from "@capacitor/core";
import PrivacyPolicy from "../../../../pages/privacyPolicy/privacyPolicy";

type Props = {
	id: string,
	loan?: Object,
	type: Array,
	originLoan: Array,
	questionnaire: Array
}

/*const NativeInput = styled(InputBase)(({theme}) => ({
	'& .MuiNativeSelect-icon': {
		mr: 1
	},
	'& .MuiInputBase-input': {
		fontSize: '16px',
		border: '1px solid',
		borderColor: '#F4F5FA',
		borderRadius: '4px',
		'&:hover': {
			borderColor: '#485776',
		},
		minHeight: '45px',
		backgroundColor: '#F4F5FA',
	},
	'& .Mui-error': {
		borderColor: 'red'
	}
}));*/

const Form = (props: Props) => {
	const {t} = useTranslation(LOANS)
	const {decimalSeparator, thousandSeparator} = useUserData()
	const matchDownSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
	const queryParams = useQueryParams()
	const getLoanFromCalc = queryParams.get('LoanAmount')
	const getRevFromCalc = queryParams.get('RealEstateValue')
	
	//const [showGDPRfile, setShowGDPRfile] = useState(false)
	const [isRecommended, setIsRecommended] = useState(false);
	const [openModal, setOpenModal] = React.useState(false);
	const handleOpenModal = () => setOpenModal(true);
	const handleCloseModal = () => setOpenModal(false);
	
	const {
		id,
		loan,
		questionnaire,
		type,
		originLoan
	} = props
	const {language} = useUserData()
	
	const {formik, choices, isLoading} = useLoanForm({
		id,
		loan,
		questionnaire,
		type,
		originLoan,
		getLoanFromCalc,
		getRevFromCalc
	})
	
	const textareaStyle = {
		fontFamily: 'Arial, sans-serif',
		display: 'flex',
		height: '144px',
		padding: '8px 16px',
		flexDirection: 'column',
		alignItems: 'center',
		gap: '10px',
		alignSelf: 'stretch',
		borderRadius: '8px',
		border: ' 0px solid #CFD3D4',
		background: '#F4F5FA',
		overflow: 'auto',
		color: '#485776',
		fontSize: '16px'
	}
	
	const originList = useMemo(() => (
		Capacitor.getPlatform() === 'android' ?
			<>
				{!formik.values.origin === true ? <option value="">
					{''}
				</option> : null}
				{originLoan.map(item => (
					<option
						key={`origin-${item.id}`}
						value={item.id}
					>
						{textTranslate(language)({
							en: item.DescriptionEn,
							el: item.DescriptionEl
						})}
					</option>
				))}
			</> :
			originLoan.map(item => (
				<MenuItem
					key={`origin-${item.id}`}
					value={item.id}
				>
					{textTranslate(language)({
						en: item.DescriptionEn,
						el: item.DescriptionEl
					})}
				</MenuItem>
			))
	), [originLoan, language, formik.values.origin])
	
	const typeList = useMemo(() => (
		Capacitor.getPlatform() === 'android' ?
			<>
				{!formik.values.type === true ? <option value="">
					{''}
				</option> : null}
				{type.map(item => (
					<option
						key={`type-${item.id}`}
						value={item.id}
					>
						{textTranslate(language)({
							en: item.DescriptionEn,
							el: item.DescriptionEl
						})}
					</option>
				))}
			</> :
			type.map(item => (
				<MenuItem
					key={`type-${item.id}`}
					value={item.id}
				>
					{textTranslate(language)({
						en: item.DescriptionEn,
						el: item.DescriptionEl
					})}
				</MenuItem>
			))
	), [type, language, formik.values.type])
	
	useEffect(() => {
		if (isRecommended === false) {
			formik.setFieldValue('PartnerName', '');
		}
	}, [formik.values.origin]);
	
	return !isLoading ? (
		<FormikProvider value={formik}>
			{!matchDownSM &&
				<Breadcrumb>
					<Breadcrumb.Link iconComponent={HomeBreadcrumb} to={getRouteUrl(ROUTE_PAGE_HOME)}/>
					<Breadcrumb.Link to={getRouteUrl(ROUTE_PAGE_LOANS_INDEX)}>
						{t('Loans')}
					</Breadcrumb.Link>
					<Breadcrumb.Item>
						{!id ? t('Loan Request') : t('Edit Request')}
					</Breadcrumb.Item>
				</Breadcrumb>
			}
			<MainCard
				title={
					<Typography variant={"h4"}>
						{!id ? t('Loan Request') : t('Edit Loan Request')}
					</Typography>
				}
				sx={{
					border: '1px solid #0BC1B6',
					mt: 3
				}}
			>
				<Grid sx={{flexGrow: 1, maxWidth: '750px'}} container spacing={2}>
					<Grid item lg={12}>
						<Grid container spacing={1} sx={{mr: 2}}>
							<Grid item xs={12}>
								<Stack spacing={1}>
									<FormControl
										variant="standard"
										fullWidth
										error={
											formik.touched["RequestedAmount"] &&
											Boolean(formik.errors["RequestedAmount"])
										}
									>
										<FormLabel htmlFor="RequestedAmount" required>{t('Loan Amount (€)')}</FormLabel>
										<NumericFormat
											value={formik.values.RequestedAmount}
											onValueChange={(values, sourceInfo) => {
												formik.setFieldValue('RequestedAmount', values.floatValue ? values.floatValue : '')
											}}
											thousandSeparator={thousandSeparator}
											decimalSeparator={decimalSeparator}
											decimalScale={2}
											customInput={TextField}
											
											id="RequestedAmount"
											placeholder={t('Enter loan amount')}
											required
											onBlur={formik.handleBlur}
											name={`RequestedAmount`}
											color={"grey"}
											size={'small'}
											fullWidth
											inputProps={{maxLength: 100}}
											error={formik.touched.RequestedAmount && Boolean(formik.errors.RequestedAmount)}
										/>
										<FormHelperText>
											<ErrorMessage name={"RequestedAmount"}/>
										</FormHelperText>
									</FormControl>
								</Stack>
							</Grid>
							<Grid item xs={12}>
								<Stack spacing={1}>
									<FormControl
										variant="outlined"
										fullWidth
										error={
											formik.touched["type"] &&
											Boolean(formik.errors["type"])
										}
									>
										<FormLabel htmlFor="type" required>{t('Loan purpose')}</FormLabel>
										<Select
											native={Capacitor.getPlatform() === 'android'}
											id="type"
											labelId="type-label"
											autoComplete={"chrome-off"}
											name={"type"}
											value={formik.values.type}
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											size={'small'}
											IconComponent={ArrowDown2}
										>
											{typeList}
										</Select>
										{/*<NativeSelect
											sx={{
												'& .MuiNativeSelect-select': {
													pl: 1
												},
											}}
											defaultValue={''}
											input={<NativeInput/>}
											id="type"
											labelId="type"
											autoComplete={"chrome-off"}
											name={"type"}
											//defaultValue=""
											value={formik.values.type}
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											size={'small'}
											IconComponent={ArrowDown2}
										>
											{typeList}
										</NativeSelect>*/}
										<FormHelperText>
											<ErrorMessage name={"type"}/>
										</FormHelperText>
									</FormControl>
								</Stack>
							</Grid>
							<Grid item xs={12}>
								<Stack spacing={1}>
									<FormControl
										variant="outlined"
										fullWidth
										error={
											formik.touched["RealEstateValue"] &&
											Boolean(formik.errors["RealEstateValue"])
										}
									>
										<FormLabel
											htmlFor="RealEstateValue">{t('Property Commercial Value')}</FormLabel>
										<NumericFormat
											value={formik.values.RealEstateValue}
											onValueChange={(values, sourceInfo) => {
												formik.setFieldValue('RealEstateValue', values.floatValue ? values.floatValue : '')
											}}
											thousandSeparator={thousandSeparator}
											decimalSeparator={decimalSeparator}
											decimalScale={2}
											customInput={TextField}
											
											id="RealEstateValue"
											placeholder={t('Enter property commercial value')}
											required
											onBlur={formik.handleBlur}
											name={`RealEstateValue`}
											color={"grey"}
											size={'small'}
											autoComplete="on"
											fullWidth
											inputProps={{maxLength: 20}}
											error={formik.touched.RealEstateValue && Boolean(formik.errors.RealEstateValue)}
										/>
										<FormHelperText>
											<ErrorMessage name={"RealEstateValue"}/>
										</FormHelperText>
									</FormControl>
								</Stack>
							</Grid>
							<Grid item xs={12}>
								<Stack spacing={1}>
									<FormControl
										variant="outlined"
										fullWidth
										error={
											formik.touched["origin"] &&
											Boolean(formik.errors["origin"])
										}
									>
										<FormLabel htmlFor="origin"
												   required>{t('How did you learn about us')}</FormLabel>
										<Select
											id="origin"
											labelId="origin-label"
											autoComplete={"chrome-off"}
											name={"origin"}
											native={Capacitor.getPlatform() === 'android'}
											defaultValue=""
											IconComponent={ArrowDown2}
											value={formik.values.origin}
											onChange={(e) => {
												formik.handleChange(e);
												const originId = e.target.value;
												const foundItem = originLoan.find(item => item.id === originId && item.isRecommendation === '1');
												if (foundItem) {
													setIsRecommended(true);
												} else {
													setIsRecommended(false);
												}
											}}
											onBlur={formik.handleBlur}
											size={'small'}
										>
											{originList}
										</Select>
										<FormHelperText>
											<ErrorMessage name={"origin"}/>
										</FormHelperText>
									</FormControl>
								</Stack>
							</Grid>
							{isRecommended === true &&
								<Grid item xs={12}>
									<Stack spacing={1}>
										<FormControl
											variant="outlined"
											fullWidth
											error={
												formik.touched["PartnerName"] &&
												Boolean(formik.errors["PartnerName"])
											}
										>
											<FormLabel htmlFor="PartnerName">{t('Recommended by')}</FormLabel>
											<TextField
												fullWidth
												id="PartnerName"
												placeholder={t('Enter partner name')}
												required
												error={formik.touched.PartnerName && Boolean(formik.errors.PartnerName)}
												value={formik.values.PartnerName}
												name={`PartnerName`}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												autoComplete="on"
												color={"grey"}
												inputProps={{maxLength: 20}}
												size={'small'}
											/>
											<FormHelperText>
												<ErrorMessage name={"PartnerName"}/>
											</FormHelperText>
										</FormControl>
									</Stack>
								</Grid>}
							<Grid item xs={12}>
								<Stack spacing={1}>
									<FormLabel htmlFor="Comments">{t('Comments')}</FormLabel>
									<TextareaAutosize
										aria-label="maximum height"
										maxRows={4}
										value={formik.values.Comments}
										onChange={formik.handleChange}
										name={'Comments'}
										style={textareaStyle}
										size={'small'}
									/>
								</Stack>
							</Grid>
							{/*TODO::for radio buttons**/}
							{/*<Grid item xs={12}>
                                <Stack spacing={1}>
                                    <FormControl>
                                        <RadioGroup
                                            aria-labelledby="row-radio-buttons-group-label"
                                            name="isRealEstate"
                                            value={formik.values.isRealEstate}
                                            onChange={formik.handleChange}
                                        >
                                            <FormControlLabel value={"isRealEstate"} control={
                                                <Radio
                                                    size={'small'}
                                                />
                                            } label={t('I already found a property')}
                                                              labelPlacement="end"/>
                                            <FormControlLabel value={"SearchByPartner"} control={
                                                <Radio
                                                    size={'small'}
                                                />
                                            } label={t('I am looking for property')}
                                                              labelPlacement="end"/>
                                            <Typography
                                                color={'secondary'}>{t('I consent to give my personal data to third party real estate agencies for property finding based on the provided personal preferences.')}</Typography>
                                        </RadioGroup>
                                    </FormControl>
                                </Stack>
                            </Grid>*/}
							<Grid item xs={12}>
								<Stack spacing={1}>
									<FormControl variant="standard" fullWidth>
										<FormControlLabel
											control={
												<Checkbox
													//checked={selectedOption === 'isRealEstate'}
													checked={formik.values.isRealEstate}
													//onChange={formik.handleChange}
													onChange={(e) => {
														formik.handleChange(e);
														// Uncheck the other checkbox if this one is checked
														if (e.target.checked) {
															formik.setFieldValue('SearchByPartner', false);
														}
													}}
													//onChange={() => handleCheckboxChange('isRealEstate')}
													onBlur={formik.handleBlur}
													size={'small'}
													name={'isRealEstate'}
												/>
											} label={t('I already found a property')} labelPlacement="end"/>
									</FormControl>
								</Stack>
							</Grid>
							<Grid item xs={12}>
								<Stack spacing={1}>
									<FormControl variant="standard" fullWidth>
										<FormControlLabel
											control={
												<Checkbox
													checked={formik.values.SearchByPartner}
													//onChange={formik.handleChange}
													onChange={(e) => {
														formik.handleChange(e);
														// Uncheck the other checkbox if this one is checked
														if (e.target.checked) {
															formik.setFieldValue('isRealEstate', false);
														}
													}}
													//onChange={() => handleCheckboxChange('SearchByPartner')}
													onBlur={formik.handleBlur}
													//checked={selectedOption === 'SearchByPartner'}
													size={'medium'}
													name={'SearchByPartner'}
												/>
											} label={t('I am looking for property')} labelPlacement="end"/>
										<Typography
											color={'secondary'}>{t('I consent to give my personal data to third party real estate agencies for property finding based on the provided personal preferences.')}</Typography>
									</FormControl>
								</Stack>
							</Grid>
						</Grid>
					</Grid>
					{formik.values.SearchByPartner /*=== 'SearchByPartner'*/ &&
						<Grid item lg={12}>
							<QuestionnaireForm
								id={id}
								formik={formik}
								choices={choices}
							/>
						</Grid>}
				</Grid>
				<FormControl
					variant="standard"
					fullWidth
					error={
						formik.touched["GDPRchecked"] &&
						Boolean(formik.errors["GDPRchecked"])
					}
					sx={{pb: 1, mt: 4}}
				>
					<FormControlLabel required control=
						{
							<Switch
								checked={formik.values.GDPRchecked}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								size={'small'}
								name={'GDPRchecked'}
								//onClick={() => setShowGDPRfile(true)}
							
							/>
						} label={
						<Typography sx={{
							fontSize: '12px',
							fontStyle: 'normal',
							fontWeight: 600,
							lineHeight: 'normal'
						}}>
							{t('I agree with the')}{' '}
							{/*<Link
								variant="caption"
								href={getRouteUrl(ROUTE_PAGE_PRIVACY_POLICY)}
								target={!Capacitor.isNativePlatform() && "_blank"}
								underline={"always"}
								color={theme.palette.primary[100]}
								sx={{
									fontSize: '12px',
									fontStyle: 'normal',
									fontWeight: 600,
									lineHeight: 'normal',
									textDecorationLine: 'underline'
								}}
							>
								{t('Terms and Policies')}
							</Link>*/}
							<Button
								onClick={handleOpenModal}
								sx={{
									fontSize: '12px',
									fontStyle: 'normal',
									fontWeight: 600,
									lineHeight: 'normal',
									textDecorationLine: 'underline',
									m: 0,
									p: 0
								}}
							>
								{t('Terms and Policies')}
							</Button>
						</Typography>
					} labelPlacement={"end"}
					/>
					<FormHelperText>
						<ErrorMessage name={"GDPRchecked"}/>
					</FormHelperText>
				</FormControl>
				<Dialog onClose={handleCloseModal} open={openModal}>
					<PrivacyPolicy isModal={true} handleCloseModal={handleCloseModal}/>
				</Dialog>
				{/* {!formik.values.GDPRchecked !== true &&
                    <GdprUpload
                        formik={formik}
                        show={showGDPRfile}
                        onClose={() => {
                            setShowGDPRfile(false);
                        }}
                    />
                }*/}
				<Grid container sx={{flexGrow: 1}} spacing={2}>
					<Grid item xs={12} sm={12} md={12} lg={12}>
						<Divider sx={{mt: '32px', mb: '16px'}}/>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12} sx={{display: 'flex', gap: '30px', mb: '16px'}}>
						<Button
							size={"small"}
							variant={"contained"}
							color={'info'}
							sx={{
								padding: '17px 54px',
								justifyContent: 'center',
								alignItems: 'center'
							}}
							onClick={formik.submitForm}
						>
							{t('Save')}
						</Button>
						<Button size={"small"}
								variant={"contained"}
								sx={{
									padding: '17px 54px',
									justifyContent: 'center',
									alignItems: 'center'
								}}
								color={"error"}
								component={RouterLink}
								to={getRouteUrl(ROUTE_PAGE_LOANS_INDEX)}
						>
							{t('Cancel')}
						</Button>
					</Grid>
				</Grid>
			</MainCard>
		</FormikProvider>
	) : (
		<ContentLoader
			withBackDrop={true}
			useCircularLoader={true}
			loading={isLoading}
			message={t("Loading... Please don't close the browser before the process ends.")}
		/>
	)
}
export default Form