import * as React from "react";
const VisibleEyeOn = (props) => (
    <svg
        width={16}
        height={16}
        viewBox="0 0 20 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g
            fillRule="evenodd"
            clipRule="evenodd"
            stroke="#485776"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path d="M13.16 8.053a3.162 3.162 0 1 1-6.322-.002 3.162 3.162 0 0 1 6.323.002Z" />
            <path d="M9.998 15.354c3.808 0 7.291-2.738 9.252-7.302C17.289 3.488 13.806.75 9.998.75h.004C6.194.75 2.711 3.488.75 8.052c1.961 4.564 5.444 7.302 9.252 7.302h-.004Z" />
        </g>
    </svg>
);
export default VisibleEyeOn;