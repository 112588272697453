// material-ui
import {Box} from '@mui/material';

// project import
import getColors from "../../utils/getColors";
import CheckIcon from "../../../../assets/images/icons/buttonsIcons/checkIcon";

// ==============================|| RADIO - COLORS ||============================== //

function getColorStyle({color, theme}) {
    const colors = getColors(theme, color);
    const {lighter, main, dark} = colors;

    return {
        '& .dot': {
            backgroundColor: theme.palette.secondary[100],
            width: '20px',
            height: '20px',
            borderRadius: '6px'
        },
        '&:hover': {
            //backgroundColor: '#96D9D7'
        },
        '&.Mui-focusVisible': {
            // borderRadius: '6px'
            // outline: `2px solid ${dark}`,
            // outlineOffset: -4
        }
    };
}

// ==============================|| CHECKBOX - SIZE STYLE ||============================== //

function getSizeStyle(size) {
    switch (size) {
        case 'small':
            return {size: 24, dotSize: 20, position: 1};
        case 'large':
            return {size: 24, dotSize: 12, position: 5};
        case 'medium':
        default:
            return {size: 20, dotSize: 10, position: 4};
    }
}

// ==============================|| CHECKBOX - STYLE ||============================== //

function radioStyle(size) {
    const sizes = getSizeStyle(size);

    return {
        '&.Mui-checked': {
            border: '#0BC1B6',
        },
        border: '#485776',
        '& .icon': {
            width: sizes.size,
            height: sizes.size,
            borderRadius: '8px',
            position: 'relative',
            '& .dot': {
                width: sizes.dotSize,
                height: sizes.dotSize,
                top: sizes.position,
                left: sizes.position,
                position: 'absolute',
                '& svg': {
                    width: '100%',
                    height: '100%',
                },
            },
        },
    };
}

// ==============================|| OVERRIDES - CHECKBOX ||============================== //

export default function Radio(theme) {
    const {palette} = theme;

    return {
        MuiRadio: {
            defaultProps: {
                className: 'size-small',
                icon:
                    <Box
                        className="icon"
                        sx={{
                            width: 16,
                            height: 16,
                            border: '1px solid',
                            borderColor: 'inherit',
                            borderRadius: '50%'
                        }}
                    />,
                checkedIcon: (
                    <Box
                        className="icon"
                        sx={{
                            width: 12,
                            height: 12,
                            border: '1px solid',
                            borderColor: 'inherit',
                            borderRadius: '50%',
                            position: 'relative'
                        }}
                    >
                        <CheckIcon
                            className="dot"
                            /*sx={{
                                width: 8,
                                height: 8,
                                backgroundColor: 'inherit',
                                borderRadius: '50%',
                                position: 'absolute',
                                top: 3,
                                left: 3
                            }}*/
                        />
                    </Box>
                )
            },
            styleOverrides: {
                root: {
                    color: palette.secondary[100],
                    '&.size-small': {
                        ...radioStyle('small')
                    },
                    '&.size-medium': {
                        ...radioStyle('medium')
                    },
                    '&.size-large': {
                        ...radioStyle('large')
                    }
                },
                colorPrimary: getColorStyle({color: 'primary', theme}),
                colorSecondary: getColorStyle({color: 'secondary', theme}),
                colorSuccess: getColorStyle({color: 'success', theme}),
                colorWarning: getColorStyle({color: 'warning', theme}),
                colorInfo: getColorStyle({color: 'info', theme}),
                colorError: getColorStyle({color: 'error', theme})
            }
        }
    };
}
