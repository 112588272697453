import * as React from "react";
const DangerTriangleIcon = (props) => (
    <svg
        width={28}
        height={24}
        viewBox="0 0 28 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g stroke="#CC5F5F" strokeLinecap="round" strokeLinejoin="round">
            <path
                clipRule="evenodd"
                d="M4.419 23.25h19.177c2.11 0 3.433-2.28 2.385-4.113L16.4 2.384C15.345.54 12.687.538 11.63 2.382L2.034 19.136c-1.048 1.832.275 4.113 2.386 4.113Z"
                strokeWidth={1.5}
            />
            <path d="M14.002 13.886V9.753" strokeWidth={1.5} />
            <path d="M13.993 18h.014" strokeWidth={2} />
        </g>
    </svg>
);
export default DangerTriangleIcon;