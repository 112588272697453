// @flow
import * as React from 'react';
import {ErrorMessage, FormikProvider} from "formik";
import {USERS} from "../../locales/namespaces";
import {useTranslation} from "react-i18next";
import {
	Box, Button, FormControl, FormControlLabel, FormHelperText, Grid,
	FormLabel, Select, Stack, Toolbar, TextField, MenuItem, IconButton,
	InputAdornment, Typography, Divider, Autocomplete, Switch, useMediaQuery
} from "@mui/material";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {getRouteUrl} from "../../../../helpers/getRouteUrl";
import {ROUTE_PAGE_USERS_DETAILS, ROUTE_PAGE_USERS_INDEX} from "../../routes/routes";
import useUserForm from "../../hooks/useUserForm";
import Breadcrumb from "../../../../components/breadcrumb/breadcrumb";
import {ROUTE_PAGE_HOME} from "../../../../routers/routes";
import useUserData from "../../../../hooks/user/useUserData";
import {textTranslate} from "../../../../helpers/helpers";
import {useEffect, useMemo, useState} from "react";
import {Link} from "react-router-dom";
import PermissionHOC from "../../../permission/components/permissionHOC/permissionHOC";
import MainCard from "../../../../theme/mantis/components/mainCard";
import axios from "axios";
import {API} from "../../../../helpers/constants";
import {enqueueSnackbar} from "notistack";
import {handleAxiosErrors} from "../../../../helpers/errorHandling";
import ContentLoader from "../../../../components/contentLoader/contentLoader";
import {useHasPermission} from "../../../permission/hooks/useHasPermission";
import HomeBreadcrumbBlue from "../../../../assets/images/icons/homeBreadcrumbBlue";
import ArrowDown2 from "../../../../assets/images/icons/arrowDown2";
import HomeBreadcrumb from "../../../../assets/images/icons/homeBreadcrumb";
import {Capacitor} from "@capacitor/core";

type Props = {
	id: string,
	user?: Object,
	partner?: Object,
	countries: Array,
	currencies: Array,
	languages: Array,
	nationalities: Array,
	partners: Array,
	//dateFormats: Array
}

const Form = (props: Props) => {
	const {t} = useTranslation(USERS)
	const {
		id,
		user,
		partner,
		countries,
		currencies,
		nationalities,
		languages,
		partners
		//dateFormats
	} = props
	const {language} = useUserData()
	const matchDownSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
	
	
	const isPartner = useHasPermission(['edit_usr_isPartner', 'view_usr_isPartner'], {
		shouldView: true,
		shouldEdit: true
	})
	
	const {formik, showPassword, handleClickShowPassword, handleMouseDownPassword, isLoading} = useUserForm({
		id,
		user,
		languages,
		countries,
		currencies,
		nationalities,
		partners,
		partner,
		isPartner
		//dateFormats
	})
	const [partnerList, setPartnerList] = useState(partners)
	
	const nationalityList = useMemo(() => (
		Capacitor.getPlatform() === 'android' ?
			<>
				{!formik.values.nationality === true ? <option value="">
					{''}
				</option> : null}
				{nationalities.map(item => (
					<option
						key={`nationality-${item.id}`}
						value={item.id}
					>
						{textTranslate(language)({
							en: item.DescriptionEn,
							el: item.Description
						})}
					</option>
				))}
			</> :
			nationalities.map(item => (
				<MenuItem
					key={`nationality-${item.id}`}
					value={item.id}
				>
					{textTranslate(language)({
						en: item.DescriptionEn,
						el: item.Description
					})}
				</MenuItem>
			))), [nationalities, language, formik.values.nationality])
	
	const currenciesList = useMemo(() => (
		Capacitor.getPlatform() === 'android' ?
			<>
				{!formik.values.currency === true ? <option value="">
					{''}
				</option> : null}
				{currencies.map(item => (
					<option
						key={`currency-${item.id}`}
						value={item.id}
					>
						{item.Description}
					</option>
				))}
			</> :
			currencies.map(item => (
				<MenuItem
					key={`currency-${item.id}`}
					value={item.id}
				>
					{item.Description}
				</MenuItem>
			))
	), [currencies, language, formik.values.currency])
	
	const handleSearch = (evt, name) => {
		let url
		let setter
		switch (name) {
			case 'partnerList':
				url = `${API}/partners/data`
				setter = setPartnerList
				break
			default:
				break
		}
		axios({
			method: 'post',
			data: {'query': evt.target.value},
			url: url
		}).then((response) => {
			setter(response.data.data)
		}).catch((error) => {
			enqueueSnackbar(handleAxiosErrors(error, t('Failed to get partners list.')), {
					variant: 'error'
				}
			)
		})
	}
	
	useEffect(() => {
		if (!formik.values.isPartner) {
			// Clear the partner value when isPartner is false
			formik.setFieldValue('partner', '');
			if (!id) {
				formik.setFieldValue('LoginName', '');
				formik.setFieldValue('username', '');
				formik.setFieldValue('email', '');
			}
			formik.setFieldValue('isAdmin', '0');
		}
	}, [formik.values.isPartner]);
	
	return !isLoading ? (
		<FormikProvider value={formik}>
			<PermissionHOC requiredPermissions={['access_user_index']}>
				<Breadcrumb>
					<Breadcrumb.Link iconComponent={HomeBreadcrumb} to={getRouteUrl(ROUTE_PAGE_HOME)}/>
					<Breadcrumb.Link to={getRouteUrl(ROUTE_PAGE_USERS_INDEX)}>
						{t('Users')}
					</Breadcrumb.Link>
					<Breadcrumb.Item>
						{!id ? t('Create') : t('Edit')}
					</Breadcrumb.Item>
				</Breadcrumb>
			</PermissionHOC>
			{!matchDownSM &&
				<PermissionHOC requiredPermissions={['access_partner_breadcrumb']}>
					<Breadcrumb>
						<Breadcrumb.Link iconComponent={HomeBreadcrumbBlue} to={getRouteUrl(ROUTE_PAGE_HOME)}/>
					</Breadcrumb>
				</PermissionHOC>
			}
			<MainCard
				sx={{
					mt: 2,
					border: '1px solid #0BC1B6',
				}}
				title={
					<Toolbar disableGutters variant={"dense"}>
						<Typography variant={"h4"} sx={{ml: 2}}>
							{!id ? t('Create User') : t('Edit Profile')}
						</Typography>
						<Box sx={{flexGrow: 1}}/>
					</Toolbar>
				}
			>
				<Grid sx={{flexGrow: 1, maxWidth: '750px'}} container spacing={2}>
					<Grid item xs={12}>
						<Grid container>
							<PermissionHOC requiredPermissions={['edit_usr_isPartner', 'view_usr_isPartner']}>
								<Grid item xs={12} md={6} lg={6}>
									<Stack spacing={1}>
										<FormControl variant="standard" fullWidth>
											<FormControlLabel
												sx={{
													".Mui-disabled": {
														'WebkitTextFillColor': "#485776",
													}
												}}
												control={
													<Switch
														disabled={id}
														checked={formik.values.isPartner}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														size={'small'}
														name={'isPartner'}
													/>
												}
												label={t('Partner')}
												labelPlacement="end"
											/>
										</FormControl>
									</Stack>
								</Grid>
							</PermissionHOC>
							<PermissionHOC requiredPermissions={['edit_usr_isactive', 'view_usr_isactive']}>
								<Grid item xs={12} md={6} lg={6}>
									<Stack spacing={1}>
										<FormControl variant="standard" fullWidth>
											<FormControlLabel
												control={
													<Switch
														checked={formik.values.isActive}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														size={'small'}
														name={'isActive'}
													/>
												}
												label={t('Active')}
												labelPlacement="end"
											/>
										</FormControl>
									</Stack>
								</Grid>
							</PermissionHOC>
							{formik.values.isPartner === true &&
								<PermissionHOC requiredPermissions={['edit_usr_isPartner', 'view_usr_isPartner']}>
									<Grid item xs={12} md={6} lg={6}>
										<Stack spacing={1}>
											<FormControl variant="standard" fullWidth>
												<FormControlLabel
													control={
														<Switch
															checked={formik.values.AccessApi}
															onChange={formik.handleChange}
															onBlur={formik.handleBlur}
															size={'small'}
															name={'AccessApi'}
														/>
													}
													label={t('Access Api')}
													labelPlacement="end"
												/>
											</FormControl>
										</Stack>
									</Grid>
								</PermissionHOC>
							}
							{formik.values.isPartner === true &&
								<PermissionHOC requiredPermissions={['edit_usr_isPartner', 'view_usr_isPartner']}>
									<Grid item xs={12} md={6} lg={6}>
										<Stack spacing={1}>
											<FormControl variant="standard" fullWidth>
												<FormControlLabel
													control={
														<Switch
															checked={formik.values.SendEmails}
															onChange={formik.handleChange}
															onBlur={formik.handleBlur}
															size={'small'}
															name={'SendEmails'}
														/>
													}
													label={t('Send Emails')}
													labelPlacement="end"
												/>
											</FormControl>
										</Stack>
									</Grid>
								</PermissionHOC>
							}
							<PermissionHOC requiredPermissions={['edit_usr_isPartner', 'view_usr_isPartner']}>
								{formik.values.isPartner === true &&
									<Grid item xs={12} md={12} lg={12}>
										<Stack>
											<FormControl
												variant="outlined"
												fullWidth
												error={
													formik.touched["partner"] &&
													Boolean(formik.errors["partner"])
												}
											>
												<FormLabel htmlFor="partner" required>{t('Partner')}</FormLabel>
												<Autocomplete
													popupIcon={<ArrowDown2/>}
													disabled={id}
													options={partnerList}
													value={formik.values.partner}
													isOptionEqualToValue={(opt, val) => {
														return opt.GID === val || !val
													}}
													getOptionLabel={option => {
														return !option ? '' : typeof option === 'string' ? partnerList.find(opt => opt.id === option)?.FullName : option?.FullName
													}}
													// onChange={(event, newValue) => {
													//     formik.setFieldValue("partner", newValue)
													// }}
													onChange={(event, newValue) => {
														formik.setFieldValue('partner', newValue);
														if (newValue) {
															// Update the formik values for LoginName, username, and email
															formik.setFieldValue('LoginName', newValue.LoginName);
															formik.setFieldValue('username', newValue.username);
															formik.setFieldValue('email', newValue.email ? newValue.email : '');
															formik.setFieldValue('isAdmin', newValue.isAdmin);
														} else {
															// Clear the values if no partner is selected
															formik.setFieldValue('LoginName', '');
															formik.setFieldValue('username', '');
															formik.setFieldValue('email', '');
															formik.setFieldValue('isAdmin', '');
														}
													}}
													onBlur={formik.handleBlur}
													size="small"
													fullWidth
													renderInput={(params) => (
														<TextField
															placeholder={t("Choose partner")}
															size={"small"}
															{...params}
															sx={{
																".Mui-disabled": {
																	'WebkitTextFillColor': "#485776"
																},
																"& fieldset": {border: 'none'},
															}}
															onChange={(event) => handleSearch(event, 'partnerList')}
															required
															error={formik.touched.partner && Boolean(formik.errors.partner)}
															inputProps={{
																...params.inputProps,
																autoComplete: 'off',
															}}
														/>
													)}
													//renderOption={(props, option) => <li {...props}>{option.FullName}</li>}
												/>
												<FormHelperText sx={{marginLeft: 0}}>
													<ErrorMessage name={"partner"}/>
												</FormHelperText>
											</FormControl>
										</Stack>
									</Grid>}
							</PermissionHOC>
							{formik.values.isPartner === true &&
								<PermissionHOC requiredPermissions={['edit_usr_isPartner', 'view_usr_isPartner']}>
									<Grid item xs={12} md={12} lg={12}>
										<Stack>
											<FormControl
												variant="standard"
												fullWidth
											>
												<FormLabel htmlFor="PublicIp">{t('Public Ip')}</FormLabel>
												<TextField
													id="PublicIp"
													placeholder={t("Enter Public Ip")}
													name={`PublicIp`}
													value={formik.values.PublicIp}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													color={"grey"}
													size={'small'}
													fullWidth
													inputProps={{maxLength: 20}}
												/>
											</FormControl>
										</Stack>
									</Grid>
								</PermissionHOC>
							}
						</Grid>
					</Grid>
					<Grid item lg={6}>
						<Grid container spacing={1} sx={{mr: 2}}>
							<Grid item xs={12}>
								<Stack spacing={1}>
									<FormControl
										variant="standard"
										fullWidth
										error={
											formik.touched["LoginName"] &&
											Boolean(formik.errors["LoginName"])
										}
									>
										<FormLabel htmlFor="LoginName" required>{t('Name')}</FormLabel>
										<TextField
											id="LoginName"
											placeholder={t("Enter name")}
											name={`LoginName`}
											value={formik.values.LoginName}
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											required
											error={formik.touched.LoginName && Boolean(formik.errors.LoginName)}
											color={"grey"}
											size={'small'}
											fullWidth
											inputProps={{maxLength: 100}}
										/>
										<FormHelperText>
											<ErrorMessage name={"LoginName"}/>
										</FormHelperText>
									</FormControl>
								</Stack>
							</Grid>
							<Grid item xs={12}>
								<Stack spacing={1}>
									<FormControl
										variant="standard"
										fullWidth
										error={
											formik.touched["username"] &&
											Boolean(formik.errors["username"])
										}>
										<FormLabel htmlFor="username" required>{t('User Name')}</FormLabel>
										<TextField
											name={`username`}
											placeholder={t("Enter username")}
											required
											error={formik.touched.username && Boolean(formik.errors.username)}
											value={formik.values.username}
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											color={"grey"}
											size={'small'}
											fullWidth
											inputProps={{maxLength: 100}}
										/>
										<FormHelperText>
											<ErrorMessage name={"username"}/>
										</FormHelperText>
									</FormControl>
								</Stack>
							</Grid>
							<PermissionHOC requiredPermissions={['edit_usr_password', 'view_usr_password']}>
								<Grid item xs={12}>
									<Stack spacing={1}>
										<FormControl
											variant="standard"
											fullWidth
											error={
												formik.touched["password"] &&
												Boolean(formik.errors["password"])
											}
										>
											<FormLabel htmlFor="password" required={!id}>{t('Password')}</FormLabel>
											<TextField
												placeholder={t("Enter password")}
												type={showPassword ? 'text' : 'password'}
												value={formik.values.password}
												name={`password`}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												fullWidth
												autoComplete="new-password"
												color={"grey"}
												size={'small'}
												required
												error={formik.touched.password && Boolean(formik.errors.password)}
												InputProps={{
													minLength: 7,
													endAdornment:
														<InputAdornment position="end">
															<IconButton
																aria-label="toggle password visibility"
																onClick={handleClickShowPassword}
																onMouseDown={handleMouseDownPassword}
																edge="end"
															>
																{showPassword ? <VisibilityOff/> : <Visibility/>}
															</IconButton>
														</InputAdornment>
												}}
											/>
											<FormHelperText>
												<ErrorMessage name={"password"}/>
											</FormHelperText>
										</FormControl>
									</Stack>
								</Grid>
								<Grid item xs={12}>
									<Stack spacing={1}>
										<FormControl
											variant="standard"
											fullWidth
											error={
												formik.touched["confirmPassword"] &&
												Boolean(formik.errors["confirmPassword"])
											}
										>
											<FormLabel htmlFor="confirmPassword"
													   required={!id}>{t('Confirm Password')}</FormLabel>
											<TextField
												placeholder={t("Enter confirm password")}
												type={showPassword ? 'text' : 'password'}
												value={formik.values.confirmPassword}
												name={`confirmPassword`}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												fullWidth
												color={"grey"}
												size={'small'}
												autoComplete="on"
												required
												error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
												InputProps={{
													minLength: 7,
													endAdornment:
														<InputAdornment position="end">
															<IconButton
																aria-label="toggle password visibility"
																onClick={handleClickShowPassword}
																onMouseDown={handleMouseDownPassword}
																edge="end"
															>
																{showPassword ? <VisibilityOff/> : <Visibility/>}
															</IconButton>
														</InputAdornment>
												}}
											/>
											<FormHelperText>
												<ErrorMessage name={"confirmPassword"}/>
											</FormHelperText>
										</FormControl>
									</Stack>
								</Grid>
							</PermissionHOC>
							<Grid item xs={12}>
								<Stack spacing={1}>
									<FormControl
										variant="standard"
										fullWidth
										error={
											formik.touched["email"] &&
											Boolean(formik.errors["email"])
										}
									>
										<FormLabel htmlFor="email" required>{t('Email')}</FormLabel>
										<TextField
											placeholder={t("Enter email")}
											type={'email'}
											value={formik.values.email}
											name={`email`}
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											fullWidth
											color={"grey"}
											size={'small'}
											required
											error={formik.touched.email && Boolean(formik.errors.email)}
											inputProps={{
												maxLength: 150,
												autoComplete: 'off',
											}}
										/>
										<FormHelperText>
											<ErrorMessage name={"email"}/>
										</FormHelperText>
									</FormControl>
								</Stack>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={6}>
						<Grid container spacing={1} sx={{mr: 2}}>
							<Grid item xs={12}>
								<Stack spacing={1}>
									<FormControl
										variant="outlined"
										fullWidth
										error={
											formik.touched["country"] &&
											Boolean(formik.errors["country"])
										}
									>
										<FormLabel htmlFor="country" required>{t('Country')}</FormLabel>
										<Autocomplete
											popupIcon={<ArrowDown2/>}
											options={countries}
											value={formik.values.country}
											isOptionEqualToValue={(opt, val) => {
												return opt.id === val.id || !val
											}}
											getOptionLabel={option => textTranslate(language)({
												en: option.DescriptionEn,
												el: option.DescriptionEl
											})}
											onChange={(event, newValue) => {
												formik.setFieldValue("country", newValue)
											}}
											onBlur={formik.handleBlur}
											size="small"
											fullWidth
											renderInput={(params) => (
												<TextField
													placeholder={t("Choose country")}
													size={"small"}
													{...params}
													required
													error={formik.touched.country && Boolean(formik.errors.country)}
													inputProps={{
														...params.inputProps,
														autoComplete: 'off',
													}}
												/>
											)}
										/>
										<FormHelperText sx={{marginLeft: 0}}>
											<ErrorMessage name={"country"}/>
										</FormHelperText>
									</FormControl>
								</Stack>
							</Grid>
							<Grid item xs={12}>
								<Stack spacing={1}>
									<FormControl
										variant="outlined"
										fullWidth
										error={
											formik.touched["nationality"] &&
											Boolean(formik.errors["nationality"])
										}
									>
										<FormLabel htmlFor="nationality" required>{t('Nationality')}</FormLabel>
										<Select
											native={Capacitor.getPlatform() === 'android'}
											labelId="nationality"
											autoComplete={"chrome-off"}
											size={"small"}
											name={"nationality"}
											value={formik.values.nationality}
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											IconComponent={ArrowDown2}
										>
											{nationalityList}
										</Select>
										<FormHelperText>
											<ErrorMessage name={"nationality"}/>
										</FormHelperText>
									</FormControl>
								</Stack>
							</Grid>
							<Grid item xs={12}>
								<Stack spacing={1}>
									<FormControl
										variant="outlined"
										fullWidth
										error={
											formik.touched["currency"] &&
											Boolean(formik.errors["currency"])
										}
									>
										<FormLabel htmlFor="currency" required>{t('Currency')}</FormLabel>
										<Select
											native={Capacitor.getPlatform() === 'android'}
											labelId="currency"
											autoComplete={"chrome-off"}
											size={"small"}
											name={"currency"}
											value={formik.values.currency}
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											IconComponent={ArrowDown2}
										>
											{currenciesList}
										</Select>
										<FormHelperText>
											<ErrorMessage name={"currency"}/>
										</FormHelperText>
									</FormControl>
								</Stack>
							</Grid>
							<Grid item xs={12}>
								<Stack spacing={1}>
									<FormControl
										variant="outlined"
										fullWidth
										error={
											formik.touched["language"] &&
											Boolean(formik.errors["language"])
										}
									>
										<FormLabel htmlFor="language" required>{t('Language')}</FormLabel>
										<Autocomplete
											popupIcon={<ArrowDown2/>}
											options={languages}
											value={formik.values.language}
											isOptionEqualToValue={(opt, val) => {
												return opt.id === val.id || !val
											}}
											getOptionLabel={option => option?.Description}
											onChange={(event, newValue) => {
												formik.setFieldValue("language", newValue)
											}}
											onBlur={formik.handleBlur}
											size="small"
											fullWidth
											renderInput={(params) => (
												<TextField
													placeholder={t("Choose language")}
													size={"small"}
													{...params}
													required
													error={formik.touched.language && Boolean(formik.errors.language)}
													inputProps={{
														...params.inputProps,
														autoComplete: 'off',
													}}
												/>
											)}
										/>
										<FormHelperText sx={{marginLeft: 0}}>
											<ErrorMessage name={"language"}/>
										</FormHelperText>
									</FormControl>
								</Stack>
							</Grid>
							{/* <FormControl
                        variant="outlined"
                        fullWidth
                        error={
                            formik.touched["DateFormatId"] &&
                            Boolean(formik.errors["DateFormatId"])
                        }
                        sx={{mb: 2}}
                    >
                        <FormLabel id="DateFormatId-label">{t('Date Format')}</FormLabel>
                        <Select
                            labelId="DateFormatId-label"
                            autoComplete={"chrome-off"}
                            size={"small"}
                            name={"DateFormatId"}
                            value={formik.values.DateFormatId}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            label={t('Date Format')}
                        >
                            {dateFormats.map(item => (
                                <MenuItem
                                    key={`DateFormatId-${item.id}`}
                                    value={item.id}
                                >
                                    {item.Description}
                                </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText>
                            <ErrorMessage name={"DateFormatId"}/>
                        </FormHelperText>
                    </FormControl>*/}
							{/*//TODO:: used when decimal change works*/}
							{/* <FormControl
                        variant="standard"
                        fullWidth
                        error={
                            formik.touched["DecimalChar"] &&
                            Boolean(formik.errors["DecimalChar"])
                        }
                        sx={{mb: 2}}
                    >
                        <TextField
                            type={'text'}
                            label={t('Decimal Char')}
                            name={`DecimalChar`}
                            value={formik.values.DecimalChar}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            fullWidth
                            color={"grey"}
                            size={'small'}
                            inputProps={{maxLength: 1}}
                        />
                        <FormHelperText>
                            <ErrorMessage name={"DecimalChar"}/>
                        </FormHelperText>
                    </FormControl>*/}
						</Grid>
					</Grid>
				</Grid>
				<Grid container sx={{flexGrow: 1}} spacing={2}>
					<Grid item xs={12} sm={12} md={12} lg={12}>
						<Divider sx={{mt: '32px', mb: '16px'}}/>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12} sx={{display: 'flex', gap: '30px', mb: '16px'}}>
						<Button
							size={"small"}
							variant={"contained"}
							color={'info'}
							sx={{
								padding: '17px 54px',
								justifyContent: 'center',
								alignItems: 'center'
							}}
							onClick={formik.submitForm}
						>
							{t('Save')}
						</Button>
						<Button
							size={"small"}
							variant={"contained"}
							color={"error"}
							sx={{
								padding: '17px 54px',
								justifyContent: 'center',
								alignItems: 'center'
							}}
							component={Link}
							to={!id ? getRouteUrl(ROUTE_PAGE_USERS_INDEX) : getRouteUrl(ROUTE_PAGE_USERS_DETAILS, {id: id})}
						>
							{t('Cancel')}
						</Button>
					</Grid>
				</Grid>
			</MainCard>
		</FormikProvider>
	) : (
		<ContentLoader
			withBackDrop={true}
			useCircularLoader={true}
			loading={isLoading}
			message={t("Loading... Please don't close the browser before the process ends.")}
		/>
	)
}
export default Form