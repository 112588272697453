import * as React from "react";
const PaperUpload = (props) => (
    <svg
        width={30}
        height={36}
        viewBox="0 0 30 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.375 19.678a1.267 1.267 0 0 0 1.804 0 1.282 1.282 0 0 0 .018-1.823l-4.931-4.997a1.332 1.332 0 0 0-.413-.278 1.143 1.143 0 0 0-.48-.104c-.173 0-.345.035-.5.104-.154.07-.291.156-.411.278l-4.914 4.997a1.297 1.297 0 0 0 0 1.823 1.267 1.267 0 0 0 1.804 0l2.732-2.777v8.382c0 .711.566 1.284 1.288 1.284.705 0 1.272-.573 1.272-1.284V16.9l2.731 2.777Zm9.341-6.706c.404-.005.844-.01 1.244-.01.43 0 .79.347.79.78v13.953c0 4.303-3.47 7.791-7.732 7.791H8.36c-4.484 0-8.109-3.661-8.109-8.173V8.606C.25 4.303 3.703.78 7.999.78h9.174c.43 0 .79.365.79.798v5.588c0 3.176 2.578 5.779 5.74 5.796.722 0 1.364.005 1.927.01a83.31 83.31 0 0 0 2.086 0Zm.48-2.533c-1.412.005-3.075 0-4.272-.012-1.9 0-3.466-1.581-3.466-3.5V2.352c0-.747.897-1.119 1.411-.58l5.06 5.315 1.859 1.952c.5.525.132 1.398-.591 1.4Z"
            fill="#0BC1B6"
        />
    </svg>
);
export default PaperUpload;