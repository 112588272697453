import * as React from "react";
const WarningIcon = (props) => (
    <svg
        width={25}
        height={22.5}
        style={{marginRight: '32px'}}
        viewBox="0 0 28 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g stroke="#fff" strokeLinecap="round" strokeLinejoin="round">
            <path
                clipRule="evenodd"
                d="M4.42 23.249h19.176c2.11 0 3.434-2.28 2.386-4.112L16.4 2.384C15.346.54 12.687.538 11.631 2.382L2.034 19.136c-1.048 1.832.274 4.113 2.385 4.113Z"
                strokeWidth={1.5}
            />
            <path d="M14.003 13.886V9.753" strokeWidth={1.5} />
            <path d="M13.994 18h.013" strokeWidth={2} />
        </g>
    </svg>
);
export default WarningIcon;