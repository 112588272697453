import * as React from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Box,
	Grid
} from '@mui/material';
import {Trans, useTranslation} from "react-i18next";
import {USERS} from "../../locales/namespaces";
import axios from "axios";
import {API} from "../../../../helpers/constants";
import {enqueueSnackbar} from "notistack";
import {handleAxiosErrors, logoutUnauthorizedUser} from "../../../../helpers/errorHandling";
import useUserData from "../../../../hooks/user/useUserData";
import {getRouteUrl} from "../../../../helpers/getRouteUrl";
import {useHistory} from "react-router-dom";
import {ROUTE_PAGE_USERS_INDEX} from "../../routes/routes";
import useUserAction from "../../../../hooks/user/useUserAction";
import DangerTriangleIcon from "../../../../assets/images/dangerTriangleIcon";

type Props = {
	id: string,
	handleClose: Function,
	open: Array
}

export default function DeleteUserAccount(props: Props) {
	const {t} = useTranslation(USERS)
	const history = useHistory()
	const {userLogout} = useUserAction()
	const {token, isLoggedIn} = useUserData()
	const {
		id,
		handleClose,
		open
	} = props
	
	const handleDelete = () => {
		axios({
			method: 'post',
			headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
			url: `${API}/user/remove`,
			data: {
				id: id
			}
		}).then(() => {
			enqueueSnackbar(t('Your account deleted successfully!'), {
					variant: 'success'
				}
			)
			history.push(getRouteUrl(ROUTE_PAGE_USERS_INDEX))
		}).catch(error => {
			enqueueSnackbar(handleAxiosErrors(error, t('The account cannot be deleted because there is a registered loan.')), {
					variant: 'error'
				}
			)
			handleClose()
			logoutUnauthorizedUser(error, isLoggedIn, userLogout)
		})
	}
	
	return (
		<Box sx={{boxShadow: '0px 4px 32px 0px rgba(61, 70, 112, 0.15)'}}>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				sx={{
					borderRadius: '12px'
				}}
			>
				<div style={{
					display: 'flex',
					padding: '5px 3.652px 4.751px 3.667px',
					justifyContent: 'center',
					alignItems: 'center',
					flexShrink: 0,
					paddingTop: '18px'
				}}>
					<DangerTriangleIcon/>
				</div>
				<Grid
					sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						paddingLeft: '24px',
						paddingRight: '24px'
					}}>
					<DialogTitle sx={{textAlign: 'center', fontSize: '20px'}} id="alert-dialog-title" variant={'h4'}>
						<Trans
							i18nKey={'delete_account_for_admin'}
							ns={USERS}
						>
							Are you sure you want to DELETE this account?<br/>
						</Trans>
					</DialogTitle>
					<DialogContent sx={{textAlign: 'center', fontSize: '14px'}}>
						<DialogContentText id="alert-dialog-description">
							<Trans
								i18nKey={'info_delete_for_admin'}
								ns={USERS}
							>
								If you press confirm all the saved data and account for this
								user will be
								deleted.<br/>
							</Trans>
						</DialogContentText>
					</DialogContent>
				</Grid>
				<DialogActions
					sx={{
						justifyContent: 'center',
						mb: '31px'
					}}
				>
					<Button
						size={"small"}
						variant={"contained"}
						color={'error'}
						component={Button}
						onClick={handleDelete}
						autoFocus
					>
						{t('Confirm')}
					</Button>
					<Button
						variant={'outlined'}
						color={'blue'}
						size={"small"}
						onClick={handleClose}
					>
						{t('Cancel')}
					</Button>
				</DialogActions>
			</Dialog>
		</Box>
	);
}