import * as ActionTypes from '../actions/types'
import * as Actions from '../actions/actions'
import {requestCreator} from '../helpers/requestCreator'
import {API, PortalToken} from "../helpers/constants";

const checkAPIConnectivity = requestCreator({
    nonApiUrl: true,
    url: API + '/portcheck.txt?t=' + (new Date()).getTime(),
    method: 'GET',
    onSuccess: Actions.checkAPIConnectivitySucceeded,
    onFailure: Actions.checkAPIConnectivityFailed
})

const userLogin = requestCreator({
    url: '/login',
    method: 'POST',
    onSuccess: Actions.userLoginSucceeded,
    onFailure: Actions.userLoginFailed,
    headers: {
        'Content-Type': 'multipart/form-data',
        'TokenGuid': PortalToken
    }
})

const googleLogin = requestCreator({
    url: '/google/login',
    method: 'POST',
    onSuccess: Actions.googleLoginSucceeded,
    onFailure: Actions.googleLoginFailed,
    headers: {
        'Content-Type': 'multipart/form-data',
        'TokenGuid': PortalToken
    }
})

const userLogout = requestCreator({
    url: '/logout',
    method: 'POST',
    onSuccess: Actions.userLogoutSucceeded,
    onFailure: Actions.userLogoutFailed,
    headers: {
        'Content-Type': 'multipart/form-data',
        'TokenGuid': PortalToken
    }
})

const requestHandler = {
    [ActionTypes.CHECK_API_CONNECTIVITY]: checkAPIConnectivity,
    [ActionTypes.USER_LOGIN]: userLogin,
    [ActionTypes.USER_LOGOUT]: userLogout,
    [ActionTypes.GOOGLE_LOGIN]: googleLogin
}

export default requestHandler
