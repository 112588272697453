import * as React from "react";
const Users3BlueIcon = (props) => (
	<svg
		width={24}
		height={24}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<g
			stroke="#485776"
			strokeWidth={1.5}
			strokeLinecap="round"
			strokeLinejoin="round"
		>
			<path d="M17.889 10.898a2.876 2.876 0 0 0 2.472-2.841 2.875 2.875 0 0 0-2.406-2.836m1.774 9.029c1.35.202 2.294.675 2.294 1.65 0 .671-.445 1.107-1.163 1.381" />
			<path
				clipRule="evenodd"
				d="M11.887 14.664c-3.214 0-5.96.487-5.96 2.432s2.729 2.445 5.96 2.445c3.214 0 5.958-.482 5.958-2.428s-2.727-2.449-5.958-2.449m0-2.776a3.819 3.819 0 1 0-3.819-3.819 3.804 3.804 0 0 0 3.791 3.819z"
			/>
			<path d="M5.884 10.898a2.875 2.875 0 0 1-2.472-2.841A2.875 2.875 0 0 1 5.818 5.22m-1.774 9.03c-1.351.202-2.294.675-2.294 1.65 0 .671.444 1.107 1.162 1.381" />
		</g>
	</svg>
);
export default Users3BlueIcon;
