// @flow
import React, {useEffect, useState} from "react";
import {
	Box,
	Card,
	CardContent,
	Grid, List,
	ListItem,
	Stack,
	Typography,
	Button
} from "@mui/material";
import {useHistory} from "react-router-dom";
import {getRouteUrl} from "../../../../helpers/getRouteUrl";
import {ROUTE_PAGE_CALCULATIONS_EDIT} from "../../routes/routes";
import {useTranslation} from "react-i18next";
import {CALCULATIONS} from "../../locales/namespaces";
import MainCard from "../../../../theme/mantis/components/mainCard";
import axios from "axios";
import {API} from "../../../../helpers/constants";
import {enqueueSnackbar} from "notistack";
import {handleAxiosErrors, logoutUnauthorizedUser} from "../../../../helpers/errorHandling";
import useUserData from "../../../../hooks/user/useUserData";
import useUserAction from "../../../../hooks/user/useUserAction";
import {textTranslate} from "../../../../helpers/helpers";
import {TypeInterestRate} from "../../../../parameters/parameters";
import {NumericFormat} from "react-number-format";
import CalculatorBlueIcon from "../../../../assets/images/icons/menuIcons/calculatorBlueIcon";
import DeleteIcon from "../../../../assets/images/icons/buttonsIcons/deleteIcon";
import ShowIcon from "../../../../assets/images/icons/buttonsIcons/showIcon";

const IndexListCalculations = () => {
	const {t} = useTranslation(CALCULATIONS)
	const {token, isLoggedIn} = useUserData()
	const {userLogout} = useUserAction()
	const [data, setData] = useState([])
	const [updateData, setUpdateData] = useState(false)
	const {language, decimalSeparator, thousandSeparator} = useUserData()
	const history = useHistory()
	
	useEffect(() => {
		axios({
			method: 'post',
			headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
			url: `${API}/calculation/indexTable/data`,
		}).then(response => {
			const results = response.data;
			setData(results.results);
			
		}).catch(error => {
			enqueueSnackbar(handleAxiosErrors(error, t('Failed to get details.')), {
					variant: 'error'
				}
			)
			logoutUnauthorizedUser(error, isLoggedIn, userLogout)
		})
	}, [updateData])
	
	const handleDelete = (id) => {
		axios({
			method: 'post',
			headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
			url: `${API}/calculation/remove`,
			data: {
				id: id
			}
		}).then(() => {
			enqueueSnackbar(t('Calculation deleted successfully!'), {
					variant: 'success'
				}
			)
			setUpdateData(!updateData)
		}).catch(error => {
			setUpdateData(!updateData)
			enqueueSnackbar(handleAxiosErrors(error, t('Failed to delete calculation!')), {
					variant: 'error'
				}
			)
			logoutUnauthorizedUser(error, isLoggedIn, userLogout)
		})
	}
	
	const getDescriptionByTypeId = (TypeId) => {
		const type = TypeInterestRate.find(item => item.id === parseInt(TypeId, 10));
		return type ? textTranslate(language)({
			en: type.DescriptionEn,
			el: type.DescriptionEl
		}) : '';
	}
	
	return (
		<Box sx={{mb: 2}}>
			{data.length <= 0 &&
				<Card sx={{mb: 2, mr: 2, ml: 2}}>
					<CardContent>
						<Typography
							style={{textAlign: 'center'}}
						>
							{t('No loan scenarios have been added yet.')}
						</Typography>
					</CardContent>
				</Card>
			}
			<Box sx={{display: 'block'}}>
				<Grid container spacing={2}>
					{data && data.map((item, index) => (
						<Grid item xs={12} sm={6} md={5} lg={4} key={index}>
							<MainCard
								sx={{
									border: '1px solid #0BC1B6',
									boxShadow: '0px 4px 32px 0px rgba(61, 70, 112,0.15 )',
									height: '100%',
									mt: 2
								}}
							>
								<List sx={{py: 0}}>
									<ListItem>
										<Grid container spacing={3}>
											<Grid item xs={12} md={12}>
												<Stack spacing={0.5}>
													<CalculatorBlueIcon width={'28px'} height={'30px'}/>
												</Stack>
											</Grid>
										</Grid>
									</ListItem>
									<ListItem>
										<Grid container spacing={3}>
											<Grid item xs={12} md={12}>
												<Typography
													color="secondary"
													variant='h6'
												>
													{t('Scenario description')}
												</Typography>
												<Typography variant={'bodyMedium'}>
													{item.Description ? item.Description : '-'}
												</Typography>
											</Grid>
										</Grid>
									</ListItem>
									<ListItem>
										<Grid container spacing={3}>
											<Grid item xs={12} md={12}>
												<Stack spacing={0.5}>
													<Typography
														color="secondary"
														variant='h6'
													>
														{t('Loan amount (€)')}
													</Typography>
													<NumericFormat
														style={{
															fontSize: '16px',
															fontWeight: "bold"
														}}
														thousandSeparator={thousandSeparator}
														decimalSeparator={decimalSeparator} displayType="text"
														value={item.RequestedAmount ? item.RequestedAmount : '-'}
													/>
												
												</Stack>
											</Grid>
										</Grid>
									</ListItem>
									<ListItem>
										<Grid container spacing={3}>
											<Grid item xs={12} md={12}>
												<Stack spacing={0.5}>
													<Typography
														color="secondary"
														variant='h6'
													>
														{t('Interest rate type')}
													</Typography>
													<Typography variant={'bodyMedium'}>
														{getDescriptionByTypeId(item.TypeId)}
													</Typography>
												</Stack>
											</Grid>
										</Grid>
									</ListItem>
									<ListItem>
										<Grid container spacing={3}>
											<Grid item xs={12} md={12}>
												<Stack spacing={0.5}>
													<Typography
														color="secondary"
														variant='h6'
													>
														{t('Next loan installment is in')}
													</Typography>
													{item.NextInstallment > Number(item.NotificationDays) || item.NextInstallment === null ?
														<Typography
															variant={'bodyMedium'}
															sx={{fontWeight: item.NextInstallment ? "bold" : 'none'}}
														>
															{item.NextInstallment ? item.NextInstallment + " " + t("days") : '-'}
														</Typography> :
														item.NextInstallment === Number(item.NotificationDays) || item.NextInstallment < Number(item.NotificationDays) || item.NextInstallment === 0 ?
															<Typography
																variant={'bodyMedium'}
																color={'error'}
																sx={{fontWeight: "bold"}}
															>
																{item.NextInstallment + " " + t(Number(item.NextInstallment) === 1 ? "day" : "days")}
															</Typography> : '-'
													}
												</Stack>
											</Grid>
										</Grid>
									</ListItem>
									<ListItem>
										<Grid container spacing={1} sx={{mt: 1, display: 'flex'}}>
											<Grid item>
												<Typography>
													<Button
														variant="contained"
														size="small"
														color={'info'}
														sx={{
															display: 'flex',
															gap: '20px',
															minWidth: '165px',
															maxWidth: '165px'
														}}
														onClick={() => history.push(getRouteUrl(ROUTE_PAGE_CALCULATIONS_EDIT, {id: item.id}))}
													>
														{t('View')}<ShowIcon/>
													</Button>
												</Typography>
											</Grid>
											<Grid item>
												<Typography>
													<Button
														variant="contained"
														size="small"
														color={'error'}
														sx={{display: 'flex', gap: '20px',minWidth: '165px', maxWidth: '165px'}}
														onClick={() => handleDelete(item.id)}
													>
														{t('Delete')} <DeleteIcon/>
													</Button>
												</Typography>
											</Grid>
										</Grid>
									</ListItem>
								</List>
							</MainCard>
						</Grid>
					))}
				</Grid>
			</Box>
		</Box>
	)
}

export default IndexListCalculations