import * as React from 'react';
import {Fragment} from 'react';
import {Grid, List, ListItem, ListItemText, Typography, Stack,useTheme} from "@mui/material";
import {textTranslate} from "../../../../helpers/helpers";
import useUserData from "../../../../hooks/user/useUserData";
import MainCard from "../../../../theme/mantis/components/mainCard";
import moment from "moment";
import {NumericFormat} from "react-number-format";

const QuestionnaireList = ({questionnaire}) => {
    const {language, thousandSeparator, decimalSeparator} = useUserData()
    const theme = useTheme();

    const Description = questionnaire.map(item => {
        return (
            <Grid container spacing={1} key={item.id} sx={{mb: 1}}>
                <Grid item xs={12} lg={12}>
                    <Typography
                        variant={'h6'}
                        color={theme.palette.primary[60]}
                    >
                        {textTranslate(language)({
                            en: item.DescriptionEn,
                            el: item.DescriptionEl
                        })}:
                    </Typography>
                </Grid>
                <Grid item xs={12} lg={12}>
                    {item.TypeId === '2' ?
                        <Typography
                            variant={'body'}
                            color={theme.palette.primary[100]}
                            sx={{
                                fontSize: '16px',
                                fontStyle: 'normal',
                                fontWeight: 600,
                                lineHeight: 'normal'
                            }}
                        >
                            {!item.Value ? '-' : moment(item.Value).format("YYYY")}
                        </Typography> : item.TypeId === '3' ?
                            <Typography
                                variant={'body'}
                                color={theme.palette.primary[100]}
                                sx={{
                                    fontSize: '16px',
                                    fontStyle: 'normal',
                                    fontWeight: 600,
                                    lineHeight: 'normal'
                                }}
                            >
                                <NumericFormat
                                    thousandSeparator={thousandSeparator}
                                    decimalSeparator={decimalSeparator} displayType="text"
                                    value={item.Value ? item.Value : '-'}
                                />
                            </Typography> :
                            <Typography
                                variant={'body'}
                                color={theme.palette.primary[100]}
                                sx={{
                                    fontSize: '16px',
                                    fontStyle: 'normal',
                                    fontWeight: 600,
                                    lineHeight: 'normal'
                                }}
                            >
                                {!item.Value ? '-' : textTranslate(language)({
                                    en: item.ValueEn,
                                    el: item.Value
                                })}
                            </Typography>
                    }
                </Grid>
            </Grid>
            /* <Box key={item.id}>
                 <div style={{display: "flex", alignItems: "baseline"}}>
                     <Grid container>
                         <Grid item xs={4}>
                             <Typography
                                 style={{flexDirection: "column"}} sx={{mb: 1}}> {textTranslate(language)({
                                 en: item.DescriptionEn,
                                 el: item.DescriptionEl
                             })}</Typography>
                         </Grid>
                         <Grid item xs={8}>
                             <Typography style={{marginLeft: 60, whiteSpace: 'break-spaces'}}
                                         sx={{mb: 1}}>{!item.Value ? '-' : item.Value}</Typography>
                         </Grid>
                     </Grid>
                 </div>
                 <Divider sx={{mb: 1}} light/>
             </Box>*/
        )
    })

    return (
        <Fragment>
            <MainCard sx={{mb: 2}}>
                <List sx={{py: 0}}>
                    <ListItem>
                        <Stack direction={'column'}>
                            <ListItemText primary={Description}/>
                        </Stack>
                    </ListItem>
                </List>
            </MainCard>
            {/* <Box sx={{ml: 2}}>
                <Box sx={{flexGrow: 1}}/>
                <Card sx={{maxWidth: '800px'}}>
                    <List dense>
                        <ListItem>
                            <Stack direction={'column'}>
                                <ListItemText primary={Description}/>
                            </Stack>
                        </ListItem>
                    </List>
                </Card>
            </Box>*/}
        </Fragment>
    )
}

export default QuestionnaireList;