// @flow
import React, {Fragment} from 'react';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {GridActionsCellItem} from "@mui/x-data-grid-premium";

type Props = {
	id: string,
	hideEditButton: boolean,
	handleEditRow: Function,
	handleDeleteRow: Function
};

const GridRowToolBar = (props: Props) => {
	const {handleEditRow, handleDeleteRow, id, hideEditButton, hideDeleteButton} = props
	return (
		<Fragment>
			{!hideEditButton && (
				<GridActionsCellItem
					icon={<EditIcon/>}
					label="Edit"
					className="textPrimary"
					onClick={() => handleEditRow(id)}
					color="blue"
				/>
			)}
			{!hideDeleteButton && (
				<GridActionsCellItem
					icon={<DeleteIcon/>}
					label="Delete"
					onClick={() => handleDeleteRow(id)}
					color={"error"}
				/>
			)}
		</Fragment>
	)
};

export default GridRowToolBar