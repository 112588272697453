// material-ui
import {alpha, createTheme} from '@mui/material/styles';

// third-party
import {presetDarkPalettes, presetPalettes} from '@ant-design/colors';

// project import
import ThemeOption from './theme';
import {ThemeMode} from "../config";

// ==============================|| DEFAULT THEME - PALETTE  ||============================== //

const Palette = (mode, presetColor) => {
    const colors = mode === ThemeMode.DARK ? presetDarkPalettes : presetPalettes;

    let greyPrimary = [
        '#EEF7F9',
        '#CCEAEB',
        '#B9E3E5',
        '#A7DEDE',
        '#96D9D7',
        '#85D4D0',
        '#76CEC9',
        '#69C8C3',
        '#5FC3BC',
        '#0BC1B6' //button color
    ]

    let greyAscent = ['#fafafa', '#8F96A9', '#434343', '#485776'];
    let greyConstant = ['#fafafb', '#e6ebf1'];

    if (mode === ThemeMode.DARK) {
        greyPrimary = ['#485776', '#5C6781', '#6C778E', '#595959', '#8F96A9', '#9FA6B6', '#B0B6C4', '#C1C6D1', '#C1C6D1', '#D3D5DF', '#E3E5EC'];
        // greyPrimary.reverse();
        greyAscent = ['#fafafa', '#8F96A9', '#434343', '#485776'];
        greyConstant = ['#485776', '#d3d8db'];
    }
    colors.grey = [...greyPrimary, ...greyAscent, ...greyConstant];

    const paletteColor = ThemeOption(colors, presetColor, mode);

    return createTheme({
        palette: {
            mode,
            common: {
                black: '#485776',
                white: '#fff'
            },
            ...paletteColor,
            text: {
                primary: mode === ThemeMode.DARK ? alpha(paletteColor.grey[900], 0.87) : paletteColor.grey.A400,
                secondary: mode === ThemeMode.DARK ? alpha(paletteColor.grey[900], 0.45) : paletteColor.grey.A100,
                disabled: mode === ThemeMode.DARK ? alpha(paletteColor.grey[900], 0.1) : paletteColor.grey[800]
            },
            action: {
                disabled: paletteColor.grey[300]
            },
            divider: mode === ThemeMode.DARK ? alpha(paletteColor.grey[900], 0.05) : paletteColor.primary[20],
            background: {
                paper: mode === ThemeMode.DARK ? paletteColor.grey[100] : '#ffffff',
                default: mode === ThemeMode.DARK ? paletteColor.grey.A50 : paletteColor.grey.ims
            }
        }
    });
};

export default Palette;