//@flow
import React from 'react';
import {
	DialogActions, Dialog, DialogContent,
	Slide, Box, Typography, Link
} from "@mui/material";
import { FormikProvider } from "formik";
import { useTranslation } from "react-i18next";
import LoadingButton from '@mui/lab/LoadingButton';
import { FILES } from "../../locales/components/namespaces";
import { AuthenticatedLink } from "../authenticatedLink/authenticatedLink";
import FileUpload from "../fileUpload/fileUpload";
import ImportAlert from "./partials/importAlert";
import { useTheme } from "@mui/material/styles";
import { Capacitor } from "@capacitor/core";

type Props = {
	loading: boolean,
	show: boolean,
	onClose: Function,
	formik: Object,
	templateUrl: string,
	importStatus: Object,
	handleImportStatus: Function,
	onFileChange: Function,
}

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} >{props.children}</Slide>;
})

const ExcelImport = (props: Props) => {
	const {
		loading,
		show,
		onClose,
		formik,
		templateUrl,
		importStatus,
		handleImportStatus,
		onFileChange
	} = props
	const { t } = useTranslation(FILES)
	const theme = useTheme();
	
	return (
		<Dialog
			maxWidth={"md"}
			open={show}
			TransitionComponent={Transition}
			keepMounted
			onClose={onClose}
		>
			<FormikProvider value={formik}>
				<Box
					sx={{
						'& .MuiTextField-root': { my: 1 },
					}}
					component="form"
					onSubmit={formik.handleSubmit}
				>
					<DialogContent
						sx={{ maxWidth: '590px' }}>
						<ImportAlert
							onClose={() => handleImportStatus(null)}
							status={importStatus}
						/>
						{props.children}
						<Box textAlign={'-webkit-center'} padding={'20px'}>
							<FileUpload  //εδώ γίνεται το ανέβασμα του αρχείου excel
								allowExcelFilesOnly
								multiple={false}
								onRemove={onFileChange}
								onFilesUpload={onFileChange}
							/>
						</Box>
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center'
							}}>
							<Typography variant={'h4'} fontWeight={400} textAlign={'center'}>
								{t('Drag and drop some files here')}
							</Typography>
							<Typography variant={'h5'} color={theme.palette.primary[60]} textAlign={'center'}>
								{t('The excel file must follow the structure described in the template excel file that you can download from ')}
								<AuthenticatedLink
									url={templateUrl}
									filename={t('Download excel template')}
								>
									<Link //Εδώ μπορεί να κατεβάσει ο χρήστης ένα κενό excel που περιέχει μόνο τους τίτλους των πεδίων και με τη σωστή σειρά
										size={"small"}
										color={'blue'}
										component={"button"}
										target={!Capacitor.isNativePlatform() && "_blank"}
										fontWeight={700}
									>
										{t('here')}
									</Link>
								</AuthenticatedLink>
							</Typography>
						</Box>
					</DialogContent>
					<DialogActions sx={{ justifyContent: 'center' }}>
						<LoadingButton
							size={"small"}
							type={"submit"}
							disabled={!(formik.isValid && formik.dirty)}
							loading={loading}
							loadingPosition="end"
							variant="contained"
							color={'info'}
							sx={{ padding: '12px 24px', mb: '20px' }}
						>
							{t('Upload Files')}
						</LoadingButton>
					</DialogActions>
				</Box>
			</FormikProvider>
		</Dialog>
	)
}

export default ExcelImport;