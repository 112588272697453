import {useFormik} from "formik";
import * as yup from "yup";
import axios from "axios";
import {API} from "../../../helpers/constants";
import {useTranslation} from "react-i18next";
import {LOANS} from "../locales/namespaces";
import {getRouteUrl} from "../../../helpers/getRouteUrl";
import {ROUTE_PAGE_LOANS_DETAILS} from "../routes/routes";
import {useHistory} from "react-router-dom";
import useUserData from "../../../hooks/user/useUserData";
import {handleAxiosErrors, logoutUnauthorizedUser} from "../../../helpers/errorHandling";
import {enqueueSnackbar} from "notistack";
import {getChoiceName, getInputType, getValue, parseChoiceValue} from "../helpers/helpers";
import {useMemo, useState} from "react";
import {textTranslate} from "../../../helpers/helpers";
import useUserAction from "../../../hooks/user/useUserAction";

const useLoanForm = ({id, loan, questionnaire, type, originLoan, getLoanFromCalc, getRevFromCalc}) => {
	const history = useHistory()
	const {token, isLoggedIn, language} = useUserData()
	const {userLogout} = useUserAction()
	const {t} = useTranslation(LOANS)
	const [isLoading, setIsLoading] = useState(false);
	
	const choices = useMemo(() => (
		questionnaire.map(choice => {
			const {ChoiceEl, ChoiceEn, ChoiceId, ChoiceValue, Options, TypeId, isRequired} = choice
			return {
				id: ChoiceId,
				name: getChoiceName(ChoiceId),
				label: textTranslate(language)({en: ChoiceEn, el: ChoiceEl}),
				required: isRequired === '1',
				inputType: getInputType(TypeId),
				dropdownOptions: Options,
				value: ChoiceValue
			}
		})), [questionnaire, language])
	
	const values = useMemo(() => {
		if (choices) {
			return choices.reduce((p, n) => {
				return {
					[n.name]: getValue(n.inputType, n.value, n.dropdownOptions),
					...p
				}
			}, {})
		}
		return []
	}, [questionnaire])
	
	const validationSchema = yup.object({
		RequestedAmount: yup.number().required(t('Required')).positive(t('Value must be a positive number.')).min(0, t('Value must be a positive number.')).max(99999999.99, t('Loan amount must be less than 100,000,000.')),
		type: yup.string().required(t('Required')),
		origin: yup.string().required(t('Required')),
		RealEstateValue: yup.number().positive(t('Value must be a positive number.')).min(0, t('Value must be a positive number.')).max(99999999.99, t('Real estate value must be less than 100,000,000.')),
		GDPRchecked: yup.boolean().oneOf([true], t("Accepting the privacy statement is mandatory to proceed.")),
		// Questionnaire validation
		estateAmount: yup.number().positive(t('Value must be a positive number.')).min(0, t('Value must be a positive number.')).max(99999999.99, t('Real estate value must be less than 100,000,000.')),
		squareMeters: yup.number().positive(t('Value must be a positive number.')).min(0, t('Value must be a positive number.')).max(1000, t('The value is too big.')),
	})
	
	const onSubmit = (values) => {
		setIsLoading(true)
		const choices = questionnaire.map((field) => {
			const {ChoiceId} = field;
			const choiceName = getChoiceName(ChoiceId)
			const choiceValue = parseChoiceValue(choiceName, values[choiceName])
			return {ChoiceId: ChoiceId, ChoiceValue: choiceValue};
		});
		axios({
			method: 'post',
			data: {
				LoanId: id,
				FileGDPR: values.FileGDPR ? values.FileGDPR[0] : '',
				Summary: values.Summary,
				RequestedAmount: values.RequestedAmount,
				TypeId: values.type,
				OriginId: values.origin,
				isRealEstate: values.isRealEstate === true ? '1' : '0',
				SearchByPartner: values.SearchByPartner === true ? '1' : '0',
				// isRealEstate: values.isRealEstate === 'isRealEstate' ? '1' : '0',/*TODO::for radio buttons**/
				GDPRchecked: values.GDPRchecked === true ? '1' : '0',
				RealEstateValue: values.RealEstateValue,
				Comments: values.Comments,
				StatusId: values.StatusId,
				propertyType: values.propertyType,
				PartnerName: values.PartnerName,
				Choices: choices
			},
			headers: {'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${token}`},
			url: !id ? `${API}/loan/store` : `${API}/loan/update`
		}).then(response => {
			enqueueSnackbar(!id ? t('Loan Stored Successfully!') : t('Loan Updated Successfully!'), {
					variant: 'success',
					onClose: () => history.push(getRouteUrl(ROUTE_PAGE_LOANS_DETAILS, {id: response.data.LoanId}))
				}
			)
			setIsLoading(false)
		}).catch((error) => {
			enqueueSnackbar(handleAxiosErrors(error, t('Failed to get loan details.')), {
					variant: 'error'
				}
			)
			setIsLoading(false)
			logoutUnauthorizedUser(error, isLoggedIn, userLogout)
		})
	}
	
	const formik = useFormik({
		initialValues: {
			FileGDPR: '',
			Summary: loan?.Summary || '',
			//RequestedAmount: loan?.RequestedAmount || '',
			RequestedAmount: getLoanFromCalc ? getLoanFromCalc : '',
			type: loan?.TypeId ? type.find(f => f.id === loan.TypeId)?.id : '',
			origin: loan?.OriginId ? originLoan.find(f => f.id === loan.OriginId)?.id : '',
			isRealEstate: loan?.isRealEstate || false,
			// isRealEstate: loan?.isRealEstate || 'isRealEstate',/*TODO::for radio buttons**/
			SearchByPartner: loan?.SearchByPartner || false,
			GDPRchecked: loan?.GDPRchecked || false,
			RealEstateValue: getRevFromCalc ? getRevFromCalc : '',
			Comments: loan?.Comments || '',
			PartnerName: loan?.PartnerName || '',
			//Partners: loan?.PartnerId ? partners.find(f => f.id === loan?.PartnerId)?.id : '',
			// Questionnaire init values
			...values
		},
		validationSchema: validationSchema,
		onSubmit: onSubmit,
	})
	
	return {
		choices,
		formik,
		isLoading
	}
}

export default useLoanForm

