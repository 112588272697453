import {forwardRef, useEffect, useRef, useState} from 'react';
import {Link} from 'react-router-dom';

// material-ui
import {useTheme} from '@mui/material/styles';
import {Avatar, Chip, ListItemButton, ListItemIcon, ListItemText, Typography, useMediaQuery} from '@mui/material';
import useConfig from "../../../../../hooks/useConfig";
import {MenuOrientation, ThemeMode} from "../../../../../config";
import Dot from "../../../../../components/@extended/dot";
import useMenuData from "../../../../../../../hooks/menu/useMenuData";
import useMenuAction from "../../../../../../../hooks/menu/useMenuAction";
import PermissionHOC from "../../../../../../../modules/permission/components/permissionHOC/permissionHOC";
import {useHasPermission} from "../../../../../../../modules/permission/hooks/useHasPermission";

// project import

// ==============================|| NAVIGATION - LIST ITEM ||============================== //

const NavItem = ({item, level, onClickAction, logout, support}) => {
    const theme = useTheme();
    const {menuOrientation} = useConfig();
    const downLG = useMediaQuery(theme.breakpoints.down('lg'));
    const {drawerOpen, openItem} = useMenuData()
    const {setMenuActiveItem, setDrawerOpen,} = useMenuAction()
    const [isHovered, setIsHovered] = useState(false);
    const closeRef = useRef()
    const isAdmin = useHasPermission(['access_mobile_side_menu'], {shouldView: true})

    let itemTarget = '_self';
    if (item.target) {
        itemTarget = '_blank';
    }

    let listItemProps = {
        component: forwardRef((props, ref) => <Link {...props} to={item.url} target={itemTarget} ref={ref}/>)
    };
    if (item?.external) {
        listItemProps = {component: 'a', href: item.url, target: itemTarget};
    }

    const Icon = item.icon;
    const itemIcon = item.icon ? <Icon style={{fontSize: drawerOpen ? '1rem' : '1.25rem'}}/> : false;

    const HoverIcon = item.hoverIcon;
    const itemHoverIcon = item.hoverIcon ? <HoverIcon style={{fontSize: drawerOpen ? '1rem' : '1.25rem'}}/> : false;

    const isChild = item.child

    const isSelected = openItem.findIndex((id) => id === item.id) > -1;

    const pathname = document.location.pathname;

    // active menu item on page load
    useEffect(() => {
        if (pathname.includes(item.url) && item?.id !== 'logout' && item?.id !== 'support') {
            setMenuActiveItem({openItem: [item.id]});
        }
        // eslint-disable-next-line
    }, [pathname]);

    const textColor = logout ? theme.palette.actions.red : 'text.primary';
    const iconSelectedColor = drawerOpen ? 'text.primary' : 'primary.main';

    /* const handleMouseEnter = () => {
         setIsHovered(true);
     };

     const handleMouseLeave = () => {
         setIsHovered(false);
     };*/
    const checkHover = (e => {
        if (closeRef.current) {
            const mouseOver = closeRef.current.contains(e.target);
            if (!isHovered && mouseOver) {
                //console.log(isHovered, mouseOver);
                setIsHovered(true)
            }

            if (isHovered && !mouseOver) {
                setIsHovered(false)
            }
        }
    });
    useEffect(() => {
        window.addEventListener("mousemove", checkHover, true);
        return () => {
            window.removeEventListener("mousemove", checkHover, true);
        }
    }, [isHovered])

    const navItem = (
        <>
            {menuOrientation === MenuOrientation.VERTICAL || downLG ? (
                //Μόνο εδώ βλέπω να γίνεται αλλαγή
                <ListItemButton
                    {...listItemProps}
                    ref={closeRef}
                    // onMouseEnter={() => handleMouseEnter()}
                    // onMouseLeave={()=>handleMouseLeave()}
                    onMouseEnter={(e) => {
                        e.preventDefault();
                        setIsHovered(true)
                    }}
                    onMouseLeave={(e) => {
                        e.preventDefault();
                        setIsHovered(false)
                    }}
                    disabled={item.disabled}
                    selected={isSelected}
                    sx={{
                        mb: isAdmin || support ? 1 : 4,
                        zIndex: 1201,
                        maxWidth: 'fit-content',
                        display: 'flex',
                        padding: '8px 24px',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '12px',
                        borderRadius: '200px',
                        border: '0px solid #485776',
                        ...(drawerOpen && {
                            border: support ? '1px solid #485776' : 'none',
                            '&:hover': {
                                // bgcolor: theme.palette.mode === ThemeMode.DARK ? 'divider' : 'primary.lighter'
                                // display: 'flex',
                                // padding: '12px 24px',
                                // justifyContent: 'center',
                                // alignItems: 'center',
                                // gap: '12px',
                                //textColor: '#fff',

                                borderRadius: '200px',
                                background: logout ? 'none' : theme.palette.primary[100],
                                boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.20) inset',

                                h6: {
                                    color: logout ? '#CC5F5F' : '#fff'
                                }
                            },
                            '&.Mui-selected': {
                                borderRadius: '200px',
                                background: logout ? 'none' : theme.palette.primary[100],
                                boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.20) inset',
                                h6: {
                                    color: '#fff'
                                },
                                '&:hover': {
                                    borderRadius: '200px',
                                    background: logout ? 'none' : theme.palette.primary[100],
                                    boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.20) inset',

                                    h6: {
                                        color: '#fff'
                                    }
                                }
                            },

                            /* bgcolor: theme.palette.mode === ThemeMode.DARK ? 'divider' : 'primary.lighter',
                             borderRight: `2px solid ${theme.palette.primary.main}`,
                             color: iconSelectedColor,
                             '&:hover': {
                                 color: iconSelectedColor,
                                 bgcolor: theme.palette.mode === ThemeMode.DARK ? 'divider' : 'primary.lighter'
                             }*/
                        }),
                        // ...(!drawerOpen && {
                        //     '&.Mui-selected': {
                        //         background: logout ? 'none' : theme.palette.primary[100],
                        //         '&:hover': {
                        //             background: logout ? 'none' : theme.palette.primary[100],
                        //         }
                        //     },
                        // })
                    }}
                    /*sx={{
                        zIndex: 1201,
                        gap: '12px',
                        //borderRadius: support ? '200px' : '0px',
                        //border: support ? '1px solid #485776' : 'none',
                        //ml: support ? 2 : 0,
                        //padding: support ? '8px 24px' : '12px 24px',
                        pl: drawerOpen ? `${level * 28}px` : 1.5,
                        py: !drawerOpen && level === 1 ? 1.25 : 1,
                        ...(drawerOpen && {
                            '&:hover': {
                                // bgcolor: theme.palette.mode === ThemeMode.DARK ? 'divider' : 'primary.lighter'
                                // display: 'flex',
                                // padding: '12px 24px',
                                // justifyContent: 'center',
                                // alignItems: 'center',
                                // gap: '12px',
                                //textColor: '#fff',
                                borderRadius: '200px',
                                background: logout ? 'none' : theme.palette.primary[100],
                                boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.20) inset'
                            },
                            '&.Mui-selected': {
                                bgcolor: theme.palette.mode === ThemeMode.DARK ? 'divider' : 'primary.lighter',
                                borderRight: `2px solid ${theme.palette.primary.main}`,
                                color: iconSelectedColor,
                                '&:hover': {
                                    color: iconSelectedColor,
                                    bgcolor: theme.palette.mode === ThemeMode.DARK ? 'divider' : 'primary.lighter'
                                }
                            }
                        }),
                        ...(!drawerOpen && {
                            '&:hover': {
                                bgcolor: 'transparent'
                            },
                            '&.Mui-selected': {
                                '&:hover': {
                                    bgcolor: 'transparent'
                                },
                                bgcolor: 'transparent'
                            },
                            // borderRadius: '16px' ,
                            // border:'1px solid #485776',
                        })
                    }}*/
                    onClick={(event) => {
                        event.stopPropagation()
                        if (downLG) setDrawerOpen(false);
                        if (onClickAction) onClickAction()
                    }}
                    title={item.hoverMessage}
                >
                    <ListItemIcon
                        sx={{
                            minWidth: 28,
                            color: isSelected ? iconSelectedColor : textColor,
                            ...(!drawerOpen && {
                                //borderRadius: 1.5,
                                width: 56,
                                height: 56,
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRadius: '16px',
                                display: 'flex',
                                padding: '16px',
                                border: logout ? '1px solid #F57E77' : '1px solid #485776',
                                background: isHovered && !logout ? '#485776' : '#FFF',
                                '&:hover': {
                                    //bgcolor: theme.palette.mode === ThemeMode.DARK ? 'secondary.light' : 'secondary.lighter'
                                }
                            }),
                            ...(!drawerOpen &&
                                isSelected && {
                                    bgcolor: theme.palette.primary[100],
                                    '&:hover': {
                                        bgcolor: theme.palette.primary[100]
                                    }
                                }),
                            ...(!drawerOpen && isChild && {
                                border: 'none',
                                borderRadius: '200px'
                                // width: 12,
                                // height: 12,
                                // alignItems: 'center',
                                // justifyContent: 'center',
                                // borderRadius: '16px',
                                // display: 'flex',
                                // padding: '16px',
                                // border: logout ? '1px solid #F57E77' : '1px solid #485776',
                                // background: isHovered && !logout ? '#485776' : '#FFF',
                                // '&:hover': {
                                //     //bgcolor: theme.palette.mode === ThemeMode.DARK ? 'secondary.light' : 'secondary.lighter'
                                //},
                            }),
                            ...(!drawerOpen && isChild && isSelected && {
                                bgcolor: theme.palette.primary[100],
                                '&:hover': {
                                    bgcolor: theme.palette.primary[100]
                                }
                            }),
                        }}
                    >
                        {isHovered || isSelected ? itemHoverIcon : itemIcon}
                    </ListItemIcon>

                    {/*{itemHoverIcon && isSelected && (
                        <ListItemIcon
                            sx={{
                                minWidth: 28,
                                color: isSelected ? iconSelectedColor : textColor,
                                ...(!drawerOpen && {
                                    //borderRadius: 1.5,
                                    width: 56,
                                    height: 56,
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    borderRadius: '16px',
                                    display: 'flex',
                                    padding: '16px',
                                    border: logout ? '1px solid #F57E77' : '1px solid #485776',
                                    background: '#FFF',
                                    '&:hover': {
                                        //bgcolor: theme.palette.mode === ThemeMode.DARK ? 'secondary.light' : 'secondary.lighter'
                                    }
                                }),
                                ...(!drawerOpen &&
                                    isSelected && {
                                        bgcolor: theme.palette.primary[100],
                                        '&:hover': {
                                            bgcolor: theme.palette.primary[100]
                                        }
                                    })
                            }}
                        >
                            {isHovered || isSelected ? itemHoverIcon : itemIcon}
                        </ListItemIcon>
                    )}*/}
                    {(drawerOpen || (!drawerOpen && level !== 1)) && (
                        <ListItemText
                            primary={
                                <Typography
                                    variant="h6"
                                    sx={{
                                        color: isSelected ? textColor : textColor,
                                        textWrap: 'wrap',
                                        // ...(drawerOpen && {
                                        //     '&:hover': {
                                        //         color: '#fff'
                                        //     }
                                        // })
                                    }}
                                >
                                    {item.title}
                                </Typography>
                            }
                        />
                    )}
                    {(drawerOpen || (!drawerOpen && level !== 1)) && item.chip && (
                        <Chip
                            color={item.chip.color}
                            variant={item.chip.variant}
                            size={item.chip.size}
                            label={item.chip.label}
                            avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
                        />
                    )}
                </ListItemButton>
            ) : (
                <ListItemButton
                    {...listItemProps}
                    disabled={item.disabled}
                    selected={isSelected}
                    sx={{
                        maxWidth: 'fit-content',
                        mb: isAdmin || support ? 1 : 4,
                        zIndex: 1201,
                        ...(drawerOpen && {
                            '&:hover': {
                                bgcolor: 'transparent'
                            },
                            '&.Mui-selected': {
                                bgcolor: 'transparent',
                                color: iconSelectedColor,
                                '&:hover': {
                                    color: iconSelectedColor,
                                    bgcolor: 'transparent'
                                }
                            }
                        }),
                        ...(!drawerOpen && {
                            '&:hover': {
                                bgcolor: 'transparent'
                            },
                            '&.Mui-selected': {
                                '&:hover': {
                                    bgcolor: 'transparent'
                                },
                                bgcolor: 'transparent'
                            }
                        })
                    }}
                    onClick={(event) => {
                        event.stopPropagation()
                        if (onClickAction) onClickAction()
                    }}
                    title={item.hoverMessage}
                >
                    {itemIcon && (
                        <ListItemIcon
                            sx={{
                                minWidth: 36,
                                ...(!drawerOpen && {
                                    borderRadius: 1.5,
                                    width: 36,
                                    height: 36,
                                    alignItems: 'center',
                                    justifyContent: 'flex-start',
                                    '&:hover': {
                                        bgcolor: 'transparent'
                                    }
                                }),
                                ...(!drawerOpen &&
                                    isSelected && {
                                        bgcolor: 'transparent',
                                        '&:hover': {
                                            bgcolor: 'transparent'
                                        }
                                    })
                            }}
                        >
                            {itemIcon}
                        </ListItemIcon>
                    )}

                    {!itemIcon && (
                        <ListItemIcon
                            sx={{
                                color: isSelected ? 'primary.main' : 'secondary.main',
                                ...(!drawerOpen && {
                                    borderRadius: 1.5,
                                    alignItems: 'center',
                                    justifyContent: 'flex-start',
                                    '&:hover': {
                                        bgcolor: 'transparent'
                                    }
                                }),
                                ...(!drawerOpen &&
                                    isSelected && {
                                        bgcolor: 'transparent',
                                        '&:hover': {
                                            bgcolor: 'transparent'
                                        }
                                    })
                            }}
                        >
                            <Dot size={4} color={isSelected ? 'primary' : 'secondary'}/>
                        </ListItemIcon>
                    )}
                    <ListItemText
                        primary={
                            <Typography variant="h6" color="inherit" sx={{textWrap: 'wrap'}}>
                                {item.title}
                            </Typography>
                        }
                    />
                    {(drawerOpen || (!drawerOpen && level !== 1)) && item.chip && (
                        <Chip
                            color={item.chip.color}
                            variant={item.chip.variant}
                            size={item.chip.size}
                            label={item.chip.label}
                            avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
                        />
                    )}
                </ListItemButton>
            )}
        </>
    )

    return item.permissions && item.permissions.length > 0 ? (
        <PermissionHOC
            requiredPermissions={[...item.permissions]}>
            {navItem}
        </PermissionHOC>
    ) : (
        <>{navItem}</>
    )
};
//
// NavItem.propTypes = {
//     item: PropTypes.object,
//     level: PropTypes.number
// };

export default NavItem;
