// material-ui
import {List, ListItemButton, ListItemIcon, ListItemText} from '@mui/material';

// assets
import {useTranslation} from "react-i18next";
import {NAVIGATION} from "../../../../../../../locales/components/namespaces";
import {Link, useHistory} from "react-router-dom";
import {getRouteUrl} from "../../../../../../../helpers/getRouteUrl";
import {ROUTE_PAGE_CUSTOMERS_DETAILS} from "../../../../../../../modules/customers/routes/routes";
import {ROUTE_PAGE_USERS_DETAILS} from "../../../../../../../modules/users/routes/routes";
import useUserData from "../../../../../../../hooks/user/useUserData";
import {ROUTE_PAGE_PRIVACY_POLICY} from "../../../../../../../routers/routes";
import ProfileIconBulk from "../../../../../../../assets/images/icons/profileIconBulk";
import DocumentIconBulk from "../../../../../../../assets/images/icons/documentIconBulk";
import LogoutIcon from "../../../../../../../assets/images/icons/logout";
import {useTheme} from "@mui/material/styles";


// ==============================|| HEADER PROFILE - PROFILE TAB ||============================== //

const ProfileTab = ({handleLogout, handleClose}) => {
    const {t} = useTranslation(NAVIGATION)
    const {customerId, userId} = useUserData()
    const theme = useTheme();
    const history = useHistory()

    const handleNavigateAndClose = (event, url) => {
        handleClose(event);
        history.push(url);
    };

    return (
        <List component="nav" sx={{p: 0, '& .MuiListItemIcon-root': {minWidth: 32}}}>
            <ListItemButton
                // component={Link}
                // to={customerId ? getRouteUrl((ROUTE_PAGE_CUSTOMERS_DETAILS), {id: customerId}) : getRouteUrl((ROUTE_PAGE_USERS_DETAILS), {id: userId})}
                onClick={(event) => handleNavigateAndClose(event,
                    customerId
                        ? getRouteUrl(ROUTE_PAGE_CUSTOMERS_DETAILS, {id: customerId})
                        : getRouteUrl(ROUTE_PAGE_USERS_DETAILS, {id: userId})
                )}
            >
                <ListItemIcon>
                    <ProfileIconBulk/>
                </ListItemIcon>
                <ListItemText primary={t("My Profile")}/>
            </ListItemButton>
            <ListItemButton
                // component={Link}
                // to={getRouteUrl((ROUTE_PAGE_PRIVACY_POLICY))}
                onClick={(event) => handleNavigateAndClose(event, getRouteUrl(ROUTE_PAGE_PRIVACY_POLICY))}
            >
                <ListItemIcon>
                    <DocumentIconBulk/>
                </ListItemIcon>
                <ListItemText primary={t("Privacy policy")}/>
            </ListItemButton>
            {/*<LanguageSelect/>*/}
            <ListItemButton onClick={handleLogout}>
                <ListItemIcon>
                    <LogoutIcon/>
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{
                    color: theme.palette.actions.red,
                }} primary={t("Logout")}/>
            </ListItemButton>
        </List>
    );
};

export default ProfileTab;
