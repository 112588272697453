export function TextField(theme) {
	return {
		MuiInputBase: {
			styleOverrides: {
				root: {
					minHeight: '52px',
					fontSize: '16px',
					'& .MuiOutlinedInput-input': {
						fontSize: '16px',
					}
				},
				input: {
					fontSize: '16px'
				}
			}
		}
	}
}