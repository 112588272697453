import React from 'react';
import {Link, Box, useTheme, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {PAGE_LOGIN} from "../../../../../locales/pages/namespaces";
import {Capacitor} from "@capacitor/core";
import {Browser} from "@capacitor/browser";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const LinkTo = ({openLink, inFooter, t, theme}) => (
	<Link
		component="button"
		onClick={openLink}
		rel="noopener"
		underline="hover"
		fontSize={inFooter ? '0.75rem' : 16}
		sx={{
			color: inFooter ? 'inherit' : theme.palette.primary[100],
			fontFamily: inFooter ? '"Public Sans", sans-serif' : 'inherit',
			minHeight: inFooter ? '10px' : 'auto',
			lineHeight: inFooter ? 1.66 : 'auto',
		}}
	>
		{inFooter ? <Typography variant="h5">{t('About IMS FC')}</Typography> : t('Learn more about IMS Company')}
		{!inFooter && <ChevronRightIcon sx={{mb: -1}}/>}
	</Link>
)

const ImsLink = ({inFooter}) => {
	const {t} = useTranslation(PAGE_LOGIN)
	const theme = useTheme();
	const openLink = async () => {
		if (Capacitor.isNativePlatform()) {
			// Open link in in-app browser on native app
			if (Capacitor.getPlatform() === 'ios') {
				window.open('https://www.ims-fc.gr/', '_self');
			} else {
				await Browser.open({url: 'https://www.ims-fc.gr/'});
			}
		} else {
			// Open link in new tab in web app
			window.open('https://www.ims-fc.gr/', '_blank');
		}
	};
	return inFooter ? (
		<LinkTo
			openLink={openLink}
			theme={theme}
			t={t}
			inFooter={inFooter}
		/>
	) : (
		<Box sx={{
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			mb: inFooter ? 0 : 2,
		}}>
			<LinkTo
				openLink={openLink}
				theme={theme}
				t={t}
				inFooter={inFooter}
			/>
		</Box>
	);
}

export default ImsLink;