import * as React from "react";
const NotificationIcon = (props) => (
    <svg
        width={20}
        height={23}
        viewBox="0 0 20 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g fill="#485776">
            <path d="M18.677 11.52c-.8-.936-1.165-1.747-1.165-3.124v-.468c0-1.794-.413-2.95-1.31-4.107C14.816 2.026 12.486.943 10.206.943h-.097C7.877.943 5.62 1.976 4.212 3.7c-.947 1.179-1.408 2.385-1.408 4.23v.467c0 1.377-.34 2.188-1.165 3.123-.607.69-.801 1.575-.801 2.534 0 .96.315 1.868.947 2.607a4.97 4.97 0 0 0 3.18 1.55 45.8 45.8 0 0 0 5.194.27c1.746 0 3.47-.124 5.193-.27a4.97 4.97 0 0 0 3.18-1.55 3.962 3.962 0 0 0 .946-2.607c0-.959-.194-1.845-.8-2.534Z" />
            <path
                opacity={0.4}
                d="M12.36 19.834c-.548-.117-3.888-.117-4.436 0-.469.108-.975.36-.975.912.027.527.335.992.763 1.287H7.71a3.983 3.983 0 0 0 1.88.803c.362.05.73.048 1.105 0a3.987 3.987 0 0 0 1.88-.802l-.002-.001c.427-.295.736-.76.763-1.287 0-.552-.507-.804-.976-.912Z"
            />
        </g>
    </svg>
);
export default NotificationIcon;
