// material-ui
import {Box, Grid, Link} from '@mui/material';

// project import


// assets
import AuthBackground from "../../assets/images/auth/authBackground";
import AuthCard from "./authCard";
import AuthFooter from "../../components/cards/authFooter";
import {Link as RouterLink} from "react-router-dom";
import {getRouteUrl} from "../../../../helpers/getRouteUrl";
import {ROUTE_PAGE_PRIVACY_POLICY, ROUTE_PAGE_UNAUTHORIZED_CALCULATION} from "../../../../routers/routes";
import ImsLink from "../../../../pages/home/dashboards/components/imsLink/imsLink";
import React from "react";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {useTranslation} from "react-i18next";
import {PAGE_LOGIN} from "../../../../locales/pages/namespaces";
import {useTheme} from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import {useLocation} from "react-router";
import {Capacitor} from "@capacitor/core";

// ==============================|| AUTHENTICATION - WRAPPER ||============================== //

const AuthWrapper = ({children}) => {
    const {t} = useTranslation(PAGE_LOGIN)
    const theme = useTheme();
    const location = useLocation()

    return (
        <Box sx={{minHeight: '100vh', position: 'relative'}}>
            <AuthBackground/>
            <Grid
                container
                //direction="column"
                justifyContent="flex-end"
                sx={{
                    minHeight: '90vh'
                }}
            >
                <Grid item xs={12}>
                    <Grid
                        item
                        xs={12}
                        container
                        justifyContent="center"
                        alignItems="start"
                       /* sx={{
                            minHeight: {
                                xs: 'calc(100vh - 140px)',
                                sm: 'calc(100vh - 100px)'
                            }
                        }}*/
                    >
                        <Grid item>
                            <AuthCard>{children}</AuthCard>
                        </Grid>
                        {(location.pathname === '/signup') ?
                            <Grid item xs={12}
                                  textAlign={'-webkit-center'}
                                /*sx={{maxWidth: 365, textWrap: 'wrap',flexShrink: 0}}*/>
                                <Typography variant="h5" sx={{maxWidth: {xs: 400, lg: 425}}}>
                                    {t('By Signing up, you agree to our')} &nbsp;
                                    <Link
                                        variant="h5"
                                        fontWeight={700}
                                        color="textPrimary"
                                        component={RouterLink}
                                        to="#">
                                        {t('Terms of Service')}
                                    </Link>
                                    &nbsp; {t('and')} &nbsp;
                                    <Link
                                        variant="h5"
                                        component={RouterLink}
                                        color="textPrimary"
                                        fontWeight={700}
                                        to={getRouteUrl(ROUTE_PAGE_PRIVACY_POLICY)}
                                        underline="hover"
                                        target={!Capacitor.isNativePlatform() && "_blank"}
                                    >
                                        {t('Privacy policy')}
                                    </Link>
                                </Typography>
                            </Grid> :
                            <>
                                {/*TODO::FOR THE SECOND VERSION OF THE APP*/}
                                <Grid item xs={12}>
                                    <Box sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        mb: 2,
                                    }}>
                                        <Link
                                            component={RouterLink}
                                            to={getRouteUrl(ROUTE_PAGE_UNAUTHORIZED_CALCULATION)}
                                            rel="noopener"
                                            underline="hover"
                                            fontSize={16}
                                            sx={{
                                                color: theme.palette.primary[100],
                                                zIndex: 1
                                            }}
                                        >
                                            {t("Calculation of mortgage installment")}
                                            <ChevronRightIcon sx={{mb: -1}}/>
                                        </Link>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <ImsLink inFooter={false}/>
                                </Grid>
                            </>
                        }
                    </Grid>
                </Grid>
                <Grid item xs={12} sx={{m: 3, mt: 1}}>
                    <AuthFooter inLogin/>
                </Grid>
            </Grid>
        </Box>
    );
}

// AuthWrapper.propTypes = {
//     children: PropTypes.node
// };

export default AuthWrapper;
