import React, {useContext, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {PARAMETERS} from "../../locales/namespaces";
import {QuestionnaireContext} from "./questionnaires";
import DataGridWrapper from "../../../../components/dataGridWrapper/dataGridWrapper";
import {CustomPagination} from "../../../../components/dataGridCustom/customPagination";
import EditableMUIDataGrid from "../../../../components/editableMUIDataGrid/editableMUIDataGrid";
import EditableMUICellValidation from "../../../../components/editableMUICellValidation/editableMUICellValidation";
import {GridEditInputCell} from "@mui/x-data-grid-premium";

const Choices = () => {
	const {
		dataChoices,
		updateChoice
	} = useContext(QuestionnaireContext);
	
	const {t} = useTranslation(PARAMETERS)
	
	const newRowModel = {
		Description: '',
		OrderNum: ''
	}
	
	const columns = useMemo(() => [
		{
			field: 'DescriptionEl',
			headerName: t('Description EL'),
			minWidth: 160,
			flex: 1,
			editable: true,
			preProcessEditCellProps: (params) => {
				const inputValue = params.props.value;
				if (inputValue === '') {
					return {...params.props, error: t('Required')};
				}
				return {...params.props, error: null}; // No error
			},
			renderEditCell: (params) => {
				return <EditableMUICellValidation {...params}>
					<GridEditInputCell {...params} />
				</EditableMUICellValidation>
			},
		},
		{
			field: 'DescriptionEn',
			headerName: t('Description EN'),
			minWidth: 160,
			flex: 1,
			editable: true,
			preProcessEditCellProps: (params) => {
				const inputValue = params.props.value;
				if (inputValue === '') {
					return {...params.props, error: t('Required')};
				}
				return {...params.props, error: null}; // No error
			},
			renderEditCell: (params) => {
				return <EditableMUICellValidation {...params}>
					<GridEditInputCell {...params} />
				</EditableMUICellValidation>
			},
		},
	
	], [t])
	
	return (
		<DataGridWrapper
			autoHeight
			sx={{
				p: 1,
				maxWidth: '600px'
			}}
		>
			<EditableMUIDataGrid
				sx={{maxHeight: 500}}
				//loading={loading}
				data={dataChoices.length > 0 ? dataChoices : []}
				columns={columns}
				newRowModel={newRowModel}
				update={updateChoice}
				hideDeleteButton={true}
				dataGridProOtherProps={{
					disableColumnMenu: true,
					slots: {
						toolbar: false,
						pagination: CustomPagination,
					}
				}}
			/>
		</DataGridWrapper>
	)
}

export default Choices
