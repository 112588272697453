import AxiosErrorMessage from "../components/axiosErrorMessage/axiosErrorMessage";
import ErrorMessage from "../components/errorMessage/errorMessage";

export const handleAxiosErrors = (error, customMessage = null, language) => {
	const {code, config, message, name, request, response} = error
	let errorMessage = message
	const errorCode = response?.status ?? code
	const responseMessage = response?.data?.message ?? ''
	switch (errorCode) {
		case 401:
			errorMessage = (language === 'en' ? 'Unauthorized' : 'Έχει γίνει αποσύνδεση.')
			break;
		case 409:
			errorMessage = (language === 'en' ? 'Duplicate Entry' : 'Διπλότυπη καταχώριση.')
			break;
		case 404:
			errorMessage = (language === 'en' ? 'Not Found' : 'Δεν βρέθηκε.')
			break;
		case 500:
			errorMessage = (language === 'en' ? 'An error occurred!' : 'Παρουσιάστηκε κάποιο σφάλμα!')
			break;
		default:
			errorMessage = errorMessage ? `${errorMessage}. ${responseMessage}` : responseMessage
			break
	}
	return customMessage ? (
		<AxiosErrorMessage
			code={code}
			errorCode={errorCode}
			errorMessage={errorMessage}
			customMessage={customMessage}
			responseMessage={responseMessage}
		/>
	) : (
		<ErrorMessage
			errorCode={errorCode}
			errorMessage={errorMessage}
			responseMessage={responseMessage}
		/>
	)
}

export const logoutUnauthorizedUser = (error, isLoggedIn, userLogout) => {
	const {code, response} = error
	const errorCode = response?.status ?? code
	const responseMessage = response?.data?.message ?? ''
	if (errorCode === 401 && isLoggedIn && (responseMessage === "Unauthenticated." || responseMessage === "Unauthenticated" || responseMessage === "Unauthorized")) {
		userLogout()
	}
}