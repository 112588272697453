import useUserData from "../../../hooks/user/useUserData";
import {useTranslation} from "react-i18next";
import {CALCULATIONS} from "../locales/namespaces";
import * as yup from "yup";
import {API} from "../../../helpers/constants";
import {enqueueSnackbar} from "notistack";
import {handleAxiosErrors, logoutUnauthorizedUser} from "../../../helpers/errorHandling";
import {useFormik} from "formik";
import axios from "axios";
import {calculateInstallments} from "../helpers/calculations";
import moment from "moment";
import useUserAction from "../../../hooks/user/useUserAction";

const useRepaymentsForm = ({
							   id,
							   handleResults,
							   onClose,
							   calculationData,
							   contributions,
							   repayments,
							   updateRepayments,
							   handleUpdateRepayments,
							   RepaymentId,
							   repaymentsEdit
						   }) => {
	const {token, isLoggedIn} = useUserData()
	const {userLogout} = useUserAction()
	const {t} = useTranslation(CALCULATIONS)
	
	const mapRepayments = repayments.map(repayment => ({
		Amount: Number(repayment.Amount),
		NewRate: Number(repayment.NewRate),
		Repayment_dt: moment(repayment.PaymentDt).format('MM/DD/YYYY'),
		id: repayment.id
	}))

//console.log( addYearsToDate(calculationData?.DateInstallment, Number(calculationData?.FloatingDuration) + Number(calculationData?.FixedDuration)))
	
	const validationSchema = yup.object({
		Amount: yup
		.number().required(t('Required'))
		.positive(t('Value must be a positive number.'))
		.min(0, t('Value must be a positive number.'))
		.max(99999999.99, t('Loan amount must be less than 100,000,000.'))
		.test('amount-validation', t('Repayment amount must be less than to the loan amount.'), value => {
			const loanAmount = calculationData?.LoanAmount || 0;
			return value < loanAmount;
		}),
		Repayment_dt: yup
		.date().typeError(t('Value must be a valid date.')).required(t('Required'))
		// .min(calculationData?.DateInstallment || moment(), t('Repayment date must not be before the first installment date.'))
		// .max(
		//     addYearsToDate(calculationData?.DateInstallment, Number(calculationData?.FloatingDuration) + Number(calculationData?.FixedDuration)),
		//     t('Repayment date must be within the total loan duration.')
		// ),
	})
	
	const onSubmit = (values) => {
		const newRate = calculationData.InterestType === 1 ? calculationData.FixedInterestRate : calculationData.InterestType === 2
			? calculationData.Spread + calculationData.Euribor :
			Number(moment(values.Repayment_dt).format('YYYY')) > Number(moment(calculationData.DateInstallment).format('YYYY')) + calculationData.FixedDuration
				? calculationData.Spread + calculationData.Euribor : calculationData.FixedInterestRate
		axios({
			method: 'post',
			data: {
				PaymentDt: values.Repayment_dt,
				Amount: values.Amount,
				NewRate: newRate,
				CalculationId: id,
				RepaymentId: RepaymentId
			},
			headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
			url: !RepaymentId ? `${API}/repayment/store` : `${API}/repayment/update`
		}).then(() => {
			handleUpdateRepayments(!updateRepayments)
			formik.resetForm()
			enqueueSnackbar(!RepaymentId ? t('Repayment Stored Successfully!') : t('Repayment Updated Successfully!'), {
				variant: 'success'
			})
			//When update repayment delete the previous value
			for (let i = 0; i <= mapRepayments.length - 1; i++) {
				if (mapRepayments[i].id === RepaymentId) {
					mapRepayments.splice(i, 1)
					break
				}
			}
			
			handleResults(calculateInstallments(
				0,
				calculationData.FixedDuration,
				calculationData.FloatingDuration,
				calculationData.FixedInterestRate,
				calculationData.Spread + calculationData.Euribor,
				contributions.find(f => f.id === calculationData.Contribution).Value,
				calculationData.LoanAmount,
				calculationData.isMonthly === 'isMonthly',
				calculationData.isHidePreviousInstallments === '1',
				moment(calculationData.DateInstallment).format('MM/DD/YYYY'),
				[...mapRepayments, {
					Amount: values.Amount,
					NewRate: values.NewRate,
					Repayment_dt: moment(values.Repayment_dt).format('MM/DD/YYYY')
				}],
				[],
				[],
				0,
				0,
				0,
				0,
				[...mapRepayments, {
					Amount: values.Amount,
					NewRate: newRate,
					Repayment_dt: moment(values.Repayment_dt).format('MM/DD/YYYY')
				}]
			));
			onClose()
		}).catch((error) => {
			enqueueSnackbar(handleAxiosErrors(error, t('Failed to get repayment.')), {
					variant: 'error'
				}
			)
			logoutUnauthorizedUser(error, isLoggedIn, userLogout)
		})
	}
	
	const formik = useFormik({
		initialValues: {
			Amount: repaymentsEdit?.Amount || 0,
			Repayment_dt: repaymentsEdit?.Repayment_dt ? moment(repaymentsEdit.Repayment_dt, "MMMM YYYY") : moment().startOf('day'),
			NewRate: repaymentsEdit?.NewRate ? repaymentsEdit?.NewRate : 5
		},
		validationSchema: validationSchema,
		onSubmit: onSubmit
	})
	
	return {
		formik,
		updateRepayments
	}
}

export default useRepaymentsForm
