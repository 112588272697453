// @flow
import * as React from 'react';
import {ErrorMessage, FormikProvider} from "formik";
import {useTranslation} from "react-i18next";
import {Button, FormControl, FormHelperText, Grid, FormLabel, Stack,} from "@mui/material";
import TextField from "@mui/material/TextField";
import {PARAMETERS} from "../../locales/namespaces";
import {withPermissionHOC} from "../../../permission/components/permissionHOC/permissionHOC";
import useInterestRateForm from "../../hooks/useInterestRateForm";
import MainCard from "../../../../theme/mantis/components/mainCard";
import ContentLoader from "../../../../components/contentLoader/contentLoader";
import {NumericFormat} from "react-number-format";
import useUserData from "../../../../hooks/user/useUserData";

type Props = {
    interests?: Object
}

const FormInterestRates = (props: Props) => {
    const {t} = useTranslation(PARAMETERS)
    const {
        interests
    } = props

    const {formik, isLoading} = useInterestRateForm({
        interests
    })
    const {decimalSeparator, thousandSeparator} = useUserData()

    return !isLoading ? (
        <MainCard sx={{maxWidth: 400}}>
            <FormikProvider value={formik}>
                <Grid container spacing={1} sx={{mr: 2}}>
                    <Grid item xs={12}>
                        <Stack spacing={1}>
                            <FormControl
                                variant="standard"
                                fullWidth
                                error={
                                    formik.touched["Spread"] &&
                                    Boolean(formik.errors["Spread"])
                                }
                                sx={{mb: 2}}
                            >
                                <FormLabel htmlFor="Spread">{t('Bank Spread')}</FormLabel>
                                <NumericFormat
                                    value={formik.values.Spread}
                                    onValueChange={(values, sourceInfo) => {
                                        formik.setFieldValue('Spread', values.floatValue ? values.floatValue : '')
                                    }}
                                    thousandSeparator={thousandSeparator}
                                    decimalSeparator={decimalSeparator}
                                    decimalScale={2}
                                    customInput={TextField}

                                    onBlur={formik.handleBlur}
                                    name={`Spread`}
                                    color={"grey"}
                                    size={'small'}
                                    fullWidth
                                    error={formik.touched.Spread && Boolean(formik.errors.Spread)}
                                />
                                <FormHelperText>
                                    <ErrorMessage name={"Spread"}/>
                                </FormHelperText>
                            </FormControl>
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        <Stack spacing={1}>
                            <FormControl
                                variant="standard"
                                fullWidth
                                error={
                                    formik.touched["Euribor"] &&
                                    Boolean(formik.errors["Euribor"])
                                }
                                sx={{mb: 2}}
                            >
                                <FormLabel htmlFor="Euribor">{t('Euribor')}</FormLabel>
                                <NumericFormat
                                    value={formik.values.Euribor}
                                    onValueChange={(values, sourceInfo) => {
                                        formik.setFieldValue('Euribor', values.floatValue ? values.floatValue : '')
                                    }}
                                    thousandSeparator={thousandSeparator}
                                    decimalSeparator={decimalSeparator}
                                    decimalScale={2}
                                    customInput={TextField}

                                    onBlur={formik.handleBlur}
                                    name={`Euribor`}
                                    color={"grey"}
                                    size={'small'}
                                    fullWidth
                                    error={formik.touched.Euribor && Boolean(formik.errors.Euribor)}
                                />
                                <FormHelperText>
                                    <ErrorMessage name={"Euribor"}/>
                                </FormHelperText>
                            </FormControl>
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        <Stack spacing={1}>
                            <FormControl
                                variant="standard"
                                fullWidth
                                error={
                                    formik.touched["StableInterest"] &&
                                    Boolean(formik.errors["StableInterest"])
                                }
                                sx={{mb: 2}}
                            >
                                <FormLabel htmlFor="StableInterest">{t('Stable Interest')}</FormLabel>
                                <NumericFormat
                                    value={formik.values.StableInterest}
                                    onValueChange={(values, sourceInfo) => {
                                        formik.setFieldValue('StableInterest', values.floatValue ? values.floatValue : '')
                                    }}
                                    thousandSeparator={thousandSeparator}
                                    decimalSeparator={decimalSeparator}
                                    decimalScale={2}
                                    customInput={TextField}

                                    onBlur={formik.handleBlur}
                                    name={`StableInterest`}
                                    color={"grey"}
                                    size={'small'}
                                    fullWidth
                                    error={formik.touched.StableInterest && Boolean(formik.errors.StableInterest)}
                                />
                                <FormHelperText>
                                    <ErrorMessage name={"StableInterest"}/>
                                </FormHelperText>
                            </FormControl>
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        <Stack spacing={1}>
                            <FormControl
                                variant="standard"
                                fullWidth
                                error={
                                    formik.touched["LoanAmount"] &&
                                    Boolean(formik.errors["LoanAmount"])
                                }
                                sx={{mb: 2}}
                            >
                                <FormLabel htmlFor="LoanAmount">{t('Loan amount (€)')}</FormLabel>
                                <NumericFormat
                                    value={formik.values.LoanAmount}
                                    onValueChange={(values, sourceInfo) => {
                                        formik.setFieldValue('LoanAmount', values.floatValue ? values.floatValue : '')
                                    }}
                                    thousandSeparator={thousandSeparator}
                                    decimalSeparator={decimalSeparator}
                                    decimalScale={2}
                                    customInput={TextField}

                                    onBlur={formik.handleBlur}
                                    name={`LoanAmount`}
                                    color={"grey"}
                                    size={'small'}
                                    fullWidth
                                    error={formik.touched.LoanAmount && Boolean(formik.errors.LoanAmount)}
                                />
                                <FormHelperText>
                                    <ErrorMessage name={"LoanAmount"}/>
                                </FormHelperText>
                            </FormControl>
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        <Stack spacing={1}>
                            <FormControl
                                variant="standard"
                                fullWidth
                                error={
                                    formik.touched["RealEstateValue"] &&
                                    Boolean(formik.errors["RealEstateValue"])
                                }
                                sx={{mb: 2}}
                            >
                                <FormLabel htmlFor="RealEstateValue">{t('Property Commercial Value (€)')}</FormLabel>
                                <NumericFormat
                                    value={formik.values.RealEstateValue}
                                    onValueChange={(values, sourceInfo) => {
                                        formik.setFieldValue('RealEstateValue', values.floatValue ? values.floatValue : '')
                                    }}
                                    thousandSeparator={thousandSeparator}
                                    decimalSeparator={decimalSeparator}
                                    decimalScale={2}
                                    customInput={TextField}

                                    onBlur={formik.handleBlur}
                                    name={`RealEstateValue`}
                                    color={"grey"}
                                    size={'small'}
                                    fullWidth
                                    error={formik.touched.RealEstateValue && Boolean(formik.errors.RealEstateValue)}
                                />
                                <FormHelperText>
                                    <ErrorMessage name={"RealEstateValue"}/>
                                </FormHelperText>
                            </FormControl>
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        <Stack spacing={1}>
                            <FormControl
                                variant="standard"
                                fullWidth
                                error={
                                    formik.touched["NotificationDays"] &&
                                    Boolean(formik.errors["NotificationDays"])
                                }
                                sx={{mb: 2}}
                            >
                                <FormLabel htmlFor="NotificationDays"
                                           required>{t('Number of days for installment notification')}</FormLabel>
                                <NumericFormat
                                    value={formik.values.NotificationDays}
                                    onValueChange={(values, sourceInfo) => {
                                        formik.setFieldValue('NotificationDays', values.floatValue ? values.floatValue : '')
                                    }}
                                    thousandSeparator={thousandSeparator}
                                    decimalSeparator={decimalSeparator}
                                    decimalScale={2}
                                    customInput={TextField}

                                    required
                                    onBlur={formik.handleBlur}
                                    name={`NotificationDays`}
                                    color={"grey"}
                                    size={'small'}
                                    fullWidth
                                    error={formik.touched.NotificationDays && Boolean(formik.errors.NotificationDays)}
                                />
                                <FormHelperText>
                                    <ErrorMessage name={"NotificationDays"}/>
                                </FormHelperText>
                            </FormControl>
                        </Stack>
                    </Grid>
                </Grid>
                <Button
                    size={"small"}
                    color={'blue'}
                    variant={"contained"}
                    sx={{mb: 2, ml: 2, mt: 2, float: 'right'}}
                    onClick={formik.submitForm}
                >
                    {t('Submit')}
                </Button>
            </FormikProvider>
        </MainCard>
    ) : (
        <ContentLoader
            withBackDrop={true}
            useCircularLoader={true}
            loading={isLoading}
            message={t("Loading... Please don't close the browser before the process ends.")}
        />
    )
}

export default withPermissionHOC(FormInterestRates, ['access_parameters'])