//@flow
import {createSelector} from 'reselect'
import {useSelector} from "react-redux";

const selectMenu = createSelector(
	(state) => state.menu,
	(item) => item.get('menu').toJS()
)

const selectOpenItem = createSelector(
	(state) => state.menu,
	(item) => item.get('openItem')
)

const selectOpenComponent = createSelector(
	(state) => state.menu,
	(item) => item.get('openComponent')
)

const selectSelectedID = createSelector(
	(state) => state.menu,
	(item) => item.get('selectedID')
)

const selectDrawerOpen = createSelector(
	(state) => state.menu,
	(item) => item.get('drawerOpen')
)

const selectComponentDrawerOpen = createSelector(
	(state) => state.menu,
	(item) => item.get('componentDrawerOpen')
)

const useMenuData = () => {
	const menu = useSelector(selectMenu)
	const openItem = useSelector(selectOpenItem)
	const openComponent = useSelector(selectOpenComponent)
	const selectedId = useSelector(selectSelectedID)
	const drawerOpen = useSelector(selectDrawerOpen)
	const componentDrawerOpen = useSelector(selectComponentDrawerOpen)
	return {
		menu,
		openItem,
		openComponent,
		selectedId,
		drawerOpen,
		componentDrawerOpen
	}
}

export default useMenuData