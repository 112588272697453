// @flow
import React, {useEffect, useState} from 'react';
import {Box, Typography, Grid, useTheme, Select, MenuItem, useMediaQuery, Stack} from "@mui/material";
import {useTranslation} from "react-i18next";
import {PAGE_HOME} from "../../../locales/pages/namespaces";
import axios from "axios";
import {API} from "../../../helpers/constants";
import {enqueueSnackbar} from "notistack";
import {handleAxiosErrors, logoutUnauthorizedUser} from "../../../helpers/errorHandling";
import useUserData from "../../../hooks/user/useUserData";
import {
	LineChart,
	Line,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	ResponsiveContainer,
	Legend
} from "recharts";
import {textTranslate} from "../../../helpers/helpers";
import useUserAction from "../../../hooks/user/useUserAction";
import MainCard from "../../../theme/mantis/components/mainCard";
import UserCountCard from "../../../theme/mantis/components/cards/statistics/userCountCard";
import RotateIcon from "../../../assets/images/icons/rotateIcon";
import UserCheckIcon from "../../../assets/images/icons/userCheckIcon";
import UserCancelIcon from "../../../assets/images/icons/userCancelIcon";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import {getRouteUrl} from "../../../helpers/getRouteUrl";
import {ROUTE_PAGE_HOME} from "../../../routers/routes";
import HomeBreadcrumbBlue from "../../../assets/images/icons/homeBreadcrumbBlue";
import UsersPartnerICon from "../../../assets/images/icons/usersPartnerIcon";
import {Capacitor} from "@capacitor/core";

const PartnerDashboard = () => {
	const {token, isLoggedIn, language} = useUserData()
	const {userLogout} = useUserAction()
	const {t} = useTranslation(PAGE_HOME)
	const theme = useTheme();
	/* const [loading, setLoading] = useState(false)*/
	const [kpiStatus, setKpiStatus] = useState([])
	const [selectedYears, setSelectedYears] = useState(1); // Default to 1 year
	const matchDownSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
	
	const handleYearsChange = (event) => {
		setSelectedYears(event.target.value);
	};
	
	useEffect(() => {
		/* setLoading(true);*/
		axios({
			method: 'get',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`,
			},
			url: `${API}/report/data?years=${selectedYears}`,
		}).then((response) => {
			setKpiStatus(response.data);
			/*   setLoading(false);*/
		}).catch((error) => {
			/* setLoading(false);*/
			enqueueSnackbar(handleAxiosErrors(error, t('Failed to get details.')), {
				variant: 'error',
			});
			logoutUnauthorizedUser(error, isLoggedIn, userLogout);
		});
	}, [selectedYears]);
	
	const data = kpiStatus && kpiStatus.countLeads ? kpiStatus.countLeads.map(item => ({
		DescriptionEn: textTranslate(language)({
			en: item.DescriptionEn,
			el: item.DescriptionEl
		}),
		newLeads: item.newLeads,
		completedLeads: item.completedLeads,
		canceledLeads: item.canceledLeads,
		
	})) : []
	
	//const minY = Math.min(...data.map((entry) => entry.newLeads));
	const maxY = Math.max(...data.map((entry) => entry.newLeads));
	
	return (
		<Box>
			{!matchDownSM &&
				<Breadcrumb>
					<Breadcrumb.Link iconComponent={HomeBreadcrumbBlue} to={getRouteUrl(ROUTE_PAGE_HOME)}/>
				</Breadcrumb>
			}
			<MainCard
				title={
					<Typography variant="h4">
						{t('Leads')}
					</Typography>
				}
				sx={{mt: '2rem', mb: '2rem'}}
			>
				<Grid container spacing={3}>
					<Grid item xs={12} lg={3} sm={6}>
						<UserCountCard
							primary={t('Total Leads')}
							secondary={!kpiStatus.KPINewLeads ? '0' : kpiStatus.KPINewLeads}
							iconPrimary={UsersPartnerICon}
							color={'#fff'}
							borderColor={'1px solid #485776'}
						/>
					</Grid>
					<Grid item xs={12} lg={3} sm={6}>
						<UserCountCard
							primary={t('Leads in progress')}
							secondary={!kpiStatus.KPIProcessingLeads ? '0' : kpiStatus.KPIProcessingLeads}
							iconPrimary={RotateIcon}
							color={'#fff'}
							borderColor={'1px solid #F4A40B'}
						/>
					</Grid>
					<Grid item xs={12} lg={3} sm={6}>
						<UserCountCard
							primary={t('Completed Leads')}
							secondary={!kpiStatus.KPICompletedLeads ? '0' : kpiStatus.KPICompletedLeads}
							iconPrimary={UserCheckIcon}
							color={'#fff'}
							borderColor={'1px solid #0BC1B6'}
						/>
					</Grid>
					<Grid item xs={12} lg={3} sm={6}>
						<UserCountCard
							primary={t('Canceled Leads')}
							secondary={!kpiStatus.KPICanceledLeads ? '0' : kpiStatus.KPICanceledLeads}
							iconPrimary={UserCancelIcon}
							color={'#fff'}
							borderColor={'1px solid #CC5F5F'}
						/>
					</Grid>
				</Grid>
			</MainCard>
			<MainCard
				title={
					<Typography variant="h4">
						{t('Leads per month')}
					</Typography>
				}
				sx={{mt: 2}}
			>
				<Stack direction={'row'} sx={{float: 'right'}}>
					<Typography
						variant={'h5'}
						color={theme.palette.primary[100]}
						fontWeight={600}
						fontSize={'14px'}
						sx={{mt: 2}}
					>
						{t('show')}
					</Typography>
					{Capacitor.getPlatform() === 'android' ?
						<Select
							native
							value={selectedYears}
							onChange={handleYearsChange}
							sx={{
								background: '#fff',
								color: theme.palette.secondary[100],
								fontWeight: 600,
								'& .MuiOutlinedInput-notchedOutline': {
									border: 'none',
									'&:hover': {
										border: "#fff"
									}
								},
								'& .MuiSvgIcon-root': {
									color: '#C2CFE0'
								}
							}}
						>
							<option value={1}>{t('This year')}</option>
							<option value={2}>{t('Last two years')}</option>
							<option value={3}>{t('Last three years')}</option>
						</Select> :
						<Select
							value={selectedYears}
							onChange={handleYearsChange}
							sx={{
								background: '#fff',
								color: theme.palette.secondary[100],
								fontWeight: 600,
								'& .MuiOutlinedInput-notchedOutline': {
									border: 'none',
									'&:hover': {
										border: "#fff"
									}
								},
								'& .MuiSvgIcon-root': {
									color: '#C2CFE0'
								}
							}}
						>
							<MenuItem value={1}>{t('This year')}</MenuItem>
							<MenuItem value={2}>{t('Last two years')}</MenuItem>
							<MenuItem value={3}>{t('Last three years')}</MenuItem>
						</Select>
					}
				</Stack>
				<Grid container
					  sx={{gap: '20px'}}
				>
					<Grid item xs={12}>
						<ResponsiveContainer width="100%" height={300}>
							<LineChart
								data={data}
								margin={{
									top: 10,
									right: 30,
									left: 0,
									bottom: 0
								}}
							>
								<CartesianGrid strokeDasharray="8 8" vertical={false}/>
								<XAxis
									dataKey="DescriptionEn"
									tick={{
										stroke: '#485776',
										fontFamily: 'Poppins',
										fontSize: '12px',
										fontStyle: 'normal',
										fontWeight: 100,
										lineHeight: 'normal',
										letterSpacing: '0.12px'
									}}
									tickSize={16}
									tickLine={false}
									axisLine={false}
								/>
								<YAxis
									domain={[0, maxY]}
									tickCount={maxY + 1}
									tick={{
										stroke: '#485776',
										fontSize: '12px'
									}}
									tickSize={16}
									tickLine={false}
									axisLine={false}
									tickMargin={20}
								/>
								<Tooltip/>
								<Legend verticalAlign="bottom" wrapperStyle={{lineHeight: "30px"}} iconType={'circle'}
										iconSize={8}/>
								<Line
									type="monotone"
									dataKey="newLeads"
									stroke={'#485776'}
									fill={"#fff"}
									strokeWidth={'2px'}
									name={t('Total Leads')}
								/>
								<Line
									type="monotone"
									dataKey="completedLeads"
									stroke={theme.palette.secondary[100]}
									fill={"#fff"}
									strokeWidth={'2px'}
									color={theme.palette.secondary[100]}
									name={t('Completed Leads')}
								/>
								<Line
									type="monotone"
									dataKey="canceledLeads"
									stroke={theme.palette.actions.red}
									strokeWidth={'2px'}
									fill={"#fff"}
									color={theme.palette.actions.red}
									name={t('Canceled Leads')}
								/>
							</LineChart>
						</ResponsiveContainer>
					</Grid>
				</Grid>
			</MainCard>
		</Box>
	
	)
}

export default PartnerDashboard;
