import axios from "axios";
import {API} from "../../../helpers/constants";
import {useEffect, useState} from "react";
import useUserData from "../../../hooks/user/useUserData";
import {handleAxiosErrors, logoutUnauthorizedUser} from "../../../helpers/errorHandling";
import {useTranslation} from "react-i18next";
import {ALERTS} from "../../../locales/components/namespaces";
import {enqueueSnackbar} from "notistack";
import useUserAction from "../../../hooks/user/useUserAction";


const useUser = ({id}) => {
	const {token, isLoggedIn} = useUserData()
	const {userLogout} = useUserAction()
	const {t} = useTranslation(ALERTS)
	const [user, setUser] = useState({})
	const [partner, setPartner] = useState({})
	const [loading, setLoading] = useState(false)
	
	useEffect(() => {
		setLoading(true)
		if (id) {
			axios({
				method: 'post',
				data: {UserId: id},
				headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
				url: `${API}/user/edit/data`,
			}).then(response => {
				let data = response.data.user
				setUser({
					isActive: data.isActive === '1',
					SendEmails: data.SendEmails === '1',
					AccessApi: data.AccessApi === '1',
					isPartner: data.isPartner === '1',
					LoginName: data.LoginName,
					PublicIp: data.PublicIp,
					username: data.username,
					password: data.password,
					confirmPassword: data.confirmPassword,
					email: data.email,
					CurrencyId: data.CurrencyId,
					NationalityId: data.NationalityId,
					LangId: data.LangId,
					CountryId: data.CountryId,
					mobilePhone: data.mobilePhone,
					phone: data.phone
				})
				
				setPartner(response.data.partner)
				/*if (response.data && response.data.partner.length > 0) {
					debugger
					const partner = response.data.partner[0]
					setPartner({
						partner: partner.GID
					})
				}*/
				
				
				// if (data && data.user.length > 0) {
				//     const user = data.user[0]
				//     setUser({
				//         isActive: user.isActive === '1',
				//         LoginName: user.LoginName,
				//         username: user.username,
				//         password: user.password,
				//         confirmPassword: user.confirmPassword,
				//         email: user.email,
				//         CurrencyId: user.CurrencyId,
				//         NationalityId:user.NationalityId,
				//         LangId: user.LangId,
				//         CountryId: user.CountryId,
				//         mobilePhone: user.mobilePhone,
				//         phone: user.phone,
				//         //DateFormatId: user.DateFormatId,
				//         //TODO:: used when decimal change works
				//         //DecimalChar: user.DecimalChar
				//
				//     })
				setLoading(false)
				
			}).catch(error => {
				setLoading(false)
				enqueueSnackbar(handleAxiosErrors(error, t('Failed to get user details.'), {
						variant: 'error'
					}
				))
				logoutUnauthorizedUser(error, isLoggedIn, userLogout)
			})
		}
	}, [id])
	
	return {
		loading,
		user,
		partner
	}
}

export default useUser
