import { forEach } from "lodash";

export const PMT = (rate, nper, pv, fv, type) => {
    if (!fv) fv = 0;
    if (!type) type = 0;
    if (rate === 0) return -(pv + fv) / nper;

    let pmt = rate / (Math.pow(1 + rate, nper) - 1) * -(pv * Math.pow(1 + rate, nper) + fv);

    if (type === 1) {
        pmt /= (1 + rate);
    }

    return pmt;
}
export const calculateInstallments = (countAA, FixedDuration, FloatDuration, FixedRate, FloatRate, Contribution, RequestedAmount, isMonthly, hidePrevious, start_date, Repayments, FixedTable, FloatTable, Repayment, SumInterest, YearInterest, YearRepayment, isRepaymentDt,YearInstallment=0) => {
    if (countAA === 0) {
        Repayments.forEach(obj => {
            if(obj.Diff != undefined) delete obj.Diff;
        });
    }
    // const monthNames = {1:"January", 2:"February", 3:"March", 4:"April", 5:"May", 6:"June",
    //     7:"July", 8:"August", 9:"September", 10:"October", 11:"November", 12:"December"};
    let FixedRatePercentage = Number(FixedRate) / 100;
    let FloatRatePercentage = Number(FloatRate) / 100;
    let ContributionPercentage = Number(Contribution) / 100;
    let FixedMonths = Number(FixedDuration) * 12; //Αρ.Δόσεων Σταθερού
    let FloatMonths = Number(FloatDuration) * 12; //Αρ.Δόσεων Κυμαινόμενου

    const currentTime = new Date();
    const month = currentTime.getMonth() +1;
    const year = currentTime.getFullYear();
    start_date = new Date(start_date);
    const diffMonthly = ((year * 12 + month) - ((start_date.getFullYear()) * 12 + (start_date.getMonth() +1))) ;
    const diffAnnual = ((year) * 12 - (start_date.getFullYear() * 12));
    if((Repayments.length>0)){
        if (!(Repayments[0].hasOwnProperty('Diff'))){
            Repayments.sort(function(a, b) {
                a = new Date(a.Repayment_dt);
                b = new Date(b.Repayment_dt);
                return (a.getTime() - b.getTime());
            });
            Repayments.forEach(obj => {
                obj.Repayment_dt = new Date(obj.Repayment_dt);
                obj.Diff = ((obj.Repayment_dt.getFullYear() * 12 + obj.Repayment_dt.getMonth() + 1) - ((start_date.getFullYear()) * 12 + start_date.getMonth() +1 )+1);
            });
        }
    }
    const FixedInst = PMT((FixedRatePercentage + ContributionPercentage) / 12, FixedMonths + FloatMonths, -RequestedAmount, 0, 0); //Δόση Σταθερού
    //const FixedTable = []; //το array για τις δόσεις σταθερού επιτοκίου
    //const FloatTable = []; //το array για τις δόσεις κυμαινόμενου επιτοκίου

    let RemainingAmount = RequestedAmount; //Υπόλοιπο Κεφάλαιο

    //Δημιουργία δόσεων σταθερού
    for (let i = 1; i <= FixedMonths; i++) {
        const NewRow = [];
        NewRow.isRepayment =0;
        if(Repayments.length>0 && Repayments[0].Diff==i){

            for(var j=1; j<=Repayments.length-1; j++) Repayments[j].Diff = Repayments[j].Diff-Repayments[0].Diff +1 ; //Υπολειπόμενοι μήνες
            RequestedAmount = RemainingAmount - Repayments[0].Amount; //Νέο ποσό
            FixedMonths = FixedMonths - i + 1; //Εναπομείναντες μήνες

            Repayment = Repayment + Repayments[0].Amount;
            Repayments.splice(0, 1);

            return calculateInstallments (countAA, Number(FixedMonths/12), Number(FloatMonths/12), Number(FixedRatePercentage*100), Number(FloatRatePercentage*100), Number(ContributionPercentage*100), RequestedAmount, isMonthly, hidePrevious, start_date, Repayments, FixedTable, FloatTable,Repayment, SumInterest, YearInterest, YearRepayment, isRepaymentDt, YearInstallment)
        }
        countAA++;
        NewRow.AA = countAA;

        NewRow.Installment = FixedInst; //Μηνιαία Δόση
        NewRow.Interest = RemainingAmount * (FixedRatePercentage + ContributionPercentage) / 12; //Τόκος
        YearInstallment += NewRow.Installment;
        NewRow.Creolysio = NewRow.Installment - NewRow.Interest;//Χρεολύσιο

        RemainingAmount -= NewRow.Creolysio;
        NewRow.RemainingAmount = RemainingAmount;//Υπόλοιπο Κεφάλαιο

        SumInterest += NewRow.Interest;
        YearInterest += NewRow.Interest;
        NewRow.SumInterest = SumInterest;//Σύνολο Τόκων

        Repayment += NewRow.Creolysio;
        YearRepayment += NewRow.Creolysio;
        NewRow.Repayment = Repayment;//Σύνολο αποπληρωμής κεφάλαιου
        NewRow.Date = ((((start_date.getMonth()+(countAA-1))%12)+1).toString() +'/'+ (Number(start_date.getFullYear())+parseInt(((start_date.getMonth()+(countAA-1)))/12)));

        if (isMonthly==1) {
            if ((diffMonthly < countAA-1 && hidePrevious) || !hidePrevious) {
                FixedTable.push(NewRow); //Δημιουργία γραμμής αν ειναι μηνιαίος υπολογισμός
            }
            continue;
        }
        if (((((start_date.getMonth()+(countAA-1))%12)+1) % 12) === 0 || countAA === (FixedMonths+FloatMonths)) { //Δημιουργία γραμμής αν είναι ετήσιος υπολογισμός και είναι ο 12ος μήνας
            NewRow.LastDt = new Date();
            NewRow.LastDt.setMonth((((start_date.getMonth()+(countAA-1))%12)+1)-1);
            NewRow.LastDt.setFullYear((Number(start_date.getFullYear())+parseInt(((start_date.getMonth()+(countAA-1)))/12)));
            NewRow.StartDt = new Date();
            NewRow.StartDt.setMonth(NewRow.LastDt.getMonth()-1);
            NewRow.StartDt.setFullYear(NewRow.LastDt.getFullYear()-1);
            NewRow.Date = NewRow.LastDt.getFullYear().toString();
            if(start_date.getMonth() == 0){
                NewRow.AA = parseInt(countAA / 12);
            }else {
                NewRow.AA = parseInt(countAA / 12) +1;
            }
            NewRow.Installment = YearInstallment; //Ετήσια Δόση
            NewRow.Interest = YearInterest; //Τόκος
            YearInterest = 0; //Μηδενίζουμε το σύνολο τόκων ανά έτος
            NewRow.Creolysio = YearRepayment; //Χρεολύσιο
            YearRepayment = 0; //Μηδενίζουμε το χρεολύσιο ανά έτος
            NewRow.SumInterest = SumInterest; //Σύνολο Τόκων
            NewRow.Repayment = Repayment; //Σύνολο αποπληρωμής κεφάλαιου
            YearInstallment = 0; //Μηδενίζουμε την δόση ανά έτος
            if ((diffAnnual < countAA && hidePrevious) || !hidePrevious) {
                FixedTable.push(NewRow)
            };
        }
    }

    if (FloatMonths === 0) {
        for(let j=0; j<Object.keys(isRepaymentDt).length; j++){
            isRepaymentDt[j].Repayment_dt = new Date(isRepaymentDt[j].Repayment_dt);
            if(isMonthly==1){
                isRepaymentDt[j] = (isRepaymentDt[j].Repayment_dt.getMonth()+1).toString() +'/'+isRepaymentDt[j].Repayment_dt.getFullYear();
            }
        }
        for(let i=0; i<isRepaymentDt.length; i++){
            for(let j=0; j<Object.keys(FixedTable).length; j++){

                if(isMonthly==1) {
                    if(FixedTable[j].Date == isRepaymentDt[i])
                        FixedTable[j].isRepayment = 1;
                }else{
                    // //Αν το δάνειο ξεκινάει Ιανουάριο στέλνουμε μορφή YYYY, αλλιώς YYYY-YYYY
                    // if(start_date.getMonth()==0){
                    //     FixedTable[j].Date = FixedTable[j].Date;
                    //     if(FixedTable[j].Date == isRepaymentDt[i].Repayment_dt.getFullYear())
                    //         FixedTable[j].isRepayment = 1;
                    // }else{
                    if((FixedTable[j].LastDt > isRepaymentDt[i].Repayment_dt) && (FixedTable[j].StartDt <= isRepaymentDt[i].Repayment_dt)){
                        if(isRepaymentDt[i].Repayment_dt.getFullYear() == FixedTable[j].Date) {
                            FixedTable[j].isRepayment = 1;
                        }
                    }
                    // }
                }
            }
        }
        return ({FixedTable: FixedTable, FloatTable: FloatTable});
    }//Αν δεν έχει κυμαινόμενο επιστρέφουμε τις δόσεις για το σταθερό.
    const FloatInst = PMT((FloatRatePercentage + ContributionPercentage) / 12, FloatMonths, -RemainingAmount, 0, 0); //Δόση Κυμαινόμενου

    //Δημιουργία δόσεων κυμαινόμενου
    for (let i = FixedMonths + 1; i <= FixedMonths + FloatMonths; i++) {
        const NewRow = [];
        NewRow.isRepayment =0;
        if(Repayments.length>0 && Repayments[0].Diff==i){
            for(let j=1; j<=Repayments.length-1; j++) Repayments[j].Diff = Repayments[j].Diff-Repayments[0].Diff +1;
            RequestedAmount = RemainingAmount - Repayments[0].Amount; //Νέο ποσό
            FloatMonths = FloatMonths - i + 1 + FixedMonths; //Εναπομείναντες μήνες
            FixedMonths=0;

            FloatRatePercentage = Number(Repayments[0].NewRate) / 100;

            Repayment = Repayment + Repayments[0].Amount;
            Repayments.splice(0, 1);

            return calculateInstallments (countAA, Number(FixedMonths/12), Number(FloatMonths/12), Number(FixedRatePercentage*100), Number(FloatRatePercentage*100), Number(ContributionPercentage*100), RequestedAmount, isMonthly, hidePrevious, start_date, Repayments, FixedTable, FloatTable,Repayment, SumInterest, YearInterest, YearRepayment, isRepaymentDt, YearInstallment)
        }
        countAA++;
        NewRow.AA = countAA;

        NewRow.Installment = FloatInst;//Μηνιαία Δόση
        YearInstallment += NewRow.Installment;
        NewRow.Interest = RemainingAmount * (FloatRatePercentage + ContributionPercentage) / 12;//Τόκος

        NewRow.Creolysio = NewRow.Installment - NewRow.Interest;//Χρεολύσιο

        RemainingAmount -= NewRow.Creolysio;
        NewRow.RemainingAmount = RemainingAmount; //Υπόλοιπο Κεφάλαιο

        SumInterest += NewRow.Interest;
        YearInterest += NewRow.Interest;
        NewRow.SumInterest = SumInterest; //Σύνολο Τόκων

        Repayment += NewRow.Creolysio;
        YearRepayment += NewRow.Creolysio;
        NewRow.Repayment = Repayment; //Σύνολο αποπληρωμής κεφάλαιου
        NewRow.Date = ((((start_date.getMonth()+(countAA-1))%12)+1).toString() +'/'+ (Number(start_date.getFullYear())+parseInt(((start_date.getMonth()+(countAA-1)))/12)));
        if (isMonthly==1) {
            if ((diffMonthly < countAA-1 && hidePrevious) || !hidePrevious){
                FloatTable.push(NewRow); //Δημιουργία γραμμής αν ειναι μηνιαίος υπολογισμός
            }
            continue;
        }
        if (((((start_date.getMonth()+(countAA-1))%12)+1) % 12) === 0 || i === (FixedMonths+FloatMonths)) { //Δημιουργία γραμμής αν είναι ετήσιος υπολογισμός και είναι ο 12ος μήνας
            //κρατάμε την τελευταία ημερομηνία για την συμπλήρωση ετήσιας δόσης
            NewRow.LastDt = new Date();
            NewRow.LastDt.setMonth((((start_date.getMonth()+(countAA-1))%12)+1)-1);
            NewRow.LastDt.setFullYear((Number(start_date.getFullYear())+parseInt(((start_date.getMonth()+(countAA-1)))/12)));
            //κρατάμε την πρώτη ημερομηνία της ετήσιας δόσης
            NewRow.StartDt = new Date();
            NewRow.StartDt.setMonth(NewRow.LastDt.getMonth()-1);
            NewRow.StartDt.setFullYear(NewRow.LastDt.getFullYear()-1);
            //Δημιουργία string για μπροστά
            NewRow.Date = NewRow.LastDt.getFullYear().toString();
            // NewRow.Date = (Number(start_date.getFullYear())+parseInt(((start_date.getMonth()+(NewRow.AA-1)))/12));
            if(start_date.getMonth() == 0){
                NewRow.AA = parseInt(countAA / 12);
            }else {
                NewRow.AA = parseInt(countAA / 12) +1;
            }
            NewRow.Installment = YearInstallment; //Ετήσια Δόση
            NewRow.Interest = YearInterest; //Τόκος
            YearInterest = 0; //Μηδενίζουμε το σύνολο τόκων ανά έτος
            NewRow.Creolysio = YearRepayment; //Χρεολύσιο
            YearRepayment = 0; //Μηδενίζουμε το χρεολύσιο ανά έτος
            NewRow.SumInterest = SumInterest; //Σύνολο Τόκων
            NewRow.Repayment = Repayment; //Σύνολο αποπληρωμής κεφάλαιου
            YearInstallment = 0; //Μηδενίζουμε τη δόση ανά έτος
            if ((diffAnnual < countAA && hidePrevious) || !hidePrevious) {
                FloatTable.push(NewRow)
            }
        }
    }

    for(let j=0; j<Object.keys(isRepaymentDt).length; j++){
        //μετατροπή σε date για συγκρίση
        isRepaymentDt[j].Repayment_dt = new Date(isRepaymentDt[j].Repayment_dt);
        if(isMonthly==1){
            //Δημιουργία string για μπροστά
            isRepaymentDt[j] = (isRepaymentDt[j].Repayment_dt.getMonth()+1).toString() +'/'+isRepaymentDt[j].Repayment_dt.getFullYear();
        }
    }
    for(let i=0; i<isRepaymentDt.length; i++){
        for(let j=0; j<Object.keys(FixedTable).length; j++){
            if(isMonthly==1) {
                //Αν τα string ειναι ίδια με μπροστά χρωμάτισε
                if(FixedTable[j].Date == isRepaymentDt[i])
                    FixedTable[j].isRepayment = 1;
            }else{
                //Αν η αποπληρωμή βρίσκετε στην σωστή χρονολογική δόση χρωμάτισε
                // if(start_date.getMonth()==0){
                // //     FixedTable[j].Date = FixedTable[j].Date.split('-')[1];
                //     if(FixedTable[j].Date == isRepaymentDt[i].Repayment_dt.getFullYear())
                //         FixedTable[j].isRepayment = 1;
                // }else{
                if((FixedTable[j].LastDt > isRepaymentDt[i].Repayment_dt) && (FixedTable[j].StartDt <= isRepaymentDt[i].Repayment_dt)){
                    if(isRepaymentDt[i].Repayment_dt.getFullYear() == FixedTable[j].Date) {
                        FixedTable[j].isRepayment = 1;
                    }
                }
                // }
            }
        }
        for(let j=0; j<Object.keys(FloatTable).length; j++){
            if(isMonthly==1) {
                if(FloatTable[j].Date == isRepaymentDt[i])
                    FloatTable[j].isRepayment = 1;
            }else{
                // if(start_date.getMonth()==0){
                //     FloatTable[j].Date = FloatTable[j].Date.split('-')[1];
                //     if(FloatTable[j].Date == isRepaymentDt[i].Repayment_dt.getFullYear())
                //         FloatTable[j].isRepayment = 1;
                // }else{
                if((FloatTable[j].LastDt > isRepaymentDt[i].Repayment_dt) && (FloatTable[j].StartDt <= isRepaymentDt[i].Repayment_dt)){
                    if(isRepaymentDt[i].Repayment_dt.getFullYear() == FloatTable[j].Date) {
                        FloatTable[j].isRepayment = 1;
                    }
                }
                //}
            }
        }
    }
    return ({FixedTable: FixedTable, FloatTable: FloatTable});
}

export const calculateLtv = (value, otherFieldValue) => {
    const parsedValue = parseInt(value);
    const ltv = otherFieldValue !== 0 ? (100 * otherFieldValue) / parsedValue : 0;
    return ltv ? ltv.toFixed(2) : 0
}