import {useState} from "react";
import axios from "axios";
import {API} from "../../../helpers/constants";
import useUserData from "../../../hooks/user/useUserData";
import {handleAxiosErrors, logoutUnauthorizedUser} from "../../../helpers/errorHandling";
import {ALERTS} from "../../../locales/components/namespaces";
import {useTranslation} from "react-i18next";
import {enqueueSnackbar} from "notistack";
import useUserAction from "../../../hooks/user/useUserAction";

const useDocumentsTypes = ({data, refreshGrid}) => {
	const {token, isLoggedIn} = useUserData()
	const {userLogout} = useUserAction()
	const {t} = useTranslation(ALERTS)
	const [loading, setLoading] = useState(false)
	
	const store = (newRow) => {
		setLoading(true)
		axios({
			method: 'post',
			headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
			url: `${API}/document/types/store`,
			data: newRow
		}).then(response => {
			refreshGrid()
			enqueueSnackbar(t('Document type added successfully!'), {
					variant: 'success'
				}
			)
			setLoading(false)
		}).catch(error => {
			enqueueSnackbar(handleAxiosErrors(error, t('Failed to store new document type!')), {
					variant: 'error'
				}
			)
			setLoading(false)
			logoutUnauthorizedUser(error, isLoggedIn, userLogout)
		})
	}
	
	const update = (newRow, oldRow) => {
		setLoading(true)
		axios({
			method: 'post',
			headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
			url: `${API}/document/types/update`,
			data: newRow
		}).then(response => {
			refreshGrid()
			enqueueSnackbar(t('Document type updated successfully!'), {
					variant: 'success'
				}
			)
			setLoading(false)
		}).catch(error => {
			enqueueSnackbar(handleAxiosErrors(error, t('Failed to update document type!')), {
					variant: 'error'
				}
			)
			setLoading(false)
			logoutUnauthorizedUser(error, isLoggedIn, userLogout)
		})
	}
	
	const remove = (id) => {
		setLoading(true)
		axios({
			method: 'post',
			headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
			url: `${API}/document/types/remove`,
			data: {
				id: id
			}
		}).then(response => {
			refreshGrid()
			enqueueSnackbar(t('Document type deleted successfully!'), {
					variant: 'success'
				}
			)
			setLoading(false)
		}).catch(error => {
			// debugger
			// console.log('error',error)
			enqueueSnackbar(handleAxiosErrors(error, error.response.data.message === 'Data Reference' ? t('The document type is used!') : t('Failed to delete document type!')), {
					variant: 'error'
				}
			)
			setLoading(false)
			logoutUnauthorizedUser(error, isLoggedIn, userLogout)
		})
	}
	
	return {
		loading,
		store,
		update,
		remove
	}
}
export default useDocumentsTypes