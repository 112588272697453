// @flow
import React from 'react';
import * as yup from 'yup'
import {ErrorMessage, FormikProvider, useFormik} from "formik";
import {useTranslation} from "react-i18next";
import {
	FormControl,
	FormControlLabel,
	FormHelperText,
	FormLabel,
	Switch,
	Box,
	TextField,
	Button,
	Typography
} from "@mui/material";
import axios from "axios";
import {API} from "../../../../helpers/constants";
import {handleAxiosErrors, logoutUnauthorizedUser} from "../../../../helpers/errorHandling";
import {USERS} from "../../locales/namespaces";
import {enqueueSnackbar} from "notistack";
import {useParams} from "react-router-dom";
import useUserData from "../../../../hooks/user/useUserData";
import useUserAction from "../../../../hooks/user/useUserAction";

const ResetUserPassForm = ({handleClose}) => {
	const {t} = useTranslation(USERS)
	const {isLoggedIn} = useUserData()
	const {userLogout} = useUserAction()
	const {id} = useParams()
	
	const validationSchema = yup.object({
		password: yup
		.string()
		.required(t('Required!')).min(6, t('Enter more than 6 characters.'))
	})
	
	const generateRandomCode = () => {
		const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqstuvwxyz0123456789#@!$&*.';
		let password = '';
		for (let i = 0; i < 6; i++) {
			const randomIndex = Math.floor(Math.random() * characters.length);
			password += characters[randomIndex];
		}
		return password;
	};
	
	const onSubmit = (values) => {
		axios({
			method: 'post',
			data: {
				iSendEmail: values.iSendEmail,
				password: values.password,
				UserId: id
			},
			headers: {'Content-Type': 'application/json'},
			url: `${API}/user/resetUserPassword`
		}).then(() => {
			enqueueSnackbar(t('Password changed successfully!'), {
					variant: 'success',
					onClose: () => {
						handleClose()
					}
				}
			)
		}).catch((error) => {
			enqueueSnackbar(handleAxiosErrors(error, t('Failed to change password.')), {
					variant: 'error'
				}
			)
			logoutUnauthorizedUser(error, isLoggedIn, userLogout)
		})
	}
	
	const formik = useFormik({
		initialValues: {
			password: generateRandomCode(),
			iSendEmail: false
		},
		validationSchema: validationSchema,
		onSubmit: onSubmit,
	})
	
	return (
		<FormikProvider value={formik}>
			<Box component="form" sx={{mb: 2}}>
				<Typography sx={{mb: 2}} id="modal-modal-description" variant="body2">
					{t("Fill in the new password.")}
				</Typography>
				<FormControl
					variant="standard"
					fullWidth
					error={
						formik.touched["password"] &&
						Boolean(formik.errors["password"])
					}
					sx={{mb: 2, mt: 2}}
				>
					<FormLabel
						htmlFor="password" required>{t('New Password')}</FormLabel>
					<TextField
						name={`password`}
						required
						error={formik.touched.password && Boolean(formik.errors.password)}
						autoComplete="password"
						value={formik.values.password}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						color={"grey"}
						size={'small'}
						fullWidth
						inputProps={{maxLength: 100}}
					/>
					<FormHelperText>
						<ErrorMessage name={"password"}/>
					</FormHelperText>
				</FormControl>
				<FormControl
					variant="standard"
					fullWidth
					error={
						formik.touched["iSendEmail"] &&
						Boolean(formik.errors["iSendEmail"])
					}
					sx={{pl: 1, pb: 2}}
				>
					<FormControlLabel
						control={
							<Switch
								checked={formik.values.iSendEmail}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								size={'small'}
								name={'iSendEmail'}
							/>
						}
						label={t('Send new password via e-mail')}
						labelPlacement="end"
						/*sx={{'.MuiFormControlLabel-label': {typography: 'body2'}}}*/
					/>
				</FormControl>
				<div style={{justifyContent: 'center'}}>
					<Button
						size={"small"}
						variant={"contained"}
						color={'info'}
						onClick={formik.submitForm}
					>
						{t('Reset')}
					</Button>
				</div>
			</Box>
		</FormikProvider>
	)
}

export default ResetUserPassForm