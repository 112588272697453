//@flow
import React from "react";
import {Grid, Typography, Stack, List, ListItem, useMediaQuery} from "@mui/material";
import {useTranslation} from "react-i18next";
import {LOANS} from "../../locales/namespaces";
import {textTranslate} from "../../../../helpers/helpers";
import useUserData from "../../../../hooks/user/useUserData";
import {NumericFormat} from "react-number-format";
import PermissionHOC from "../../../permission/components/permissionHOC/permissionHOC";
import {useTheme} from "@mui/material/styles";

const LoansDetails = ({loanDetails}) => {
	const {t} = useTranslation(LOANS)
	const theme = useTheme();
	const {language, thousandSeparator, decimalSeparator} = useUserData()
	const matchBetweenXSMD = useMediaQuery(theme.breakpoints.between('xs', 'md'));
	
	return (
		<List sx={{py: 0}}>
			<ListItem>
				<Grid container spacing={3}>
					<PermissionHOC requiredPermissions={['view_loan_cust_name']}>
						<Grid item xs={12} md={6}>
							<Stack spacing={0.5}>
								<Typography
									color="secondary"
									variant={'h6'}
								>
									{t('Name')}
								</Typography>
								<Typography variant={'bodyMedium'}>{loanDetails.CustName}</Typography>
							</Stack>
						</Grid>
					</PermissionHOC>
					<PermissionHOC requiredPermissions={['view_loan_email']}>
						<Grid item xs={12} md={6}>
							<Stack spacing={0.5}>
								<Typography
									color="secondary"
									variant={'h6'}
								>
									{t('Email')}
								</Typography>
								<Typography variant={'bodyMedium'}>{loanDetails.email}</Typography>
							</Stack>
						</Grid>
					</PermissionHOC>
				</Grid>
			</ListItem>
			<ListItem>
				<Grid container spacing={3}>
					<PermissionHOC requiredPermissions={['view_loan_phone']}>
						<Grid item xs={12} md={6}>
							<Stack spacing={0.5}>
								<Typography
									color="secondary"
									variant={'h6'}
								>
									{t('Phone')}
								</Typography>
								<Typography variant={'bodyMedium'}>{loanDetails.MobilePhone}</Typography>
							</Stack>
						</Grid>
					</PermissionHOC>
				</Grid>
			</ListItem>
			<ListItem>
				<Grid container spacing={3}>
					<Grid item xs={12} md={6}>
						<Stack spacing={0.5}>
							<Typography
								color="secondary"
								variant={'h6'}
							>
								{t('Real Estate Value')}
							</Typography>
							<Typography>
								<NumericFormat
									style={{
										fontSize: '16px',
										fontWeight: "bold"
									}}
									thousandSeparator={thousandSeparator}
									decimalSeparator={decimalSeparator} displayType="text"
									value={loanDetails.RealEstateValue ? loanDetails.RealEstateValue : '0'}
								/>
							</Typography>
						</Stack>
					</Grid>
					<Grid item xs={12} md={6}>
						<Stack spacing={0.5}>
							<Typography
								color="secondary"
								variant={'h6'}
							>
								{t('Requested Amount')}
							</Typography>
							<Typography>
								<NumericFormat
									style={{
										fontSize: '16px',
										fontWeight: "bold"
									}}
									thousandSeparator={thousandSeparator}
									decimalSeparator={decimalSeparator} displayType="text"
									value={loanDetails.RequestedAmount ? loanDetails.RequestedAmount : '0'}
								/>
							</Typography>
						</Stack>
					</Grid>
				</Grid>
			</ListItem>
			<ListItem>
				{matchBetweenXSMD ?
					<Grid container spacing={3}>
						<Grid item xs={12} md={6}>
							<Stack spacing={0.5}>
								<Typography
									color="secondary"
									variant={'h6'}
								>
									{t('Approved amount')}
								</Typography>
								<Typography>
									<NumericFormat
										style={{
											fontSize: '16px',
											fontWeight: "bold"
										}}
										thousandSeparator={thousandSeparator}
										decimalSeparator={decimalSeparator} displayType="text"
										value={loanDetails.ApprovedAmount ? loanDetails.ApprovedAmount : '-'}
									/>
								</Typography>
							</Stack>
						</Grid>
						<Grid item xs={12} md={6}>
							<Stack spacing={0.5}>
								<Typography
									color="secondary"
									variant={'h6'}
								>
									{t('Bank')}
								</Typography>
								<Typography variant={'bodyMedium'}>
									{loanDetails.Bank ? loanDetails.Bank : '-'}
								</Typography>
							</Stack>
						</Grid>
					</Grid>
					:
					<Grid container spacing={3}>
						<Grid item xs={12} md={6}>
							<Stack spacing={0.5}>
								<Typography
									color="secondary"
									variant={'h6'}
								>
									{t('Bank')}
								</Typography>
								<Typography variant={'bodyMedium'}>
									{loanDetails.Bank ? loanDetails.Bank : '-'}
								</Typography>
							</Stack>
						</Grid>
						<Grid item xs={12} md={6}>
							<Stack spacing={0.5}>
								<Typography
									color="secondary"
									variant={'h6'}
								>
									{t('Approved amount')}
								</Typography>
								<Typography>
									<NumericFormat
										style={{
											fontSize: '16px',
											fontWeight: "bold"
										}}
										thousandSeparator={thousandSeparator}
										decimalSeparator={decimalSeparator} displayType="text"
										value={loanDetails.ApprovedAmount ? loanDetails.ApprovedAmount : '-'}
									/>
								</Typography>
							</Stack>
						</Grid>
					</Grid>
				}
			</ListItem>
			<ListItem>
				<Grid container spacing={3}>
					<Grid item xs={12} md={6}>
						<Stack spacing={0.5}>
							<Typography
								color="secondary"
								variant={'h6'}
							>
								{t('Loan Purpose')}
							</Typography>
							<Typography variant={'bodyMedium'}>
								{textTranslate(language)({
									en: loanDetails.TypeEn,
									el: loanDetails.TypeEl
								})}
							</Typography>
						</Stack>
					</Grid>
					<Grid item xs={12} md={6}>
						<Stack spacing={0.5}>
							<Typography
								color="secondary"
								variant={'h6'}
							>
								{t('Loan status')}
							</Typography>
							<Typography variant={'bodyMedium'}>
								{textTranslate(language)({
									en: loanDetails.StatusEn,
									el: loanDetails.StatusEl
								})}
							</Typography>
						</Stack>
					</Grid>
				</Grid>
			</ListItem>
			<ListItem>
				<Grid container spacing={3}>
					<PermissionHOC requiredPermissions={['view_loan_origin']}>
						<Grid item xs={12} md={6}>
							<Stack spacing={0.5}>
								<Typography
									color="secondary"
									variant={'h6'}
								>
									{t('Loan Origin')}
								</Typography>
								<Typography variant={'bodyMedium'}>
									{textTranslate(language)({
										en: loanDetails.OriginEn,
										el: loanDetails.OriginEl
									})}
								</Typography>
							</Stack>
						</Grid>
					</PermissionHOC>
					{loanDetails.PartnerName && <Grid item xs={12} md={6}>
						<Stack spacing={0.5}>
							<Typography
								color="secondary"
								variant={'h6'}
							>
								{t('Recommended by')}
							</Typography>
							<Typography
								variant={'bodyMedium'}>{loanDetails.PartnerName}</Typography>
						</Stack>
					</Grid>}
				</Grid>
			</ListItem>
			<ListItem>
				<PermissionHOC requiredPermissions={['view_loan_comments']}>
					<Grid container spacing={3}>
						<Grid item xs={12} md={12}>
							<Stack spacing={0.5}>
								<Typography
									color="secondary"
									variant={'h6'}
								>
									{t('Comments')}
								</Typography>
								<Typography variant={'bodyMedium'}>{loanDetails.Comments}</Typography>
							</Stack>
						</Grid>
					</Grid>
				</PermissionHOC>
			</ListItem>
		
		</List>
	)
}

export default LoansDetails