import * as Namespaces from './namespaces'

const locales = {
	[Namespaces.WIZARD]: {
		el: {
			'Hello Think Plus!': 'Γεια σου Think Plus',
			hello: 'Γειά σου: Think Plus',
		},
		en: {
			hello: 'Hello: Think Plus',
		}
	}
}

export default locales