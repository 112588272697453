import * as React from "react";
const ActivityIcon = (props) => (
    <svg
        width={32}
        height={31}
        viewBox="0 0 32 31"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g
            stroke="#485776"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path d="m8.366 19.9 4.49-5.836 5.121 4.023 4.394-5.67" />
            <circle cx={27.492} cy={4.026} r={2.883} />
            <path d="M19.886 2.406H8.984c-4.517 0-7.318 3.199-7.318 7.716v12.123c0 4.518 2.746 7.703 7.318 7.703H21.89c4.517 0 7.318-3.185 7.318-7.703V11.687" />
        </g>
    </svg>
);
export default ActivityIcon;