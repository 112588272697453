import {useEffect, useState} from "react";
import axios from "axios";
import {API} from "../../../helpers/constants";
import {useTranslation} from "react-i18next";
import {ALERTS} from "../../../locales/components/namespaces";
import useUserData from "../../../hooks/user/useUserData";
import {enqueueSnackbar} from "notistack";
import {handleAxiosErrors, logoutUnauthorizedUser} from "../../../helpers/errorHandling";
import useUserAction from "../../../hooks/user/useUserAction";

const useLoanType = () => {
	const {t} = useTranslation(ALERTS)
	const {token, isLoggedIn} = useUserData()
	const {userLogout} = useUserAction()
	const [loading, setLoading] = useState(false)
	const [loanType, setLoanType] = useState([])
	const [updateLoanType, setUpdateLoanType] = useState(false)
	
	useEffect(() => {
		setLoading(true)
		axios({
			method: 'get',
			headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
			url: `${API}/loan/type/list`,
		}).then((response) => {
			setLoanType(response.data)
			setLoading(false)
		}).catch(error => {
			setLoading(false)
			enqueueSnackbar(handleAxiosErrors(error, t('Failed to get loan types list.')), {
					variant: 'error'
				}
			)
			logoutUnauthorizedUser(error, isLoggedIn, userLogout)
		})
	}, [updateLoanType])
	
	const update = (newRow, oldRow) => {
		axios({
			method: 'post',
			headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
			url: `${API}/loan/type/update`,
			data: newRow
		}).then(response => {
			enqueueSnackbar(t('Loan type updated successfully!'), {
					variant: 'success'
				}
			)
			setUpdateLoanType(!updateLoanType)
		}).catch(error => {
			setUpdateLoanType(!updateLoanType)
			enqueueSnackbar(handleAxiosErrors(error, t('Failed to update loan type!')), {
					variant: 'error'
				}
			)
			logoutUnauthorizedUser(error, isLoggedIn, userLogout)
		})
	}
	
	return {
		loading,
		loanType,
		update
	}
}

export default useLoanType
