import * as React from "react";
const DownloadIcon = (props) => {
    return(

        <svg
            width={24}
            height={24}
            style={{opacity: props.disabled === true ? 0.6 : 1}}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4m4-5 5 5 5-5m-5 5V3"
                stroke="#0BC1B6"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
export default DownloadIcon;