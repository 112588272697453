// @flow
import * as React from 'react';
import {ErrorMessage, FormikProvider} from "formik";
import {useTranslation} from "react-i18next";
import {
    Button,
    FormControl,
    FormHelperText,
    TextField,
    Dialog,
    DialogContent,
    Stack,
    DialogActions,
    Box, Typography, Grid, FormLabel, DialogTitle
} from "@mui/material";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {CALCULATIONS} from "../../locales/namespaces";
import useRepaymentsForm from "../../hooks/useRepaymentsForm"
import MainCard from "../../../../theme/mantis/components/mainCard";
import {NumericFormat} from "react-number-format";
import useUserData from "../../../../hooks/user/useUserData";
import CalendarIcon from "../../../../assets/images/icons/kpisIcons/calendarIcon";
import {useTheme} from "@mui/material/styles";

const RepaymentsForm = ({
                            id,
                            open,
                            onClose,
                            handleResults,
                            calculationData,
                            contributions,
                            repayments,
                            updateRepayments,
                            handleUpdateRepayments,
                            handleRemoveRepayment,
                            RepaymentId,
                            repaymentsEdit
                        }) => {
    const {t} = useTranslation(CALCULATIONS)
    const {decimalSeparator, thousandSeparator} = useUserData()
    const theme = useTheme();
    const {formik} = useRepaymentsForm({
        id,
        handleResults,
        onClose,
        calculationData,
        contributions,
        repayments,
        updateRepayments,
        handleUpdateRepayments,
        handleRemoveRepayment,
        RepaymentId,
        repaymentsEdit
    })

    const onCloseRepayments = () => {
        onClose()
        formik.resetForm()
    }

    return (
        <Dialog
            open={open}
            onClose={onCloseRepayments}
        >
            <FormikProvider value={formik}>
                <Box sx={{p: 1}}>
                    <MainCard sx={{maxWidth: 500}}>
                        <DialogTitle>
                            <Typography variant="h4">
                                {!RepaymentId ? t('Add repayment') : t('Edit repayment')}
                            </Typography>
                        </DialogTitle>
                        <DialogContent>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Stack spacing={1}>
                                        <FormControl
                                            variant="standard"
                                            fullWidth
                                            error={
                                                formik.touched["Amount"] &&
                                                Boolean(formik.errors["Amount"])
                                            }
                                        >
                                            <FormLabel htmlFor="Amount" required>{t('Repayment amount (€)')}</FormLabel>
                                            <NumericFormat
                                                value={formik.values.Amount}
                                                onValueChange={(values, sourceInfo) => {
                                                    formik.setFieldValue('Amount', values.floatValue ? values.floatValue : '')
                                                }}
                                                thousandSeparator={thousandSeparator}
                                                decimalSeparator={decimalSeparator}
                                                decimalScale={2}
                                                customInput={TextField}

                                                required
                                                onBlur={formik.handleBlur}
                                                name={`Amount`}
                                                color={"grey"}
                                                size={'small'}
                                                fullWidth
                                                inputProps={{maxLength: 100, step: 1000}}
                                                error={formik.touched.Amount && Boolean(formik.errors.Amount)}
                                            />
                                            <FormHelperText>
                                                <ErrorMessage name={"Amount"}/>
                                            </FormHelperText>
                                        </FormControl>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12}>
                                    <Stack spacing={1}>
                                        <FormControl
                                            variant="standard"
                                            fullWidth
                                            error={
                                                formik.touched["Repayment_dt"] &&
                                                Boolean(formik.errors["Repayment_dt"])
                                            }
                                        >
                                            <FormLabel
                                                htmlFor="Repayment_dt" required>{t('Date of repayment')}</FormLabel>
                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                <DatePicker
                                                    views={['year', 'month']}
                                                    openTo="year"
                                                    inputFormat="MMMM YYYY"
                                                    value={formik.values.Repayment_dt}
                                                    onChange={(newValue) => {
                                                        formik.setFieldValue('Repayment_dt', newValue);
                                                    }}
                                                    textField={(params) =>
                                                        <TextField {...params} />
                                                    }
                                                    slots={{
                                                        openPickerIcon: CalendarIcon
                                                    }}
                                                    sx={{
                                                        '& .MuiButtonBase-root': {
                                                            color: theme.palette.primary[100],
                                                            background: theme.palette.primary.main,
                                                        },
                                                        '& .MuiSvgIcon-root': {
                                                            order: -1,
                                                            marginRight: theme => theme.spacing(1),
                                                        },
                                                        '& .MuiInputBase-root': {
                                                            flexDirection: 'row-reverse',
                                                        },
                                                    }}
                                                />
                                            </LocalizationProvider>
                                            <FormHelperText>
                                                <ErrorMessage name={"Repayment_dt"}/>
                                            </FormHelperText>
                                        </FormControl>
                                    </Stack>
                                </Grid>
                                {/*<Grid item xs={12}>
                                    <Stack spacing={1}>
                                        <FormControl
                                            variant="standard"
                                            fullWidth
                                            error={
                                                formik.touched["NewRate"] &&
                                                Boolean(formik.errors["NewRate"])
                                            }
                                        >
                                            <FormLabel
                                                htmlFor="NewRate">{t('New interest rate (%)')}</FormLabel>
                                            <NumericFormat
                                                value={formik.values.NewRate}
                                                onValueChange={(values, sourceInfo) => {
                                                    formik.setFieldValue('NewRate', values.floatValue ? values.floatValue : '')
                                                }}
                                                thousandSeparator={thousandSeparator}
                                                decimalSeparator={decimalSeparator}
                                                decimalScale={2}
                                                customInput={TextField}

                                                onBlur={formik.handleBlur}
                                                name={`NewRate`}
                                                color={"grey"}
                                                size={'small'}
                                                fullWidth
                                                inputProps={{maxLength: 100}}
                                                error={formik.touched.NewRate && Boolean(formik.errors.NewRate)}
                                            />
                                            <FormHelperText>
                                                <ErrorMessage name={"NewRate"}/>
                                            </FormHelperText>
                                        </FormControl>
                                    </Stack>
                                </Grid>*/}
                                <Grid item xs={12}>
                                    <Stack spacing={1}>
                                        <DialogActions sx={{justifyContent: 'center'}}>
                                            <Button
                                                size={"small"}
                                                variant={"contained"}
                                                color={'info'}
                                                onClick={formik.submitForm}
                                            >
                                                {t('Save')}
                                            </Button>
                                        </DialogActions>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </DialogContent>
                    </MainCard>
                </Box>
            </FormikProvider>
        </Dialog>
    )
}

export default RepaymentsForm;