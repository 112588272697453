import * as React from "react";
const ArrowDownCircle = (props) => (
    <svg
        width={24}
        height={24}
        viewBox="0 0 21 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g
            stroke="#fff"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path
                clipRule="evenodd"
                d="M1.25 10a9.25 9.25 0 1 0 18.5 0 9.25 9.25 0 0 0-18.5 0Z"
            />
            <path d="m7.029 8.558 3.471 3.486 3.471-3.486" />
        </g>
    </svg>
);
export default ArrowDownCircle;