import {EDITABLE_MUI_GRID as LOCALE} from '../locales/components/namespaces'

export const muiGridLocales = (t) => ({
	noResultsOverlayLabel: t(LOCALE + ':noResultsOverlayLabel'),
	noRowsLabel: t(LOCALE + ':noRowsLabel'),
	toolbarDensity: t(LOCALE + ':toolbarDensity'),
	toolbarDensityLabel: t(LOCALE + ':toolbarDensityLabel'),
	toolbarDensityCompact: t(LOCALE + ':toolbarDensityCompact'),
	toolbarDensityStandard: t(LOCALE + ':toolbarDensityStandard'),
	toolbarDensityComfortable: t(LOCALE + ':toolbarDensityComfortable'),
	toolbarColumns: t(LOCALE + ':toolbarColumns'),
	toolbarColumnsLabel: t(LOCALE + ':toolbarColumnsLabel'),
	toolbarFilters: t(LOCALE + ':toolbarFilters'),
	toolbarFiltersLabel: t(LOCALE + ':toolbarFiltersLabel'),
	toolbarFiltersTooltipHide: t(LOCALE + ':toolbarFiltersTooltipHide'),
	toolbarFiltersTooltipShow: t(LOCALE + ':toolbarFiltersTooltipShow'),
	toolbarFiltersTooltipActive: t(LOCALE + ':toolbarFiltersTooltipActive'),
	toolbarQuickFilterPlaceholder: t(LOCALE + ':toolbarQuickFilterPlaceholder'),
	toolbarQuickFilterLabel: t(LOCALE + ':toolbarQuickFilterLabel'),
	toolbarQuickFilterDeleteIconLabel: t(LOCALE + ':toolbarQuickFilterDeleteIconLabel'),
	toolbarExport: t(LOCALE + ':toolbarExport'),
	toolbarExportLabel: t(LOCALE + ':toolbarExportLabel'),
	toolbarExportCSV: t(LOCALE + ':toolbarExportCSV'),
	toolbarExportPrint: t(LOCALE + ':toolbarExportPrint'),
	toolbarExportExcel: t(LOCALE + ':toolbarExportExcel'),
	columnsPanelTextFieldLabel: t(LOCALE + ':columnsPanelTextFieldLabel'),
	columnsPanelTextFieldPlaceholder: t(LOCALE + ':columnsPanelTextFieldPlaceholder'),
	columnsPanelDragIconLabel: t(LOCALE + ':columnsPanelDragIconLabel'),
	columnsPanelShowAllButton: t(LOCALE + ':columnsPanelShowAllButton'),
	columnsPanelHideAllButton: t(LOCALE + ':columnsPanelHideAllButton'),
	filterPanelAddFilter: t(LOCALE + ':filterPanelAddFilter'),
	filterPanelRemoveAll: t(LOCALE + ':filterPanelRemoveAll'),
	filterPanelDeleteIconLabel: t(LOCALE + ':filterPanelDeleteIconLabel'),
	filterPanelLogicOperator: t(LOCALE + ':filterPanelLogicOperator'),
	filterPanelOperator: t(LOCALE + ':filterPanelOperator'),
	filterPanelOperatorAnd: t(LOCALE + ':filterPanelOperatorAnd'),
	filterPanelOperatorOr: t(LOCALE + ':filterPanelOperatorOr'),
	filterPanelColumns: t(LOCALE + ':filterPanelColumns'),
	filterPanelInputLabel: t(LOCALE + ':filterPanelInputLabel'),
	filterPanelInputPlaceholder: t(LOCALE + ':filterPanelInputPlaceholder'),
	filterOperatorContains: t(LOCALE + ':filterOperatorContains'),
	filterOperatorEquals: t(LOCALE + ':filterOperatorEquals'),
	filterOperatorStartsWith: t(LOCALE + ':filterOperatorStartsWith'),
	filterOperatorEndsWith: t(LOCALE + ':filterOperatorEndsWith'),
	filterOperatorIs: t(LOCALE + ':filterOperatorIs'),
	filterOperatorNot: t(LOCALE + ':filterOperatorNot'),
	filterOperatorAfter: t(LOCALE + ':filterOperatorAfter'),
	filterOperatorOnOrAfter: t(LOCALE + ':filterOperatorOnOrAfter'),
	filterOperatorBefore: t(LOCALE + ':filterOperatorBefore'),
	filterOperatorOnOrBefore: t(LOCALE + ':filterOperatorOnOrBefore'),
	filterOperatorIsEmpty: t(LOCALE + ':filterOperatorIsEmpty'),
	filterOperatorIsNotEmpty: t(LOCALE + ':filterOperatorIsNotEmpty'),
	filterOperatorIsAnyOf: t(LOCALE + ':filterOperatorIsAnyOf'),
	filterValueAny: t(LOCALE + ':filterValueAny'),
	filterValueTrue: t(LOCALE + ':filterValueTrue'),
	filterValueFalse: t(LOCALE + ':filterValueFalse'),
	columnMenuLabel: t(LOCALE + ':columnMenuLabel'),
	columnMenuShowColumns: t(LOCALE + ':columnMenuShowColumns'),
	columnMenuManageColumns: t(LOCALE + ':columnMenuManageColumns'),
	columnMenuFilter: t(LOCALE + ':columnMenuFilter'),
	columnMenuHideColumn: t(LOCALE + ':columnMenuHideColumn'),
	columnMenuUnsort: t(LOCALE + ':columnMenuUnsort'),
	columnMenuSortAsc: t(LOCALE + ':columnMenuSortAsc'),
	columnMenuSortDesc: t(LOCALE + ':columnMenuSortDesc'),
	columnHeaderFiltersTooltipActive: t(LOCALE + ':columnHeaderFiltersTooltipActive'),
	columnHeaderFiltersLabel: t(LOCALE + ':columnHeaderFiltersLabel'),
	columnHeaderSortIconLabel: t(LOCALE + ':columnHeaderSortIconLabel'),
	footerRowSelected: t(LOCALE + ':footerRowSelected'),
	footerTotalRows: t(LOCALE + ':footerTotalRows'),
	footerTotalVisibleRows: t(LOCALE + ':footerTotalVisibleRows'),
	checkboxSelectionHeaderName: t(LOCALE + ':checkboxSelectionHeaderName'),
	checkboxSelectionSelectAllRows: t(LOCALE + ':checkboxSelectionSelectAllRows'),
	checkboxSelectionUnselectAllRows: t(LOCALE + ':checkboxSelectionUnselectAllRows'),
	checkboxSelectionSelectRow: t(LOCALE + ':checkboxSelectionSelectRow'),
	checkboxSelectionUnselectRow: t(LOCALE + ':checkboxSelectionUnselectRow'),
	booleanCellTrueLabel: t(LOCALE + ':booleanCellTrueLabel'),
	booleanCellFalseLabel: t(LOCALE + ':booleanCellFalseLabel'),
	actionsCellMore: t(LOCALE + ':actionsCellMore'),
	pinToLeft: t(LOCALE + ':pinToLeft'),
	pinToRight: t(LOCALE + ':pinToRight'),
	unpin: t(LOCALE + ':unpin'),
	treeDataGroupingHeaderName: t(LOCALE + ':treeDataGroupingHeaderName'),
	treeDataExpand: t(LOCALE + ':treeDataExpand'),
	treeDataCollapse: t(LOCALE + ':treeDataCollapse'),
	groupingColumnHeaderName: t(LOCALE + ':groupingColumnHeaderName'),
	groupColumn: t(LOCALE + ':groupColumn'),
	unGroupColumn: t(LOCALE + ':unGroupColumn'),
	detailPanelToggle: t(LOCALE + ':detailPanelToggle'),
	expandDetailPanel: t(LOCALE + ':expandDetailPanel'),
	collapseDetailPanel: t(LOCALE + ':collapseDetailPanel'),
	rowReorderingHeaderName: t(LOCALE + ':rowReorderingHeaderName'),
	aggregationMenuItemHeader: t(LOCALE + ':aggregationMenuItemHeader'),
	aggregationFunctionLabelSum: t(LOCALE + ':aggregationFunctionLabelSum'),
	aggregationFunctionLabelAvg: t(LOCALE + ':aggregationFunctionLabelAvg'),
	aggregationFunctionLabelMin: t(LOCALE + ':aggregationFunctionLabelMin'),
	aggregationFunctionLabelMax: t(LOCALE + ':aggregationFunctionLabelMax'),
	aggregationFunctionLabelSize: t(LOCALE + ':aggregationFunctionLabelSize'),
	headerFilterOperatorContains: t(LOCALE + ':headerFilterOperatorContains'),
	headerFilterOperatorEquals: t(LOCALE + ':headerFilterOperatorEquals'),
	headerFilterOperatorStartsWith: t(LOCALE + ':headerFilterOperatorStartsWith'),
	headerFilterOperatorEndsWith: t(LOCALE + ':headerFilterOperatorEndsWith'),
	headerFilterOperatorIs: t(LOCALE + ':headerFilterOperatorIs'),
	headerFilterOperatorNot: t(LOCALE + ':headerFilterOperatorNot'),
	headerFilterOperatorAfter: t(LOCALE + ':headerFilterOperatorAfter'),
	headerFilterOperatorOnOrAfter: t(LOCALE + ':headerFilterOperatorOnOrAfter'),
	headerFilterOperatorBefore: t(LOCALE + ':headerFilterOperatorBefore'),
	headerFilterOperatorOnOrBefore: t(LOCALE + ':headerFilterOperatorOnOrBefore'),
	headerFilterOperatorIsEmpty: t(LOCALE + ':headerFilterOperatorIsEmpty'),
	headerFilterOperatorIsNotEmpty: t(LOCALE + ':headerFilterOperatorIsNotEmpty'),
	headerFilterOperatorIsAnyOf: t(LOCALE + ':headerFilterOperatorIsAnyOf'),
	/* 'headerFilterOperator=': 'Equals',
	 'headerFilterOperator!=': 'Not equals',
	 'headerFilterOperator>': 'Greater than',
	 'headerFilterOperator>=': 'Greater than or equal to',
	 'headerFilterOperator<': 'Less than',
	 'headerFilterOperator<=': 'Less than or equal to',*/
	// Columns management text
	columnsManagementSearchTitle: t(LOCALE + ':columnsManagementSearchTitle'),
	columnsManagementNoColumns: t(LOCALE + ':columnsManagementNoColumns'),
	columnsManagementShowHideAllText: t(LOCALE + ':columnsManagementShowHideAllText'),
	// Used core components translation keys
	MuiTablePagination: {},
	
	
})