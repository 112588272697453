import * as React from 'react';
import {Fragment, useMemo, useState} from 'react';
import {useTranslation} from "react-i18next";
import {LOANS} from "../../locales/namespaces";
import {
	Card,
	CardContent,
	Divider,
	MenuItem,
	Select,
	TextField,
	Typography,
	List,
	ListItem,
	ListItemText,
	Stack,
	Button,
	Grid,
	Box,
	Popover,
	useTheme,
	InputAdornment
} from "@mui/material";
import useDocuments from "../../../parameters/hooks/useDocuments";
import {enqueueSnackbar} from "notistack";
import {textTranslate, toCamelCase} from "../../../../helpers/helpers";
import useUserData from "../../../../hooks/user/useUserData";
import moment from "moment";
import MainCard from "../../../../theme/mantis/components/mainCard";
import InfoCircle from "../../../../assets/images/icons/infoCircle";
import PaperUpload from "../../../../assets/images/icons/buttonsIcons/paperUpload";
import Search from "../../../../assets/images/icons/search";
import ArrowDown2 from "../../../../assets/images/icons/arrowDown2";
import Polygon3Icon from "../../../../assets/images/icons/polygon3Icon";
import {Capacitor} from "@capacitor/core";

const DocumentsList = ({loansDocuments, handleUpdateDocuments, updateDocuments, status}) => {
	const {t} = useTranslation(LOANS)
	const theme = useTheme();
	const {language} = useUserData()
	const {store, uploading} = useDocuments({updateDocuments, handleUpdateDocuments});
	const [anchorEl, setAnchorEl] = useState(null);
	const [popoverContent, setPopoverContent] = useState('');
	const [filterValue, setFilterValue] = React.useState('');
	const [selectedStatusId, setSelectedStatusId] = useState('all');
	
	//Άνοιγμα button για προβολή πλήρους τίτλου περιγραφή εγγράφου
	const handlePopoverOpen = (event, content) => {
		setAnchorEl(event.currentTarget);
		setPopoverContent(content);
	};
	
	//Κλείσιμο button προβολής πλήρους τίτλου περιγραφή εγγράφου
	const handlePopoverClose = () => {
		setAnchorEl(null);
		setPopoverContent('');
	};
	
	//Map για τα statuses δανείου
	const statusOptions = status.map((option) => ({
		id: option.id,
		label: textTranslate(language)({
			en: option.DescriptionEn,
			el: option.DescriptionEl,
		}),
	}));
	
	//Φιλτράρισμα λίστας πληκτρολογώντας όνομα εγγράφου
	const handleFilterChange = (event) => {
		setFilterValue(event.target.value);
	};
	
	//Φιλτράρισμα λίστας βάση του status που έχει επιλεχθεί
	const handleStatusChange = (event) => {
		setSelectedStatusId(event.target.value);
	};
	
	//Filter για αναζήτηση από τη λίστα βάση ονόματος περιγραφής και κατάστασης που έχει επιλεχθεί
	const filteredDocuments = useMemo(() =>
			loansDocuments.filter((document) =>
				document.sortDescription.toLowerCase().includes(filterValue.toLowerCase()) &&
				(selectedStatusId === 'all' || document.StatusId === selectedStatusId)
			),
		[loansDocuments, filterValue, selectedStatusId]
	);
	
	//Button για ανέβασμα εγγράφου με μήνυμα λάθους εάν είναι πάνω από 50ΜΒ ή δεν είναι στη μορφή pdf
	const UploadButton = ({LoanRelId, LoanId}) => {
		const handleFileUpload = async (event) => {
			const file = event.target.files[0];
			const allowedFileTypes = [
				".doc",
				".docx",
				"application/msword",
				"application/vnd.openxmlformats-officedocument.wordprocessingml.document",
				"application/pdf",
				"image/jpeg",
				"image/jpg",
				"image/png",
				"application/x-zip-compressed",
				"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
				"application/zip",
				"application/x-7z-compressed",
				"application/vnd.ms-word.document.macroEnabled.12",
				"text/plain"
			];
			
			if (file && file.size > 50 * 1024 * 1024) {
				enqueueSnackbar(t('File size exceeds 50MB.'), {
						variant: 'error'
					}
				)
				return;
			}
			if (!allowedFileTypes.includes(file.type)) {
				enqueueSnackbar(t('Invalid file type.'), {
					variant: 'error'
				});
				return;
			}
			
			const newRow = {
				file: file,
				LoanRelId: LoanRelId,
				LoanId: LoanId
			};
			
			store(newRow);
		};
		
		return (
			//Δημιουργία button ανεβάσματος εγγράφου
			<Stack alignItems="center" spacing={2}>
				<Button
					sx={{
						border: 'none',
						'&:hover': {
							border: 'none',
						},
					}}
					variant="outlined"
					color={'info'}
					component="label"
					size="small"
					disabled={uploading}
				>
					{uploading ? t("Uploading...") : <PaperUpload/>}
					<input
						hidden
						//accept="application/pdf, image/jpeg, image/jpg, image/png, application/zip, application/x-7z-compressed"
						accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.png,.jpg,.jpeg,application/pdf,image/jpeg"
						type="file"
						onChange={handleFileUpload}
					/>
				</Button>
			</Stack>
		);
	};
	
	//Map ανάλογα με την περιγραφή του ονόματος του εγγράφου εάν είναι πάνω από 20 χαρακτήρες ώστε να εμφανιστεί η σύντομη περιγραφή μαζί με button για πληροφορίες για τον πλήρη τίτλο
	const Description = useMemo(
		() => filteredDocuments.map(document => {
			const isDescriptionTooLong = document.Description.length > 3;
			//const descriptionContent = isDescriptionTooLong ? document.Description : '';
			const descriptionText = isDescriptionTooLong
				? textTranslate(language)({
					en: document.sortDescriptionEn,
					el: document.sortDescription
				})
				: textTranslate(language)({
					en: document.sortDescriptionEn,
					el: document.sortDescription
				});
			
			return (
				//Δημιουργία λίστας εγγράφων με 5 στήλες περιγραφή, όνομα εγγράφου εάν έχει ανέβει, κατάσταση, ημερομηνία που ανέβηκε και το button για το upload
				<MainCard key={document.DocTypeId} content={false}>
					<List sx={{p: 0}}>
						<ListItem>
							<Grid container spacing={1}>
								<Grid item xs={12} sm={12} md={5} lg={5}>
									<ListItemText
										primary={
											<div style={{
												display: 'flex',
												flexDirection: 'row',
												alignItems: 'flex-start',
												m: '0px auto',
												alignSelf: 'right'
											}}>
												{isDescriptionTooLong && (
													<Button
														sx={{maxHeight: '20px'}}
														
														onClick={(event) => handlePopoverOpen(event, textTranslate(language)({
															en: document.DescriptionEn,
															el: document.Description
														}))}
													>
														<InfoCircle/>
													</Button>
												)}
												<Typography variant="bodyMedium">
													{descriptionText}
												</Typography>
											</div>
										}
									/>
								</Grid>
								<Grid item xs={12} sm={12} md={3} lg={3}>
									<Typography variant="h5" color={theme.palette.primary[60]}>
										{document.OriginalFileName}
									</Typography>
									<Typography variant="h5" color={theme.palette.primary[60]}>
										{!document.DateAdded ? null : moment(document.DateAdded).format("DD-MM-YYYY")}
									</Typography>
								</Grid>
								<Grid item xs={12} sm={12} md={2} lg={3}>
									<ListItemText
										color={theme.palette.primary[100]}
										sx={{
											fontSize: '14px',
											fontStyle: 'normal',
											fontWeight: 400,
											lineHeight: '22px'
										}}
										primary={!document.StatusEl ? '-' : textTranslate(language)({
											en: document.StatusEn/* && toCamelCase(document.StatusEn)*/,
											el: document.StatusEl/* && toCamelCase(document.StatusEl)*/
										})}/>
								</Grid>
								<Grid item xs={12} sm={1} md={2} lg={1}>
									<UploadButton
										LoanRelId={document.LoanRelId}
										LoanId={document.LoanId}
									/>
								</Grid>
							</Grid>
						</ListItem>
					</List>
				</MainCard>
			)
		}), [filteredDocuments, updateDocuments, handleUpdateDocuments, uploading, loansDocuments])

//Για να είναι προεπιλεγμένη η τωρινή κατάσταση του δανείου
	// useEffect(() => {
	//     setSelectedStatusId(loanStatus);
	// }, [loanStatus]);
	
	return (
		<Fragment>
			{/* Φίλτρα λίστας*/}
			<Grid container spacing={1} sx={{
				/*maxWidth: {
					lg: '900px'
				}*/
			}}>
				<Grid item xs={12} sm={12} md={6} lg={6}>
					<TextField
						fullWidth
						sx={{mr: 1}}
						id="filter-input"
						placeholder={t('Search...')}
						InputProps={{
							startAdornment: <InputAdornment position="start"><Search/></InputAdornment>,
						}}
						variant="outlined"
						value={filterValue}
						onChange={handleFilterChange}
					/>
				</Grid>
				<Grid item xs={12} sm={12} md={6} lg={6}>
					{Capacitor.getPlatform() === 'android' ?
						<Select
							native
							value={selectedStatusId}
							onChange={handleStatusChange}
							sx={{minWidth: 150}}
							fullWidth
							IconComponent={ArrowDown2}
						>
							<option value="all">{t('Select all')}</option>
							{statusOptions.map((option) => (
								<option key={option.id} value={option.id}>
									{option.label}
								</option>
							))}
						</Select> :
						<Select
							value={selectedStatusId}
							onChange={handleStatusChange}
							sx={{minWidth: 150}}
							fullWidth
							IconComponent={ArrowDown2}
						>
							<MenuItem value="all">{t('Select all')}</MenuItem>
							{statusOptions.map((option) => (
								<MenuItem key={option.id} value={option.id}>
									{option.label}
								</MenuItem>
							))}
						</Select>}
				</Grid>
			</Grid>
			{/*Card σε περίπτωση που το υπάρχον status δε χρειάζεται έγγραφα να γίνουν upload*/}
			<Box sx={{minHeight: 400, maxHeight: 600, overflow: 'auto'}}>
				<Divider sx={{mb: 1}} light/>
				{Description.length <= 0 || filteredDocuments.length === 0 &&
					<Card sx={{mb: 1, mt: 1}}>
						<CardContent>
							<Typography sx={{
								mt: 1,
								mb: 1,
								ml: 1,
								mr: 1
							}}>{t('There are currently no documents to upload.')}
							</Typography>
						</CardContent>
					</Card>
				}
				<List>
					<ListItem sx={{display: 'block'}} disablePadding>
						<ListItemText
							primary={Description}
						>
						</ListItemText>
					</ListItem>
				</List>
			</Box>
			{/*Popover για περισσότερες λεπτομέρειες τίτλου περιγραφής εγγράφου ήταν πολύ μεγάλος*/}
			<Popover
				open={Boolean(anchorEl)}
				anchorEl={anchorEl}
				onClose={handlePopoverClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
				sx={{
					'.MuiPaper-root': {
						background: 'transparent',
						overflowY: 'hidden',
					}
				}}
			>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'flex-start',
						gap: '-4px',
						flexShrink: 0,
					}}
				>
					<Polygon3Icon/>
					<Box
						sx={{
							background: theme.palette.primary[100],
							display: 'flex',
							padding: '8px 17px 21px 17px',
							flexDirection: 'column',
							alignItems: 'flex-end',
							gap: '20px',
							maxWidth: '1000px',
							borderRadius: '0px 12px 12px 12px',
							m: -0.3,
							minWidth: '100px'
						}}
					>
						<Button
							onClick={handlePopoverClose}
							color={'primary'}
							sx={{
								height: '13px',
								minWidth: '9px',
								maxWidth: '9px',
								color: '#FFF',
								textAlign: 'right',
								fontSize: '14px',
								fontStyle: 'normal',
								fontWeight: 700,
								lineHeight: '22px',
								// m:1,
								'&:hover': {
									color: '#fff'
								}
							}}
						>x</Button>
						<Typography
							variant={'h5'}
							sx={{
								color: '#fff'
							}}
						>{popoverContent}</Typography>
					</Box>
				</div>
			</Popover>
		</Fragment>
	)
}

export default DocumentsList