import * as React from "react";
const InfoCircle = (props) => (
    <svg
        width={20}
        height={20}
        viewBox="0 0 21 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M.25 10.133c0-5.519 4.48-10 10-10 5.53 0 10 4.481 10 10 0 5.521-4.47 10-10 10-5.52 0-10-4.479-10-10Zm9.12-3.79c0-.479.4-.88.88-.88s.87.401.87.88v4.42a.87.87 0 0 1-.87.87.879.879 0 0 1-.88-.87v-4.42Zm.89 8.471c-.49 0-.88-.4-.88-.88s.39-.87.87-.87c.49 0 .88.39.88.87s-.39.88-.87.88Z"
            fill="#485776"
        />
    </svg>
);
export default InfoCircle;