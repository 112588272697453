import { useEffect, useState } from "react";
import axios from "axios";
import { API } from "../../../helpers/constants";
import { useTranslation } from "react-i18next";
import { ALERTS } from "../../../locales/components/namespaces";
import useUserData from "../../../hooks/user/useUserData";
import { enqueueSnackbar } from "notistack";
import { handleAxiosErrors, logoutUnauthorizedUser } from "../../../helpers/errorHandling";
import useUserAction from "../../../hooks/user/useUserAction";

const useLoanStatus = () => {
	const { t } = useTranslation(ALERTS)
	const { token, isLoggedIn } = useUserData()
	const { userLogout } = useUserAction()
	const [loading, setLoading] = useState(false)
	const [loanStatus, setLoanStatus] = useState([])
	const [updateLoanStatus, setUpdateLoanStatus] = useState(false)
	
	useEffect(() => {
		setLoading(true)
		axios({
			method: 'get',
			headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
			url: `${API}/loan/status/list`,
		}).then((response) => {
			setLoanStatus(response.data.map(status => ({
				...status,
				DescriptionEn: status.DescriptionEn /*&& toCamelCase(status.DescriptionEn)*/,
				DescriptionEl: status.DescriptionEl /*&& toCamelCase(status.DescriptionEl)*/,
				PartnerDescription: status.PartnerDescription /*&& toCamelCase(status.PartnerDescription)*/,
				
			})))
			setLoading(false)
		}).catch(error => {
			setLoading(false)
			enqueueSnackbar(handleAxiosErrors(error, t('Failed to get loan statuses list.')), {
					variant: 'error'
				}
			)
			logoutUnauthorizedUser(error, isLoggedIn, userLogout)
		})
	}, [updateLoanStatus])
	
	const update = (newRow, oldRow) => {
		axios({
			method: 'post',
			headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
			url: `${API}/loan/status/update`,
			data: newRow
		}).then(() => {
			enqueueSnackbar(t('Loan status updated successfully!'), {
					variant: 'success'
				}
			)
			setUpdateLoanStatus(!updateLoanStatus)
		}).catch(error => {
			setUpdateLoanStatus(!updateLoanStatus)
			enqueueSnackbar(handleAxiosErrors(error, t('Failed to update loan status!')), {
					variant: 'error'
				}
			)
			logoutUnauthorizedUser(error, isLoggedIn, userLogout)
		})
	}
	
	return {
		loading,
		loanStatus,
		update
	}
}

export default useLoanStatus
