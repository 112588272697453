import {useEffect, useRef, useState} from 'react';

// material-ui
import {useTheme} from '@mui/material/styles';
import {
	Box,
	ClickAwayListener,
	Grid,
	List,
	ListItemButton,
	ListItemText,
	Paper,
	Popper,
	Typography,
	useMediaQuery
} from '@mui/material';

// project import

// assets
import useUserAction from "../../../../../../hooks/user/useUserAction";
import useUserData from "../../../../../../hooks/user/useUserData";
import {useTranslation} from "react-i18next";
import {Languages} from "../../../../../../helpers/constants";
import Transitions from "../../../../components/@extended/transitions";
import ArrowDown2 from "../../../../../../assets/images/icons/arrowDown2";
import ListItemIcon from "@mui/material/ListItemIcon";
import * as React from "react";


// import Transitions from 'components/@extended/Transitions';

// ==============================|| HEADER CONTENT - LOCALIZATION ||============================== //

const Localization = () => {
	const theme = useTheme();
	const matchesXs = useMediaQuery(theme.breakpoints.down('md'));
	const {setUserLanguage} = useUserAction()
	const {language} = useUserData()
	const {i18n} = useTranslation()
	useEffect(() => {
		i18n.changeLanguage(language)
	}, [i18n, language])
	
	const anchorRef = useRef(null);
	const [open, setOpen] = useState(false);
	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen);
	};
	
	const handleClose = (event) => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}
		setOpen(false);
	};
	
	const handleListItemClick = (lang) => {
		setUserLanguage(lang);
		setOpen(false);
	};
	
	const iconBackColorOpen = theme.palette.primary.main;
	const iconBackColor = '#F4F5FA';
	
	return (
		<Box sx={{flexShrink: 0, ml: 0.75}}>
			<ListItemButton
				sx={{
					color: 'text.primary',
					borderRadius: '8px',
					border: '1px solid #5C6781',
					width: '87px',
					height: '52px',
					bgcolor: open ? iconBackColorOpen : iconBackColor,
				}}
				aria-label="open localization"
				ref={anchorRef}
				aria-controls={open ? 'localization-grow' : undefined}
				aria-haspopup="true"
				onClick={handleToggle}>
				<ListItemText
					sx={{mr: 1}}
					primary={language === Languages.EL ? 'ΕΛ' : 'EN'}
				/>
				<ListItemIcon>
					<ArrowDown2/>
				</ListItemIcon>
			</ListItemButton>
			{/*<IconButton
                color="secondary"
                variant="light"
                sx={{color: 'text.primary', bgcolor: open ? iconBackColorOpen : iconBackColor}}
                aria-label="open localization"
                ref={anchorRef}
                aria-controls={open ? 'localization-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
            >
                <TranslationOutlined/>
                 <ArrowDown2/>
                {language === Languages.EL ? 'ΕΛ' : 'EN'}
                <ListItemIcon>
                    <ArrowDown2 />
                </ListItemIcon>
                {language === Languages.EL ? 'ΕΛ' : 'EN'}

            </IconButton>*/}
			<Popper
				sx={{zIndex: 1}}
				placement={matchesXs ? 'bottom-start' : 'bottom'}
				open={open}
				anchorEl={anchorRef.current}
				role={undefined}
				transition
				disablePortal
				popperOptions={{
					modifiers: [
						{
							name: 'offset',
							options: {
								offset: [matchesXs ? 0 : 0, 9]
							}
						}
					]
				}}
			>
				{({TransitionProps}) => (
					<Transitions type="grow" position={matchesXs ? 'top-right' : 'top'} in={open} {...TransitionProps}>
						<Paper sx={{boxShadow: theme.customShadows.z1}}>
							<ClickAwayListener onClickAway={handleClose}>
								<List
									component="nav"
									sx={{
										p: 0,
										width: '100%',
										minWidth: 200,
										maxWidth: 290,
										bgcolor: theme.palette.background.paper,
										borderRadius: 0.5,
										[theme.breakpoints.down('md')]: {
											maxWidth: 250
										}
									}}
								>
									<ListItemButton
										selected={language === Languages.EN}
										onClick={() => handleListItemClick(Languages.EN)}
									>
										<ListItemText
											primary={
												<Grid container>
													<Typography color="textPrimary">English</Typography>
													<Typography variant="caption" color="textSecondary"
																sx={{ml: '8px'}}>
														(EN)
													</Typography>
												</Grid>
											}
										/>
									</ListItemButton>
									<ListItemButton
										selected={language === Languages.EL}
										onClick={() => handleListItemClick(Languages.EL)}
									>
										<ListItemText
											primary={
												<Grid container>
													<Typography color="textPrimary">Ελληνικά</Typography>
													<Typography variant="caption" color="textSecondary"
																sx={{ml: '8px'}}>
														(EL)
													</Typography>
												</Grid>
											}
										/>
									</ListItemButton>
								</List>
							</ClickAwayListener>
						</Paper>
					</Transitions>
				)}
			</Popper>
		</Box>
	);
};

export default Localization;
