import * as React from "react";
const EmailIcon = (props) => (
    <svg
        width={20}
        height={18}
        viewBox="0 0 22 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round">
            <path
                d="m16.903 7.069-4.443 3.613c-.84.666-2.02.666-2.86 0L5.12 7.069"
                stroke="#6E7079"
            />
            <path
                clipRule="evenodd"
                d="M15.909 19.217c3.041.009 5.091-2.49 5.091-5.561V6.787c0-3.071-2.05-5.57-5.091-5.57H6.09C3.05 1.217 1 3.716 1 6.787v6.869c0 3.07 2.05 5.57 5.091 5.561h9.818Z"
                stroke="#485776"
            />
        </g>
    </svg>
);
export default EmailIcon;