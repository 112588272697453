import {FormLabel, IconButton, Typography} from "@mui/material";
import InfoCircle from "../../../../assets/images/icons/infoCircle";
import React from "react";

const IconButtonInfoCircle = (props) => {
	const {title, onClick, ariaDescribedby, id, htmlFor, isLtv, ref} = props
	return (
		<div
			ref={ref}
			style={{
				display: 'flex',
				alignItems: 'center',
				gap: isLtv ? '1px' : '8px'
			}}
		>
			{isLtv ?
				<Typography variant="h5" color="secondary">{title}</Typography>
				: <FormLabel htmlFor={htmlFor}>{title}</FormLabel>
			}
		</div>
	)
}

export default IconButtonInfoCircle;