import useUserData from "../../../hooks/user/useUserData";
import {useTranslation} from "react-i18next";
import {ALERTS} from "../../../locales/components/namespaces";
import {useEffect, useState} from "react";
import {API} from "../../../helpers/constants";
import {enqueueSnackbar} from "notistack";
import {handleAxiosErrors, logoutUnauthorizedUser} from "../../../helpers/errorHandling";
import axios from "axios";
import useUserAction from "../../../hooks/user/useUserAction";

const useRepayments = ({id}) => {
	const {token, isLoggedIn} = useUserData()
	const {userLogout} = useUserAction()
	const {t} = useTranslation(ALERTS)
	const [loading, setLoading] = useState(false)
	const [repayments, setRepayments] = useState([])
	const [updateRepayments, setUpdateRepayments] = useState(false)
	
	const handleUpdateRepayments = (updateRepayments) => setUpdateRepayments(updateRepayments)
	
	useEffect(() => {
		setLoading(true)
		axios({
			method: 'post',
			data: {CalculationId: id},
			headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
			url: `${API}/repayment/details/data`,
		}).then(response => {
			const repaymentsData = response.data
			setRepayments(repaymentsData)
			setLoading(false)
		}).catch(error => {
			setLoading(false)
			enqueueSnackbar(handleAxiosErrors(error, t('Failed to get details.')), {
					variant: 'error'
				}
			)
			logoutUnauthorizedUser(error, isLoggedIn, userLogout)
		})
	}, [updateRepayments])
	const handleRemoveRepayment = (id, callback = null) => {
		axios({
			method: 'post',
			headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
			url: `${API}/repayment/remove`,
			data: {
				RepaymentId: id
			}
		}).then(() => {
			enqueueSnackbar(t('Repayment deleted successfully!'), {
					variant: 'success'
				}
			)
			setUpdateRepayments(!updateRepayments)
			if (callback) callback(id)
		}).catch(error => {
			setUpdateRepayments(!updateRepayments)
			enqueueSnackbar(handleAxiosErrors(error, t('Failed to delete repayment!')), {
					variant: 'error'
				}
			)
			logoutUnauthorizedUser(error, isLoggedIn, userLogout)
		})
	}
	
	return {
		loading,
		repayments,
		updateRepayments,
		handleUpdateRepayments,
		handleRemoveRepayment,
	}
}
export default useRepayments

//TODO::Use Logic if Edit
// useEffect(() => {
//     setLoading(false)
//     if (id) {
//         axios({
//             method: 'post',
//             data: {RepaymentId: id},
//             headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
//             url: `${API}/repayment/edit/data`,
//         }).then(response => {
//             let data = response.data
//             if (data.repayments && data.repayments.length > 0) {
//                 const repayments = data.repayments[0]
//                 setRepayments({
//                     LoanAmount: Number(repayments.RequestedAmount),
//                     FixedInterestRate: Number(repayments.FixedRate),
//                     FloatingRate: Number(repayments.FloatRate),
//                     ContributionId: repayments.ContributionId,
//                     DateInstallment: moment(repayments.StartDt),
//                 })
//             }
//             setLoading(false)
//         }).catch(error => {
//             setLoading(false)
//             enqueueSnackbar(handleAxiosErrors(error, t('Failed to get repayment details.')), {
//                     variant: 'error'
//                 }
//             )
//              logoutUnauthorizedUser(error, isLoggedIn, userLogout)
//         })
//     }
// }, [id])