// @flow
import React from 'react';
import * as yup from 'yup'
import {ErrorMessage, FormikProvider, useFormik} from "formik";
import {useTranslation} from "react-i18next";
import {PAGE_LOGIN} from "../../locales/pages/namespaces";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormHelperText, Grid,
    FormLabel, Stack, TextField, Button, Typography
} from "@mui/material";
import axios from "axios";
import {API} from "../../helpers/constants";
import {useHistory} from "react-router-dom";
import {getRouteUrl} from "../../helpers/getRouteUrl";
import {ROUTE_PAGE_RESET_PASSWORD} from "../../routers/routes";
import {handleAxiosErrors, logoutUnauthorizedUser} from "../../helpers/errorHandling";
import {enqueueSnackbar} from "notistack";
import useUserData from "../../hooks/user/useUserData";
import useUserAction from "../../hooks/user/useUserAction";
import ArrowDownCircle from "../../assets/images/icons/buttonsIcons/arrowDownCircle";
import AnimateButton from "../../theme/mantis/components/@extended/animateButton";

const ResetPassModal = ({open, onClose}) => {

    const {t} = useTranslation(PAGE_LOGIN)
    const history = useHistory()
    const {isLoggedIn, loading} = useUserData()
    const {userLogout} = useUserAction()

    const validationSchema = yup.object({
        email: yup
            .string()
            .required(t('required')).email(t('Enter a valid email'))
    })

    const onSubmit = (values) => {
        axios({
            method: 'get',
            headers: {'Content-Type': 'application/json'},
            url: `${API}/request/new-password?email=${values.email}`
        }).then(response => {
            history.push(getRouteUrl(ROUTE_PAGE_RESET_PASSWORD, {}, {
                recoveryPwdTime: response.data.recoveryPwdTime,
                resetTime: response.data.resetTime,
                resetType: response.data.resetType,
                email: values.email
            }))
        }).catch(error => {
            enqueueSnackbar(handleAxiosErrors(error, t('The email you entered was not found!')), {
                    variant: 'error'
                }
            )
            logoutUnauthorizedUser(error, isLoggedIn, userLogout)
        })
    }

    const formik = useFormik({
        initialValues: {
            email: ''
        },
        validationSchema: validationSchema,
        onSubmit: onSubmit,
    })

    return (
        <Dialog
            open={open}
            onClose={onClose}
        >
            <FormikProvider value={formik}>
                <Grid container spacing={1} sx={{maxWidth: 600, p: 2}}>
                    <Grid item xs={12}>
                        <Stack direction="row" justifyContent="space-between" alignItems="baseline">
                            <DialogTitle>
                                <Typography variant="h4">{t("Reset Password")}</Typography>
                            </DialogTitle>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sx={{ml: 3}}>
                        <Typography id="modal-modal-description" variant="body">
                            {t("Fill in your email and you will receive a message to reset your password.")}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Stack spacing={1}>
                                    <DialogContent>
                                        <FormControl
                                            variant="standard"
                                            fullWidth
                                            error={
                                                formik.touched["email"] &&
                                                Boolean(formik.errors["email"])
                                            }
                                        >
                                            <FormLabel htmlFor="email" required>{t('Email')}</FormLabel>
                                            <TextField
                                                name={`email`}
                                                required
                                                error={formik.touched.email && Boolean(formik.errors.email)}
                                                autoComplete="email"
                                                value={formik.values.email}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                color={"grey"}
                                                size={'small'}
                                                fullWidth
                                                inputProps={{maxLength: 100}}
                                            />
                                            <FormHelperText>
                                                <ErrorMessage name={"email"}/>
                                            </FormHelperText>
                                        </FormControl>
                                    </DialogContent>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} sx={{mb: -1, ml: 2}}>
                                <Typography
                                    variant="h6"
                                    color={'secondary'}
                                >
                                    {t('Do not forgot to check SPAM box.')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <DialogActions sx={{justifyContent: 'center'}}>
                                    <AnimateButton type={'slide'}>
                                        <Button
                                            sx={{gap: '20px', p: '17px 54px'}}
                                            disableElevation
                                            disabled={loading}
                                            //fullWidth
                                            size="large"
                                            variant="contained"
                                            color="info"
                                            onClick={formik.submitForm}
                                        >
                                            <ArrowDownCircle/>{t('Send')}
                                        </Button>
                                    </AnimateButton>
                                </DialogActions>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </FormikProvider>
        </Dialog>

    )
}

export default ResetPassModal