import {useMemo} from 'react';

// material-ui
import {useTheme} from '@mui/material/styles';
import {Box, Drawer, useMediaQuery} from '@mui/material';

// project import
import DrawerHeader from './drawerHeader';
import DrawerContent from './drawerContent';
import MiniDrawerStyled from './miniDrawerStyled';
import {DRAWER_WIDTH} from "../../../config";
import useMenuData from "../../../../../hooks/menu/useMenuData";
import useMenuAction from "../../../../../hooks/menu/useMenuAction";
import bgSideBarImage from "../../../../../assets/images/ims-bg-SideBar.jpg";

// ==============================|| MAIN LAYOUT - DRAWER ||============================== //

const MainDrawer = ({window}) => {
    const theme = useTheme();
    const matchDownMD = useMediaQuery(theme.breakpoints.down('sm'));
    // responsive drawer container
    const container = window !== undefined ? () => window().document.body : undefined;
    const {drawerOpen} = useMenuData()
    const {setDrawerOpen} = useMenuAction()
    
    const drawerContent = useMemo(() => <DrawerContent/>, []);
    const drawerHeader = useMemo(() => <DrawerHeader drawerOpen={drawerOpen}/>, [drawerOpen]);

    return (
        <Box component="nav" sx={{flexShrink: {md: 0}, zIndex: 1200}} aria-label="mailbox folders">
            {!matchDownMD ? (
                <MiniDrawerStyled variant="permanent" open={drawerOpen}
                                  onClick={(event) => setDrawerOpen(!drawerOpen)}
                >
                    {drawerHeader}
                    {drawerContent}
                </MiniDrawerStyled>
            ) : (
                <Drawer
                    container={container}
                    variant="temporary"
                    open={drawerOpen}
                    onClose={() => setDrawerOpen(!drawerOpen)}
                    ModalProps={{keepMounted: true}}
                    sx={{
                        display: {xs: 'block', lg: 'none'},
                        '& .MuiDrawer-paper': {
                            boxSizing: 'border-box',
                            width: DRAWER_WIDTH,
                            borderRight: `1px solid ${theme.palette.divider}`,
                            boxShadow: 'inherit',
                            backgroundImage: `url(${bgSideBarImage})`,
                            position: 'absolute',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center center',
                            '&:before': {
                                position: 'absolute',
                                width: '100%',
                                height: '100%',
                                content: '""',
                                display: 'block',
                            }
                        }
                    }}
                    onClick={() => setDrawerOpen(!drawerOpen)}
                >
                    {drawerHeader}
                    {drawerContent}
                </Drawer>
            )}
        </Box>
    );
};

export default MainDrawer;
