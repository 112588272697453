//@flow
import {Dialog, DialogActions, DialogContent, DialogTitle, Button} from "@mui/material";
import {useTranslation} from "react-i18next";
import {EDITABLE_MUI_GRID} from "../../../locales/components/namespaces";

type Props = {
	deleteDialogOpen: Object<{
		open: boolean,
		id: string
	}>,
	handleDeleteDialogOpen: Function,
	handleDeleteConfirmation: Function
}

const RowConfirmationDialog = (props: Props) => {
	const {
		deleteDialogOpen,
		handleDeleteConfirmation,
		handleDeleteDialogOpen
	} = props
	const {t} = useTranslation(EDITABLE_MUI_GRID)
	
	return (
		<Dialog
			maxWidth={"md"}
			open={deleteDialogOpen.open}
			keepMounted
			onClose={() => {
				handleDeleteDialogOpen({
					open: false,
					id: null
				})
			}}
		>
			<DialogTitle>{t('Delete Entry Confirmation.')}</DialogTitle>
			<DialogContent dividers>
				{t(`Press Yes to confirm entry deletion.`)}
			</DialogContent>
			<DialogActions>
				<Button
					onClick={() => {
						const rowId = deleteDialogOpen.id
						handleDeleteDialogOpen({
							open: false,
							id: null
						})
						handleDeleteConfirmation(rowId)
					}}
				>
					{t('yes')}
				</Button>
				<Button
					onClick={() => {
						handleDeleteDialogOpen({
							open: false,
							id: null
						})
					}}
				>
					{t('no')}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default RowConfirmationDialog