import * as React from "react";
const BankIcon = (props) => (
    <svg
        width={26}
        height={26}
        viewBox="0 0 26 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M15.752 1.866 25 8.494 1 8.506l9.208-6.64c1.62-1.155 3.923-1.155 5.543 0Z"
            stroke="#fff"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M7.002 13.79v6m6-6v6m6-6v6M1.758 25h22.487"
            stroke="#fff"
            strokeWidth={1.5}
            strokeLinecap="round"
        />
    </svg>
);
export default BankIcon;