import {useState} from "react";

const usePopover = () => {
	const [anchorEl, setAnchorEl] = useState(null)
	
	const handleAnchorElClick = (id) => {
		//console.log(id)
		setAnchorEl(document.getElementById(id));
	};
	
	const handleAnchorElClose = () => {
		setAnchorEl(null);
	};
	
	const openAnchorEl = Boolean(anchorEl);
	const idAnchorEl = openAnchorEl ? 'shared-popover' : undefined;
	
	return {
		handleAnchorElClick,
		handleAnchorElClose,
		openAnchorEl,
		idAnchorEl,
		anchorEl,
		setAnchorEl
	}
}

export default usePopover;
