import {useFormik} from "formik";
import * as yup from "yup";
import axios from "axios";
import {API} from "../../../helpers/constants";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import {CUSTOMERS} from "../locales/namespaces";
import useUserData from "../../../hooks/user/useUserData";
import {getRouteUrl} from "../../../helpers/getRouteUrl";
import {ROUTE_PAGE_CUSTOMERS_DETAILS} from "../routes/routes";
import {useHistory} from "react-router-dom";
import {handleAxiosErrors, logoutUnauthorizedUser} from "../../../helpers/errorHandling";
import {enqueueSnackbar} from "notistack";
import useUserAction from "../../../hooks/user/useUserAction";
import {validateAFM} from "../../../helpers/validateAFM";

const useCustomerForm = ({
							 id,
							 customer,
							 countries,
							 currencies,
							 languages,
							 //dateFormats,
							 nationalities,
							 hasVatAccess,
							 hasPasswordAccess
						 }) => {
	const {token, isLoggedIn} = useUserData()
	const {
		userLogout,
		getUserPermissions
	} = useUserAction()
	const history = useHistory()
	const {t} = useTranslation(CUSTOMERS)
	const [showPassword, setShowPassword] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [errorMess, setError] = useState(null);
	
	const validationSchema = yup.object({
		username: hasPasswordAccess === true ? yup.string().required(t('Required')) : null,
		password: hasPasswordAccess === true ? (!id ? yup.string()
		.required(t('Required'))
		.matches(/^.{60}$|(^((?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d)|(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[^a-zA-Z\d])|(?=.*?[A-Z])(?=.*?\d)(?=.*?[^a-zA-Z\d])|(?=.*?[a-z])(?=.*?\d)(?=.*?[^a-zA-Z\d])).{7,16}$)/,
			t('Password must be between 7-16 latin characters and contain at least 3 of the following. -Upper case character -Lower case character -Numeric digit -Special character')) : yup.string().notRequired().matches(/^.{60}$|(^((?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d)|(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[^a-zA-Z\d])|(?=.*?[A-Z])(?=.*?\d)(?=.*?[^a-zA-Z\d])|(?=.*?[a-z])(?=.*?\d)(?=.*?[^a-zA-Z\d])).{7,16}$)/,
			t('Password must be between 7-16 latin characters and contain at least 3 of the following. -Upper case character -Lower case character -Numeric digit -Special character'))) : null,
		confirmPassword: hasPasswordAccess === true ? (!id ? yup.string()
		.required(t('Required'))
		.oneOf([yup.ref('password'), null], t('Passwords must match')) : yup.string().test('passwords-match', t('Passwords must match'), function (value) {
			return this.parent.password === value
		})) : null,
		CustName: yup.string().required(t('Required')),
		email: yup.string().required(t('Required')).email(t('Enter a valid email')),
		country: yup.object().required(t('Required')),
		currency: yup.string().required(t('Required')),
		nationality: yup.string().required(t('Required')),
		language: yup.object().required(t('Required')),
		/* mobilePhone: yup.number().required(t('Required')).positive(t('Enter a valid phone')).integer(t('Enter a valid phone')).test(
			 'Enter a valid phone',
			 t('Enter a valid phone'),
			 function (value) {
				 return value ? String(value).length >= 8 && String(value).length <= 15 : true
			 }
		 ),*/
		mobilePhone: yup
		.string()
		.required(t('Required'))
		.matches(/^\d+$/, t('Enter a valid phone'))
		.test(
			'valid-length',
			t('Enter a valid phone'),
			function (value) {
				return value ? value.length >= 8 && value.length <= 15 : true;
			}
		),
		PhoneCode: yup.string().required(t('Required')),
		//CallingCode: yup.string().required(t('Required')).matches(/^\d+$/, t('Enter a valid code')),
		homePhone: yup
		.string()
		.matches(/^\d+$/, t('Enter a valid phone'))
		.test(
			'valid-length',
			t('Enter a valid phone'),
			function (value) {
				return value ? value.length >= 8 && value.length <= 15 : true;
			}
		),
		vat: hasVatAccess === true
			? yup
			.string()
			/* .required(t('Required'))*/
			.matches(/^\d+$/, t('Enter a valid Taxpayer identification number'))
			.test(
				'Enter a valid Taxpayer identification number',
				t('Enter a valid Taxpayer identification number'),
				function (value) {
					return value
						? (this.parent['country'].id === '14' && validateAFM(value)) ||
						this.parent['country'].id !== '14'
						: true;
				}
			)
			: null,
		RequestedAmount: yup.number().positive(t('Value must be a positive number.')).min(0, t('Value must be a positive number.')).max(99999999.99, t('Loan amount must be less than 100,000,000.')),
		isInformed: yup.boolean().oneOf([true], t("Accepting the privacy statement is mandatory to proceed.")),
		//TODO:: It will be used as soon as the change to decimal that the user has generally selected in the Portal works
		//DateFormatId: yup.string().required(t('Required')),
		//DecimalChar: yup.string().required(t('Required'))
	})
	
	const onSubmit = (values) => {
		setIsLoading(true)
		axios({
			method: 'post',
			data: {
				CustId: id,
				CustName: values.CustName,
				username: values.username,
				password: values.password,
				ErpCode: values.ErpCode,
				ErpId: values.ErpId,
				isActive: values.isActive,
				isSendEmail: values.isSendEmail,
				isInformed: values.isInformed,
				isUser: true,
				email: values.email,
				//TODO:: It will be used as soon as the change to decimal that the user has generally selected in the Portal works
				//DateFormatId: values.DateFormatId,
				//DecimalChar: values.DecimalChar,
				LangId: values.language.id,
				CurrencyId: values.currency,
				CountryId: values.country.id,
				NationalityId: values.nationality,
				OriginId: values.origin,
				VatRegNo: values.vat.toString(),
				MobilePhone: values.mobilePhone.toString(),
				HomePhone: values.homePhone.toString(),
				//CallingCode: values.CallingCode.toString(),
				CallingCode: values.PhoneCode,
				Comments: values.Comments,
				RequestedAmount: values.RequestedAmount,
				isInternal: !id ? true : null
			},
			headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
			url: !id ? `${API}/customer/store` : `${API}/customer/update`
		}).then(response => {
			enqueueSnackbar(!id ? t('Customer Stored Successfully!') : t('Profile saving completed successfully!'), {
					variant: 'success',
					onClose: () => history.push(getRouteUrl(ROUTE_PAGE_CUSTOMERS_DETAILS, {id: response.data.custid}))
				}
			)
			setIsLoading(false)
			getUserPermissions()
		}).catch((error) => {
			if (id) {
				enqueueSnackbar(handleAxiosErrors(error), {
						variant: 'error'
					}
				)
			} else {
				setError(
					handleAxiosErrors(
						error,
						error?.response?.data?.message
							? error?.response?.data?.message
							: t("The customer's details are already registered in our system")
					)
				);
			}
			setIsLoading(false)
			logoutUnauthorizedUser(error, isLoggedIn, userLogout)
		})
	}
	
	const formik = useFormik({
		initialValues: {
			isActive: id ? customer.isActive : true,
			isSendEmail: id ? customer?.isSendEmail : true,
			isInformed: false,
			isUser: customer?.isUser || true,
			ErpCode: customer?.ErpCode || '',
			Code: customer?.Code || '',
			ErpId: customer?.ErpId || '',
			CustName: customer?.CustName || '',
			username: customer?.username || '',
			password: customer?.password || '',
			confirmPassword: customer?.password || '',
			email: customer?.email || '',
			vat: customer?.vat || '',
			currency: customer?.CurrencyId ? currencies.find(f => f.id === customer.CurrencyId)?.id : currencies.length > 0 ? currencies.find(f => f.isDefault === '1')?.id : '',
			//language: customer?.LangId ? languages.find(f => f.id === customer.LangId)?.id : languages.length > 0 ? languages.find(f => f.isDefault === '1')?.id : '',
			language: customer?.LangId ? languages.find(f => f.id === customer.LangId) : languages.find(f => f.isDefault == 1),
			country: customer?.CountryId ? countries.find(f => f.id === customer.CountryId) : countries.find(f => f.isDefault == 1),
			//nationality: customer?.NationalityId ? nationalities.find(f => f.id === customer.NationalityId)?.id : nationalities.length > 0 ? nationalities[0]?.id : '',
			nationality: customer?.NationalityId ? nationalities.find(f => f.id === customer.NationalityId)?.id : nationalities.length > 0 ? nationalities.find(f => f.isDefault === '1')?.id : '',
			mobilePhone: customer?.mobilePhone || '',
			PhoneCode: customer?.CallingCode ? countries.find(f => f.id === customer.CallingCode)?.id : countries.length > 0 ? countries.find(f => f.isDefault === '1')?.id : '',
			homePhone: customer?.homePhone || '',
			Comments: customer?.Comments || '',
			RequestedAmount: customer?.RequestedAmount || 0,
			//TODO:: It will be used as soon as the change to decimal that the user has generally selected in the Portal works
			// DateFormatId: customer?.DateFormatId ? dateFormats.find(f => f.id === customer.DateFormatId)?.id : dateFormats.find(f => f.isDefault)?.id,
			//DecimalChar: customer?.DecimalChar || ',',
		},
		validationSchema: validationSchema,
		onSubmit: onSubmit,
	})
	
	const handleClickShowPassword = () => setShowPassword((show) => !show);
	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};
	
	return {
		formik,
		showPassword,
		handleClickShowPassword,
		handleMouseDownPassword,
		isLoading,
		errorMess, // New state variable for error information
		closeError: () => setError(null), // Function to close the error Dialog
	}
}

export default useCustomerForm