//@flow
import React, {useMemo, useState} from "react";
import {DataGridPremium, GridActionsCellItem} from '@mui/x-data-grid-premium';
//import {useMuiGridServerSide} from "../../../../hooks/useMuiGridServerSide";
import EditIcon from "@mui/icons-material/Edit";
import {useTranslation} from "react-i18next";
import {CUSTOMERS} from "../../locales/namespaces";
import DataGridWrapper from "../../../../components/dataGridWrapper/dataGridWrapper";
import {muiGridLocales} from "../../../../helpers/muiGridLocales";

const CalculationsHistory = () => {
    const {t} = useTranslation(CUSTOMERS)
    //const url = `customer/indexTable/data`
    const [loanCalculatorOpen, setLoanCalculatorOpen] = useState(false)

    /* const {
         isLoading,
         data,
         refreshGrid
     } = useMuiGridServerSide({
         //url,
         /!*initialPageSize: 50,*!/ //TODO:: We will see if we need it
         initialSortModel: [{name: 'id', direction: 'descending'}]
     })
 */
    const columns = useMemo(() => [
        {
            headerName: t('Actions'),
            field: 'actions',
            type: 'actions',
            minWidth: 65, flex: 1,
            getActions: (/*params: any*/) => [
                <GridActionsCellItem
                    // TODO:: ADD Link to go to Loan Edit Page
                    /*component={Link}
                    to={getRouteUrl(ROUTE_PAGE_CUSTOMERS_EDIT, {id: params.id})}*/
                    icon={<EditIcon/>}
                    label={t("Edit")}>
                </GridActionsCellItem>
            ],
        },
        {
            field: 'loanAmount',
            headerName: t('Loan Amount (€)'),
            minWidth: 150, flex: 1,
            type: 'number',
            aggregable: false,
        },
        {
            field: 'interestRate',
            headerName: t('Interest Rate'),
            minWidth: 200, flex: 1,
        },
        {
            field: 'repaymentInYears',
            headerName: t('Repayment In Years'),
            minWidth: 180, flex: 1,
        },
        {
            field: 'totalRepaymentAmount',
            headerName: t('Total Repayment Amount'),
            minWidth: 250, flex: 1,
            type: 'number',
            aggregable: false,
        },
        {
            field: 'finalInterest',
            headerName: t('Final Interest'),
            minWidth: 200, flex: 1,
        },
        {
            field: 'monthlyAllowance',
            headerName: t('Monthly Allowance'),
            minWidth: 150, flex: 1,
            tupe: 'number',
            aggregable: false,
        }
    ], [/*data,refreshGrid*/])

    return (
        /*<Box>
            <Toolbar disableGutters variant={"dense"}>

                <Box sx={{flexGrow: 1}}/>
                {/!*<Stack direction="row">
                    <Button
                        size={"small"}
                        variant={"contained"}
                        onClick={() => setLoanCalculatorOpen(true)}
                    >
                        {t('Add')}
                    </Button>
                </Stack>*!/}
            </Toolbar>
            <LoanCalculatorDialog
                open={loanCalculatorOpen}
                onClose={() => setLoanCalculatorOpen(false)}
            />
            <Divider sx={{mb: 2}} light/>*/
        <DataGridWrapper
            sx={{
                mt: 1,
                // maxWidth: '800px',
                height: '400px'
            }}
        >
            <DataGridPremium
                sx={{
                    bgcolor: 'background.paper', ".MuiDataGrid-aggregationColumnHeaderLabel": {
                        display: "none"
                    }
                }}
                columns={columns}
                density={'compact'}
                rows={[]}
                //loading={isLoading}
                localeText={muiGridLocales(t)}
                disableAggregation={true}
                disableRowGrouping={true}
            />
        </DataGridWrapper>
        /*</Box>*/
    )
}
export default CalculationsHistory;