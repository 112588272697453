import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {PAGE_LOGIN} from "../../locales/pages/namespaces";
import useUserAction from "../../hooks/user/useUserAction";
import useUserData from "../../hooks/user/useUserData";
import {useHistory} from 'react-router-dom'
import {ROUTE_PAGE_RESET_PASSWORD} from "../../routers/routes";
import {getRouteUrl} from "../../helpers/getRouteUrl";
import {LoginForm} from "../../components/loginForm/loginForm";
import * as yup from "yup";
import {useFormik} from "formik";
import {enqueueSnackbar} from "notistack";
import {handleAxiosErrors} from "../../helpers/errorHandling";
import AuthWrapper from "../../theme/mantis/sections/auth/authWrapper";
import {Grid} from "@mui/material";
import {Preferences} from '@capacitor/preferences';
import {
	BiometricAuth,
	CheckBiometryResult,
	BiometryError,
	BiometryErrorType,
	AndroidBiometryStrength
} from '@aparajita/capacitor-biometric-auth';

const Login = () => {
	const history = useHistory();
	const {t} = useTranslation(PAGE_LOGIN)
	const {userLogin, checkAPIConnectivity} = useUserAction();
	const {loading, errorLogin, errorConnectivity, alterPasswordOnLogin, isLoggedIn} = useUserData();
	const [isLoginOneTime, setIsLoginOneTime] = useState('')
	
	const onSubmit = async (values) => {
		await Preferences.set({key: 'username', value: values.username})
		await Preferences.set({key: 'password', value: values.password})
		userLogin(values)
	}
	
	const validationSchema = yup.object({
		username: yup
		.string()
		.required(t('required')),
		password: yup
		.string()
		.required(t('required')),
	})
	
	const formik = useFormik({
		initialValues: {
			username: '',
			password: '',
			rememberMe: false,
			email: '',
		},
		validationSchema: validationSchema,
		onSubmit: onSubmit,
	})
	
	useEffect(() => {
		const fetchStoredCredentials = async () => {
			const username = await Preferences.get({key: 'username'});
			//const password = await Preferences.get({key: 'password'});
			setIsLoginOneTime(username.value)
			
			if (username.value) {
				formik.setFieldValue('username', username.value)
			}
			/*if (password.value) {
				formik.setFieldValue('password', password.value)
			}*/
		};
		
		fetchStoredCredentials()
		checkAPIConnectivity()
	}, [checkAPIConnectivity])
	
	useEffect(() => {
		if (errorLogin) {
			enqueueSnackbar(handleAxiosErrors(errorLogin, t(errorLogin)), {
					variant: 'error'
				}
			)
		}
	}, [errorLogin])
	
	useEffect(() => {
		if (errorConnectivity) {
			enqueueSnackbar(handleAxiosErrors(errorConnectivity, t(errorConnectivity)), {
					variant: 'error'
				}
			)
		}
	}, [errorConnectivity])
	
	useEffect(() => {
		if (isLoggedIn && alterPasswordOnLogin) {
			history.push(getRouteUrl(ROUTE_PAGE_RESET_PASSWORD, {}, {username: formik.values.username}))
		}
	}, [alterPasswordOnLogin, isLoggedIn])
	
	// TODO::Not working. Retry after publishing native apps to stores.
	
	
	const handleBiometricLogin = async () => {
		try {
			const biometryInfo: CheckBiometryResult = await BiometricAuth.checkBiometry()
			console.log('biometryInfo: CheckBiometryResult', biometryInfo)
			
			if (biometryInfo.isAvailable) {
				console.log('biometryInfo.isAvailable', biometryInfo.isAvailable)
				await BiometricAuth.authenticate({
					reason: 'Please authenticate',
					cancelTitle: 'Cancel',
					allowDeviceCredential: true,
					iosFallbackTitle: 'Use passcode',
					androidTitle: 'Biometric login',
					androidSubtitle: 'Log in using biometric authentication',
					androidConfirmationRequired: false,
					androidBiometryStrength: AndroidBiometryStrength.weak
				})
				
				const username = await Preferences.get({key: 'username'})
				const password = await Preferences.get({key: 'password'})
				
				if (username.value && password.value) {
					formik.setFieldValue('username', username.value)
					formik.setFieldValue('password', password.value)
					userLogin({username: username.value, password: password.value})
				} else {
					enqueueSnackbar('Biometric authentication failed', {variant: 'error'});
				}
			} else {
				enqueueSnackbar('Biometry not available', {variant: 'error'})
			}
		} catch (error) {
			if (error instanceof BiometryError && error.code !== BiometryErrorType.userCancel) {
				enqueueSnackbar('Biometric authentication error: ' + error.message, {variant: 'error'})
			}
		}
	}
	
	return (
		<AuthWrapper>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<LoginForm
						formik={formik}
						loading={loading}
						handleBiometricLogin={handleBiometricLogin}
						isLoginOneTime={isLoginOneTime}
					/>
				</Grid>
			</Grid>
		</AuthWrapper>
	)
}

export default Login